import { useRef, useEffect, useCallback } from 'react';
import { useFilterDates } from './useFilterDates';
export function useFilters(events) {
    const { startDate, endDate } = useFilterDates();
    const filters = useRef({
        gradeId: null,
        disciplineIds: null,
        classIds: null,
        startDate,
        endDate
    });
    useEffect(() => {
        return events.filters.gradeIdChanged.subscribe((payload) => {
            filters.current.gradeId = payload?.newId ?? null;
        });
    }, [events.filters.gradeIdChanged]);
    useEffect(() => {
        return events.filters.disciplineIdsChanged.subscribe((payload) => {
            filters.current.disciplineIds =
                payload?.newIds?.length > 0 ? payload.newIds : null;
        });
    }, [events.filters.disciplineIdsChanged]);
    useEffect(() => {
        return events.filters.classIdsChanged.subscribe((payload) => {
            filters.current.classIds =
                payload?.newIds?.length > 0 ? payload.newIds : null;
        });
    }, [events.filters.classIdsChanged]);
    useEffect(() => {
        return events.filters.startDateChanged.subscribe((payload) => {
            filters.current.startDate = payload?.newDate;
        });
    }, [events.filters.startDateChanged]);
    useEffect(() => {
        return events.filters.endDateChanged.subscribe((payload) => {
            filters.current.endDate = payload?.newDate;
        });
    }, [events.filters.endDateChanged]);
    return useCallback(() => filters.current, []);
}
