import React from 'react';
import { AddOrEditActivityPopupContextProvider } from '../common/addOrEditActivity/context';
import Methodology from '../common/addOrEditActivity/components/Methodology';
import ActivityType from '../common/addOrEditActivity/components/ActivityType/ActivityType';
import ClassLessonAndDateList from '../common/addOrEditActivity/components/ClassLessonAndDateList';
import AddActivityButton from './components/AddActivityButton';
import { StyledTitle, StyledCancelButton } from '../common/styles';
import { StyledButtonsWrapper, StyledContentWrapper, StyledFirstColumnWrapper, StyledPopup, StyledSecondColumnWrapper } from '../common/addOrEditActivity/styles';
const AddActivityPopup = (props) => {
    return (React.createElement(StyledPopup, { isFullScreen: true, canBeDisplayed: props.canBeDisplayed, handleExternalClick: props.handleExternalClick },
        React.createElement(AddOrEditActivityPopupContextProvider, { planning: props.planning, disciplines: props.disciplines, classroomGroups: props.classroomGroups },
            React.createElement(StyledTitle, null, "Adicionar Atividade"),
            React.createElement(StyledContentWrapper, null,
                React.createElement(StyledFirstColumnWrapper, null,
                    React.createElement(ActivityType, null),
                    React.createElement(ClassLessonAndDateList, null)),
                React.createElement(StyledSecondColumnWrapper, null,
                    React.createElement(Methodology, null))),
            React.createElement(StyledButtonsWrapper, null,
                React.createElement(StyledCancelButton, { onClick: props.handleCancelButtonClicked }, "Cancelar"),
                React.createElement(AddActivityButton, { planning: props.planning, handleAddSuccess: props.handleActivityAdded, newActivityIsUnforeseen: props.newActivityIsUnforeseen })))));
};
export default AddActivityPopup;
