import styled from 'styled-components';
export const StyledWrapper = styled.div `
  padding: 4px 0;
  z-index: 2;

  position: absolute;
  top: calc(100% + 5px);
  left: 0;

  overflow-y: auto;

  width: 100%;
  max-height: 200px;
  height: fit-content;

  display: flex;
  flex-direction: column;

  background-color: white;

  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
`;
export const StyledNoOptionsMessage = styled.span `
  font-size: 12px;
  font-weight: 700;
  font-style: normal;

  margin-left: 8px;

  color: ${(props) => props.theme.colors.gray700};
`;
