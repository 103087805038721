import React from 'react';
import { CurriculumComponent, Class, Classroom, Date } from './Columns';
import { Container, Header, Body } from './styles';
export const Table = ({ content }) => {
    return (React.createElement(Container, null,
        React.createElement(Header, null,
            React.createElement("tr", null,
                React.createElement("th", null, "Componente Curricular"),
                React.createElement("th", null, "Turma"),
                React.createElement("th", null, "Aula"),
                React.createElement("th", null, "Data"))),
        React.createElement(Body, null, content?.map((discipline) => discipline.classes.map((clasz, classIndex) => (React.createElement("tr", { key: `${discipline.id}-${classIndex}` },
            classIndex === 0 ? (React.createElement(CurriculumComponent, { discipline: discipline })) : null,
            React.createElement(Class, { class: clasz }),
            React.createElement(Classroom, { classroom: clasz.classroom }),
            React.createElement(Date, { classroom: clasz.classroom }))))))));
};
