import React, { useMemo } from 'react';
import { createFollowUpPageStore } from './store';
import { FollowUpPageContext } from './context';
import { PortalsContextProvider } from '../common/contexts/portals';
import { MainTableFocusContextProvider } from '../common/contexts/mainTableFocus';
import Header from './components/Header';
import Body from './components/Body';
import Subtitle from './components/Subtitle';
import { useFetchCommonData } from './hooks/useFetchCommonData';
import { StyledPageWrapper } from '../common/components/PageRelated/styles';
const FollowUp = () => {
    const storeApi = useMemo(() => {
        return createFollowUpPageStore();
    }, []);
    useFetchCommonData(storeApi);
    return (React.createElement(FollowUpPageContext.Provider, { value: storeApi },
        React.createElement(PortalsContextProvider, null,
            React.createElement(MainTableFocusContextProvider, null,
                React.createElement(StyledPageWrapper, null,
                    React.createElement(Header, null),
                    React.createElement(Body, null),
                    React.createElement(Subtitle, null))))));
};
export default FollowUp;
