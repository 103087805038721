import { handleApiCallError } from '../common';
import { getDidaticSequenceById, updateDidaticSequence } from '../didaticSequence';
import { addClassroomAndDidacticSequence, deleteClassroomAndSequence } from '../classroomAndDidaticSequence';
function addNewClassrooms(params) {
    return params.newClassrooms.map((classroom) => {
        const classroomAndSequence = params.oldClassrooms.find((c) => c.classroom?.id === classroom.id);
        return addClassroomAndDidacticSequence({
            sequenceId: params.id,
            classroomId: classroom?.id,
            abortSignal: params.abortSignal,
            foreseen: classroomAndSequence?.foreseen ?? true,
            executed: classroomAndSequence?.wasExecuted ?? false,
            executedClassroomId: classroomAndSequence?.executedClassroom?.id ?? null
        });
    });
}
function deleteOldClassroomsAndSequences(params) {
    return params.oldClassrooms.map((item) => {
        return deleteClassroomAndSequence({
            abortSignal: params.abortSignal,
            classroomAndSequenceId: item.id
        });
    });
}
export async function updateDidacticSequenceAndItsClassrooms(params) {
    try {
        await Promise.all(deleteOldClassroomsAndSequences(params));
        await Promise.all(addNewClassrooms(params));
        await updateDidaticSequence({
            id: params.id,
            newIndex: params.newIndex,
            isDeleted: params.isDeleted,
            abortSignal: params.abortSignal,
            description: params.description,
            impactTheMDC: params.impactTheMDC,
            evaluationTypeId: params.evaluationTypeId
        });
        return getDidaticSequenceById({
            id: params.id,
            abortSignal: params.abortSignal
        });
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
