import { useGroupContext } from '../../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
export function useMoveActivity() {
    const moveActivity = useGroupContext((slice) => slice.sequenceOperations.move);
    function moveActivityUp(sequence) {
        return new Promise((resolve) => {
            moveActivity(sequence.id, 'up');
            resolve();
        });
    }
    function moveActivityDown(sequence) {
        return new Promise((resolve) => {
            moveActivity(sequence.id, 'down');
            resolve();
        });
    }
    return { moveActivityUp, moveActivityDown };
}
