import LaunchReportDisciplineClass from './LaunchReportDisciplineClass';
export default class LaunchReportDiscipline {
    id;
    name;
    classes;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new LaunchReportDiscipline();
        instance.id = json.Id;
        instance.name = json.Name;
        instance.classes = Array.isArray(json.Classes)
            ? json.Classes.map((c) => LaunchReportDisciplineClass.fromJson(c))
            : [];
        return instance;
    }
}
