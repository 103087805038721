import { updatePlanning } from '../../../../../../../../../services/pedagogicalPlanning';
export function updateAnalystNotes(planning, notes) {
    return updatePlanning({
        analystNotes: notes,
        id: planning.id,
        order: planning.order,
        stageId: planning.stage?.id,
        statusId: planning.status?.id,
        group: planning.associatedGroup,
        observedLags: planning.observedLags,
        teacherNotes: planning.teacherNotes,
        supervisorNotes: planning.supervisorNotes
    });
}
