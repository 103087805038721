import styled from 'styled-components';
import MenuActionsPopup from '../../../../../../../../common/components/Popups/MenuActionsPopup';
import { StyledBasicButton } from '../../../../../../../../common/components/Popups/common/styles';
export const MenuButton = styled(StyledBasicButton) `
  display: flex;
  height: 32px;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 6px;
  color: ${(props) => props.theme.colors.blackAlpha500};
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  background: ${({ theme }) => theme.colors.white};
`;
export const MenuActionsPopupStyled = styled(MenuActionsPopup) `
  position: absolute;
  top: 90%;
  right: 20px;

  @media (max-width: 950px) {
    top: 99%;
    right: 50%;
    transform: translateX(50%);
  }
`;
