import React, { useState } from 'react';

import { Pagination } from '@campus/components';
import { useContent } from '@campus/discipline';

import { useQuestionReview } from '../../context/QuestionReviewContext';
import {
  Header,
  Number,
  QuestionContainer,
  QuestionContent,
  QuestionLabel,
  QuestionLine,
  QuestionTitle,
  QuestionOpen,
  QuestionOpenButton
} from './styles';

const QuestionHeader: React.FC = () => {
  const {
    questionsQuantity,
    questionNumber,
    question,
    goNextQuestion,
    goPrevQuestion
  } = useQuestionReview();

  const { questionsHtml } = useContent();
  const [questionOpen, setQuestionOpen] = useState(false);

  const questionHtml = questionsHtml.find(
    (q) => q.id === question.contentItemId
  );

  return (
    <div>
      <Header>
        <QuestionLabel>
          <Number>Questão #{questionNumber}</Number>
          <QuestionContainer>
            <QuestionContent
              shortQuestion={questionOpen || questionHtml?.html?.length <= 160}
            >
              {questionHtml && (
                <QuestionTitle
                  dangerouslySetInnerHTML={{ __html: questionHtml.html }}
                ></QuestionTitle>
              )}
            </QuestionContent>
          </QuestionContainer>
        </QuestionLabel>
        {questionsQuantity > 1 && (
          <Pagination>
            <Pagination.Prev onClick={goPrevQuestion} />
            <Pagination.Next onClick={goNextQuestion} />
          </Pagination>
        )}
      </Header>
      {questionHtml && (
        <QuestionOpen hidden={questionHtml?.html?.length <= 160}>
          <QuestionLine ml={-14} />
          <QuestionOpenButton
            role="button"
            onClick={() => {
              setQuestionOpen(!questionOpen);
            }}
          >
            {questionOpen ? 'REDUZIR' : 'AMPLIAR'}
          </QuestionOpenButton>
          <QuestionLine mr={-14} />
        </QuestionOpen>
      )}
    </div>
  );
};

export default QuestionHeader;
