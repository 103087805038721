import { useState } from 'react';
import { RequestStatus } from '../../../../../../../../../types/dataFetching';
import { times } from '../../../../../../../../../constants';
import { useIsMounted } from '../../../../../../../../../hooks';
import { addStagesAndDeleteAreaSkillAndStages } from '../../../../../../../../../../../services/agregators/areaSkillAndStage';
export function useUpdateAreaSkillStages() {
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const { isMounted } = useIsMounted();
    function resetStatusAfterDelay() {
        setTimeout(() => {
            if (isMounted()) {
                setRequestStatus(RequestStatus.NotStarted);
            }
        }, times.toResetARequestStatus);
    }
    function stagesThatShouldBeAdded(params) {
        return params.allStages.filter((s) => {
            const isSelected = params.selectedStages.some((ss) => {
                return ss.id === s.id;
            });
            const skillStages = params.areaSkill.unitStages;
            const isNotInSkill = skillStages?.every((us) => {
                return s.id !== us.stage?.id;
            });
            return isSelected && isNotInSkill;
        });
    }
    function areaSkillAndStagesThatShouldBeDeleted(params) {
        const skillStages = params.areaSkill?.unitStages ?? [];
        return skillStages.filter((s) => {
            return params.selectedStages.every((ss) => {
                return ss?.id !== s.stage?.id;
            });
        });
    }
    function update(params) {
        return new Promise((resolve, reject) => {
            const stagesToAdd = stagesThatShouldBeAdded(params);
            const areaSkillAndStagesToDelete = areaSkillAndStagesThatShouldBeDeleted(params);
            const thereAreNoStages = stagesToAdd.length === 0;
            const thereAreNoAreaSkillAndStages = areaSkillAndStagesToDelete.length === 0;
            if (thereAreNoStages && thereAreNoAreaSkillAndStages) {
                resolve({ skillWasUpdated: false });
            }
            setRequestStatus(RequestStatus.Loading);
            addStagesAndDeleteAreaSkillAndStages({
                stagesToAdd,
                areaSkillAndStagesToDelete,
                areaSkillId: params.areaSkill?.id
            })
                .then(() => {
                setRequestStatus(RequestStatus.Succeeded);
                resolve({ skillWasUpdated: true });
            })
                .catch(() => {
                setRequestStatus(RequestStatus.Failed);
                reject();
            })
                .finally(resetStatusAfterDelay);
        });
    }
    return { update, requestStatus };
}
