import React from 'react';
import Square from './components/Square';
import { calculateDistanceFromTop } from './helpers/distances';
import { StyledLine, StyledWrapper } from './styles';
const squareWidth = 2;
const Line = (props) => {
    if (!props.groupInfos)
        return null;
    if (!props.activityInfos)
        return null;
    if (props.width <= 0)
        return null;
    if (props.distanceFromLeftOfParent < 0)
        return null;
    const top = calculateDistanceFromTop(props.groupInfos, props.activityInfos);
    if (top < 0)
        return null;
    const { width, distanceFromLeftOfParent: left } = props;
    const classBoxRect = props.activityInfos?.getClassBoxBoundingClientRect();
    const height = classBoxRect?.height ?? 0;
    const lineY = height / 2;
    const lineX1 = squareWidth * 2 + 1;
    const lineX2 = width - lineX1;
    return (React.createElement(StyledWrapper, { "$top": top, "$left": left, "$width": width, "$height": height },
        React.createElement(Square, { size: squareWidth, x: 1, y: lineY }),
        React.createElement(StyledLine, { x1: lineX1, x2: lineX2, y1: lineY, y2: lineY }),
        React.createElement(Square, { size: squareWidth, y: lineY, x: lineX2 })));
};
export default Line;
