import React, { useState } from 'react';
import { toast } from '@campus/components';
import { getAlertMessage } from '../../../../../../../../../../services/launchReport';
import { useLaunchReportContext } from '../../../../../../../../context';
import { Tooltip } from '../../../../../Tooltip';
import { Container, Button } from './styles';
export const CopyAlertMessageButton = ({ teacherId }) => {
    const [isGeneratingMessage, setIsGeneratingMessage] = useState(false);
    const { managerId, getFilters } = useLaunchReportContext();
    async function onClick() {
        if (isGeneratingMessage)
            return;
        try {
            setIsGeneratingMessage(true);
            const filters = getFilters();
            const response = await getAlertMessage({
                ...filters,
                teacherId,
                managerId
            });
            if (response.error) {
                toast.error('Falha ao copiar mensagem');
                return;
            }
            await navigator.clipboard.writeText(response.data);
            toast.info('Mensagem copiada');
        }
        catch {
            toast.error('Falha ao copiar mensagem');
        }
        finally {
            setIsGeneratingMessage(false);
        }
    }
    return (React.createElement(Container, { "$isGeneratingMessage": isGeneratingMessage },
        React.createElement(Button, { onClick: onClick, disabled: isGeneratingMessage }),
        React.createElement(Tooltip, null, isGeneratingMessage
            ? 'Gerando mensagem...'
            : 'Copiar mensagem de alerta')));
};
