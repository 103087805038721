import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { usePortalsContext } from '../context';
export function usePortal(wrapper) {
    const { addPortal, removePortal } = usePortalsContext();
    const portalIdRef = useRef();
    function create(component) {
        const portal = createPortal(component, wrapper);
        portalIdRef.current = addPortal(portal);
    }
    function destroy(params) {
        if (params?.afterMillis > 0) {
            const id = portalIdRef.current;
            setTimeout(() => removePortal(id), params.afterMillis);
        }
        else {
            removePortal(portalIdRef.current);
        }
    }
    return { create, destroy };
}
