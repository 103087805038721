import { applyFilters } from '../../../../../common/stores/slices/mainTable/filters';
export function updateSlice(store, updates) {
    const filters = { ...store.mainTable.filters, ...updates };
    const filteredContent = applyFilters(store.mainTable.nonFilteredContent, filters);
    return {
        mainTable: {
            ...store.mainTable,
            filters,
            filteredContent
        }
    };
}
