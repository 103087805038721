export function convertClassesAndInfos(classesAndInfos) {
    const result = [];
    for (const infos of classesAndInfos.values()) {
        if (!infos.isChecked)
            continue;
        result.push({
            executed: true,
            executedClassroomId: infos.selectedClassroom?.id,
            classroomAndSequence: infos.associatedClassroomAndSequence
        });
    }
    return result;
}
export function shouldButtonBeDisabled(classesAndInfos) {
    if (!classesAndInfos)
        return true;
    if (classesAndInfos.size === 0)
        return true;
    for (const infos of classesAndInfos.values()) {
        if (infos.isChecked)
            return false;
    }
    return true;
}
