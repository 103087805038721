import styled from 'styled-components';
export const Container = styled.header.attrs({
    className: 'full-width subgrid'
}) `
  min-height: 95px;

  padding: 11px 0;

  align-content: end;

  background: ${({ theme }) => theme.colors.CSA.orange};
`;
export const Title = styled.h1.attrs({
    className: 'content'
}) `
  margin: 0;

  font-size: 24px;
  font-weight: 700;

  line-height: 110%;

  color: ${({ theme }) => theme.colors.white};
`;
export const SubTitle = styled.h2.attrs({
    className: 'content'
}) `
  margin: 0;

  font-size: 12px;
  font-weight: 500;

  line-height: 16px;

  color: ${({ theme }) => theme.colors.white};
`;
