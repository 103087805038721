import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { useFollowUpMainTableContext } from '../../../../../../../../../context';
import Rectangle from './components/Rectangle';
import { getActivityRelatedRectanglesInfos } from './helpers/rectangles';
const Rectangles = (props) => {
    const theme = useTheme();
    const activitiesAndInfos = useFollowUpMainTableContext((store) => store.activitiesAndInfos);
    const [rectangles, setRectangles] = useState([]);
    const colors = useMemo(() => ({
        white: 'white',
        blue: theme.colors.blue1,
        orange: theme.colors.orange1
    }), [theme]);
    useEffect(() => {
        if (!props.groupInfos)
            return;
        if (!props.group?.didacticSequence?.length)
            return;
        setRectangles(props.group.didacticSequence.flatMap((activity) => {
            const activityInfos = activitiesAndInfos.get(activity.id);
            if (!activityInfos)
                return [];
            const rectanglesInfos = getActivityRelatedRectanglesInfos(activity, colors);
            return rectanglesInfos.map((infos, index) => (React.createElement(Rectangle, { key: activity.id + index, groupInfos: props.groupInfos, activityInfos: activityInfos, startDate: infos.startDate, circleColor: infos.circleColor, backgroundColor: infos.backgroundColor, border: {
                    color: infos.borderColor,
                    dashed: infos.borderShouldBeDashed
                }, parentElement: props.parentElement })));
        }));
    }, [
        props.groupInfos,
        props.group?.didacticSequence,
        props.parentElement,
        colors,
        activitiesAndInfos
    ]);
    return React.createElement(React.Fragment, null, rectangles);
};
export default Rectangles;
