import styled from 'styled-components';
import { StyledColumnWrapper } from '../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/common/styles';
export const StyledWrapper = styled(StyledColumnWrapper) `
  display: flex;
  flex-direction: column;
`;
export const StyledActivityButtonWrapper = styled.div `
  padding: 7px 8px;
`;
export const StyledEmptyButtonSpace = styled.div `
  height: 20px;
`;
