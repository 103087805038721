import React from 'react';
import { widths as baseWidths } from '../common/sizes';
import { createHeaders as baseCreateHeaders, createRow as baseCreateRow } from '../common/components/Body/components/Table/creators';
import Date from './components/Columns/Date';
import Skills from './components/Columns/Skills';
const widths = baseWidths.classDiaryColumns;
const headerCreators = {
    date: () => ({
        label: 'Data',
        width: widths.date
    }),
    skill: () => ({
        label: 'Habilidade',
        width: widths.skill
    })
};
const columnCreators = {
    date: (params) => ({
        ...headerCreators.date(params),
        content: React.createElement(Date, { date: params.date })
    }),
    skill: (params) => ({
        ...headerCreators.skill(params),
        content: React.createElement(Skills, { areasSkills: params.areasSkills })
    })
};
export function createHeaders() {
    const params = {
        date: null,
        areasSkills: []
    };
    return baseCreateHeaders(headerCreators, params);
}
export function createRow(params) {
    return baseCreateRow(columnCreators, params);
}
