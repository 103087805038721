import { HeightRule, TableRow } from 'docx';
import Skill from './Columns/Skill';
import LearningObjectives from './Columns/LearningObjectives';
import KnowledgeObjects from './Columns/KnowledgeObjects';
import { heights } from '../../../../common/sizesDocx';
export default function Row(props) {
    const heightValue = props.isTheLastRowInTheTable
        ? heights.table.lastRow
        : heights.table.normalRow;
    return new TableRow({
        height: { value: heightValue, rule: HeightRule.ATLEAST },
        children: [
            Skill(props?.areaSkill),
            LearningObjectives(props?.areaSkill?.learningObjectives),
            KnowledgeObjects(props?.areaSkill?.knowledgeObjects)
        ]
    });
}
