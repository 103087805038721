import React from 'react';
import styled from 'styled-components';

import { ContentEditor, TextOverlay } from '@campus/components';

type AnswerTextProps = {
  answerText?: string;
  color?: string;
  isOpenQuestion?: boolean;
};

const TextContainer = styled.div<{ $color?: string; $withoutAnswer: boolean }>`
  width: 100%;
  max-height: 1rem;
  color: ${(props) => props.$color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => (props.$withoutAnswer ? '' : 'text-align: center;')}
`;

const AnswerText: React.FC<AnswerTextProps> = ({ answerText, color, isOpenQuestion }) => {
  if (!answerText) {
    return (
      <TextContainer $withoutAnswer $color="#666666">
        Sem resposta
      </TextContainer>
    );
  }

  return (
    <>
      {isOpenQuestion ?
        <TextOverlay tooltip={<ContentEditor readOnly content={answerText} />}>
          <TextContainer $withoutAnswer={false} $color={color}>
            ...
          </TextContainer>
        </TextOverlay>

        :
        <TextContainer $withoutAnswer={false} $color={color}>{answerText}</TextContainer>
      }
    </>

  );
};

export default AnswerText;
