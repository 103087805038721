import { useMemo } from 'react';
import { createOptionFrom, createSelectOptionsFrom } from './helpers';
const defaultOption = {
    value: null,
    label: '---'
};
export function useOptionsManager({ selectedClassroom, classroomGroups }) {
    const selectedOption = useMemo(() => {
        return Number.isInteger(selectedClassroom?.number)
            ? createOptionFrom(selectedClassroom.number)
            : defaultOption;
    }, [selectedClassroom]);
    const options = useMemo(() => {
        return createSelectOptionsFrom(classroomGroups);
    }, [classroomGroups]);
    return { options, selectedOption };
}
