import { useCallback, useEffect, useRef } from 'react';
import { useLaunchReportContext } from '../../../context';
export function useSelections() {
    const { events } = useLaunchReportContext();
    const allTeachersAreSelected = useRef(false);
    const idsOfAllTeachers = useRef([]);
    const selectedTeacherIds = useRef([]);
    useEffect(() => {
        return events.table.dataFetched.subscribe((payload) => {
            if (!payload)
                return;
            idsOfAllTeachers.current = payload.idsOfAllTeachers;
            selectedTeacherIds.current = selectedTeacherIds.current.filter((id) => {
                return idsOfAllTeachers.current.includes(id);
            });
        });
    }, [events.table.dataFetched]);
    useEffect(() => {
        return events.table.dataFetchFailed.subscribe(() => {
            idsOfAllTeachers.current = [];
            selectedTeacherIds.current = [];
        });
    }, [events.table.dataFetchFailed]);
    useEffect(() => {
        return events.table.selectAllChanged.subscribe((payload) => {
            if (!payload)
                return;
            allTeachersAreSelected.current = payload.isChecked;
            selectedTeacherIds.current = payload.isChecked
                ? idsOfAllTeachers.current.slice(0)
                : [];
        });
    }, [events.table.selectAllChanged]);
    useEffect(() => {
        return events.table.teacherSelectionChanged.subscribe((payload) => {
            if (!payload)
                return;
            if (payload.isSelected) {
                selectedTeacherIds.current.push(payload.teacherId);
            }
            else {
                const index = selectedTeacherIds.current.indexOf(payload.teacherId);
                if (index !== -1)
                    selectedTeacherIds.current.splice(index, 1);
            }
            allTeachersAreSelected.current =
                idsOfAllTeachers.current.length > 0 &&
                    selectedTeacherIds.current.length === idsOfAllTeachers.current.length;
        });
    }, [events.table.teacherSelectionChanged]);
    useEffect(() => {
        return events.filters.someFilterChanged.subscribe(() => {
            idsOfAllTeachers.current = [];
            selectedTeacherIds.current = [];
            allTeachersAreSelected.current = false;
        });
    }, [events.filters.someFilterChanged]);
    const getTotalNumberOfTeachers = useCallback(() => {
        return idsOfAllTeachers.current.length;
    }, []);
    const getNumberOfSelectedTeachers = useCallback(() => {
        return selectedTeacherIds.current.length;
    }, []);
    const getSelectedTeacherIds = useCallback(() => {
        return selectedTeacherIds.current;
    }, []);
    const areAllTeachersSelected = useCallback(() => {
        return allTeachersAreSelected.current;
    }, []);
    const isTeacherSelected = useCallback((teacherId) => {
        return (allTeachersAreSelected.current ||
            selectedTeacherIds.current.includes(teacherId));
    }, []);
    return {
        getTotalNumberOfTeachers,
        getNumberOfSelectedTeachers,
        getSelectedTeacherIds,
        areAllTeachersSelected,
        isTeacherSelected
    };
}
