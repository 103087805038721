import { handleApiCallError } from '../common';
import { getAreaSkillById } from '../areaSkill';
import { addPlanningObject } from '../pedagogicalPlanningObject';
import { addPlanningObjective } from '../pedagogicalPlanningObjective';
import { addPlanning, getPlanningById } from '../pedagogicalPlanning';
function addObjectives(areaSkill, newPlanningId, abortSignal) {
    return areaSkill.learningObjectives.map((learningObjective) => {
        return addPlanningObjective({
            abortSignal,
            programmed: false,
            planningId: newPlanningId,
            learningObjectiveId: learningObjective.id
        });
    });
}
function addObjects(areaSkill, newPlanningId, abortSignal) {
    return areaSkill.knowledgeObjects.map((knowledgeObject) => {
        return addPlanningObject({
            abortSignal,
            programmed: false,
            planningId: newPlanningId,
            knowledgeObjectId: knowledgeObject.id
        });
    });
}
export async function addAreaSkillAndRelated(params) {
    try {
        const getSkillResponse = await getAreaSkillById({
            id: params.areaSkill.id,
            abortSignal: params.abortSignal
        });
        const addPlanningResponse = await addPlanning({
            group: params.group,
            order: params.order,
            gradeId: params.gradeId,
            statusId: params.statusId,
            teacherId: params.teacherId,
            segmentId: params.segmentId,
            abortSignal: params.abortSignal,
            disciplineId: params.disciplineId,
            areaSkillId: params.areaSkill.id,
            analystNotes: params.analystNotes,
            observedLags: params.observedLags,
            teacherNotes: params.teacherNotes,
            supervisorNotes: params.supervisorNotes
        });
        const addObjectivesPromises = addObjectives(getSkillResponse.data, addPlanningResponse.data.id, params.abortSignal);
        const addObjectsPromises = addObjects(getSkillResponse.data, addPlanningResponse.data.id, params.abortSignal);
        await Promise.all([...addObjectsPromises, ...addObjectivesPromises]);
        return getPlanningById({
            abortSignal: params.abortSignal,
            id: addPlanningResponse.data.id
        });
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
