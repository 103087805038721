import styled from 'styled-components';
import PopupBox from '../PopupBox';
export const StyledPopup = styled(PopupBox) `
  width: min(860px, 80dvw);
  height: min(710px, 90dvh);

  position: relative;

  display: flex;
  flex-direction: column;

  border-radius: 4px;
  background: linear-gradient(180deg, #f8f8f8 69.15%, #eeeeee 100%);
`;
export const StyledTitleWrapper = styled.div `
  padding: 27px 30px 13px 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;
