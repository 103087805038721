import styled from 'styled-components';
import { Image } from '@campus/components';

export const Content = styled.div`
  display: flex;
`;

export const Tile = styled.div`
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    height: 48px;
    width: 48px;
  }

  span {
    font-size: 12px;
    color: ${(props) => props.theme.colors.gray600};
  }

  p {
    margin: 0;
    color: ${(props) => props.theme.colors.primary};
    font-weight: ${(props) => props.theme.weight.semiBold};
  }
`;

export const Answer = styled.div`
  padding: 10px 4px;
  font-family: 'Gilroy';
  font-style: normal;
  color: ${(props) => props.theme.colors.gray600};
  font-weight: ${(props) => props.theme.weight.semiBold};
  font-size: 14px;
  line-height: 143%;
`;

export const ProfileImage = styled(Image)<{ $hasImage: boolean }>`
  height: 48px;
  width: 48px;
  ${(props) => (props.$hasImage ? '' : 'background: #E6E6E6;padding: 3px;')}
`;

export const HTMLReader = styled.div`
  & b,
  strong {
    font-weight: ${(props) => props.theme.weight.bold};
  }

  & p:last-child {
    margin-bottom: 0;
  }
`;

export const LinkButton = styled.a`
  padding: 10px 4px;
`;

export const StyledSpan = styled.span`
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: ${(props) => props.theme.weight.regular};
  font-size: 12px;
  line-height: 120%;
  color: ${(props) => props.theme.colors.gray600};
`;

export const StyledStudentName = styled.p`
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: ${(props) => props.theme.weight.bold};
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.blue1};
`;
