import { useCallback, useEffect, useRef } from 'react';
export function useIsMounted() {
    const isMountedRef = useRef(true);
    const isMounted = useCallback(() => {
        return isMountedRef.current;
    }, []);
    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    });
    return { isMounted };
}
