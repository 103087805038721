import styled from 'styled-components';
export const StyledWrapper = styled.header `
  width: 100%;

  display: flex;
  flex-direction: column;

  padding: 43px 0 11px 21px;

  background: rgba(47, 128, 237, 1);

  @media (max-height: 840px) {
    padding-top: 30px;
  }

  @media (max-height: 600px) {
    padding-top: 20px;
  }
`;
export const StyledTitleAndSelectorWrapper = styled.div `
  display: flex;
  column-gap: 8px;
  align-items: center;
`;
export const StyledTitle = styled.h1 `
  margin: 0;

  font-style: normal;
  font-weight: 600;
  font-size: 24px;

  line-height: 26px;

  color: rgba(255, 255, 255, 1);

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 20px;
  }
`;
export const StyledHeaderSubtitle = styled.h5 `
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 10px;
  }
`;
