import React from 'react';

import { StatusIcon } from '@campus/commons';

import StudentAnswerReport from 'models/StudentAnswerReport';

import TriedPopover from './components/TriedPopover';

import { StatusContainer, TriedPopoverButton } from './styles';

type QuestionStatusProps = {
  containerRef: React.MutableRefObject<any>;

  data: StudentAnswerReport;
};

const QuestionStatus: React.FC<QuestionStatusProps> = ({
  containerRef,
  data
}) => {
  return (
    <TriedPopover containerRef={containerRef} data={data}>
      {({ ref, onClick }) => (
        <StatusContainer ref={ref}>
          <TriedPopoverButton onClick={onClick} aria-label="Popover Tentativas">
            <StatusIcon
              status={data.state}
              statusName={
                data.multiAnswers
                  ? `${data.stateText} Atividade com mais de uma realização | envio pelo(a) estudante.`
                  : data.stateText
              }
              statusTitle={data.stateTitle}
              stateDate={data.stateDate}
              attention={data.multiAnswers}
            />
          </TriedPopoverButton>
        </StatusContainer>
      )}
    </TriedPopover>
  );
};

export default QuestionStatus;
