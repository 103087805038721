import React from 'react';
import Checkbox from '../../../common/objectsAndObjectives/components/Checkbox';
import Description from '../../../common/objectsAndObjectives/components/Description';
import { useUpdate } from './hooks/useUpdate';
import { useCheckbox } from '../../../common/objectsAndObjectives/hooks/useCheckbox';
import { renderMessage } from '../../../common/objectsAndObjectives/messages';
import { StyledWrapper } from './styles';
const Object = (props) => {
    const knowledgeObject = props.object?.knowledgeObject;
    const checkbox = useCheckbox({
        readOnly: props.readOnly,
        planning: props.planning,
        checkedByDefault: props.object?.wasProgrammed ?? false
    });
    const updates = useUpdate({
        ...props,
        handleUpdateFailed: checkbox.handleUpdateFailed
    });
    function onToggle() {
        if (props.readOnly)
            return;
        checkbox.toggle();
        updates.onCheckboxToggle({
            isCheckboxNowChecked: checkbox.isChecked()
        });
    }
    return (React.createElement(StyledWrapper, null,
        React.createElement(Checkbox, { color: checkbox.color, disabled: checkbox.isDisabled, checked: checkbox.isChecked(), onClick: onToggle }),
        React.createElement("div", null,
            React.createElement(Description, { disabled: checkbox.isDisabled, content: knowledgeObject?.description, onClick: onToggle }, knowledgeObject.description),
            renderMessage(updates.requestStatus))));
};
export default Object;
