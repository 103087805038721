import React, { useContext, useEffect, useMemo, useState } from 'react';

import { SelectOption, toast } from '@campus/components';
import { QuestionType, useContextManager } from '@campus/commons';
import { useContent } from '@campus/discipline';

import StudentAnswerReport from 'models/StudentAnswerReport';

import {
  changeAnswerScore,
  launchStudentsZero,
  resetStudentGrades
} from 'services/answers';
import { useActivityReport } from './ActivityReportContext';

export type LaunchGradeForm = {
  question: SelectOption<string>;
  score: string;
};

type ReportActionsProps = {
  loading: boolean;
  showGradeModal: boolean;
  allowedQuestions: LaunchGradeForm['question'][];
  hasSelectedStudents: boolean;
  setSelectedStudents: (rows: StudentAnswerReport[]) => void;
  zeroGrades: () => void;
  resetGrades: () => void;
  launchGrades: (data: LaunchGradeForm) => void;
  setShowGradeModal: (show: boolean) => void;
  selectedStudents: StudentAnswerReport[];
};

const ReportActionsContext = React.createContext<ReportActionsProps>(null);

export const useReportActions = () => useContext(ReportActionsContext);

export const ReportActionsProvider: React.FC = ({ children }) => {
  const { contentId } = useContent();
  const { classroom } = useContextManager();
  const [loading, setLoading] = useState(false);

  const { updateStudents, questions, students } = useActivityReport();

  const [showGradeModal, setShowGradeModal] = useState<boolean>(false);
  const [selectedStudents, setSelectedStudents] = useState<
    StudentAnswerReport[]
  >([]);

  const zeroGrades = async () => {
    setLoading(true);
    const studentsIds = students.map((s) => s.id);
    const response = await launchStudentsZero(contentId, studentsIds);

    if (response.data) {
      setShowGradeModal(false);
      updateStudents(response.data);
    } else if (response.error) {
      toast.error(response.error.message);
    }

    setLoading(false);
  };

  const launchGrades = async (data: LaunchGradeForm) => {
    setLoading(true);
    const question = data.question.value;

    const response = await changeAnswerScore(contentId, {
      score: parseFloat(data.score),
      studentId: selectedStudents.map((s) => s.student.id),
      contentItemId: question
    });

    if (response.data) {
      setShowGradeModal(false);
      updateStudents(response.data);
    } else if (response.error) {
      toast.error(response.error.message);
    }

    setLoading(false);
  };

  const resetGrades = async () => {
    setLoading(true);

    const response = await resetStudentGrades(contentId, {
      classId: classroom.id,
      studentsIds: selectedStudents.map((s) => s.student.id)
    });

    if (response.data) {
      setShowGradeModal(false);
      updateStudents(response.data);
    } else if (response.error) {
      toast.error(response.error.message);
    }

    setLoading(false);
  };

  const allowedQuestions = useMemo(() => {
    const allowedCorrection = [QuestionType.OPEN, QuestionType.ATTACHMENT];

    return questions
      .filter((q) => allowedCorrection.includes(q?.questionType))
      .map((q) => ({ value: q.contentItemId, label: q.title }));
  }, [questions]);

  useEffect(() => {
    const allowedCorrection = [QuestionType.OPEN, QuestionType.ATTACHMENT];
    if (showGradeModal) {
      const hasAllowedQuestions = !!questions.filter((q) =>
        allowedCorrection.includes(q?.questionType)
      ).length;
      if (!hasAllowedQuestions) {
        setShowGradeModal(false);
        toast.warning(
          'Essa funcionalidade é aplicada apenas quando existe questões abertas na trilha.'
        );
      }
    }
  }, [questions, showGradeModal]);

  return (
    <ReportActionsContext.Provider
      value={{
        loading,
        showGradeModal,
        allowedQuestions,
        zeroGrades,
        resetGrades,
        launchGrades,
        setShowGradeModal,
        setSelectedStudents,
        hasSelectedStudents: selectedStudents.length > 0,
        selectedStudents
      }}
    >
      {children}
    </ReportActionsContext.Provider>
  );
};
