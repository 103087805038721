import styled from 'styled-components';
export const StyledWrapper = styled.div `
  position: relative;

  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;

  background-color: ${(props) => props.$backgroundColor};

  border-radius: 2px;
  border-color: ${(props) => props.$borderColor};
  border-width: ${(props) => (props.$thickenBorder ? '2px' : '1px')};
  border-style: ${(props) => (props.$useDashedBorder ? 'dashed' : 'solid')};
`;
