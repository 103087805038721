class Teacher {
  id?: string;

  name?: string;

  imageUrl?: string;

  static fromJson(data: any): Teacher {
    const teacher = new Teacher();
    teacher.id = data.Id;
    teacher.name = data.Name || data.User?.Name;
    teacher.imageUrl = data.ImageUrl;

    return teacher;
  }
}

export default Teacher;
