import React, { useEffect, useState } from 'react';
import { toast } from '@campus/components';
import { sendEmailToTeachers } from '../../../../../../../services/launchReport';
import { useLaunchReportContext } from '../../../../../context';
import { useTableAndRelatedContext } from '../../../context';
import { Tooltip } from '../../Tooltip';
import { Container, Button } from './styles';
export const SendEmailButton = () => {
    const { managerId, events } = useLaunchReportContext();
    const { getSelectedTeacherIds, getNumberOfSelectedTeachers } = useTableAndRelatedContext();
    const [isDisabled, setIsDisabled] = useState(true);
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    useEffect(() => {
        return events.table.selectAllChanged.subscribe((payload) => {
            setIsDisabled(!payload?.isChecked);
        });
    }, [events.table.selectAllChanged]);
    useEffect(() => {
        return events.table.teacherSelectionChanged.subscribe(() => {
            setIsDisabled(getNumberOfSelectedTeachers() === 0);
        });
    }, [events.table.teacherSelectionChanged, getNumberOfSelectedTeachers]);
    async function onClick() {
        if (isDisabled)
            return;
        try {
            setIsDisabled(true);
            setIsSendingEmail(true);
            const teacherIds = getSelectedTeacherIds();
            const response = await sendEmailToTeachers({
                managerId,
                teacherIds
            });
            if (response.error) {
                toast.error('Falha ao enviar e-mails');
            }
            else {
                toast.info('E-mails enviados!');
            }
        }
        catch {
            toast.error('Falha ao enviar e-mails');
        }
        finally {
            setIsDisabled(false);
            setIsSendingEmail(false);
        }
    }
    return (React.createElement(Container, null,
        React.createElement(Button, { onClick: onClick, disabled: isDisabled, "$isBusy": isSendingEmail }),
        React.createElement(Tooltip, null, isSendingEmail ? 'Enviando e-mails...' : 'Enviar alertas por e-mail')));
};
