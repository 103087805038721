import { isIndexValid } from '../../../../common/utils/arrays';
export const createOperationsSlice = (set) => ({
    update(updatedSkill) {
        set((store) => {
            const current = store.mainTable.content;
            const index = current.findIndex((s) => {
                return s.id === updatedSkill.id;
            });
            if (!isIndexValid(index, current))
                return store;
            const content = [...current];
            content[index] = updatedSkill;
            const mainTable = { ...store.mainTable, content };
            return { ...store, mainTable };
        });
    }
});
