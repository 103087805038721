import React from 'react';
import styled from 'styled-components';
import { Image, OverlayTrigger, Tooltip } from '@campus/components';
import { Student } from '@campus/commons';
import Avatar from '@campus/components/assets/icons/avatarGray.svg';
import { capitalizeFirstLetter } from 'services/student';

type StudentNameProps = {
  student?: Student;
  onClick: () => any;
};

const StudentImage = styled(Image)<{ $hasImage: boolean }>`
  height: 24px;
  width: 24px;
  object-fit: cover;
  ${(props) => props.$hasImage ? '' : 'background: #E6E6E6;padding: 3px;'}
`;

const StudentCell = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;  
  padding: 5px 8px;
`;

const StudentName: React.FC<StudentNameProps> = ({ student, onClick }) => {
  return (
    <StudentCell onClick={onClick}>
      <StudentImage
        roundedCircle
        src={student.imageUrl ?? Avatar}
        $hasImage={!!student.imageUrl}
      />
      <OverlayTrigger
        placement="right"
        overlay={(props) => (
          <Tooltip id="tooltip-progress-evaluation" {...props}>
            <span>
              {student?.name ? capitalizeFirstLetter(student?.name) : ''}
            </span>
          </Tooltip>
        )}
      >
        <span>
          {student?.nameFormatted
            ? capitalizeFirstLetter(student?.nameFormatted)
            : ''}
        </span>
      </OverlayTrigger>
    </StudentCell>
  );
};

export default StudentName;
