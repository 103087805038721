import InsightQuestion from './InsightQuestion';
import Score from './Score';

class Insight {
  id?: string;

  answerCount?: number;

  answerPercentage?: number;

  averageScore?: number;

  averagePercentage?: number;

  aboveAvgStudentCount?: number;

  aboveAvgStudentPercentage?: number;

  bellowAvgStudentCount?: number;

  bellowAvgStudentPercentage?: number;

  totalStudents?: number;

  totalWeight?: number;

  scores: Score[];

  questions: InsightQuestion[];

  teacherAnswerCount: number;

  static fromJson(data: any): Insight {
    const insight = new Insight();

    insight.id = data?.Id;

    insight.answerCount = data?.AnswerCount ?? 0;

    insight.answerPercentage = data?.AnswerPercentage ?? 0;

    insight.averageScore = data?.AverageScore ?? 0;

    insight.averagePercentage = data?.AveragePercentage ?? 0;

    insight.aboveAvgStudentCount = data?.AboveAvgStudentCount ?? 0;

    insight.aboveAvgStudentPercentage = data?.AboveAvgStudentPercentage ?? 0;

    insight.bellowAvgStudentCount = data?.BellowAvgStudentCount ?? 0;

    insight.bellowAvgStudentPercentage = data?.BellowAvgStudentPercentage ?? 0;

    insight.totalStudents = data?.TotalStudents ?? 0;

    insight.totalWeight = data?.TotalWeight ?? 0;

    insight.scores = data.Scores?.map((s) => Score.fromJson(s)) ?? [];

    insight.questions =
      data.Questions?.map((s) => InsightQuestion.fromJson(s)) ?? [];

    insight.teacherAnswerCount = data?.TeacherAnswerCount;

    return insight;
  }
}

export default Insight;
