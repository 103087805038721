import { useCallback, useEffect } from 'react';
import { useAuthority } from '@campus/auth';
import { useUserId } from '@campus/commons';
import { useFetch } from './useFetch';
import { getUserPermissions } from '../../../../services/userPermissions';
export function useFetchUserPermissions(props) {
    const { currentContext } = useAuthority();
    const { contextType } = currentContext.person;
    const { teacherId, managerId } = useUserId(contextType);
    const { data, requestStatus, fetchStarted, resetStatus, handleFailure, handleSuccess } = useFetch({ initialData: null });
    const fetchData = useCallback((abortSignal) => {
        if (!props.selectedGrade)
            return;
        if (!props.selectedDisciplines?.length)
            return;
        fetchStarted();
        getUserPermissions({
            abortSignal,
            teacherId,
            managerId,
            loggedAs: contextType,
            gradeId: props.selectedGrade.id,
            disciplineIds: props.selectedDisciplines.map((d) => d.id)
        })
            .then((response) => handleSuccess(response.data))
            .catch((response) => handleFailure(response.error?.error))
            .finally(resetStatus);
    }, [
        props.selectedGrade,
        props.selectedDisciplines,
        teacherId,
        managerId,
        contextType,
        fetchStarted,
        resetStatus,
        handleSuccess,
        handleFailure
    ]);
    useEffect(() => {
        const abortController = new AbortController();
        fetchData(abortController.signal);
        return () => abortController.abort();
    }, [fetchData]);
    return { data, requestStatus };
}
