import { Classroom } from '@campus/commons';
import AnswerQuestion from './AnswerQuestion';
import StudentAnswerReport from './StudentAnswerReport';

class ContentAnswer {
  contentId?: string;

  questions?: AnswerQuestion[];

  students?: StudentAnswerReport[];

  totalWeight?: number;

  classrooms?: Classroom[];

  static fromJson(data: any): ContentAnswer {
    const contentAnswer = new ContentAnswer();
    contentAnswer.contentId = data?.ContentId;
    contentAnswer.totalWeight = data?.TotalWeight;

    const students = data?.Students;
    if (students instanceof Array) {
      contentAnswer.students = students.map((s) =>
        StudentAnswerReport.fromJson(s)
      );
    }

    const classrooms = data?.Classes;
    if (classrooms instanceof Array) {
      contentAnswer.classrooms = classrooms.map((s) => Classroom.fromJson(s));
    }

    const questions = data?.Questions;
    if (questions instanceof Array) {
      contentAnswer.questions = questions.map((s) =>
        AnswerQuestion.fromJson(s)
      );
    }

    return contentAnswer;
  }
}

export default ContentAnswer;
