import { Api } from '@campus/auth';
import { PaginationModel, Response } from '@campus/commons';
import AreaSkill from '../models/AreaSkill';
import { handleApiCallError } from './common';
const baseEndpointsPath = 'areaskill';
const endpoints = {
    get: {
        paginated: baseEndpointsPath + '/paged',
        all: baseEndpointsPath + '/areaskillcomplete',
        byId: baseEndpointsPath + '/areaskillcomplete',
        groupedAndPaginated: baseEndpointsPath + '/grouped'
    }
};
export async function getAreaSkillById(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.get.byId, {
            signal: params?.abortSignal,
            params: { id: params.id }
        });
        const skill = AreaSkill.fromJson(response.data?.at(0));
        return Response.fromData(skill, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function getAllAreaSkills(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.get.all, {
            signal: params?.abortSignal,
            params: {
                GradeId: params.gradeId,
                SemesterId: params.stageId,
                SegmentId: params.segmentId,
                DisciplineId: params.disciplineId
            }
        });
        const skills = response.data.map((item) => AreaSkill.fromJson(item));
        return Response.fromData(skills, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function getAreaSkillsGrouped(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const skipCount = params.pageIndex * params.pageSize;
        const response = await axiosInstance.get(endpoints.get.groupedAndPaginated, {
            signal: params?.abortSignal,
            params: {
                SkipCount: skipCount,
                GradeId: params.gradeId,
                SemesterId: params.stageId,
                SegmentId: params.segmentId,
                SkillCode: params.skillCode,
                PlanningStageId: params.stageId,
                MaxResultCount: params.pageSize,
                DisciplineId: params.disciplineId
            }
        });
        const amount = response.data.TotalCount;
        const itemsCount = response.data.ItemsCount;
        const fetched = (skipCount ?? 0) + itemsCount;
        const hasNextPage = fetched < amount;
        const items = response.data.Items?.map((items) => {
            return items.map((skills) => {
                return skills.map(AreaSkill.fromJson);
            });
        }) ?? [];
        const paginated = new PaginationModel(items, amount, itemsCount, hasNextPage);
        return Response.fromData(paginated, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
