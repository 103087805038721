import { updateSlice } from './slice';
import { getFromStorage, keys } from './storage';
import { tryFillAvailableDisciplines, tryFillAvailableGrades, tryFillAvailableSegments } from './availableComponents';
import { getDistinct } from '../../../utils/arrays';
export function tryFillLastSelectedSegment(api) {
    const store = api.getState();
    const segments = store.commonData.segments.data;
    const lastSelectedSegmentId = getFromStorage(keys.segment);
    if (!lastSelectedSegmentId)
        return;
    const selectedSegment = segments?.find((s) => {
        return s.id === lastSelectedSegmentId;
    });
    api.setState((store) => {
        return updateSlice(store, { selectedSegment });
    });
}
export function tryFillLastSelectedGrade(api) {
    const store = api.getState();
    const grades = store.commonData.grades.data;
    const lastSelectedGradeId = getFromStorage(keys.grade);
    if (!lastSelectedGradeId)
        return;
    const selectedGrade = grades?.find((g) => {
        return g.id === lastSelectedGradeId;
    });
    api.setState((store) => {
        return updateSlice(store, { selectedGrade });
    });
}
export function tryFillLastSelectedDisciplines(api) {
    const store = api.getState();
    let disciplines = store.commonData.disciplines.data;
    const lastSelectedDisciplineIds = getFromStorage(keys.disciplines);
    if (!lastSelectedDisciplineIds)
        return;
    disciplines = disciplines?.filter((d) => {
        return lastSelectedDisciplineIds.includes(d.id);
    });
    const selectedDisciplines = getDistinct(disciplines, (d) => d.id);
    api.setState((store) => {
        return updateSlice(store, { selectedDisciplines });
    });
}
function tryFillThings(api) {
    tryFillLastSelectedGrade(api);
    tryFillLastSelectedSegment(api);
    tryFillLastSelectedDisciplines(api);
    tryFillAvailableSegments(api);
    tryFillAvailableGrades(api);
    tryFillAvailableDisciplines(api);
}
export function setupEventHandlers(storeApi, commonDataEvents) {
    commonDataEvents.segmentsChanged.subscribe(() => {
        tryFillThings(storeApi);
    });
    commonDataEvents.gradesChanged.subscribe(() => {
        tryFillThings(storeApi);
    });
    commonDataEvents.disciplinesChanged.subscribe(() => {
        tryFillThings(storeApi);
    });
}
