import styled from 'styled-components';
import PopupBox from '../PopupBox';
import { StyledTitle as BaseTitle } from '../common/styles';
export const StyledPopup = styled(PopupBox) `
  padding: 14px;

  min-width: 315px;

  display: flex;
  flex-direction: column;
`;
export const StyledTitle = styled(BaseTitle) `
  padding-bottom: 9px;

  border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
`;
export const StyledButtonsWrapper = styled.div `
  margin-top: auto;
  padding-top: 17px;

  display: flex;
  justify-content: space-between;

  border-top: 1px solid ${(props) => props.theme.colors.gray5};
`;
export const StyledMessageWrapper = styled.div `
  flex-grow: 1;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledMessage = styled.i `
  color: ${(props) => props.theme.colors.gray500};
`;
