import { getDateInfos } from '../../../helpers/dates';
import { getTodaysDateWithoutTime } from '../../../../../../../../../common/utils/dates';
export function calculateDistanceToRight(months, parentElement) {
    const today = getTodaysDateWithoutTime();
    const infos = getDateInfos(today, months, parentElement);
    if (!infos)
        return -1;
    if (infos.dateIsInsideAWeek === true) {
        const { weekElementRect: weekRect } = infos;
        const { parentElementRect: parentRect } = infos;
        const { percentageOfDateInWeek: percentageOfDate } = infos;
        const offset = percentageOfDate * weekRect.width;
        return Math.round(parentRect.right - weekRect.left - offset);
    }
    const { parentElementRect: parentRect } = infos;
    const { leftWeekElementRect: leftRect } = infos;
    const { rightWeekElementRect: rightRect } = infos;
    const { percentageOfDateBetweenWeeks: percentageOfDate } = infos;
    const distanceBetweenWeeks = rightRect.left - leftRect.right;
    const offset = percentageOfDate * distanceBetweenWeeks;
    return Math.round(parentRect.right - leftRect.right - offset);
}
