export function getActivityContentTextColor(state, theme) {
    if (state.deleted) {
        return theme.colors.gray700;
    }
    else if (state.unforeseen) {
        return theme.colors.orange1;
    }
    else if (state.wasExecuted && state.executedClassroomIsTheExpectedOne) {
        return theme.colors.green3;
    }
    else {
        return theme.colors.gray700;
    }
}
export function getClassBoxBorderColor(state, theme) {
    if (state.deleted) {
        return theme.colors.gray200;
    }
    else if (state.wasExecuted && state.executedClassroomIsTheExpectedOne) {
        return theme.colors.green3;
    }
    else if (state.unforeseen ||
        (state.wasExecuted && !state.executedClassroomIsTheExpectedOne)) {
        return theme.colors.orange1;
    }
    else {
        return theme.colors.gray200;
    }
}
export function getClassBoxIconAndTextColor(state, theme) {
    if (state.deleted) {
        return theme.colors.gray500;
    }
    else if (state.wasExecuted && state.executedClassroomIsTheExpectedOne) {
        return theme.colors.green3;
    }
    else if (state.unforeseen ||
        (state.wasExecuted && !state.executedClassroomIsTheExpectedOne)) {
        return theme.colors.orange1;
    }
    else {
        return theme.colors.gray500;
    }
}
