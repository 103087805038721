import React from 'react';
import BaseHeader from '../../../../../common/components/PageRelated/Body/components/Header';
import ExportButton from '../../../../../common/components/PageRelated/Body/components/Header/components/Buttons/ExportButton';
import ToggleFullScreenButton from '../../../../../common/components/PageRelated/Body/components/Header/components/Buttons/ToggleFullScreenButton';
import SegmentGradeAndDisciplines from '../../../../../common/components/PageRelated/Body/components/Header/components/SegmentGradeAndDisciplines';
import { useAnnualTimelinePageContext } from '../../../../context';
import { StyledRightSideWrapper } from './styles';
const Header = () => {
    const { content, stages, components } = useAnnualTimelinePageContext((store) => ({
        content: store.mainTable.content,
        stages: store.commonData.stages.data,
        components: store.curriculumComponents
    }));
    return (React.createElement(BaseHeader, null,
        React.createElement("div", null,
            React.createElement(BaseHeader.Title, null),
            React.createElement(SegmentGradeAndDisciplines, { grades: components.availableGrades, segments: components.availableSegments, disciplines: components.availableDisciplines, selectedGrade: components.selectedGrade, selectedSegment: components.selectedSegment, selectedDisciplines: components.selectedDisciplines, setSelectedGrade: components.selectedGradeChanged, setSelectedSegment: components.selectedSegmentChanged, addToSelectedDisciplines: components.addToSelectedDisciplines, removeFromSelectedDisciplines: components.removeFromSelectedDisciplines })),
        React.createElement(StyledRightSideWrapper, null,
            React.createElement(ExportButton, { skills: content, allStages: stages, selectedGrade: components.selectedGrade, selectedSegment: components.selectedSegment, selectedDisciplines: components.selectedDisciplines }),
            React.createElement(ToggleFullScreenButton, null))));
};
export default Header;
