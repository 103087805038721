import { Segment } from '@campus/commons';
export default class Grade {
    id;
    name;
    segment;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new Grade();
        instance.id = json.Id;
        instance.name = json.Name;
        instance.segment = json.Segment ? Segment.fromJson(json.Segment) : null;
        return instance;
    }
}
