import styled from 'styled-components';
import { ButtonWithIcon } from '../../../../../../../ButtonWithIcon';
import { hovering } from '../../../../../../../commonStyles';
export const Container = styled.div.attrs((props) => ({
    className: props.$isGeneratingMessage ? 'busy' : ''
})) `
  position: relative;

  > :last-child {
    opacity: 0;

    z-index: -1;

    transition: opacity ${hovering.transitionMs};
  }

  &:hover > :last-child {
    opacity: 1;

    z-index: 5;
  }

  &.busy > :first-child svg {
    color: ${({ theme }) => theme.colors.blue1};
  }
`;
export const Button = styled(ButtonWithIcon).attrs({
    iconNameInAssets: 'clipboard'
}) `
  display: block;

  svg {
    width: 18px;
    height: 18px;

    color: ${({ theme }) => theme.colors.gray3};
  }

  &:not(:disabled) svg {
    transition: color ${hovering.transitionMs};

    :hover {
      color: ${hovering.color};
    }
  }
`;
