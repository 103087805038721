import React from 'react';
import { widths as baseWidths } from '../common/sizes';
import { createHeaders as baseCreateHeaders, createRow as baseCreateRow } from '../common/components/Body/components/Table/creators';
import Stage from '../common/components/Body/components/Table/components/Columns/Stage';
import Skill from '../common/components/Body/components/Table/components/Columns/Skill';
import ThemeUnit from '../common/components/Body/components/Table/components/Columns/ThemeUnit';
import Discipline from '../common/components/Body/components/Table/components/Columns/Discipline';
import KnowledgeObjects from '../common/components/Body/components/Table/components/Columns/KnowledgeObjects';
import LearningObjectives from '../common/components/Body/components/Table/components/Columns/LearningObjectives';
const widths = baseWidths.annualTimelineTableColumns;
const headerCreators = {
    discipline: () => ({
        label: 'Disciplina',
        width: widths.discipline
    }),
    themeUnit: ({ displayDisciplineColumn }) => ({
        label: 'Unidade Temática',
        width: displayDisciplineColumn
            ? widths.themeUnit.withDisciplineColumn
            : widths.themeUnit.withoutDisciplineColumn
    }),
    skill: ({ displayDisciplineColumn }) => ({
        label: 'Habilidade',
        width: displayDisciplineColumn
            ? widths.skill.withDisciplineColumn
            : widths.skill.withoutDisciplineColumn
    }),
    learningObjectives: ({ displayDisciplineColumn }) => ({
        label: 'Objetivos de Aprendizagem',
        width: displayDisciplineColumn
            ? widths.learningObjectives.withDisciplineColumn
            : widths.learningObjectives.withoutDisciplineColumn
    }),
    knowledgeObjects: ({ displayDisciplineColumn }) => ({
        label: 'Objetos de Conhecimento',
        width: displayDisciplineColumn
            ? widths.knowledgeObjects.withDisciplineColumn
            : widths.knowledgeObjects.withoutDisciplineColumn
    }),
    stage: ({ displayDisciplineColumn }) => ({
        label: 'Etapa',
        width: displayDisciplineColumn
            ? widths.stage.withDisciplineColumn
            : widths.stage.withoutDisciplineColumn
    })
};
const columnCreators = {
    discipline: (params) => ({
        ...headerCreators.discipline(params),
        content: React.createElement(Discipline, { discipline: params.areaSkill.discipline })
    }),
    themeUnit: (params) => ({
        ...headerCreators.themeUnit(params),
        content: React.createElement(ThemeUnit, { unit: params.areaSkill?.themeUnit?.name })
    }),
    skill: (params) => ({
        ...headerCreators.skill(params),
        content: React.createElement(Skill, { areaSkill: params.areaSkill })
    }),
    learningObjectives: (params) => ({
        ...headerCreators.learningObjectives(params),
        content: React.createElement(LearningObjectives, { areaSkill: params.areaSkill })
    }),
    knowledgeObjects: (params) => ({
        ...headerCreators.knowledgeObjects(params),
        content: React.createElement(KnowledgeObjects, { objects: params.areaSkill?.knowledgeObjects })
    }),
    stage: (params) => {
        const { unitStages, programStages } = params.areaSkill;
        const stages = unitStages?.length > 0 ? unitStages : programStages;
        return {
            ...headerCreators.stage(params),
            content: React.createElement(Stage, { areaSkillAndStages: stages })
        };
    }
};
export function createHeaders(params) {
    let creators = headerCreators;
    if (!params.displayDisciplineColumn) {
        const { discipline, ...others } = headerCreators;
        creators = others;
    }
    return baseCreateHeaders(creators, params);
}
export function createRow(params) {
    let creators = columnCreators;
    if (!params.displayDisciplineColumn) {
        const { discipline, ...others } = columnCreators;
        creators = others;
    }
    return baseCreateRow(creators, params);
}
