import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import ClassroomAndDidacticSequence from '../models/ClassroomAndDidacticSequence';
import { handleApiCallError } from './common';
const baseEndpointsPath = '/classroomdidacticsequence';
const endpoints = {
    update: { classroom: baseEndpointsPath },
    add: { classroomAndSequence: baseEndpointsPath },
    delete: { classroomAndSequence: baseEndpointsPath },
    get: {
        classroomAndSequence: baseEndpointsPath,
        classroomsBySequence: baseEndpointsPath + '/bydidacticsequence'
    }
};
export async function getClassroomAndSequenceById(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpointWithId = `${endpoints.get.classroomAndSequence}/${params.id}`;
        const response = await axiosInstance.get(endpointWithId, {
            signal: params?.abortSignal
        });
        const item = ClassroomAndDidacticSequence.fromJson(response?.data);
        return Response.fromData(item, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function updateClassroomAndSequence(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpointWithId = `${endpoints.update.classroom}/${params.classroomAndSequenceId}`;
        await axiosInstance.put(endpointWithId, {
            Executed: params.executed,
            Foreseen: params.foreseen,
            ClassroomId: params.classroomId,
            Id: params.classroomAndSequenceId,
            DidacticSequenceId: params.didaticSequenceId,
            ExecutedClassroomId: params.executedClassroomId
        }, { signal: params.abortSignal });
        return getClassroomAndSequenceById({
            abortSignal: params.abortSignal,
            id: params.classroomAndSequenceId
        });
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function deleteClassroomAndSequence(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpointWithId = `${endpoints.delete.classroomAndSequence}/${params.classroomAndSequenceId}`;
        const response = await axiosInstance.delete(endpointWithId, {
            signal: params.abortSignal
        });
        return Response.fromData(true, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function addClassroomAndDidacticSequence(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.post(endpoints.add.classroomAndSequence, {
            ClassroomId: params.classroomId,
            Foreseen: params.foreseen ?? true,
            Executed: params.executed ?? false,
            DidacticSequenceId: params.sequenceId,
            ExecutedClassroomId: params.executedClassroomId ?? null
        }, { signal: params.abortSignal });
        return Response.fromData(true, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function getClassroomsBySequence(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.get.classroomsBySequence, {
            signal: params?.abortSignal,
            params: {
                MaxResultCount: 1000,
                DidacticSequenceId: params.sequenceId
            }
        });
        const items = response.data?.map((item) => ClassroomAndDidacticSequence.fromJson(item));
        return Response.fromData(items, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
