import { Paragraph, TableCell, TextRun, VerticalAlign, WidthType } from 'docx';
import { teacherTheme } from '@campus/components';
import { emptyBorder } from '../../../../../common/borderDocx';
import { columnBorder, createColumnPadding } from './common';
import { fontSizes, margins, widths } from '../../../../../common/sizesDocx';
const padding = createColumnPadding(widths.table.columns.annualTimeline.learningObjectives);
function createObjectiveData(objective) {
    return new Paragraph({
        indent: {
            left: margins.table.body.columns.left,
            right: margins.table.body.columns.right
        },
        children: [
            new TextRun({
                bold: false,
                text: `(${objective.code?.trim()}) `,
                size: fontSizes.table.columnContent,
                color: teacherTheme.colors.CSA.purple
            }),
            new TextRun({
                bold: false,
                text: objective.description.trim(),
                color: teacherTheme.colors.gray700,
                size: fontSizes.table.columnContent
            })
        ]
    });
}
export default function LearningObjectives(objectives) {
    const sorted = objectives?.sort((a, b) => {
        return a?.code?.localeCompare(b?.code);
    });
    const objectiveData = sorted.map((o) => {
        return createObjectiveData(o);
    });
    return new TableCell({
        verticalAlign: VerticalAlign.TOP,
        children: [padding, ...objectiveData, padding],
        width: {
            size: widths.table.columns.annualTimeline.learningObjectives,
            type: WidthType.PERCENTAGE
        },
        borders: {
            top: columnBorder,
            bottom: columnBorder,
            right: columnBorder,
            left: emptyBorder
        }
    });
}
