import { sortPlanningGroups } from '../../../../common/utils/plannings';
import { createFiltersSliceCreator } from './filters/slice';
import { createOperationsSliceCreator } from './operations/slice';
export function createMainTableSlice(events) {
    return (set, get, ...args) => ({
        filteredContent: [],
        nonFilteredContent: [],
        filters: createFiltersSliceCreator(events)(set, get, ...args),
        operations: createOperationsSliceCreator(events)(set, get, ...args),
        getContent() {
            return get().mainTable.filteredContent;
        },
        setContent(content) {
            set((store) => {
                const sorted = sortPlanningGroups(content);
                return {
                    ...store,
                    mainTable: {
                        ...store.mainTable,
                        filteredContent: sorted,
                        nonFilteredContent: sorted
                    }
                };
            });
            events.contentSet.emit();
        }
    });
}
