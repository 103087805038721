function areClassroomsTheSame(params) {
    const allInfos = params.classesAndInfos.values();
    const sequenceClassrooms = params.sequence.classrooms;
    for (const infos of allInfos) {
        const selectedClassroomIsInSequence = sequenceClassrooms.some((c) => {
            return c.classroom?.id === infos.selectedClassroom?.id;
        });
        if (infos.isChecked !== selectedClassroomIsInSequence) {
            return false;
        }
    }
    return true;
}
export function shouldButtonBeDisabled(params) {
    const hasNoDescription = !params.description;
    const descriptionIsTheSame = params.description && params.description === params.sequence.description;
    const evalutionTypeIsTheSame = params.evaluationType &&
        params.evaluationType?.id === params.sequence.evaluationType?.id;
    const classroomsAreTheSame = areClassroomsTheSame(params);
    const impactIsTheSame = params.sequence.impactTheMDC === params.impactTheMDC;
    return (hasNoDescription ||
        params.anImageIsBeingUploaded ||
        (descriptionIsTheSame &&
            evalutionTypeIsTheSame &&
            classroomsAreTheSame &&
            impactIsTheSame));
}
