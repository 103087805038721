import React from 'react';
import { useFinishActivityPopupContext } from '../../context';
import { StyledList, StyledPlaceholder } from './styles';
const ClassLessonAndDateList = () => {
    const { classesAndInfos, updateClassesAndInfos } = useFinishActivityPopupContext((store) => ({
        classesAndInfos: store.classesAndInfos,
        updateClassesAndInfos: store.updateClassesAndInfos
    }));
    if (classesAndInfos.size > 0) {
        return (React.createElement(StyledList, { classInfos: [...classesAndInfos.values()], updateClassInfos: updateClassesAndInfos }));
    }
    return React.createElement(StyledPlaceholder, { text: 'Todas as atividades foram finalizadas' });
};
export default ClassLessonAndDateList;
