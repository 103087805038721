import React from 'react';
import Table from './components/Table';
import Filters from './components/Filters';
import { StyledTabContentWrapper, StyledLineBehindFilters } from '../common/styles';
const ScheduleSkillsTab = () => {
    return (React.createElement(StyledTabContentWrapper, null,
        React.createElement(Filters, null),
        React.createElement(StyledLineBehindFilters, null),
        React.createElement(Table, null)));
};
export default ScheduleSkillsTab;
