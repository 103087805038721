import { createStore } from 'zustand';
import { createSequenceOperationsSlice } from './slices/sequenceOperations/slice';
export function createGroupStore(params) {
    return createStore()((set, ...args) => ({
        group: params.groupFromFilteredContent,
        _groupFromNonFilteredContent: params.groupFromNonFilteredContent,
        sequenceOperations: createSequenceOperationsSlice({
            updateGroupOnMainTable: params.updateGroupOnMainTable
        })(set, ...args)
    }));
}
