import React from 'react';
import { useTheme } from 'styled-components';
import Item from '../components/Item';
import Label from '../components/Label';
import Square from '../components/Square';
import Check from '../components/Icons/Check';
import PadLock from '../components/Icons/PadLock';
import CircleWithCheckBold from '../components/Icons/CircleWithCheck';
import { StyledHightlightedText, StyledSquareInnerText } from '../styles';
export function usePieces() {
    const theme = useTheme();
    const pieces = {
        used: (React.createElement(Item, null,
            React.createElement(Square, { backgroundColor: theme.colors.blue500, border: { color: theme.colors.blue500 } },
                React.createElement(Check, null)),
            React.createElement(Label, null, "Utilizados"))),
        unnused: (React.createElement(Item, null,
            React.createElement(Square, { backgroundColor: 'white', border: { color: theme.colors.gray200, thicken: true } }),
            React.createElement(Label, null, "N\u00E3o Utilizados"))),
        notPlanned: (React.createElement(Item, null,
            React.createElement(Square, { backgroundColor: 'white', border: { color: theme.colors.orange1 } }),
            React.createElement(Label, null, "Diferente do Planejado"))),
        planned: (React.createElement(Item, null,
            React.createElement(Square, { backgroundColor: 'white', border: { color: theme.colors.green3 } }),
            React.createElement(Label, null, "Realizado = Planejado"))),
        skillOfOtherComponent: (React.createElement(Item, null,
            React.createElement(Square, { backgroundColor: 'white', border: { color: '#B7312C' } }),
            React.createElement(Label, null, "Habilidade de outro componente/ s\u00E9rie"))),
        plannedButDeletedSkill: (React.createElement(Item, null,
            React.createElement(Square, { backgroundColor: 'white', border: { color: theme.colors.gray200, dashed: true } }),
            React.createElement(Label, null, "Habilidade planejada, por\u00E9m removida"))),
        evaluativeObjective: (React.createElement(Item, null,
            React.createElement(Square, { backgroundColor: 'white', border: { color: theme.colors.gray200 } },
                React.createElement(StyledSquareInnerText, null, "T")),
            React.createElement(Label, null,
                "Objetivo Avaliativo (",
                React.createElement(StyledHightlightedText, null, "Negrito"),
                ")"))),
        editionAndRemotionBlocked: (React.createElement(Item, null,
            React.createElement(PadLock, null),
            React.createElement(Label, null, "Edi\u00E7\u00E3o/ remo\u00E7\u00E3o bloqueada ap\u00F3s finaliza\u00E7\u00E3o"))),
        evaluative: (React.createElement(Item, null,
            React.createElement(CircleWithCheckBold, null),
            React.createElement(Label, null, "Avaliativa")))
    };
    return { pieces };
}
