import React from 'react';
import { useWeeklyPlanningPageMainTableContext } from '../../../../../../context';
import { useGroupContext } from '../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import Cell from '../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/components/Rows/components/Cell';
import { useBorders } from './hooks/useBorders';
import { StyledWrapper } from './styles';
const Row = (props) => {
    const group = useGroupContext((store) => store.group);
    const { columns } = useWeeklyPlanningPageMainTableContext();
    const borders = useBorders({ group, rowInfos: props.rowInfos });
    const planning = props.rowInfos.original;
    const isTheFirstRowOnGroup = group.plannings?.at(0)?.id === planning?.id;
    const isTheLastRowOnGroup = group.plannings?.at(-1)?.id === planning?.id;
    function renderCells() {
        return props.rowInfos.cells.map((cell) => (React.createElement(Cell, { key: cell.column.id, cellInfos: cell, allColumns: columns, draggableProvided: props.draggableProvided, isInTheFirstRowOfTheGroup: isTheFirstRowOnGroup, numberOfPlanningsInGroup: group.plannings?.length ?? 0 })));
    }
    const rowProps = props.rowInfos.getRowProps();
    rowProps.style.display = 'table-row';
    return (React.createElement(StyledWrapper, { ...rowProps, "$isTheFirstRowOnGroup": isTheFirstRowOnGroup, "$isTheLastRowOnGroup": isTheLastRowOnGroup, "$currentRowBorder": {
            isRed: borders.currentRowBorderShouldBeRed,
            isDashed: borders.currentRowBorderShouldBeDashed
        }, "$lastRowBorder": {
            isRed: borders.lastRowBorderShouldBeRed,
            isDashed: borders.lastRowBorderShouldBeDashed
        } }, renderCells()));
};
export default Row;
