import { useEffect, useMemo, useState } from 'react';
import { useFinishActivityPopupContext } from '../../../../../context';
import { createSelectOptionsFrom, getInitialValue } from './helpers';
export function useOptionsManager() {
    const { classesAndInfos, classroomGroups, updateSelectedClassrooms } = useFinishActivityPopupContext((store) => ({
        classesAndInfos: store.classesAndInfos,
        classroomGroups: store.classroomGroups,
        updateSelectedClassrooms: store.updateSelectedClassroomForEveryClass
    }));
    const options = useMemo(() => {
        if (classesAndInfos.size === 0)
            return [];
        return createSelectOptionsFrom(classroomGroups);
    }, [classroomGroups]);
    const [selectedOption, setSelectedOption] = useState(() => {
        return getInitialValue(classroomGroups, classesAndInfos, options);
    });
    useEffect(() => {
        if (!selectedOption) {
            setSelectedOption(getInitialValue(classroomGroups, classesAndInfos, options));
        }
    }, [classesAndInfos, classroomGroups, options, selectedOption]);
    function optionClicked(option) {
        setSelectedOption(option);
        updateSelectedClassrooms(option ? Number(option.value) : null);
    }
    return { options, selectedOption, optionClicked };
}
