import React, { useState } from 'react';
import Popup from './components/Popup';
import { StyledButton, StyledWrapper } from './styles';
const ExportButton = (props) => {
    const [canShowPopup, setCanShowPopup] = useState(false);
    const showPopup = () => setCanShowPopup(true);
    const hidePopup = () => setCanShowPopup(false);
    return (React.createElement(StyledWrapper, null,
        React.createElement(StyledButton, { onClick: showPopup }, "Exportar"),
        React.createElement(Popup, { canBeDisplayed: canShowPopup, handleExternalClick: hidePopup, skills: props.skills, allStages: props.allStages, selectedGrade: props.selectedGrade, selectedSegment: props.selectedSegment, selectedDisciplines: props.selectedDisciplines })));
};
export default ExportButton;
