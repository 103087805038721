import styled from 'styled-components';
export const StyledWrapper = styled.td `
  padding: 0;

  background-color: white;

  width: ${(props) => props.$width}px;
  min-width: ${(props) => props.$width}px;
  max-width: ${(props) => props.$width}px;

  height: 1px;

  @-moz-document url-prefix() {
    height: 100%;
  }
`;
