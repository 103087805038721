import styled from 'styled-components';
import ClassBox from '../../../../../common/components/ClassBox';
import ClassroomSelectorMenu from '../../../../../../Basics/ClassroomSelectorMenu';
export const StyledWrapper = styled.div `
  position: relative;
`;
export const StyledBox = styled(ClassBox) `
  ${(props) => (props.$disabled ? '' : 'cursor: pointer;')}
`;
export const StyledMenu = styled(ClassroomSelectorMenu) `
  z-index: 1;

  position: absolute;
  top: 100%;
  right: 0;
`;
