import { useCallback, useEffect, useState } from 'react';
export function useControlLastColumnRightBorder(props) {
    const [removeBorder, setRemoveBorder] = useState(false);
    const updateState = useCallback(() => {
        if (!props.tableElement)
            return;
        const { scrollWidth, clientWidth } = props.tableElement;
        setRemoveBorder(scrollWidth > clientWidth);
    }, [props.tableElement]);
    useEffect(() => {
        window.addEventListener('resize', updateState);
        return () => window.removeEventListener('resize', updateState);
    }, [updateState]);
    useEffect(() => {
        updateState();
    }, [updateState]);
    return { removeBorder };
}
