import React from 'react';
import PageBody from '../../../common/components/PageRelated/Body';
import { useWeeklyPlanningPageContext } from '../../context';
import Header from './components/Header';
import Table from './components/Table';
import FiltersAndSelectors from './components/FiltersAndSelectors';
const Body = () => {
    const tableContent = useWeeklyPlanningPageContext((store) => store.mainTable.nonFilteredContent);
    return (React.createElement(PageBody, null,
        React.createElement(Header, null),
        tableContent?.length > 0 ? React.createElement(FiltersAndSelectors, null) : null,
        React.createElement(Table, null)));
};
export default Body;
