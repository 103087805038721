import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import PedagogicalPlanningStatus from '../models/PedagogicalPlanningStatus';
import { handleApiCallError } from './common';
const endpoints = {
    getAll: {
        status: '/pedagogicalplanningstatus'
    }
};
export async function getAllPlanningStatuses(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.getAll.status, { signal: params?.abortSignal });
        const content = response.data.map((item) => PedagogicalPlanningStatus.fromJson(item));
        return Response.fromData(content, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
