import { StyleSheet } from '@react-pdf/renderer';
import { fontSizes, margins, paddings } from '../../../../sizes';
const styles = StyleSheet.create({
    wrapper: {
        paddingBottom: paddings.footer.evaluationSection.bottom,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: '#B3B3B3'
    },
    title: {
        margin: 0,
        fontSize: fontSizes.footer.evaluationSection,
        fontWeight: 700,
        color: '#4D4D4D'
    },
    description: {
        margin: 0,
        marginTop: margins.footer.evaluationSection.subtitle.top,
        fontSize: fontSizes.footer.evaluationSection,
        fontWeight: 500,
        color: '#4D4D4D'
    }
});
export default styles;
