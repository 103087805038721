import { Api } from '@campus/auth';
import { Response, PaginationModel } from '@campus/commons';

import Grade from 'models/Grade';

export const listGrade = async (
  name?: string
): Promise<Response<PaginationModel<Grade>>> => {
  try {
    const response = await Api.INSTANCE.getApi().get('/grade', {
      params: { MaxResultCount: 1000, Name: name }
    });

    return Response.fromData(
      PaginationModel.fromJson(Grade, response.data),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};

export const getGrade = async (id?: string): Promise<Response<Grade>> => {
  try {
    const response = await Api.INSTANCE.getApi().get(`/grade/${id}`);

    return Response.fromData(Grade.fromJson(response.data), response.status);
  } catch (e: any) {
    return Response.error(e);
  }
};