import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import PedagogicalPlanning from '../models/PedagogicalPlanning';
import PedagogicalPlanningGroup from '../models/PedagogicalPlanningGroup';
import PedagogicalPlanningDiary from '../models/pedagogicalPlanningDiary';
import { handleApiCallError } from './common';
const baseEndpointsPath = '/pedagogicalplanning';
const endpoints = {
    add: { areaSkill: baseEndpointsPath },
    update: { planning: baseEndpointsPath },
    get: {
        planning: baseEndpointsPath + '/byid',
        plannings: baseEndpointsPath + '/complete',
        diary: baseEndpointsPath + '/diary'
    }
};
export async function getPlanningById(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.get.planning, {
            signal: params.abortSignal,
            params: { Id: params.id }
        });
        const content = PedagogicalPlanning.fromJson(response.data);
        return Response.fromData(content, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function addPlanning(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.post(endpoints.add.areaSkill, {
            GradeId: params.gradeId,
            SegmentId: params.segmentId,
            TeacherId: params.teacherId,
            AreaSkillId: params.areaSkillId,
            DisciplineId: params.disciplineId,
            PedagogicalPlanningStatusId: params.statusId,
            Order: params.order ?? null,
            Group: params.group ?? null,
            ObservedLags: params.observedLags ?? null,
            TeacherObservation: params.teacherNotes ?? null,
            AnalystObservation: params.analystNotes ?? null,
            SupervisorObservation: params.supervisorNotes ?? null
        }, { signal: params.abortSignal });
        const planning = PedagogicalPlanning.fromJson(response.data);
        return Response.fromData(planning, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function getAllPlanningsGrouped(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.get.plannings, {
            signal: params?.abortSignal,
            params: {
                GradeId: params.gradeId,
                SegmentId: params.segmentId,
                DisciplineId: params.disciplineId,
                Planning: !params.getJustNotBeingPlanned
            }
        });
        const content = response.data.map((group) => {
            return PedagogicalPlanningGroup.fromJson(group);
        });
        return Response.fromData(content, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function updatePlanning(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpointWithId = `${endpoints.update.planning}/${params.id}`;
        const response = await axiosInstance.put(endpointWithId, {
            Order: params.order ?? null,
            Group: params.group ?? null,
            SemesterId: params.stageId,
            ObservedLags: params.observedLags ?? null,
            AnalystObservation: params.analystNotes,
            TeacherObservation: params.teacherNotes,
            SupervisorObservation: params.supervisorNotes,
            PedagogicalPlanningStatusId: params.statusId
        }, { signal: params.abortSignal });
        return Response.fromData(null, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function deletePlanning(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpointWithId = `${endpoints.update.planning}/${params.planning.id}`;
        const response = await axiosInstance.delete(endpointWithId, {
            signal: params.abortSignal
        });
        return Response.fromData(null, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function getDataToDiary(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.get.diary, {
            params: {
                SegmentId: params.segmentId,
                GradeId: params.gradeId,
                DisciplineId: params.disciplineId,
                ClassId: params.classId
            }
        });
        const dataDiary = PedagogicalPlanningDiary.fromJson(response.data);
        return Response.fromData(dataDiary, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
