import React from 'react';
import Objective from './components/Objective';
import { StyledWrapper } from './styles';
const LearningObjectives = (props) => {
    function renderObjectives() {
        const objectives = props.planning.objectives.sort((a, b) => {
            const aCode = a.learningObjective?.code;
            const bCode = b.learningObjective?.code;
            return aCode?.localeCompare(bCode);
        });
        return objectives.map((objective) => (React.createElement(Objective, { key: objective.id, objective: objective, planning: props.planning, readOnly: props.doNotAllowUpdates, handleObjectiveUpdated: props.handleObjectiveUpdated })));
    }
    return React.createElement(StyledWrapper, null, renderObjectives());
};
export default LearningObjectives;
