import { useMemo, useState } from 'react';
export function useOptionsManager(props) {
    const allOptions = useMemo(() => {
        const stages = props.allStages ?? [];
        return stages
            .sort((a, b) => a.fullName.localeCompare(b.fullName))
            .map((s) => ({ label: s.fullName, value: s.id, stage: s }));
    }, [props.allStages]);
    const [selectedOptions, setSelectedOptions] = useState(() => {
        const stages = props.areaSkill?.unitStages ?? [];
        return stages?.map((s) => ({
            stage: s.stage,
            value: s.stage?.id,
            label: s.stage?.fullName
        }));
    });
    function handleOptionClicked(params) {
        if (props.readOnly)
            return;
        const { isNowChecked, option } = params;
        if (isNowChecked) {
            setSelectedOptions((options) => [...options, option]);
        }
        else {
            setSelectedOptions((options) => options.filter((o) => o.value !== option.value));
        }
    }
    return {
        allOptions,
        selectedOptions,
        handleOptionClicked
    };
}
