import { useEffect } from 'react';
function fixCalendarPosition(calendar) {
    const isOpen = calendar.classList.contains('react-date-picker__calendar--open');
    if (!isOpen)
        return;
    const pageContent = document.querySelector('#launch-report-page-content');
    const pageContentRect = pageContent.getBoundingClientRect();
    const calendarRect = calendar.getBoundingClientRect();
    if (calendarRect.right > pageContentRect.right) {
        calendar.style.right = '0';
        calendar.style.left = 'revert';
    }
}
export function useFixCalendarPosition(pickerContainerRef) {
    useEffect(() => {
        const picker = pickerContainerRef.current.firstElementChild;
        let calendarObserver = null;
        const pickerObserver = new MutationObserver((records) => {
            for (const record of records) {
                for (const node of record.addedNodes) {
                    for (const child of node.childNodes) {
                        if (child instanceof HTMLElement &&
                            child.classList.contains('react-date-picker__calendar')) {
                            pickerObserver.disconnect();
                            fixCalendarPosition(child);
                            calendarObserver = new MutationObserver(() => {
                                fixCalendarPosition(child);
                            });
                            calendarObserver.observe(child, { attributes: true });
                        }
                    }
                }
            }
        });
        pickerObserver.observe(picker, { childList: true, subtree: true });
        return () => {
            pickerObserver.disconnect();
            calendarObserver?.disconnect();
        };
    }, []);
}
