import styled from 'styled-components';
import { CustomClassicCKEditor } from '@campus/components';
import Circle from '../../../../../../../../../../Basics/Circle/Circle';
const { elementClassNames } = CustomClassicCKEditor;
export const StyledWrapper = styled.div `
  overflow: hidden;
  max-height: ${(props) => props.$maxHeight}px;

  display: flex;
  column-gap: 6px;

  & ${elementClassNames.editableArea} {
    font-size: 12px;
    overflow: hidden;
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
  }

  & ${elementClassNames.toolbar} {
    display: none;
  }

  & ${elementClassNames.holeEditor} {
    max-height: ${(props) => props.$maxHeight}px;
  }

  & ${elementClassNames.holeEditor} p {
    line-height: 130%;
    margin: 0 !important;
  }

  & ${elementClassNames.holeEditor} a {
    cursor: pointer;
    color: ${(props) => props.theme.colors.blue1};

    :hover {
      text-decoration: underline;
      color: ${(props) => props.theme.colors.CSA.darkBlue};
    }
  }

  & ${elementClassNames.holeEditor} p,
  & ${elementClassNames.holeEditor} a {
    font-weight: normal;
  }

  & ${elementClassNames.holeEditor} b,
  & ${elementClassNames.holeEditor} strong {
    font-weight: ${(props) => props.theme.weight.bold};
  }

  & ${elementClassNames.holeEditor} p,
  & ${elementClassNames.holeEditor} b,
  & ${elementClassNames.holeEditor} strong {
    color: ${(props) => props.$textColor ? props.$textColor : props.theme.colors.gray700};
  }

  & ${elementClassNames.holeEditor} p,
  & ${elementClassNames.holeEditor} a,
  & ${elementClassNames.holeEditor} b,
  & ${elementClassNames.holeEditor} strong {
    ${(props) => {
    if (!props.$isDeleted)
        return;
    return `
        text-decoration-line: line-through;
        color: ${props.theme.colors.gray500};
      `;
}}
  }

  & ${elementClassNames.editableArea} img {
    margin: 0;
  }

  & ${elementClassNames.editableArea} figure {
    min-width: 0;
    margin: 0.9em 0;
  }

  & ${elementClassNames.widget}, & ${elementClassNames.holeEditor} img,
  & ${elementClassNames.holeEditor} figure {
    outline: none !important;
  }

  & ${elementClassNames.holeEditor} img,
  & ${elementClassNames.holeEditor} figure {
    ${(props) => {
    if (!props.$isDeleted)
        return;
    return `filter: grayscale(100%);`;
}}
  }
`;
export const StyledCircle = styled(Circle) `
  margin-top: 1px;

  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;

  & > div {
    width: 8px;
    height: 8px;
    min-width: 8px;
    min-height: 8px;
  }
`;
