import React from 'react';
import { AddOrEditActivityPopupContextProvider } from '../common/addOrEditActivity/context';
import Methodology from '../common/addOrEditActivity/components/Methodology';
import ActivityType from '../common/addOrEditActivity/components/ActivityType';
import ClassLessonAndDateList from '../common/addOrEditActivity/components/ClassLessonAndDateList';
import EditActivityButton from './components/EditActivityButton';
import { StyledTitle, StyledCancelButton } from '../common/styles';
import { StyledButtonsWrapper, StyledContentWrapper, StyledFirstColumnWrapper, StyledPopup, StyledSecondColumnWrapper } from '../common/addOrEditActivity/styles';
const EditActivityPopup = (props) => {
    return (React.createElement(StyledPopup, { isFullScreen: true, canBeDisplayed: props.canBeDisplayed, handleExternalClick: props.handleExternalClick },
        React.createElement(AddOrEditActivityPopupContextProvider, { sequence: props.sequence, planning: props.planning, disciplines: props.disciplines, classroomGroups: props.classroomGroups },
            React.createElement(StyledTitle, null, "Editar Atividade"),
            React.createElement(StyledContentWrapper, null,
                React.createElement(StyledFirstColumnWrapper, null,
                    React.createElement(ActivityType, null),
                    React.createElement(ClassLessonAndDateList, { sequence: props.sequence })),
                React.createElement(StyledSecondColumnWrapper, null,
                    React.createElement(Methodology, null))),
            React.createElement(StyledButtonsWrapper, null,
                React.createElement(StyledCancelButton, { onClick: props.handleCancelButtonClicked }, "Cancelar"),
                React.createElement(EditActivityButton, { sequence: props.sequence, handleActivityUpdated: props.handleActivityUpdated })))));
};
export default EditActivityPopup;
