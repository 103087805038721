import styled from 'styled-components';
export const StyledWrapper = styled.div `
  flex: 1;
  overflow: hidden;
  padding: 20px 16px;
`;
export const StyledScrollableArea = styled.div `
  height: 100%;
  overflow: auto;
`;
export const StyledContentWrapper = styled.div `
  width: 100%;
  min-width: fit-content;

  height: fit-content;
  min-height: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 10px;

  [data-sticky-td] {
    position: sticky;
  }
`;
