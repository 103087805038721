import React, { useCallback } from 'react';
import TextArea from '../common/components/TextArea';
import { updateAnalystNotes } from './updates';
import { getPlaceholder } from '../common/components/TextArea/placeholders';
import { StyledWrapper } from './styles';
const AnalystNotes = (props) => {
    const isPlanningDeleted = props.planning?.status?.isDeleted() ?? false;
    const readOnly = props.doNotAllowUpdates || isPlanningDeleted;
    const placeholder = getPlaceholder({
        readOnly,
        isPlanningDeleted
    });
    const updateContent = useCallback(async (content) => {
        return updateAnalystNotes(props.planning, content).then(() => {
            props.planning.analystNotes = content;
            props.handleAnalystNotesUpdated(props.planning);
        });
    }, [props.planning, props.handleAnalystNotesUpdated]);
    const onChange = useCallback((content) => {
        props.planning.analystNotes = content;
        props.handleAnalystNotesUpdated(props.planning);
    }, [props.planning, props.handleAnalystNotesUpdated]);
    return (React.createElement(StyledWrapper, null,
        React.createElement(TextArea, { key: props.planning.id, readOnly: readOnly, placeholder: placeholder, initialValue: props.planning.analystNotes, onChange: onChange, updateContent: updateContent })));
};
export default AnalystNotes;
