import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import styles from './styles';
const Header = (props) => {
    function renderColumns() {
        return props.columns.map((column, index) => {
            const isTheFirstColumn = index === 0;
            const isTheLastColumn = index === props.columns.length - 1;
            const wrapperStyles = isTheFirstColumn
                ? styles.firstColumnWrapper
                : styles.normalColumnWrapper;
            return (React.createElement(View, { key: column.label, style: {
                    ...wrapperStyles,
                    width: column.width,
                    borderRightWidth: isTheLastColumn ? 0 : '1px',
                    borderLeftWidth: 0
                } },
                React.createElement(Text, { style: styles.columnLabel }, column.label)));
        });
    }
    return (React.createElement(View, { fixed: true, style: styles.wrapper }, renderColumns()));
};
export default Header;
