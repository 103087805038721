import React, { useState } from 'react';
import { useLaunchReportContext } from '../../../context';
import { useFilterDates } from '../../../hooks';
import { DatePicker } from './DatePicker';
import { Label } from './commonStyles';
export const StartDate = () => {
    const { events } = useLaunchReportContext();
    const { startDate } = useFilterDates();
    const [value, setValue] = useState(startDate);
    function onChange(newDate) {
        setValue(newDate);
        events.filters.startDateChanged.emit({ newDate });
        events.filters.someFilterChanged.emit();
    }
    return (React.createElement("div", null,
        React.createElement(Label, null, "Data In\u00EDcio"),
        React.createElement(DatePicker, { value: value, onChange: onChange })));
};
