import { diffInDays } from '../../../../../../../common/utils/dates';
export function calculatePercentageEachDayInRange(start, end) {
    return 1 / (diffInDays(start, end) + 1);
}
export function calculatePercentageEachDayInWeek(week) {
    return calculatePercentageEachDayInRange(week.startDate, week.endDate);
}
export function calculatePercentageOfDateInRange(date, rangeStart, rangeEnd) {
    const range = diffInDays(rangeStart, rangeEnd);
    const correctedStartValue = diffInDays(rangeStart, date);
    return correctedStartValue / range;
}
export function calculatePercentageOfDateInWeek(date, week) {
    return calculatePercentageOfDateInRange(date, week.startDate, week.endDate);
}
export function isDateInWeek(date, week) {
    return date >= week.startDate && date <= week.endDate;
}
export function getMonthWithDateInside(months, date) {
    return months.find((month) => {
        return month.number === date.getMonth();
    });
}
export function isWeek(object) {
    return (!!object &&
        Object.hasOwn(object, 'startDate') &&
        Object.hasOwn(object, 'endDate'));
}
export function getWeekOrWeeksThatContainsDate(date, month) {
    for (let i = 0; i < month.weeks.length; ++i) {
        const currentWeek = month.weeks[i];
        if (isDateInWeek(date, currentWeek))
            return currentWeek;
        const dateIsBeforeCurrentWeek = date < currentWeek.endDate;
        const previousWeek = i === 0 ? null : month.weeks[i - 1];
        const dateIsAfterPreviousWeek = !!previousWeek && date > previousWeek.startDate;
        if (dateIsBeforeCurrentWeek && (!previousWeek || dateIsAfterPreviousWeek)) {
            return { left: previousWeek, right: currentWeek };
        }
    }
    throw new Error('Unreachable state reached');
}
