import { getDateInfos } from './dates';
import { getTodaysDateWithoutTime } from '../../../../../../../common/utils/dates';
function calculateDistanceOfTodayToLeft(months, bodyElement) {
    const today = getTodaysDateWithoutTime();
    const infos = getDateInfos(today, months, bodyElement);
    if (!infos)
        return -1;
    if (infos.dateIsInsideAWeek === true) {
        const { weekElementRect: weekRect } = infos;
        const { parentElementRect: parentRect } = infos;
        const { percentageOfDateInWeek: percentageOfDate } = infos;
        const offset = percentageOfDate * weekRect.width;
        return Math.round(weekRect.left - parentRect.left + offset);
    }
    const { parentElementRect: parentRect } = infos;
    const { leftWeekElementRect: leftRect } = infos;
    const { rightWeekElementRect: rightRect } = infos;
    const { percentageOfDateBetweenWeeks: percentageOfDate } = infos;
    const distanceBetweenWeeks = rightRect.left - leftRect.right;
    const offset = percentageOfDate * distanceBetweenWeeks;
    return Math.round(leftRect.right - parentRect.left + offset);
}
export function scrollBodyAndHeaderToTodaysDate(months, bodyElement, headerElement) {
    if (!bodyElement || !headerElement)
        return;
    if (!months || months.length === 0)
        return;
    const distance = calculateDistanceOfTodayToLeft(months, bodyElement);
    if (distance < 0)
        return;
    const bodyRect = bodyElement.getBoundingClientRect();
    const left = distance - bodyRect.width / 2;
    headerElement.scrollTo({ top: 0, left, behavior: 'smooth' });
    bodyElement.scrollTo({ top: 0, left, behavior: 'smooth' });
}
export function setupScrollEventListener(bodyElement, headerElement) {
    if (!bodyElement || !headerElement)
        return;
    function handleScroll() {
        const left = bodyElement.scrollLeft;
        headerElement?.scrollTo({ left });
    }
    bodyElement.addEventListener('scroll', handleScroll);
    return () => bodyElement.removeEventListener('scroll', handleScroll);
}
