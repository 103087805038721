import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
import FilterSkillsPopup from '../Popups/FilterSkillsPopup';
export const StyledWrapper = styled.div `
  position: relative;
`;
export const StyledBasicButton = styled.button `
  height: 32px;

  white-space: nowrap;

  font-size: 14px;
  font-weight: 600;
  font-style: normal;

  border-width: 1px;
  border-radius: 6px;
  border-style: solid;

  &:not(:disabled):hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.7;
  }
`;
export const StyledFilterButton = styled(StyledBasicButton) `
  background-color: white;
  color: ${(props) => props.theme.colors.gray200};
  border-color: ${(props) => props.theme.colors.gray200};
`;
export const StyledPopup = styled(FilterSkillsPopup) `
  position: absolute;
  top: 100%;
  transform: translateY(3%);
`;
export const Button = styled.button.attrs((props) => ({
    className: props.$isActive ? 'active' : ''
})) `
  height: 24px;

  padding: 4px 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.colors.white};

  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.gray200};

  &:focus-visible {
    border-color: transparent;
  }

  &.active {
    background: ${({ theme }) => theme.colors.blue1};
    border-color: ${({ theme }) => theme.colors.blue1};
  }
`;
export const CalendarIcon = styled(CampusIcon).attrs({
    name: 'calendarGray'
}) `
  display: block;

  path {
    stroke: ${({ theme, $isActive }) => $isActive ? theme.colors.white : theme.colors.blackAlpha500};
  }
`;
