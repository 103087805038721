import React from 'react';
import { useWeeklyPlanningPageContext } from '../../../../../../../context';
import BaseColumn from '../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/AnalystNotes';
const AnalystNotes = (props) => {
    const { updatePlanning, userPermissions } = useWeeklyPlanningPageContext((store) => ({
        updatePlanning: store.mainTable.operations.updatePlanning,
        userPermissions: store.commonData.userPermissions.data
    }));
    const doNotAllowUpdates = !userPermissions?.userCan.updateTheManagerColumns();
    return (React.createElement(BaseColumn, { planning: props.planning, handleAnalystNotesUpdated: updatePlanning, doNotAllowUpdates: doNotAllowUpdates }));
};
export default AnalystNotes;
