import { useState } from 'react';
import { useWeeklyPlanningPageContext } from '../../../../../../../../../../../../context';
import { useGroupContext } from '../../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import { RequestStatus } from '../../../../../../../../../../../../../common/types/dataFetching';
import { times } from '../../../../../../../../../../../../../common/constants';
import { useIsMounted } from '../../../../../../../../../../../../../common/hooks';
import { updateDidacticSequenceClassrooms } from '../../../../../../../../../../../../../../services/agregators/updateDidacticSequenceClassrooms';
export function useUpdateSequenceClassrooms(props) {
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const availableClassrooms = useWeeklyPlanningPageContext((store) => store.commonData.availableClassrooms.data);
    const { updateActivity } = useGroupContext((store) => ({
        updateActivity: store.sequenceOperations.update
    }));
    const { isMounted } = useIsMounted();
    function update(params) {
        setRequestStatus(RequestStatus.Loading);
        updateDidacticSequenceClassrooms({
            sequence: props.sequence,
            allClassroomGroups: availableClassrooms,
            classroomNumber: Number(params.selectedOption.value)
        })
            .then((response) => {
            updateActivity(response.data);
            params.onSuccess?.();
            setRequestStatus(RequestStatus.Succeeded);
        })
            .catch(() => {
            setRequestStatus(RequestStatus.Failed);
        })
            .finally(() => {
            setTimeout(() => {
                if (isMounted()) {
                    setRequestStatus(RequestStatus.NotStarted);
                }
            }, times.toResetARequestStatus);
        });
    }
    return { update, requestStatus };
}
