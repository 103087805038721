import React from 'react';
import { Button, Modal } from '@campus/components';
import DeleteButton from './components/DeleteButton/DeleteButton';
const DeletionModal = (props) => {
    return (React.createElement(Modal, { show: props.canBeDisplayed, onHide: props.handleExternalClick },
        React.createElement(Modal.Header, { closeButton: true },
            React.createElement(Modal.Title, null, props.title)),
        React.createElement(Modal.Body, null, props.description),
        React.createElement(Modal.Footer, null,
            React.createElement(Button, { variant: 'light', onClick: props.handleCancelButtonClicked }, "Cancelar"),
            React.createElement(DeleteButton, { onClick: props.handleConfirmButtonClicked }))));
};
export default DeletionModal;
