import styled from 'styled-components';
export const StyledWrapper = styled.div `
  width: 24px;
  height: 24px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 2px;
  border: 1px solid
    ${(props) => (props.$isSelected ? props.theme.colors.blue500 : '#D2D2D2')};

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }
`;
export const StyledText = styled.span `
  font-size: 14px;
  font-weight: 600;
  font-style: normal;

  color: ${(props) => props.$isSelected ? props.theme.colors.blue500 : props.theme.colors.gray3};
`;
