import styled from 'styled-components';
import { breakpoints } from './breakpoints';
export const Page = styled.div `
  height: 100svh;

  padding-bottom: 20px;

  background: ${({ theme }) => theme.colors.white};

  overflow: hidden;

  --_inline-padding: max(1rem, (100% - 1040px) / 2);

  display: grid;
  align-content: start;
  grid-template-rows: auto 1fr;
  grid-template-columns:
    [full-width-start] var(--_inline-padding)
    [content-start] 1fr [content-end]
    var(--_inline-padding) [full-width-end];

  .subgrid {
    display: grid;
    grid-template-columns: inherit;
  }

  .full-width {
    grid-column: full-width;
  }

  .content {
    grid-column: content;
  }

  @media (max-height: ${breakpoints.smallScreenHeight}) {
    height: auto;
    min-height: 100svh;

    overflow-y: auto;
    overflow-x: hidden;
  }
`;
export const PageContent = styled.div.attrs({
    className: 'content'
}) `
  overflow: hidden;

  display: flex;
  flex-direction: column;

  @media (max-height: ${breakpoints.smallScreenHeight}) {
    overflow: visible;
  }
`;
export const FiltersAndTableSeparator = styled.hr `
  all: unset;

  display: block;

  margin: 20px 0 16px 0;

  border-top: 1px solid ${({ theme }) => theme.colors.gray5};
`;
