import React from 'react';
import { TableAndRelatedContextProvider } from './context';
import { SelectionActionsAndIndicators, Table, PageSelector, NoTableContent } from './components';
import { useFetchTableContent } from './hooks';
import { Container, TableAndNoContent } from './styles';
export const TableAndRelated = () => {
    const { data, requestStatus } = useFetchTableContent();
    return (React.createElement(TableAndRelatedContextProvider, null,
        React.createElement(Container, null,
            data?.length > 0 ? React.createElement(SelectionActionsAndIndicators, null) : null,
            React.createElement(TableAndNoContent, null,
                React.createElement(Table, { content: data }),
                React.createElement(NoTableContent, { requestStatus: requestStatus, numberOfItems: data?.length ?? 0 })),
            data?.length > 0 ? React.createElement(PageSelector, null) : null)));
};
