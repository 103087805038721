export var PlanningStatusDescriptions;
(function (PlanningStatusDescriptions) {
    PlanningStatusDescriptions["Deleted"] = "Deletado";
    PlanningStatusDescriptions["Finished"] = "Finalizado";
    PlanningStatusDescriptions["BeingPlanned"] = "Em planejamento";
    PlanningStatusDescriptions["IncludedAfterFinalized"] = "Incluido Ap\u00F3s Finalizado";
})(PlanningStatusDescriptions || (PlanningStatusDescriptions = {}));
function getDescriptionKey(description) {
    const keys = Object.keys(PlanningStatusDescriptions);
    const key = keys.find((k) => PlanningStatusDescriptions[k] === description);
    return PlanningStatusDescriptions[key];
}
export default class PedagogicalPlanningStatus {
    id;
    description;
    isFinished() {
        return this.description === PlanningStatusDescriptions.Finished;
    }
    isBeingPlanned() {
        return this.description === PlanningStatusDescriptions.BeingPlanned;
    }
    isDeleted() {
        return this.description === PlanningStatusDescriptions.Deleted;
    }
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new PedagogicalPlanningStatus();
        instance.id = json.Id;
        instance.description = getDescriptionKey(json.Description);
        return instance;
    }
}
