import { useCallback, useEffect } from 'react';
import { useFetch } from './useFetch';
import { getFilteredClasses } from '../../../../services/class';
export function useFetchFilterClasses(props) {
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess, resetStatus } = useFetch({ initialData: null });
    const fetchData = useCallback((abortSignal) => {
        if (!props.selectedGrade)
            return;
        if (!props.selectedDisciplines?.length)
            return;
        fetchStarted();
        getFilteredClasses({
            abortSignal,
            gradeId: props.selectedGrade.id,
            disciplineIds: props.selectedDisciplines.map((d) => d.id)
        })
            .then((response) => handleSuccess(response.data))
            .catch((response) => handleFailure(response.error?.error))
            .finally(resetStatus);
    }, [
        props.selectedGrade,
        props.selectedDisciplines,
        fetchStarted,
        resetStatus,
        handleSuccess,
        handleFailure
    ]);
    useEffect(() => {
        const abortController = new AbortController();
        fetchData(abortController.signal);
        return () => abortController.abort();
    }, [fetchData]);
    return { data, requestStatus };
}
