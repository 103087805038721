import styled from 'styled-components';
export const StyledWrapper = styled.div `
  position: relative;

  padding: 5px 15px;

  &:hover {
    background-color: #dae4fd;
  }
`;
export const StyledCode = styled.span `
  font-size: 14px;
  font-weight: 800;
  font-style: normal;

  color: ${(props) => props.$color ? props.$color : props.theme.colors.gray700};
`;
export const StyledDescription = styled.span `
  margin-left: 3px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: ${(props) => props.theme.colors.gray700};
`;
export const SpanHighlighted = styled.span `
  background-color: ${(props) => props.theme.colors.yellow2};
`;
