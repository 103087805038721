import React from 'react';
import { RequestStatus } from '@campus/commons';
import { NoTableContent as NoContent } from '../../../NoTableContent';
import { CircleWithCheckIcon } from './styles';
export const NoTableContent = ({ numberOfItems, requestStatus }) => {
    if (numberOfItems > 0)
        return null;
    let text = null;
    let icon = null;
    switch (requestStatus) {
        case RequestStatus.Loading: {
            text = 'Carregando conteúdo...';
            break;
        }
        case RequestStatus.Failed: {
            text = 'Falha ao carregar conteúdo';
            break;
        }
        default:
            break;
    }
    if (!text && numberOfItems === 0) {
        icon = React.createElement(CircleWithCheckIcon, null);
        text = 'Todas as aulas estão lançadas corretamente!';
    }
    if (!text)
        return null;
    return React.createElement(NoContent, { text: text, icon: icon });
};
