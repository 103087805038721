import React, { useState } from 'react';
import { Popup } from './components/Popup';
import CodeAndDescription from '../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/SkillRelated/components/CodeAndDescription';
import { StyledIconsAndPopupWrapper, StyledWrapper } from './styles';
import { StyledIconsWrapper, StyledHamburgerIconWrapper, StyledHamburgerIcon } from '../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/SkillRelated/components/Icons/styles';
import Infos from './components/Infos';
const Skill = (props) => {
    const [canShowPopup, setCanShowPopup] = useState(false);
    const showPopup = () => setCanShowPopup(true);
    const hidePopup = () => setCanShowPopup(false);
    const planning = props.row.original;
    const isPlanningDeleted = planning?.status?.isDeleted() ?? false;
    return (React.createElement(StyledWrapper, null,
        React.createElement(StyledIconsAndPopupWrapper, null,
            React.createElement(StyledIconsWrapper, null,
                React.createElement(StyledHamburgerIconWrapper, { onClick: showPopup },
                    React.createElement(StyledHamburgerIcon, null))),
            React.createElement(Popup, { planning: planning, canBeDisplayed: canShowPopup, planningIndex: props.row.index, handleExternalClick: hidePopup })),
        React.createElement("div", null,
            React.createElement(CodeAndDescription, { areaSkill: planning?.areaSkill, addLineThroughDescription: isPlanningDeleted }),
            React.createElement(Infos, { areaSkill: planning?.areaSkill }))));
};
export default Skill;
