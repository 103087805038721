export const columns = [
    {
        id: 'disciplineName',
        Header: 'Componente Curricular',
        centered: true,
        sticky: 'left',
        borderOn: 'right',
        width: 190,
        minWidth: 190
    },
    {
        id: 'themeUnit',
        Header: 'Unidade Temática',
        sticky: 'left',
        borderOn: 'right',
        width: 190,
        minWidth: 190
    },
    {
        id: 'skill',
        Header: 'Habilidade',
        sticky: 'left',
        borderOn: 'right',
        centered: true,
        width: 320,
        minWidth: 320
    },
    {
        id: 'evaluative',
        Header: 'Avaliativa',
        borderOn: 'right',
        centered: true,
        width: 90,
        minWidth: 90
    },
    {
        id: 'learningObjectives',
        Header: 'Objetivos de Aprendizagem',
        borderOn: 'right',
        centered: true,
        width: 300,
        minWidth: 300
    },
    {
        id: 'knowledgeObjects',
        Header: 'Objetos de Conhecimento',
        borderOn: 'right',
        width: 230,
        minWidth: 230,
        centered: true
    },
    {
        id: 'didaticSequence',
        Header: 'Sequência Didática',
        borderOn: 'right',
        centered: true,
        width: 600,
        minWidth: 600
    },
    {
        id: 'teacherObservation',
        Header: 'Observações do Professor',
        borderOn: 'right',
        centered: true,
        width: 235,
        minWidth: 235
    },
    {
        id: 'observedLags',
        Header: 'Defasagens Observadas',
        borderOn: 'right',
        centered: true,
        width: 235,
        minWidth: 235
    },
    {
        id: 'analystNotes',
        Header: 'Observações do Analista',
        borderOn: 'right',
        centered: true,
        width: 235,
        minWidth: 235
    },
    {
        id: 'supervisorObservation',
        Header: 'Observações do Supervisor',
        centered: true,
        width: 235,
        minWidth: 235
    },
    {
        id: 'fakeColumn',
        Header: '',
        width: 0,
        minWidth: 0,
        columns: [
            {
                id: 'innerFakeColumn',
                Header: '',
                width: 0,
                minWidth: 0
            }
        ]
    }
];
