import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import { handleApiCallError } from './common';
const baseEndpointPath = '/pedagogicalplanningobject';
const endpoints = {
    add: { object: baseEndpointPath },
    update: { object: baseEndpointPath },
    delete: { object: baseEndpointPath }
};
export async function addPlanningObject(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        await axiosInstance.post(endpoints.add.object, {
            Programmed: params.programmed,
            PedagogicalPlanningId: params.planningId,
            ObjectKnowledgeId: params.knowledgeObjectId
        }, { signal: params?.abortSignal });
        return Response.fromData(null, 200);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function updatePlanningObject(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpointWithId = `${endpoints.update.object}/${params.objectId}`;
        const response = await axiosInstance.put(endpointWithId, {
            Programmed: params.wasProgrammed
        }, { signal: params?.abortSignal });
        return Response.fromData(null, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function deletePlanningObject(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpointWithId = `${endpoints.delete.object}/${params.objectId}`;
        const response = await axiosInstance.delete(endpointWithId, {
            signal: params?.abortSignal
        });
        return Response.fromData(true, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
