import { getMainClassroomAndSequenceFrom } from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/common/helpers/sequenceRelated';
export function getActivityState(sequence) {
    const classrooms = sequence?.classrooms;
    const main = getMainClassroomAndSequenceFrom(classrooms);
    const classroom = main?.classroom;
    const executedClassroom = main?.executedClassroom;
    return {
        unforeseen: !main?.foreseen ?? false,
        deleted: sequence?.isDeleted ?? false,
        wasExecuted: main?.wasExecuted ?? false,
        executedClassroomIsTheExpectedOne: classroom?.id === executedClassroom?.id
    };
}
