import React, { useCallback, useEffect, useRef } from 'react';
export function withIntersectionObserver(element) {
    return (props) => {
        const ref = useRef(null);
        const optionsRef = useRef(props.options);
        const whenReachedRef = useRef(props.whenReached);
        const stopObservingRef = useRef(props.stopObservingAfterReach ?? false);
        const createObserver = useCallback(() => {
            const observer = new IntersectionObserver((entries) => {
                const entry = entries.at(0);
                if (!entry || !entry.isIntersecting)
                    return;
                if (stopObservingRef.current) {
                    observer.unobserve(entry.target);
                }
                whenReachedRef.current();
            }, optionsRef.current);
            return observer;
        }, []);
        useEffect(() => {
            const observer = createObserver();
            if (ref.current)
                observer.observe(ref.current);
            return () => observer.disconnect();
        }, [createObserver]);
        return React.createElement("div", { ref: ref }, element);
    };
}
