import React, { useEffect, useState } from 'react';
import { useCalendarContext } from '../../../../../../context';
import { useFollowUpMainTableContext } from '../../../../../../../../../context';
import Line from './components/Line';
import { getPlannedDates } from './helpers/dates';
import { calculateDistanceToLeft, calculateLineWidth } from './helpers/distances';
const Lines = (props) => {
    const months = useCalendarContext((store) => store.months);
    const activitiesAndInfos = useFollowUpMainTableContext((store) => store.activitiesAndInfos);
    const [lines, setLines] = useState([]);
    useEffect(() => {
        if (!props.groupInfos)
            return;
        if (!props.parentElement)
            return;
        if (!props.group?.didacticSequence?.length)
            return;
        const plannedDates = getPlannedDates(props.group.didacticSequence);
        const left = calculateDistanceToLeft(plannedDates.start, months, props.parentElement);
        if (left < 0)
            return;
        const width = calculateLineWidth(plannedDates.end, months, props.parentElement, left);
        if (width <= 0)
            return;
        setLines(props.group.didacticSequence.map((activity) => {
            const activityInfos = activitiesAndInfos.get(activity.id);
            return (React.createElement(Line, { key: activity.id, groupInfos: props.groupInfos, activityInfos: activityInfos, width: width, distanceFromLeftOfParent: left }));
        }));
    }, [
        props.groupInfos,
        props.group?.didacticSequence,
        props.parentElement,
        activitiesAndInfos,
        months
    ]);
    return React.createElement(React.Fragment, null, lines);
};
export default Lines;
