import styled from 'styled-components';
import { StyledComponentName } from '../common/styles';
export const StyledWrapper = styled.div `
  width: fit-content;

  cursor: pointer;

  display: flex;
  flex-wrap: wrap;
  column-gap: 9px;
  align-items: center;
`;
export const StyledDisciplineName = styled(StyledComponentName) `
  ${(props) => (props.$color ? `color: ${props.$color};` : '')}
`;
export const StyledSeparator = styled.span `
`;
