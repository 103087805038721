import React from 'react';
import { columns } from '../../../../../../columns';
import { getColumnDefinition } from '../../../../../../../../../../../common/components/PageRelated/Body/components/Table/helpers/columns';
import { StyledWrapper } from './styles';
const Cell = (props) => {
    const columnDefinition = getColumnDefinition({
        allColumns: columns,
        columnInstance: props.cellInfos.column
    });
    const maxWidth = Number(columnDefinition?.width);
    const cellProps = props.cellInfos.getCellProps();
    const removeRightBorder = columnDefinition.doNotAddRightBorderToCell;
    return (React.createElement(StyledWrapper, { ...cellProps, "$maxWidth": maxWidth, "$removeRightBorder": removeRightBorder }, props.cellInfos.render('Cell')));
};
export default Cell;
