import React, { useState } from 'react';
import { useCalendarContext } from '../../../../../context';
import { prettyDay } from './helpers';
import BlueLayer from '../../../../BlueLayer';
import Separator from '../../../../Separator';
import { StyledWeekAndSeparatorWrapper, StyledWeekText, StyledWeekTextWrapper, StyledWeeksWrapper, StyledWrapper } from './styles';
const wrapperId = 'table-header-weeks-row';
const WeeksRow = () => {
    const months = useCalendarContext((store) => store.months);
    const [wrapper, setWrapper] = useState();
    function renderMonthWeeks(month) {
        return month.weeks.map((week, index) => (React.createElement(StyledWeekAndSeparatorWrapper, { key: month.number + index },
            React.createElement(StyledWeekTextWrapper, null,
                React.createElement(StyledWeekText, null,
                    prettyDay(week.startDate),
                    "-",
                    prettyDay(week.endDate))),
            React.createElement(Separator, { month: month, correspondingWeek: week }))));
    }
    function renderWeeks() {
        return months.map((month) => (React.createElement(StyledWeeksWrapper, { key: month.number }, renderMonthWeeks(month))));
    }
    return (React.createElement(StyledWrapper, { id: wrapperId, ref: setWrapper },
        renderWeeks(),
        React.createElement(BlueLayer, { parentElement: wrapper })));
};
export default WeeksRow;
