import { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { useAddSkillPopupContext } from '../../../../../../../../context';
const useSkillCodeFilter = () => {
    const [skillCode, setSkillCode] = useState('');
    const [skillCodeDebounce] = useDebounce(skillCode, 1000);
    const { setFilteredSkillCode } = useAddSkillPopupContext((store) => ({
        setFilteredSkillCode: store.filters.setFilteredSkillCode
    }));
    const handleInputChange = (e) => {
        setSkillCode(e.target.value);
    };
    useEffect(() => {
        setFilteredSkillCode(skillCodeDebounce);
    }, [setFilteredSkillCode, skillCodeDebounce]);
    return { handleInputChange };
};
export default useSkillCodeFilter;
