import React from 'react';
import CodeAndDescription from '../SkillRelated/components/CodeAndDescription/CodeAndDescription';
import { StyledCircleWithCheck, StyledCircleWithCheckWrapper, StyledWrapper } from './styles';
const Skill = (props) => {
    const isEvaluative = props.skill.evaluative;
    return (React.createElement(StyledWrapper, null,
        React.createElement(StyledCircleWithCheckWrapper, null, isEvaluative ? React.createElement(StyledCircleWithCheck, null) : null),
        React.createElement(CodeAndDescription, { areaSkill: props.skill })));
};
export default Skill;
