import styled from 'styled-components';
import { Form } from 'react-bootstrap';
export const StyledCheckbox = styled(Form.Check.Input).attrs({
    type: 'checkbox'
}) `
  &[type='checkbox'] {
    margin: 0;

    width: 16px;
    height: 16px;
    min-width: 16px;

    box-shadow: none;

    ${(props) => (props.readOnly ? '' : 'cursor: pointer;')}

    &, &:focus {
      border-radius: 2px;
      border-style: solid;

      border-width: ${(props) => (props.$thickenBorder ? '2px' : '1px')};

      border-color: ${(props) => props.$borderColor ? props.$borderColor : 'rgba(0, 0, 0, 0.25)'};
    }

    ${(props) => props.$backgroundColor
    ? `background-color: ${props.$backgroundColor};`
    : ''};
  }

  &[type='checkbox']:checked {
    background-size: 17px;
    border-color: ${(props) => props.$borderColor ? props.$borderColor : '#2f80ed'};
  }
`;
