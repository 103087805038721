import { StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
    wrapper: {
        flexGrow: 1,
        flexShrink: 1,
        display: 'flex',
        flexDirection: 'row'
    }
});
export default styles;
