import React, { useEffect, useCallback, useMemo, useState } from 'react';
import ReactSelect from 'react-select';
import { useTheme } from 'styled-components';

import { SelectOption } from '@campus/components';
import { Discipline } from '@campus/commons';

import { getAllDisciplinesByGrade } from 'services/discipline';

import { DropdownIndicator, selectStyles } from '../../utils/selectStyles';

type DisciplineOption = SelectOption<Discipline>;

const formatValue = (discipline: Discipline): DisciplineOption => ({
  value: discipline,
  label: discipline.name
});

interface DisciplineSelectProps {
  discipline?: Discipline;
  gradeId?: string;
  onDisciplineChange: (discipline: Discipline) => void;
}

const allOptions = { value: null, label: 'Todos os componentes curriculares' };

const DisciplineSelect: React.FC<DisciplineSelectProps> = ({
  gradeId,
  onDisciplineChange,
  discipline
}) => {
  const theme = useTheme();

  const [disciplines, setDisciplines] = useState<Array<DisciplineOption>>([]);

  const getDisciplines = useCallback(async () => {
    const result = await getAllDisciplinesByGrade(gradeId);
    const data = result.data.items.map((d) => formatValue(d));
    setDisciplines(data);
  }, [gradeId]);

  useEffect(() => {
    if (gradeId) getDisciplines();
  }, [gradeId, getDisciplines]);

  const options = useMemo(
    () => (gradeId ? [allOptions, ...disciplines] : [allOptions]),
    [gradeId, disciplines]
  );

  const handleChange = (option: DisciplineOption) =>
    onDisciplineChange(option.value);

  return (
    <ReactSelect
      name="curricular_component"
      placeholder="Todos os componentes curriculares"
      noOptionsMessage={() => 'Nenhuma opção disponível'}
      loadingMessage={() => 'Carregando componentes curriculares...'}
      value={discipline ? formatValue(discipline) : allOptions}
      options={options}
      onChange={handleChange}
      isClearable={false}
      hideSelectedOptions={true}
      styles={selectStyles(theme)}
      components={{
        DropdownIndicator: () => <DropdownIndicator />
      }}
    />
  );
};

export default DisciplineSelect;
