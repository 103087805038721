function selectCommonTextStyles(theme) {
    return {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '20px',
        color: theme.colors.blackAlpha500
    };
}
export function createStyles(theme) {
    const textStyles = selectCommonTextStyles(theme);
    return {
        control: (baseStyles) => ({
            ...baseStyles,
            minHeight: '24px',
            height: '24px',
            minWidth: '80px',
            boxShadow: 'none',
            padding: '0px 5px 0px 8px',
            gap: '8px',
            borderRadius: '2px',
            border: `1px solid ${theme.colors.gray200}`,
            ':hover': {
                cursor: 'pointer',
                border: `1px solid ${theme.colors.gray200}`
            },
            alignItems: 'center',
            justifyContent: 'center'
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            ...textStyles,
            margin: 0,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }),
        noOptionsMessage: (baseStyles) => ({
            ...baseStyles,
            ...textStyles,
            padding: '0 2px'
        }),
        loadingMessage: (baseStyles) => ({
            ...baseStyles,
            ...textStyles,
            padding: '0 2px'
        }),
        input: (baseStyles) => ({
            ...baseStyles,
            ...textStyles,
            margin: 0
        }),
        indicatorSeparator: (baseStyles, { hasValue, selectProps: { isClearable } }) => ({
            ...baseStyles,
            margin: '8px 4px',
            ...(!isClearable || !hasValue ? { display: 'none' } : {})
        }),
        valueContainer: (baseStyles) => ({
            ...baseStyles,
            ...textStyles,
            padding: 0
        }),
        clearIndicator: (baseStyles) => ({
            ...baseStyles,
            padding: 0,
            color: theme.colors.gray700
        }),
        dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            padding: 0,
            transition: 'none',
            svg: {
                display: 'block',
                width: '20px',
                height: '20px',
                color: theme.colors.gray700
            },
            zIndex: 999
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            ...textStyles,
            margin: 0,
            color: `${theme.colors.blackAlpha500}`
        }),
        multiValue: (baseStyles) => ({
            ...baseStyles,
            margin: 0,
            backgroundColor: 'none',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }),
        multiValueRemove: () => ({
            display: 'none'
        }),
        multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            ...textStyles,
            padding: 0,
            paddingLeft: 0
        }),
        menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: 6,
            boxShadow: 'none',
            background: 'white',
            border: `1px solid ${theme.colors.gray200}`
        }),
        menuList: (baseStyles) => ({
            ...baseStyles,
            padding: '4px 0',
            overflowX: 'hidden'
        }),
        option: (baseStyles, { isDisabled }) => ({
            ...baseStyles,
            ...textStyles,
            lineHeight: '18px',
            padding: '6px 8px 6px 12px',
            overflowWrap: 'anywhere',
            background: 'none',
            ':active': {
                background: theme.colors.gray50
            },
            ':hover': {
                background: theme.colors.gray50
            },
            ...(isDisabled ? {} : { cursor: 'pointer' })
        })
    };
}
