import styled from 'styled-components';
import { CustomClassicCKEditor } from '@campus/components';
const { elementClassNames, customProperties } = CustomClassicCKEditor;
export const StyledWrapper = styled.div `
  ${customProperties.borderRadius}: 6px;

  & ${elementClassNames.holeEditor} {
    width: 45dvw;
  }

  & ${elementClassNames.editableArea} {
    font-size: 14px;
  }

  & ${elementClassNames.toolbar} {
    padding: 0 12px;
  }

  & ${elementClassNames.contentPlaceholder}::before {
    cursor: auto;
    color: ${(props) => props.theme.colors.gray300};
  }

  & ${elementClassNames.body}, & ${elementClassNames.editableArea} {
    height: 45dvh !important;
  }

  & ${elementClassNames.editableArea} {
    padding: 8.5px 12px !important;
  }

  & ${elementClassNames.holeEditor} a {
    cursor: pointer;
    color: ${(props) => props.theme.colors.blue1};

    :hover {
      text-decoration: underline;
      color: ${(props) => props.theme.colors.CSA.darkBlue};
    }
  }

  & ${elementClassNames.holeEditor} p {
    margin: 0 !important;
  }

  & ${elementClassNames.holeEditor} p,
  & ${elementClassNames.holeEditor} a,
  & ${elementClassNames.contentPlaceholder}::before {
    font-weight: normal;
  }

  & ${elementClassNames.holeEditor} b,
  & ${elementClassNames.holeEditor} strong {
    font-weight: ${(props) => props.theme.weight.bold};
  }

  & ${elementClassNames.editableArea} figure {
    min-width: 0;
    margin: 0.9em 0;
  }

  @media (max-height: 650px) {
    & ${elementClassNames.body}, & ${elementClassNames.editableArea} {
      height: 50dvh !important;
    }
  }
`;
