import { getDistinct } from '../../../../utils/arrays';
function mapSkillsAndStagesToSkillsInfos(skills, mainTableContent) {
    const areaSkills = getDistinct(skills, (s) => s.id);
    return areaSkills.map((skill) => {
        const isInMainTable = mainTableContent?.some((group) => {
            return group?.plannings.some((planning) => {
                return planning?.areaSkill?.id === skill?.id;
            });
        });
        return { skill, isInMainTable };
    });
}
export function createTableContent(data, store) {
    const content = [];
    for (const items of data) {
        if (!items || items.length === 0)
            continue;
        for (const skills of items) {
            if (!skills)
                continue;
            if (skills.length === 0)
                continue;
            const infos = mapSkillsAndStagesToSkillsInfos(skills, store.mainTableContent);
            const { themeUnit, discipline } = skills[0];
            content.push({ skills: infos, themeUnit, discipline });
        }
    }
    return content;
}
