import { Paragraph, TableCell, TextRun, VerticalAlign, WidthType } from 'docx';
import { teacherTheme } from '@campus/components';
import { emptyBorder } from '../../../../../common/borderDocx';
import { columnBorder, createColumnPadding } from './common';
import { fontSizes, margins, widths } from '../../../../../common/sizesDocx';
const padding = createColumnPadding(widths.table.columns.annualTimeline.knowledgeObjects);
function createObjectDescription(description) {
    return new Paragraph({
        indent: {
            left: margins.table.body.columns.left,
            right: margins.table.body.columns.right
        },
        children: [
            new TextRun({
                bold: false,
                text: description?.trim(),
                color: teacherTheme.colors.gray700,
                size: fontSizes.table.columnContent
            })
        ]
    });
}
export default function KnowledgeObjects(objects) {
    const descriptions = objects.map((o) => {
        return createObjectDescription(o?.description);
    });
    return new TableCell({
        verticalAlign: VerticalAlign.TOP,
        children: [padding, ...descriptions, padding],
        width: {
            size: widths.table.columns.annualTimeline.knowledgeObjects,
            type: WidthType.PERCENTAGE
        },
        borders: {
            top: columnBorder,
            bottom: columnBorder,
            right: columnBorder,
            left: emptyBorder
        }
    });
}
