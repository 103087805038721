import styled from 'styled-components';
export const StyledWrapper = styled.div `
  background-color: white;

  max-width: ${(props) => props.$maxWidth}px;

  border-style: solid;
  border-width: 0;
  border-right-width: ${(props) => (props.$removeRightBorder ? '0' : '1px')};
  border-color: ${(props) => props.theme.colors.gray200};

  &:first-child {
    border-left-width: 1px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
