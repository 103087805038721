import { updateSlice } from './helpers';
export const createFiltersSlice = (set) => ({
    filteredStageId: null,
    filteredGradeId: null,
    filteredSegmentId: null,
    filteredDisciplineId: null,
    filteredSkillCode: '',
    setFilteredDisciplineId(id) {
        set((store) => {
            return updateSlice(store, { filteredDisciplineId: id });
        });
    },
    setFilteredGradeId(id) {
        set((store) => {
            return updateSlice(store, { filteredGradeId: id });
        });
    },
    setFilteredSegmentId(id) {
        set((store) => {
            return updateSlice(store, { filteredSegmentId: id });
        });
    },
    setFilteredStageId(id) {
        set((store) => {
            return updateSlice(store, { filteredStageId: id });
        });
    },
    setFilteredSkillCode(code) {
        set((store) => {
            return updateSlice(store, { filteredSkillCode: code });
        });
    },
    resetFilters() {
        set((store) => {
            return updateSlice(store, {
                filteredStageId: null,
                filteredGradeId: null,
                filteredSegmentId: null,
                filteredDisciplineId: null,
                filteredSkillCode: ''
            });
        });
    }
});
