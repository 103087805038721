import React from 'react';
import { StyledCheckbox, StyledLabel, StyledWrapper } from './styles';
const Discipline = (props) => {
    const isCheckboxChecked = props.selectedDisciplines?.some((d) => {
        return d.id === props.discipline?.id;
    });
    function onClick() {
        if (isCheckboxChecked) {
            props.removeFromSelectedDisciplines(props.discipline?.id);
        }
        else {
            props.addToSelectedDisciplines(props.discipline?.id);
        }
    }
    return (React.createElement(StyledWrapper, { onClick: onClick },
        React.createElement(StyledCheckbox, { checked: isCheckboxChecked }),
        React.createElement(StyledLabel, null, props.discipline?.name)));
};
export default Discipline;
