import React from 'react';
import Group from './components/Group';
import { StyledTableBodyWrapper } from '../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/commonStyles';
const TableBody = (props) => {
    const { tableInstance } = props;
    function renderRows() {
        return tableInstance.rows.map((row) => {
            tableInstance.prepareRow(row);
            return React.createElement(Group, { key: row.id, group: row.original });
        });
    }
    return (React.createElement(StyledTableBodyWrapper, { ...tableInstance.getTableBodyProps() }, renderRows()));
};
export default TableBody;
