import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useAddOrEditActivityPopupContext } from '../../../../context';
import Circle from '../../../../../../../PageRelated/Subtitle/components/Circle';
import { RequestStatus } from '../../../../../../../../types/dataFetching';
import { createOptions, createValue, getSelectorState, shouldUseGrayColor } from './helpers';
import { StyledOptionLabel, StyledOptionLabelAndIconWrapper, StyledSelect } from './styles';
const statusesAndPlaceholders = Object.freeze({
    [RequestStatus.Loading]: 'Carregando...',
    [RequestStatus.Failed]: 'Falha ao carregar tipos'
});
const defaultValue = Object.freeze({
    label: 'Selecione uma opção',
    isAPlaceholder: true,
    evaluationType: null
});
const Selector = () => {
    const theme = useTheme();
    const { impactTheMDC, evaluationTypes, selectedEvaluationType, setImpactTheMDC, setSelectedEvaluationType } = useAddOrEditActivityPopupContext((store) => ({
        impactTheMDC: store.impactTheMDC,
        evaluationTypes: store.evaluationTypes,
        selectedEvaluationType: store.selectedEvaluationType,
        setImpactTheMDC: store.setImpactTheMDC,
        setSelectedEvaluationType: store.setSelectedEvaluationType
    }));
    const options = useMemo(() => {
        return createOptions(evaluationTypes, defaultValue);
    }, [evaluationTypes]);
    const state = getSelectorState(evaluationTypes.status);
    const placeholder = statusesAndPlaceholders[evaluationTypes.status];
    const value = createValue(selectedEvaluationType, impactTheMDC, placeholder);
    function onOptionChange(selectedOption) {
        setImpactTheMDC(selectedOption?.impactTheMDC);
        setSelectedEvaluationType(selectedOption?.evaluationType);
    }
    function formatOptionLabel(option) {
        const showCircle = !option.isAPlaceholder;
        const color = showCircle
            ? shouldUseGrayColor(option)
                ? theme.colors.gray4
                : option.evaluationType?.color
            : null;
        return (React.createElement(StyledOptionLabelAndIconWrapper, null,
            showCircle ? React.createElement(Circle, { color: color, borderColor: color }) : null,
            React.createElement(StyledOptionLabel, null, option.label)));
    }
    return (React.createElement(StyledSelect, { value: value, options: options, isLoading: state.isLoading, isDisabled: state.isDisabled, onChange: onOptionChange, formatOptionLabel: formatOptionLabel }));
};
export default Selector;
