import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Select, SelectOption, Skeleton } from '@campus/components';

import ReportActions from './ReportActions';

import { useActivityReport } from '../context/ActivityReportContext';
import { Divider } from '../styles';
import DowloadAction from './DowloadAction';

const Root = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  p {
    margin: 0;
    font-weight: ${(props) => props.theme.weight.bold};
    color: ${(props) => props.theme.colors.gray300};
    font-size: 14px;
  }
`;

const ReportTableHeader: React.FC = () => {
  const { loading, classFilter, classrooms, changeClassroom } =
    useActivityReport();

  const onChange = (item: SelectOption) => {
    changeClassroom(item?.value as string);
  };

  const options = useMemo(() => {
    const classroomsMapped = [{ value: '', label: 'TODAS' }];

    classrooms.sort((c1, c2) => c1.name.trim().localeCompare(c2.name.trim()));
    classrooms.forEach((i) => {
      classroomsMapped.push({
        value: i.id,
        label: i.name
      });
    });

    return classroomsMapped;
  }, [classrooms]);

  const defaultOption = useMemo(
    () =>
      classFilter ? { value: classFilter?.id, label: classFilter?.name } : null,
    [classFilter]
  );

  const selectStyle = {
    menu: (base) => ({
      ...base,
      marginTop: 3,
      paddingTop: 4,
      paddingBottom: 4
    }),
    control: () => ({
      display: 'flex',
      border: '1px solid #cccccc',
      borderRadius: '6px',
      height: '32px',
      alignItems: 'center',
      padding: 1
    }),
    dropdownIndicator: (_, state) => ({
      color: '#4D4D4D',
      width: 25,
      height: 27
    }),
    indicatorContainer: (base) => ({
      ...base,
      width: 23,
      height: 25
    }),
    option: (base, state) => ({
      ...base,
      '&:hover': {
        background: '#e9ecef'
      },
      color: '#212529',
      fontFamily: 'Gilroy',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '110%',
      backgroundColor: state.isSelected ? 'rgba(255, 255, 255, 0.4)' : 'white'
    }),    
    placeholder: (base) => ({
      ...base,
      fontSize: 14,
      color: '#4D4D4D',
      fontFamily: 'Gilroy'
    }),
    input: (base) => ({
      ...base,  
      caretColor: 'transparent',
    }),
    singleValue: (base) => ({
      ...base,  
      color: '#4D4D4D',
      fontFamily: 'Gilroy',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '110%',
    }),
  };

  return (
    <>
      <Root>
        <ReportActions />
        <Container>
          <Container>
            <p>Filtrar por:</p>
            {loading ? (
              <Skeleton height="38px" width="140px" />
            ) : (
              <Select
                value={defaultOption}
                placeholder="TODAS"
                options={options}
                onChange={onChange}
                styles={selectStyle}
              />
            )}
          </Container>
          <DowloadAction />
        </Container>
      </Root>
      <Divider />
    </>
  );
};

export default ReportTableHeader;
