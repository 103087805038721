import styled from 'styled-components';
import { Input } from '@campus/components';

export const Content = styled.div`
  padding: 20px;
`;

export const Divider = styled.hr`
  color: ${(props) => props.theme.colors.gray5};
  opacity: 1;
  height: 1;
  margin: 0;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${(props) => props.theme.mediaQuery.desktopDown} {
    height: 100px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.colors.gray800};
  font-weight: ${(props) => props.theme.weight.semiBold};
  font-size: 25px;
  margin: 0;
`;

export const TextareaContainer = styled.div`
  margin: 10px 0;
`;

export const ScoreInput = styled(Input)`
  margin: 0 10px;
  width: 45px;

  input {
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const ScoreContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  p {
    margin: 0px;
    color: ${(props) => props.theme.colors.gray300};
    font-weight: ${(props) => props.theme.weight.bold};
  }

  span {
    color: ${(props) => props.theme.colors.gray300};
    font-weight: ${(props) => props.theme.weight.regular};
  }

  .invalid-feedback {
    position: absolute;
  }
`;

export const ScoreTag = styled.div`
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.weight.bold};
  font-size: 16px;
  padding: 3px 8px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
`;

export const TeacherComment = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.colors.gray600};
  font-weight: ${(props) => props.theme.weight.semiBold};
  margin: 0;
`;

export const ActionContainer = styled.div`
  display: flex;
  font-size: 12px;
  color: ${(props) => props.theme.colors.gray3};
  font-weight: ${(props) => props.theme.weight.semiBold};

  p {
    margin: 0;
  }

  button {
    font-size: 12px;
    color: ${(props) => props.theme.colors.gray3};
    font-weight: ${(props) => props.theme.weight.semiBold};
    background-color: transparent;
    border: none;
    text-decoration: underline;
    padding: 0;
  }
`;

export const CommonAnswerContainer = styled.div`
  padding: 14px;
  cursor: pointer;
`;

export const CommonAnswerCard = styled.div`
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 6px;
  width: 100%;
`;

interface ICommonAnswerHeaderProps {
  $level: number;
}

export const CommonAnswerHeader = styled.div<ICommonAnswerHeaderProps>`
  font-weight: 800;
  font-size: 11px;
  line-height: 20px;
  color: ${({ $level }) =>
    $level === 0 ? '#6fcf97' : $level === 1 ? '#F2994A' : '#EB5757'};
`;

export const CommonAnswerContent = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
`;
