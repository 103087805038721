import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const StyledWrapper = styled.div `
  ${(props) => (props.$disabled ? '' : 'cursor: pointer;')}
`;
export const StyledIcon = styled(CampusIcon).attrs({
    name: 'trash'
}) `
  width: 16px;
  height: 16px;

  vertical-align: top;

  color: ${(props) => props.$color ? props.$color : props.theme.colors.gray500};
`;
