import React from 'react';
import { Container, ChartBarQuestionOpen, ChartBar } from '../styles';

type ProgressBarProps = {
  percentage: number;
  correct?: boolean;
  alignRight?: boolean;
  children?: React.ReactNode;
  isOpenQuestion?: boolean;
  range?: number;
  borderRadius?: string;
};

const ProgressBar = React.forwardRef<HTMLDivElement, ProgressBarProps>(
  (
    {
      percentage,
      isOpenQuestion,
      range,
      alignRight,
      correct,
      children,
      borderRadius,
      ...props
    },
    ref
  ) => {
    if (percentage === 0) {
      return null;
    }
    
    return (
      <Container
        ref={ref}
        {...props}
        $percentage={percentage}
        $correct={correct}
      >
        {isOpenQuestion ? (
          <ChartBarQuestionOpen
            $alignRight={alignRight}
            $range={range}
            $correct={true}
            $borderRadius={borderRadius}
          >
            {children}
            <p>{percentage}%</p>
          </ChartBarQuestionOpen>
        ) : (
          <ChartBar
            $alignRight={alignRight}
            $correct={correct}
            $borderRadius={borderRadius}
          >
            {children}
          </ChartBar>
        )}
      </Container>
    );
  }
);

export default ProgressBar;
