import React, { useState } from 'react';
import PopupWithList from '../../../../../../../../../../Popups/PopupWithList/PopupWithList';
import EditActivityPopup from '../../../../../../../../../../Popups/EditActivityPopup';
import { times } from '../../../../../../../../../../../constants';
const OptionsPopup = (props) => {
    const [canShowEditPopup, setCanShowEditPopup] = useState(false);
    const hideEditPopup = () => setCanShowEditPopup(false);
    function handleActivityUpdated(updatedSequence) {
        props.updateActivity(updatedSequence);
        setTimeout(() => {
            setCanShowEditPopup(false);
        }, times.toHideAModalOrPopupOnSuccess);
    }
    async function onEditOptionClick() {
        setCanShowEditPopup(true);
    }
    async function onRemoveOptionClick() {
        await props.handleRemoveOptionClick();
    }
    async function onDuplicateOptionClick() {
        await props.handleDuplicateOptionClick();
    }
    async function onMoveUpOptionClick() {
        await props.handleMoveUpOptionClick();
    }
    async function onMoveDownOptionClick() {
        await props.handleMoveDownOptionClick();
    }
    const items = [
        {
            label: 'Editar',
            onClick: onEditOptionClick,
            disabled: props.disableEdit ?? false
        },
        {
            label: 'Remover',
            onClick: onRemoveOptionClick,
            disabled: props.disableRemove ?? false
        },
        {
            label: 'Duplicar',
            onClick: onDuplicateOptionClick,
            disabled: props.disableDuplicate ?? false
        },
        {
            label: 'Mover para cima',
            onClick: onMoveUpOptionClick,
            disabled: props.disableMoveUp ?? false
        },
        {
            label: 'Mover para baixo',
            onClick: onMoveDownOptionClick,
            disabled: props.disableMoveDown ?? false
        }
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(PopupWithList, { items: items, className: props.className, canBeDisplayed: props.canBeDisplayed, handleExternalClick: props.handleExternalClick }),
        React.createElement(EditActivityPopup, { canBeDisplayed: canShowEditPopup, sequence: props.sequence, planning: props.planning, disciplines: props.disciplines, classroomGroups: props.classroomGroups, handleExternalClick: hideEditPopup, handleCancelButtonClicked: hideEditPopup, handleActivityUpdated: handleActivityUpdated })));
};
export default OptionsPopup;
