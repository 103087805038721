import React from 'react';
import { SegmentAndGrade, CurriculumComponents, Classes, StartDate, EndDate } from './components';
import { Container, Group } from './styles';
export const Filters = () => {
    return (React.createElement(Container, null,
        React.createElement(Group, null,
            React.createElement(SegmentAndGrade, null),
            React.createElement(CurriculumComponents, null),
            React.createElement(Classes, null)),
        React.createElement(Group, null,
            React.createElement(StartDate, null),
            React.createElement(EndDate, null))));
};
