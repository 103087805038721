import styled from 'styled-components';
export const StyledWrapper = styled.div `
  z-index: 1;

  position: absolute;
  ${(props) => (props.$top >= 0 ? `top: ${props.$top}px;` : '')}
  ${(props) => (props.$bottom >= 0 ? `bottom: ${props.$bottom}px;` : '')}

  ${(props) => {
    if (!props.$hideChildren)
        return;
    return `
      height: 0;
      overflow: hidden;
    `;
}}
`;
