import React from 'react';
import { pdf } from '@react-pdf/renderer';
import { capitalizeFirstLetter, useContextManager } from '@campus/commons';
import { downloadFile } from './common';
import ClassDiaryReport from '../ClassDiaryReport';
function getTeachersNames(teachers) {
    const capitalizedNames = teachers.map((teacher) => capitalizeFirstLetter(teacher.name));
    if (capitalizedNames.length === 1) {
        return capitalizedNames[0];
    }
    else if (capitalizedNames.length === 2) {
        return `${capitalizedNames[0]} e ${capitalizedNames[1]}`;
    }
    else {
        const lastTeacher = capitalizedNames.pop();
        return `${capitalizedNames.join(', ')} e ${lastTeacher}`;
    }
}
export function useClassDiaryReport(props) {
    const { period } = useContextManager();
    async function download(content, data, selectedClass) {
        const filename = 'Relatório de Conteúdos';
        const teacherName = data.teachers?.length > 0 ? getTeachersNames(data.teachers) : '';
        let blob;
        if (props.type === 'pdf') {
            const report = pdf(React.createElement(ClassDiaryReport, { content: content, information: {
                    discipline: props.selectedDisciplines[0]?.name || '',
                    teacherName: teacherName,
                    selectedGrade: props.selectedGrade,
                    period: period.name,
                    selectedClass,
                    lastModificationDate: data.lastModificationDateFormated
                } }));
            blob = await report.toBlob();
        }
        await downloadFile(blob, filename);
    }
    return { download };
}
