import React from 'react';
import { formatDate, getWeekDayFrom } from './helpers';
import { StyledDate, StyledWeekday, StyledWrapper } from './styles';
const Date = (props) => {
    const classroomDate = props.infos.selectedClassroom?.date;
    const date = classroomDate ? formatDate(classroomDate) : '';
    const weekday = classroomDate ? getWeekDayFrom(classroomDate) : '';
    return (React.createElement(StyledWrapper, null,
        React.createElement(StyledWeekday, null, weekday),
        React.createElement(StyledDate, null, date)));
};
export default Date;
