import styled from 'styled-components';
export const StyledWrapper = styled.div `
  display: grid;
  row-gap: 8px;
  column-gap: 10px;
  grid-template-columns: 1fr 1fr;
`;
export const StyledNoContentMessageWrapper = styled.div `
  min-width: 180px;
`;
export const StyledNoContentMessage = styled.i `
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: ${(props) => props.theme.colors.gray3};
`;
