import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const Container = styled.div `
  position: absolute;
  top: 100%;
  left: 50%;
  translate: -50%;

  & > div {
    display: block;
    position: static;
  }
`;
export const Icon = styled(CampusIcon).attrs({
    name: 'tooltipBalloon'
}) `
  display: block;

  color: #4a4a4a;
`;
export const Text = styled.span `
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;

  white-space: nowrap;

  font-size: 12px;
  font-weight: 500;

  color: ${({ theme }) => theme.colors.white};
`;
