import { useCallback, useEffect } from 'react';
import { useFetch } from './useFetch';
import { getAllPlanningStatuses } from '../../../../services/pedagogicalPlanningStatus';
export function useFetchPlanningStatuses() {
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess } = useFetch({
        initialData: []
    });
    const fetchPlanningStatuses = useCallback((abortSignal) => {
        fetchStarted();
        return getAllPlanningStatuses({ abortSignal })
            .then((response) => handleSuccess(response.data))
            .catch(handleFailure);
    }, [fetchStarted, handleFailure, handleSuccess]);
    useEffect(() => {
        const abortController = new AbortController();
        fetchPlanningStatuses(abortController.signal);
        return () => abortController.abort();
    }, [fetchPlanningStatuses]);
    return { data, requestStatus };
}
