import styled from 'styled-components';
import { Table } from '../../../../TableCommons';
export const Container = styled(Table.Container) ``;
export const Header = styled(Table.Header) `
  th:nth-child(1) {
    min-width: 402px;
  }

  th:nth-child(2) {
    min-width: 94px;
  }

  th:nth-child(3) {
    min-width: 69px;
  }

  th:nth-child(4) {
    min-width: 106px;
  }
`;
export const Body = styled(Table.Body) ``;
