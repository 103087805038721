export default class ClassroomReportRelatedInformation {
    weeklyWorkload;
    approximateNumberOfClasses;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new ClassroomReportRelatedInformation();
        instance.weeklyWorkload = json.WeeklyClass;
        instance.approximateNumberOfClasses = json.TotalClass;
        return instance;
    }
}
