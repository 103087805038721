import styled from 'styled-components';
import { StyledColumnWrapper } from '../common/styles';
export const StyledWrapper = styled(StyledColumnWrapper) `
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledTextWrapper = styled.div `
  height: 24px;
  min-width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;

  border: 1px solid ${(props) => props.theme.colors.gray200};
`;
export const StyledText = styled.span `
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
`;
