export function sortItems(items) {
    const locale = 'br';
    const comparisonOptions = { sensitivity: 'accent' };
    return items.sort((first, second) => {
        return first.name?.localeCompare(second.name, locale, comparisonOptions);
    });
}
export function compareAreaSkills(firstOne, secondOne) {
    if (!firstOne && !secondOne)
        return 0;
    if (!firstOne)
        return -1;
    if (!secondOne)
        return 1;
    const codesAreEqual = firstOne.code === secondOne.code;
    const themeUnitsAreEqual = firstOne.themeUnit?.id === secondOne.themeUnit?.id;
    const disciplinesAreEqual = firstOne.discipline?.id === secondOne.discipline?.id;
    if (disciplinesAreEqual && themeUnitsAreEqual && codesAreEqual) {
        return compareStages(firstOne.programStages, secondOne.programStages);
    }
    if (disciplinesAreEqual && codesAreEqual) {
        return compareThemeUnits(firstOne.themeUnit, secondOne.themeUnit);
    }
    if (disciplinesAreEqual) {
        return compareSkillCodes(firstOne.code, secondOne.code);
    }
    return compareDisciplines(firstOne.discipline, secondOne.discipline);
}
function compareStages(firstStages, secondStages) {
    const firstNames = firstStages?.map((s) => s.stage?.fullName);
    const secondNames = secondStages?.map((s) => s.stage?.fullName);
    const firstSorted = firstNames?.sort()?.join(',');
    const secondSorted = secondNames?.sort()?.join(',');
    return firstSorted?.localeCompare(secondSorted);
}
function compareThemeUnits(firstUnit, secondUnit) {
    const firstName = firstUnit?.name;
    const secondName = secondUnit?.name;
    return firstName?.localeCompare(secondName);
}
function compareSkillCodes(firstCode, secondCode) {
    return firstCode?.localeCompare(secondCode);
}
function compareDisciplines(firstDiscipline, secondDiscipline) {
    const firstName = firstDiscipline?.name;
    const secondName = secondDiscipline?.name;
    return firstName?.localeCompare(secondName);
}
