import { BorderStyle } from 'docx';
export const emptyBorder = {
    style: BorderStyle.NONE,
    size: 0,
    color: 'FFFFFF'
};
export const emptyTableCellBorders = {
    top: emptyBorder,
    bottom: emptyBorder,
    left: emptyBorder,
    right: emptyBorder
};
