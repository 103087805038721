import { QuestionType } from '@campus/commons';
import InsightAnswer from './InsightAnswer';

class InsightQuestion {
  id?: string;

  count?: number;

  questionType?: QuestionType;

  title?: string;

  weight?: number;

  answers: InsightAnswer[];

  averageScore?: number;

  static fromJson(data: any): InsightQuestion {
    const question = new InsightQuestion();

    question.id = data?.Id;

    question.count = data?.Count;

    question.questionType = data?.QuestionType;

    question.title = data?.Title;

    question.weight = data?.Weight;

    question.answers =
      data.Answers?.map((s) => InsightAnswer.fromJson(s)) ?? [];

    question.averageScore = data?.AverageScore;

    return question;
  }
}

export default InsightQuestion;
