import React, { useState } from 'react';
import SelectedGradeAndSegment from './components/SelectedGradeAndSegment/SelectedGradeAndSegment';
import SelectedDisciplines from './components/SelectedDisciplines';
import { StyledPopup, StyledContentWrapper } from './styles';
const SegmentGradeAndDisciplines = (props) => {
    const [canShowPopup, setCanShowPopup] = useState(false);
    const showPopup = () => setCanShowPopup(true);
    const hidePopup = () => setCanShowPopup(false);
    const { selectedDisciplines, selectedGrade, selectedSegment } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledPopup, { ...props, canBeDisplayed: canShowPopup, handleExternalClick: hidePopup }),
        React.createElement(StyledContentWrapper, null,
            React.createElement(SelectedGradeAndSegment, { onClick: showPopup, grade: selectedGrade, segment: selectedSegment }),
            React.createElement(SelectedDisciplines, { onClick: showPopup, disciplines: selectedDisciplines }))));
};
export default SegmentGradeAndDisciplines;
