import { useCallback, useEffect } from 'react';
import { getAllStages } from '../../../../services/stage';
import { useFetch } from './useFetch';
export function useFetchStages() {
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess } = useFetch({
        initialData: []
    });
    const fetchSemesters = useCallback((abortSignal) => {
        fetchStarted();
        return getAllStages({ abortSignal })
            .then((response) => handleSuccess(response.data))
            .catch(handleFailure);
    }, [fetchStarted, handleFailure, handleSuccess]);
    useEffect(() => {
        const abortController = new AbortController();
        fetchSemesters(abortController.signal);
        return () => abortController.abort();
    }, [fetchSemesters]);
    return { data, requestStatus };
}
