import React from 'react';
import Date from './components/Date';
import ClassName from './components/ClassName';
import ClassBox from './components/ClassBox';
import { StyledWrapper } from './styles';
const ClassLessonAndDate = (props) => {
    function onCheckboxToggle() {
        const isNowChecked = !props.infos.isChecked;
        props.updateIsChecked(isNowChecked);
    }
    function handleOptionClicked(option) {
        props.updateSelectedClassroom(props.infos.allClassrooms.find((c) => {
            return c.id === option.value;
        }));
    }
    return (React.createElement(StyledWrapper, null,
        React.createElement(ClassName, { infos: props.infos, onCheckboxToggle: onCheckboxToggle }),
        React.createElement(ClassBox, { infos: props.infos, handleOptionClicked: handleOptionClicked }),
        React.createElement(Date, { infos: props.infos })));
};
export default ClassLessonAndDate;
