import React from 'react';
import { components } from 'react-select';
export function MultiValueSelectValue(props) {
    let text = null;
    const selectedOptions = props.getValue();
    if (selectedOptions.length === 1) {
        text = props.selectProps.getOptionLabel(selectedOptions[0]);
    }
    else if (props.index === 0) {
        // this will be rendered for each selected option,
        // so the summary will only be rendered if the first
        // option is being rendered (so index equals to 0)
        text = `${selectedOptions.length} itens selecionados`;
    }
    return text ? (React.createElement(components.MultiValue, { ...props }, text)) : null;
}
