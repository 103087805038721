import React, { useMemo } from 'react';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { RequestStatus } from '@campus/commons';
import { useLaunchReportContext } from '../../../../context';
import { useFetchGrades } from '../../hooks';
import { SelectDropdownIndicator } from '../SelectDropdownIndicator';
import { Label } from '../commonStyles';
import { styles } from './styles';
const defaultOption = {
    label: 'Todos os Segmentos/Séries',
    value: null
};
export const SegmentAndGrade = () => {
    const theme = useTheme();
    const { events } = useLaunchReportContext();
    const { data, requestStatus } = useFetchGrades();
    const options = useMemo(() => {
        return [defaultOption, ...(data ?? [])];
    }, [data]);
    function onChange(newValue) {
        if ('value' in newValue) {
            events.filters.gradeIdChanged.emit({ newId: null });
        }
        else {
            events.filters.gradeIdChanged.emit({ newId: newValue.id });
        }
        events.filters.someFilterChanged.emit();
    }
    return (React.createElement("div", null,
        React.createElement(Label, null, "Segmento/ S\u00E9rie"),
        React.createElement(Select, { options: options, defaultValue: defaultOption, styles: styles(theme), hideSelectedOptions: true, onChange: onChange, noOptionsMessage: () => 'Nenhuma opção disponível', loadingMessage: () => 'Carregando segmentos e séries...', components: {
                DropdownIndicator: SelectDropdownIndicator
            }, isLoading: requestStatus === RequestStatus.Loading, getOptionValue: (gradeOrOption) => {
                return 'value' in gradeOrOption
                    ? gradeOrOption.value
                    : gradeOrOption.id;
            }, getOptionLabel: (gradeOrOption) => {
                if ('label' in gradeOrOption) {
                    return gradeOrOption.label;
                }
                const { segment, name } = gradeOrOption;
                return segment?.name ? `${segment.name} - ${name}` : name;
            } })));
};
