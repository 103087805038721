import React from 'react';
import StageFilter from './components/StageFilter';
import DisciplineFilter from './components/DisciplineFilter';
import SegmentAndSeriesFilter from './components/SegmentAndSeriesFilter';
import { useFetchDisciplines } from './hooks/useFetchDisciplines';
import { StyledWrapper } from './styles';
const Filters = () => {
    const { data: disciplines } = useFetchDisciplines();
    return (React.createElement(StyledWrapper, null,
        React.createElement(SegmentAndSeriesFilter, { disciplines: disciplines }),
        React.createElement(DisciplineFilter, { disciplines: disciplines }),
        React.createElement(StageFilter, null)));
};
export default Filters;
