import { useCallback, useEffect } from 'react';
import { useFetch } from './useFetch';
import { getAllSegments } from '../../../../services/segment';
export function useFetchSegments() {
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess } = useFetch({
        initialData: []
    });
    const fetchSegments = useCallback((abortSignal) => {
        fetchStarted();
        return getAllSegments(null, { abortSignal })
            .then((response) => handleSuccess(response.data))
            .catch(handleFailure);
    }, [fetchStarted, handleFailure, handleSuccess]);
    useEffect(() => {
        const abortController = new AbortController();
        fetchSegments(abortController.signal);
        return () => abortController.abort();
    }, [fetchSegments]);
    return { data, requestStatus };
}
