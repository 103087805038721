import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const StyledWrapper = styled.div `
  & > div {
    display: block;
    position: relative;
  }
`;
export const StyledIcon = styled(CampusIcon).attrs({
    name: 'selectArrowDown'
}) `
  vertical-align: top;

  width: 20px;
  height: 24px;

  stroke-width: 0;

  color: ${(props) => props.$color ? props.$color : props.theme.colors.gray700};
`;
