import PedagogicalPlanning from '../../../../../../../../../../../../models/PedagogicalPlanning';
import { useGroupContext } from '../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import { useFollowUpPageContext } from '../../../../../../../../../../context';
import { deletePlanningAndAssociatedThings } from '../../../../../../../../../../../../services/agregators';
export function useRemovePlanning() {
    const group = useGroupContext((store) => store.group);
    const { planningStatuses, updatePlanning } = useFollowUpPageContext((store) => ({
        planningStatuses: store.commonData.planningStatuses.data,
        updatePlanning: store.mainTable.operations.updatePlanning
    }));
    function createUpdatedPlanning(planning, status) {
        const newPlanning = new PedagogicalPlanning();
        Object.assign(newPlanning, planning);
        newPlanning.status = status;
        return newPlanning;
    }
    function removePlanning(planning) {
        return new Promise((resolve, reject) => {
            const status = planningStatuses?.find((s) => {
                return s?.isDeleted();
            });
            if (!status)
                reject();
            deletePlanningAndAssociatedThings({
                planning,
                associatedGroup: group,
                deletedStatusId: status?.id
            })
                .then(() => {
                updatePlanning(createUpdatedPlanning(planning, status));
                resolve();
            })
                .catch(reject);
        });
    }
    return { removePlanning };
}
