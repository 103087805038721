import styled from 'styled-components';
export const StyledTitle = styled.h4 `
  margin: 0;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;

  color: ${(props) => props.theme.colors.gray1};
`;
export const StyledMessageWrapper = styled.div `
  display: flex;
  align-items: center;

  margin-right: 30px;
`;
export const StyledMessage = styled.i `
  white-space: nowrap;

  color: ${(props) => props.theme.colors.gray500};
`;
export const StyledBasicButton = styled.button `
  height: 32px;

  border-radius: 5px;

  font-size: 14px;
  font-weight: 500;

  ${(props) => {
    if (props.disabled) {
        return 'opacity: 0.8;';
    }
    return `
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }
    `;
}}
`;
export const StyledBlueButton = styled(StyledBasicButton) `
  padding: 0 16px;

  color: white;
  background-color: ${(props) => props.theme.colors.blue1};

  border: 1px solid ${(props) => props.theme.colors.blue1};
`;
export const StyledCancelButton = styled(StyledBasicButton) `
  padding: 0 15px;

  background-color: white;
  color: ${(props) => props.theme.colors.gray4};

  border: 1px solid ${(props) => props.theme.colors.gray4};
`;
export const StyledCloseButton = styled(StyledBasicButton) `
  padding: 0 12px;

  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.gray200};

  background-color: white;
  color: ${(props) => props.theme.colors.gray500};
`;
