import React from 'react';
import ArrowIcon from '../../../common/ArrowIcon';
import { StyledName, StyledSeparator, StyledWrapper } from './styles';
import { StyledNoComponentSelectedMessage } from '../common/styles';
const SelectedGradeAndSegment = (props) => {
    return (React.createElement(StyledWrapper, { onClick: props.onClick },
        !!props.segment ? (React.createElement(StyledName, null, props.segment.name)) : (React.createElement(StyledNoComponentSelectedMessage, null, "Segmento n\u00E3o selecionado")),
        React.createElement(StyledSeparator, { "$darker": !!props.segment || !!props.grade }, "-"),
        !!props.grade ? (React.createElement(StyledName, null, props.grade.name)) : (React.createElement(StyledNoComponentSelectedMessage, null, "Ano n\u00E3o selecionado")),
        React.createElement(ArrowIcon, null)));
};
export default SelectedGradeAndSegment;
