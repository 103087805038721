import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import styles from './styles';
const Skill = (props) => {
    return (React.createElement(View, { style: styles.wrapper },
        React.createElement(Text, { widows: 1, style: {
                ...styles.codeAndDescription,
                fontWeight: props.areaSkill.evaluative ? 700 : 600
            } },
            React.createElement(Text, { style: {
                    color: props.areaSkill?.discipline?.color
                } },
                "(",
                props.areaSkill.code?.trim(),
                ")"),
            React.createElement(Text, { style: styles.description },
                ' ',
                props.areaSkill.description?.trim()))));
};
export default Skill;
