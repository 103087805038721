import { useCallback, useEffect, useState } from 'react';
import { useAddSkillPopupContext } from '../../../../../../../../context';
import { createOptionsFrom } from '../../../../../../common/helpers';
const defaultOption = {
    label: 'Todos os segmentos/séries',
    value: null
};
export function useOptionsManager(props) {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const { filteredDisciplineId, setFilteredGradeId } = useAddSkillPopupContext((store) => ({
        setFilteredGradeId: store.filters.setFilteredGradeId,
        filteredDisciplineId: store.filters.filteredDisciplineId
    }));
    const createOptions = useCallback((disciplines) => {
        if (!disciplines)
            return [];
        if (disciplines.length === 0)
            return [];
        const grades = disciplines.reduce((map, d) => {
            if (!!d.grade)
                map.set(d.grade.id, d.grade);
            return map;
        }, new Map());
        return createOptionsFrom({
            defaultOption,
            items: [...grades.values()],
            getItemValue: (g) => g?.id,
            getItemLabel: (g) => g?.segment?.name + ' - ' + g?.name
        });
    }, []);
    useEffect(() => {
        let disciplines = props.disciplines;
        if (!!filteredDisciplineId) {
            disciplines = disciplines.filter((d) => {
                return d.id === filteredDisciplineId;
            });
        }
        const options = createOptions(disciplines);
        setOptions(options);
        if (options.every((option) => option.value !== selectedOption.value)) {
            setSelectedOption(defaultOption);
        }
    }, [props.disciplines, filteredDisciplineId]);
    function onOptionChange(option) {
        setSelectedOption(option);
        setFilteredGradeId(option.value);
    }
    return { options, selectedOption, onOptionChange };
}
