import { useCallback, useRef, useState } from 'react';
import { RequestStatus } from '../../types/dataFetching';
import { times } from '../../constants';
import { isCancellation } from '../../utils/dataFetching';
export function useFetch(props) {
    const [data, setData] = useState(props?.initialData);
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const initialDataRef = useRef(props?.initialData);
    const fetchStarted = useCallback((params) => {
        if (!params?.keepData) {
            setData(initialDataRef.current);
        }
        setRequestStatus(RequestStatus.Loading);
    }, []);
    const handleSuccess = useCallback((data) => {
        if (data)
            setData(data);
        setRequestStatus(RequestStatus.Succeeded);
    }, []);
    const handleFailure = useCallback((error) => {
        if (!error || !isCancellation(error)) {
            setData(initialDataRef.current);
            setRequestStatus(RequestStatus.Failed);
        }
    }, []);
    const resetDataAndStatus = useCallback(() => {
        setData(initialDataRef.current);
        setRequestStatus(RequestStatus.NotStarted);
    }, []);
    const resetData = useCallback(() => {
        setData(initialDataRef.current);
    }, []);
    const resetStatus = useCallback((params) => {
        const reset = () => {
            if (params?.condition?.()) {
                setRequestStatus(RequestStatus.NotStarted);
            }
        };
        if (params?.afterDelay) {
            setTimeout(reset, times.toResetARequestStatus);
        }
        else {
            reset();
        }
    }, []);
    return {
        data,
        requestStatus,
        fetchStarted,
        resetStatus,
        resetData,
        resetDataAndStatus,
        handleFailure,
        handleSuccess
    };
}
