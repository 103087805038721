import styled from 'styled-components';
import { classNames } from '../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/components/Rows/components/Cell/classNames';
export const StyledWrapper = styled.tr `
  height: 1px;

  &:first-child :first-child .${classNames.cell} {
    border-top-left-radius: 4px;
  }

  &:first-child :first-child .${classNames.cell} :first-child {
    border-top-left-radius: 4px;
  }

  &:last-child :first-child .${classNames.cell} {
    border-bottom-left-radius: 4px;
  }

  &:last-child :first-child .${classNames.cell} :first-child {
    border-bottom-left-radius: 4px;
  }

  & .${classNames.cell}:not(.${classNames.cellWithoutRightBorder}) {
    border-right: 1px solid ${(props) => props.theme.colors.gray200};
  }

  ${(props) => {
    const red = props.theme.colors.error;
    const gray = props.theme.colors.gray200;
    const currentRowBorder = `
      ${props.$currentRowBorder.isDashed ? '2px' : '1px'}
      ${props.$currentRowBorder.isDashed ? 'dashed' : 'solid'}
      ${props.$currentRowBorder.isRed ? red : gray}
    `;
    const lastRowBorder = `
      ${props.$lastRowBorder.isDashed ? '2px' : '1px'}
      ${props.$lastRowBorder.isDashed ? 'dashed' : 'solid'}
      ${props.$lastRowBorder.isRed ? red : gray}
    `;
    return `
      & :first-child .${classNames.cell} {
        border-left: ${currentRowBorder};
      }

      & .${classNames.cellThatSpanAcrossAllRows} {
        border-top: ${currentRowBorder};
        border-bottom: ${lastRowBorder};
      }

      &:first-child .${classNames.cell}:not(.${classNames.cellThatSpanAcrossAllRows}) {
        border-top: ${currentRowBorder};
      }

      & .${classNames.cell}:not(.${classNames.cellThatSpanAcrossAllRows}) {
        border-bottom: ${currentRowBorder};
      }
    `;
}}
`;
