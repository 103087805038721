export default class KnowledgeObject {
    id;
    description;
    static fromJson(json) {
        if (!json)
            return;
        const knowledgeObject = new KnowledgeObject();
        knowledgeObject.id = json.Id;
        knowledgeObject.description = json.Name;
        return knowledgeObject;
    }
}
