import { Api } from '@campus/auth';
import { Segment, Response, PaginationModel } from '@campus/commons';
const endpoints = {
    getAll: '/segment'
};
export async function getAllSegments(name, params = null) {
    try {
        const apiInstance = Api.INSTANCE.getApi();
        const response = await apiInstance.get(endpoints.getAll, {
            params: { Name: name, MaxResultCount: 1000 },
            signal: params.abortSignal
        });
        const paginated = PaginationModel.fromJson(Segment, response.data);
        return Response.fromData(paginated.items, response.status);
    }
    catch (e) {
        return Response.error(e);
    }
}
