import styled from 'styled-components';
export const StyledWrapper = styled.div `
  padding: 14px;

  background-color: white;

  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);

  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
`;
export const StyledTitle = styled.h3 `
  font-size: 14px;
  font-style: normal;
  font-weight: 600;

  color: ${(props) => props.theme.colors.gray800};
`;
export const StyledContentWrapper = styled.div `
  margin-top: 7px;

  max-height: 200px;

  padding-right: 5px;

  overflow-y: auto;
  overflow-x: hidden;

  display: grid;
  row-gap: 6px;
  column-gap: 5px;
  grid-template-columns: repeat(8, 1fr);

  &::-webkit-scrollbar-track {
    margin-left: 10px;
  }
`;
export const StyledNoOptionsMessage = styled.span `
  margin: auto;

  font-size: 12px;
  font-weight: 600;

  color: ${(props) => props.theme.colors.gray800};
`;
