export function getGroupIndex(store, groupNumber) {
    if (groupNumber === null)
        return null;
    if (groupNumber === undefined)
        return null;
    const content = store.mainTable.nonFilteredContent;
    const index = content.findIndex((g) => {
        return g.number === groupNumber;
    });
    return index === -1 ? null : index;
}
export function getGroupAndPlanningIndices(store, planning) {
    const groupNumber = planning?.associatedGroup;
    const groupIndex = getGroupIndex(store, groupNumber);
    if (groupIndex === null) {
        return { groupIndex, planningIndex: null };
    }
    const group = store.mainTable.nonFilteredContent[groupIndex];
    const planningIndex = group.plannings.findIndex((p) => {
        return p.id === planning.id;
    });
    return {
        groupIndex,
        planningIndex: planningIndex === -1 ? null : planningIndex
    };
}
export function updateContentInStore(store, newContent) {
    return {
        mainTable: {
            ...store.mainTable,
            filteredContent: newContent,
            nonFilteredContent: newContent
        }
    };
}
