import React from 'react';
import { RequestStatus } from '@campus/commons';
import { Table, NoTableContent, PageSelector } from './components';
import { useFetchTableContent } from './hooks';
import { Modal, Header, Title, CloseButton, Separator } from './styles';
export const EmptyClassListModal = ({ teacherId, filters, hide }) => {
    const { data, requestStatus, pageIndex, setPageIndex, numberOfPages } = useFetchTableContent({ teacherId, filters });
    return (React.createElement(Modal, { show: true, onHide: hide, size: "lg" },
        React.createElement(Header, null,
            React.createElement(Title, null, "Lista de Aula Vazias"),
            React.createElement(CloseButton, { onClick: hide }, "Fechar")),
        React.createElement(Separator, null),
        React.createElement(Table, { content: data }),
        data?.length > 0 ? null : (React.createElement(NoTableContent, { requestStatus: requestStatus })),
        data?.length > 0 ? (React.createElement(PageSelector, { pageIndex: pageIndex, setPageIndex: setPageIndex, numberOfPages: numberOfPages, isLoadingPageContent: requestStatus === RequestStatus.Loading })) : null));
};
