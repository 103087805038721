import PedagogicalPlanning from './PedagogicalPlanning';
import { getDistinct } from '../pages/common/utils/arrays';
export default class PedagogicalPlanningGroup {
    number;
    plannings;
    didacticSequence;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new PedagogicalPlanningGroup();
        instance.plannings =
            json.map((item) => {
                return PedagogicalPlanning.fromJson(item);
            }) ?? [];
        instance.number = instance.plannings.at(0)?.associatedGroup;
        instance.didacticSequence = getDistinct(instance.plannings.flatMap((p) => p.didaticSequences), (d) => d.id);
        return instance;
    }
}
