import styled from 'styled-components';
import BaseList, { Placeholder } from '../../../common/components/ClassLessonAndDateList';
export const StyledList = styled(BaseList) `
  margin-top: 11px;
  height: min(350px, 40vh);
`;
export const StyledPlaceholder = styled(Placeholder) `
  margin-top: 11px;
  height: min(350px, 40vh);
`;
