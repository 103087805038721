import { StyleSheet } from '@react-pdf/renderer';
import { fontSizes, margins, paddings } from '../../../../../../sizes';
const commonStyles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        borderWidth: '1px',
        borderLeftWidth: 0,
        borderStyle: 'solid',
        borderColor: '#B3B3B3',
        backgroundColor: '#F2F2F2',
        paddingTop: paddings.table.headerLabels.top
    }
});
const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: margins.table.header.top,
        marginBottom: margins.table.header.bottom
    },
    firstColumnWrapper: {
        ...commonStyles.wrapper,
        justifyContent: 'center'
    },
    normalColumnWrapper: {
        ...commonStyles.wrapper,
        justifyContent: 'center'
    },
    columnLabel: {
        fontWeight: 700,
        fontSize: fontSizes.table.headerLabel,
        color: '#666666'
    }
});
export default styles;
