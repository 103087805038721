import { compareAsc } from 'date-fns';
const millisecondsPerDay = 1000 * 60 * 60 * 24;
export function isDateInvalid(date) {
    const valueOf = date?.valueOf();
    const isInfinity = valueOf === Infinity || valueOf === -Infinity;
    return !valueOf || isInfinity || isNaN(valueOf);
}
export function toBrazilianDateString(date) {
    return date?.toLocaleDateString('pt-br', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
}
export function fromBrazilianToAmericanDate(date, separator = '/') {
    if (!date)
        return null;
    const pieces = date.split(separator);
    const day = pieces.at(0) ? parseInt(pieces[0]) : 0;
    const month = pieces.at(1) ? parseInt(pieces[1]) : 0;
    const year = pieces.at(2) ? parseInt(pieces[2]) : 0;
    return new Date(year, month, day);
}
export function convertDateToAmericanDateString(date) {
    return date?.toISOString()?.slice(0, 10) ?? null;
}
export function getMonthNameFrom(monthNumber) {
    const date = new Date(new Date().getFullYear(), monthNumber);
    const monthName = date.toLocaleString('pt-br', { month: 'long' });
    return monthName.replace(/\b\w/, (l) => l.toUpperCase());
}
export function formatMonthNumberToHaveTwoDigits(monthNumber) {
    const asString = monthNumber.toString();
    return monthNumber < 10 ? asString.padStart(2, '0') : asString;
}
export function diffInDays(from, to) {
    const milliseconds = to.valueOf() - from.valueOf();
    const days = milliseconds / millisecondsPerDay;
    return Math.round(days);
}
export function copyDateWithoutTimePortion(date) {
    if (!date)
        return null;
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return new Date(year, month, day);
}
export function getTodaysDateWithoutTime() {
    const today = new Date();
    return copyDateWithoutTimePortion(today);
}
export function compareDatesWithoutTime(date, dataFilt) {
    return compareAsc(date, dataFilt);
}
export function getMonthRange() {
    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth(), 1);
    const end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return { start, end };
}
export function getWeekRange() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const start = new Date(today);
    start.setDate(today.getDate() - dayOfWeek);
    const end = new Date(start);
    end.setDate(start.getDate() + 6);
    return { start, end };
}
