import React, { useRef } from 'react';
import Item from './components/Item';
import { useExternalClick } from '../../../../../../../../../../../../hooks';
import { StyledNoOptionsMessage, StyledWrapper } from './styles';
const Menu = (props) => {
    const wrapperRef = useRef(null);
    useExternalClick(wrapperRef, props.handleExternalClick);
    function handleOptionClicked(option, isNowChecked) {
        props.handleOptionClicked({ option, isNowChecked });
    }
    function renderContent() {
        if (props.options?.length > 0) {
            return renderOptions();
        }
        return (React.createElement(StyledNoOptionsMessage, null, "Nenhuma op\u00E7\u00E3o dispon\u00EDvel"));
    }
    function renderOptions() {
        return props.options.map((option) => {
            const checkedByDefault = props.selectedOptions?.some((o) => o.value === option.value);
            return (React.createElement(Item, { key: option.value, label: option.label, checkedByDefault: checkedByDefault, onClick: ({ isNowChecked }) => handleOptionClicked(option, isNowChecked) }));
        });
    }
    return props.canBeDisplayed ? (React.createElement(StyledWrapper, { ref: wrapperRef }, renderContent())) : null;
};
export default Menu;
