import React, { useEffect } from 'react';
import { useSticky } from 'react-table-sticky';
import { useBlockLayout, useTable } from 'react-table';
import { useAnnualTimelinePageContext } from '../../../../context';
import { columns } from './columns';
import TableBody from './components/TableBody';
import TableHeader from '../../../../../common/components/PageRelated/Body/components/Table/components/TableHeader';
import TablePlaceholder from '../../../../../common/components/PageRelated/Body/components/Table/components/TablePlaceholder';
import { useHideDisciplineColumn } from '../../../../../common/components/PageRelated/Body/components/Table/hooks/useHideDisciplineColumn';
import { getPlaceholderContent } from './placeholders';
import { useFetchAreaSkills } from './hooks/useFetchAreaSkills';
import { useTrackElement } from '../../../../../common/components/PageRelated/Body/components/Table/hooks/useTrackElement';
import { StyledWrapper, StyledScrollableArea, StyledContentWrapper } from '../../../../../common/components/PageRelated/Body/components/Table/commonStyles';
const Table = () => {
    const { content, selectedGrade, selectedSegment, selectedDisciplines, setContent } = useAnnualTimelinePageContext((store) => ({
        content: store.mainTable.content,
        setContent: store.mainTable.setContent,
        selectedGrade: store.curriculumComponents.selectedGrade,
        selectedSegment: store.curriculumComponents.selectedSegment,
        selectedDisciplines: store.curriculumComponents.selectedDisciplines
    }));
    const table = useTrackElement();
    const fetch = useFetchAreaSkills({
        grade: selectedGrade,
        segment: selectedSegment,
        disciplines: selectedDisciplines
    });
    const tableInstance = useTable({
        columns,
        data: content,
        initialState: { hiddenColumns: ['disciplineName'] }
    }, useBlockLayout, useSticky);
    useHideDisciplineColumn({
        tableInstance,
        selectedDisciplines,
        columnId: 'disciplineName'
    });
    useEffect(() => {
        setContent(fetch.data);
    }, [fetch.data, setContent]);
    function getPlaceholder() {
        return getPlaceholderContent({
            selectedGrade,
            selectedSegment,
            selectedDisciplines,
            requestStatus: fetch.requestStatus
        });
    }
    return content.length > 0 ? (React.createElement(StyledWrapper, { ...tableInstance.getTableProps() },
        React.createElement(StyledScrollableArea, { ref: table.ref },
            React.createElement(StyledContentWrapper, null,
                React.createElement(TableHeader, { columns: columns, tableInstance: tableInstance, tableElement: table.element }),
                React.createElement(TableBody, { tableInstance: tableInstance }))))) : (React.createElement(TablePlaceholder, { content: getPlaceholder() }));
};
export default Table;
