import styled from 'styled-components';
import Checkbox from '../../../../../../../../../../../../../Basics/Checkbox/Checkbox';
export const StyledWrapper = styled.div `
  cursor: pointer;

  padding: 2px 8px;

  display: flex;
  column-gap: 10px;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }
`;
export const StyledCheckbox = styled(Checkbox) `
  cursor: pointer;
`;
export const StyledLabel = styled.span `
  font-size: 12px;
  font-weight: 600;
  font-style: normal;

  color: ${(props) => props.theme.colors.gray700};
`;
