import { useWeeklyPlanningPageContext } from '../../../../../context';
export function useDragAndDrop() {
    const { reorderPlannings } = useWeeklyPlanningPageContext((store) => ({
        reorderPlannings: store.mainTable.operations.reorder
    }));
    function onDragEnd(result) {
        if (!result.destination)
            return;
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;
        reorderPlannings(sourceIndex, destinationIndex);
    }
    return { onDragEnd };
}
