import styled from 'styled-components';
import { Modal as BaseModal } from '@campus/components';
import { hovering } from '../../commonStyles';
export const Modal = styled(BaseModal) `
  .modal-dialog {
    width: fit-content;
    max-width: calc(100% - var(--bs-modal-margin) * 2);
  }

  .modal-content {
    padding: 22px 35px 30px 32px;

    background: ${({ theme }) => theme.colors.white};

    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.gray200};

    box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);
  }
`;
export const Header = styled.div `
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
export const CloseButton = styled.button `
  height: 32px;

  padding: 0 12px;

  font-size: 14px;
  font-weight: 600;

  line-height: 20px;

  color: ${(props) => props.theme.colors.gray500};
  background: ${(props) => props.theme.colors.white};

  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.gray200};

  transition: border-color ${hovering.transitionMs};

  :hover {
    border-color: ${hovering.color};
    background: ${(props) => props.theme.colors.white};
  }
`;
export const Title = styled.h2 `
  margin: 0;

  font-size: 22px;
  font-weight: 700;
  line-height: 130%;

  color: ${({ theme }) => theme.colors.gray1};
`;
export const Separator = styled.hr `
  all: unset;

  display: block;

  margin: 13px 0 20px 0;

  border-top: 1px solid ${({ theme }) => theme.colors.gray4};
`;
