import { useGroupContext } from '../../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import DidaticSequence from '../../../../../../../../../../../../../../models/DidaticSequence';
import { deleteDidaticSequenceAndItsClassrooms } from '../../../../../../../../../../../../../../services/agregators';
export function useRemoveActivity() {
    const { remove, update } = useGroupContext((slice) => ({
        update: slice.sequenceOperations.update,
        remove: slice.sequenceOperations.remove
    }));
    function createUpdatedSequence(sequence) {
        const newSequence = new DidaticSequence();
        Object.assign(newSequence, sequence);
        newSequence.isDeleted = true;
        newSequence.classrooms = [];
        return newSequence;
    }
    function removeActivity(sequence, planning) {
        return new Promise((resolve, reject) => {
            deleteDidaticSequenceAndItsClassrooms({ sequence, planning })
                .then((response) => {
                if (response.data.wasReallyDeleted === true) {
                    remove(sequence.id);
                }
                else {
                    update(createUpdatedSequence(sequence));
                }
                resolve();
            })
                .catch(reject);
        });
    }
    return { removeActivity };
}
