import styled from 'styled-components';
import { StyledColumnWrapper } from '../common/styles';
export const StyledWrapper = styled(StyledColumnWrapper) `
  padding: 10px 8px;

  display: flex;
  row-gap: 11px;
  flex-direction: column;
  justify-content: center;
`;
export const StyledProgramSection = styled.p `
  margin: 0;

  line-height: 13.2px;
`;
const StageNameAndSectionTitleCommon = styled.span `
  display: block;

  font-size: 12px;
  font-style: normal;

  color: ${(props) => props.theme.colors.gray700};
`;
export const StyledProgramStageName = styled(StageNameAndSectionTitleCommon) `
  font-weight: 500;
`;
export const StyledSectionTitle = styled(StageNameAndSectionTitleCommon) `
  font-weight: 700;
`;
