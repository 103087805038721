import { BorderStyle, HeightRule, Paragraph, Table, TableCell, TableRow, TextRun, WidthType } from 'docx';
import { teacherTheme } from '@campus/components';
import { emptyTableCellBorders } from '../../../../common/borderDocx';
import { fontSizes, margins, widths } from '../../../../common/sizesDocx';
const border = {
    style: BorderStyle.SINGLE,
    color: teacherTheme.colors.gray300,
    size: 1
};
export default function EvaluationSection() {
    const title = new TableRow({
        children: [
            new TableCell({
                borders: emptyTableCellBorders,
                width: { size: widths.holeDocument, type: WidthType.PERCENTAGE },
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                bold: true,
                                text: 'AVALIAÇÃO',
                                color: teacherTheme.colors.gray700,
                                size: fontSizes.footer.evaluationSection
                            })
                        ]
                    })
                ]
            })
        ]
    });
    const message = new TableRow({
        children: [
            new TableCell({
                borders: emptyTableCellBorders,
                width: { size: widths.holeDocument, type: WidthType.PERCENTAGE },
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                bold: true,
                                color: teacherTheme.colors.gray700,
                                size: fontSizes.footer.evaluationSection,
                                text: 'Os instrumentos avaliativos consistirão em ' +
                                    'atividades-síntese e atividades processuais, distribuídas ' +
                                    'ao longo do semestre. O calendário será disponibilizado ' +
                                    'pela supervisão do segmento.'
                            })
                        ]
                    })
                ]
            })
        ]
    });
    const messageMarginTop = new TableRow({
        height: {
            rule: HeightRule.EXACT,
            value: margins.footer.evaluationSection.message.top
        },
        children: [
            new TableCell({
                children: [new Paragraph({})],
                borders: emptyTableCellBorders,
                width: { size: widths.holeDocument, type: WidthType.PERCENTAGE }
            })
        ]
    });
    const messageMarginBottom = new TableRow({
        height: {
            rule: HeightRule.EXACT,
            value: margins.footer.evaluationSection.message.bottom
        },
        children: [
            new TableCell({
                children: [new Paragraph({})],
                borders: { ...emptyTableCellBorders, bottom: border },
                width: { size: widths.holeDocument, type: WidthType.PERCENTAGE }
            })
        ]
    });
    return new Table({
        columnWidths: [widths.holeDocument],
        rows: [title, messageMarginTop, message, messageMarginBottom],
        width: { size: widths.holeDocument, type: WidthType.PERCENTAGE }
    });
}
