import React from 'react';
import { QuestionType } from '@campus/commons';

import QuestionHeader from '../QuestionHeader';
import QuestionAnswer from '../QuestionAnswer';
import QuestionCorrection from '../QuestionCorrection';

import { useQuestionReview } from '../../context/QuestionReviewContext';
import {
  Container,
  Content,
  Divider,
  QuestionItem,
  QuestionList
} from './styles';

const QuestionReview: React.FC = () => {
  return (
    <Container>
      <Content>
        <QuestionHeader />
        <QuestionAnswers />
      </Content>
      <Divider />
      <Content>
        <QuestionAnswer />
      </Content>
      <QuestionCorrection />
    </Container>
  );
};

const QuestionAnswers = () => {
  const { question } = useQuestionReview();

  switch (question.questionType) {
    case QuestionType.MULTIPLE:
    case QuestionType.UNIQUE:
      return (
        <QuestionList>
          {question?.answers.map((a) => (
            <QuestionItem
              key={a.id}
              $correct={a.correct}
            >{`${a.optionText} - ${a.text.replace(/<[^>]*>?/gm, '')}`}</QuestionItem>
          ))}
        </QuestionList>
      );
    default:
      return <></>;
  }
};

export default QuestionReview;
