import React, { useState } from 'react';
import { useHandlers } from './hooks/useHandlers';
import { StyledTitle } from '../../../../../../../../../Popups/common/styles';
import StagesColumn from './components/Columns/StagesColumn';
import DocumentsColumn from './components/Columns/DocumentsColumn';
import { StyledColumnsWrapper, StyledTitleWrapper, StyledWrapper, StyledMessageWrapper, StyledMessage } from './styles';
const Popup = (props) => {
    const [selectedStudyUnitVersion, setSelectedStudyUnitVersion] = useState();
    const [selectedType, setSelectedType] = useState('pdf');
    const { generateAnnualTimelineReport, generateAnnualTimelineDocxReport, generateStudyUnitReport, generateStudyUnitReportDocx, reportBeingGenerated, stageBeingUsed } = useHandlers(props);
    function onStudyUnitStageClick(stage, type) {
        if (type === 'pdf') {
            generateStudyUnitReport(selectedStudyUnitVersion, stage);
        }
        else if (type === 'docx') {
            generateStudyUnitReportDocx(selectedStudyUnitVersion, stage);
        }
    }
    function onAnnualTimelineClick() {
        setSelectedStudyUnitVersion(null);
        generateAnnualTimelineReport();
    }
    function onAnnualTimelineDocxClick() {
        setSelectedStudyUnitVersion(null);
        generateAnnualTimelineDocxReport();
    }
    const allComponentsArePresent = !!props.selectedGrade &&
        !!props.selectedSegment &&
        props.selectedDisciplines?.length > 0;
    const isThereAnySkillOnTable = props.skills?.length > 0;
    function handleExternalClick() {
        setSelectedStudyUnitVersion(null);
        props.handleExternalClick();
    }
    return (React.createElement(StyledWrapper, { isFullScreen: false, canBeDisplayed: props.canBeDisplayed, handleExternalClick: handleExternalClick },
        React.createElement(StyledTitleWrapper, null,
            React.createElement(StyledTitle, null, "Exportar Documentos")),
        allComponentsArePresent && isThereAnySkillOnTable ? (React.createElement(StyledColumnsWrapper, null,
            React.createElement(DocumentsColumn, { skills: props.skills, selectedGrade: props.selectedGrade, selectedSegment: props.selectedSegment, selectedDisciplines: props.selectedDisciplines, stageBeingUsed: stageBeingUsed, reportBeingGenerated: reportBeingGenerated, selectedStudyUnitVersion: selectedStudyUnitVersion, onAnnualTimelineItemClick: onAnnualTimelineClick, onAnnualTimelineItemDocxClick: onAnnualTimelineDocxClick, setSelectedStudyUnitVersion: setSelectedStudyUnitVersion, setSelectedType: setSelectedType, type: selectedType }),
            React.createElement(StagesColumn, { skills: props.skills, allStages: props.allStages, selectedGrade: props.selectedGrade, selectedSegment: props.selectedSegment, selectedDisciplines: props.selectedDisciplines, stageBeingUsed: stageBeingUsed, reportBeingGenerated: reportBeingGenerated, selectedStudyUnitVersion: selectedStudyUnitVersion, onStageClick: onStudyUnitStageClick, type: selectedType }))) : (React.createElement(StyledMessageWrapper, null,
            React.createElement(StyledMessage, null, !allComponentsArePresent
                ? 'É necessário selecionar os componentes curriculares antes de fazer uma exportação.'
                : !isThereAnySkillOnTable
                    ? 'É necessário ter alguma habilidade para que os relatórios possam ser gerados.'
                    : '')))));
};
export default Popup;
