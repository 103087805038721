import React, { useEffect, useMemo } from 'react';
import { createAddOrEditActivityPopupStore } from '../store';
import { useFetchEvaluationTypes } from '../hooks/useFetchEvaluationTypes';
import { AddOrEditActivityPopupContext } from '.';
export const Provider = (props) => {
    const abortController = useMemo(() => new AbortController(), []);
    const store = useMemo(() => {
        return createAddOrEditActivityPopupStore({
            sequence: props.sequence,
            planning: props.planning,
            abortSignal: abortController.signal
        });
    }, [abortController, props.sequence, props.planning]);
    const evaluationTypes = useFetchEvaluationTypes(props.disciplines);
    useEffect(() => {
        store.getState().setClassesAndInfos(props.classroomGroups);
    }, [store, props.classroomGroups?.data, props.classroomGroups?.status]);
    useEffect(() => {
        store.getState().setEvaluationTypes(evaluationTypes);
    }, [store, evaluationTypes]);
    useEffect(() => {
        return () => abortController.abort();
    }, [abortController]);
    return (React.createElement(AddOrEditActivityPopupContext.Provider, { value: store }, props.children));
};
