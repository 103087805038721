import React, { useMemo, useState } from 'react';
import { useAddSkillPopupContext } from '../../../../../context';
import { createOptionsFrom } from '../../../common/helpers';
import { StyledSelect } from '../../../common/styles';
import { StyledWrapper, StyledLabel } from './styles';
const DisciplineToAddSkillOn = () => {
    const { selectedDisciplines, setNewSkillDisciplineId } = useAddSkillPopupContext((store) => ({
        selectedDisciplines: store.selectedDisciplines,
        setNewSkillDisciplineId: store.setNewSkillDisciplineId
    }));
    const [selectedOption, setSelectedOption] = useState();
    const options = useMemo(() => {
        const disciplines = [...selectedDisciplines];
        for (const discipline of disciplines) {
            discipline.name = discipline.name.toUpperCase();
        }
        return createOptionsFrom({
            items: disciplines,
            getItemValue: (d) => d.id,
            getItemLabel: (d) => d.name
        });
    }, [selectedDisciplines]);
    function onOptionChange(option) {
        setSelectedOption(option);
        setNewSkillDisciplineId(selectedDisciplines?.find((d) => {
            return d.id === option.value;
        })?.id);
    }
    return selectedDisciplines.length > 1 ? (React.createElement(StyledWrapper, null,
        React.createElement(StyledLabel, null, "Adicionar habilidade em:"),
        React.createElement(StyledSelect, { options: options, value: selectedOption, defaultValue: options[0], onChange: onOptionChange }))) : null;
};
export default DisciplineToAddSkillOn;
