import React, { useState } from 'react';
import { getMonthRange, getWeekRange } from '../../../../../../../common/utils/dates';
import FilterSkillsButton from '../../../../../../../common/components/FilterSkillsButton';
import { useWeeklyPlanningPageContext } from '../../../../../../context';
import { Button, FilterGroup } from '../../styles';
const FilterDate = () => {
    const filters = useWeeklyPlanningPageContext((store) => ({
        minimumClassroomsDate: store.mainTable.filters.minimumClassroomsDate,
        maximumClassroomsDate: store.mainTable.filters.maximumClassroomsDate,
        setMinimumClassroomsDate: store.mainTable.filters.setMinimumClassroomsDate,
        setMaximumClassroomsDate: store.mainTable.filters.setMaximumClassroomsDate
    }));
    const [activeFilter, setActiveFilter] = useState('allTime');
    const setDate = (start, end) => {
        filters.setMaximumClassroomsDate(end);
        filters.setMinimumClassroomsDate(start);
    };
    return (React.createElement(FilterGroup, null,
        React.createElement("span", null, "Per\u00EDodo:"),
        React.createElement("div", null,
            React.createElement(Button, { "$isActive": activeFilter === 'allTime', onClick: () => {
                    setActiveFilter('allTime');
                    setDate(null, null);
                } }, "Todo o tempo"),
            React.createElement(Button, { "$isActive": activeFilter === 'thisMonth', onClick: () => {
                    setActiveFilter('thisMonth');
                    const month = getMonthRange();
                    setDate(month.start, month.end);
                } }, "Este m\u00EAs"),
            React.createElement(Button, { "$isActive": activeFilter === 'thisWeek', onClick: () => {
                    setActiveFilter('thisWeek');
                    const week = getWeekRange();
                    setDate(week.start, week.end);
                } }, "Esta semana"),
            React.createElement(FilterSkillsButton, { isActive: activeFilter === 'customPeriod', setActiveFilter: () => setActiveFilter('customPeriod'), minimumDateFilterValue: filters.minimumClassroomsDate, maximumDateFilterValue: filters.maximumClassroomsDate, onMinimumDateFilterChange: filters.setMinimumClassroomsDate, onMaximumDateFilterChange: filters.setMaximumClassroomsDate }))));
};
export default FilterDate;
