import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 40%;
  max-width: 45%;
  height: fit-content;
  border: 1px solid ${(props) => props.theme.colors.gray5};
  border-radius: 4px;

  @media ${(props) => props.theme.mediaQuery.desktopDown} {
    min-width: 40%;
    max-width: 45%;
  }
`;

export const Content = styled.div`
  padding: 12px 14px;
`;

export const QuestionList = styled.div`
  margin: 0;
  padding: 0 4px;
  list-style-type: none;
  font-weight: ${(props) => props.theme.weight.bold};
  color: ${(props) => props.theme.colors.gray600};
`;

export const Divider = styled.hr`
  color: ${(props) => props.theme.colors.gray5};
  opacity: 1;
  height: 1;
  margin: 0;
`;

export const QuestionItem = styled.li<{ $correct: boolean }>`
  ${(props) => (props.$correct ? `color: ${props.theme.colors.green3}` : '')};
`;
