import PedagogicalPlanningGroup from '../../../../../../../../../../../models/PedagogicalPlanningGroup';
export function generateGroupUpdater(updateGroup) {
    return (store, updates) => {
        const oldGroup = store._groupFromNonFilteredContent;
        const newGroup = new PedagogicalPlanningGroup();
        newGroup.number = updates.number ?? oldGroup.number;
        newGroup.plannings = updates.plannings ?? oldGroup.plannings;
        newGroup.didacticSequence =
            updates.didacticSequence ?? oldGroup.didacticSequence;
        updateGroup(newGroup);
        return { ...store, group: newGroup };
    };
}
