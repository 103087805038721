import React from 'react';
import DisciplineModule, { Variant } from '@campus/discipline';
import { useTeacherId } from '@campus/commons';

import DisciplineInsights from 'pages/DisciplineInsights';
import ActivityReport from 'pages/ActivityReport';

const Discipline: React.FC = () => {
  const teacherId = useTeacherId();

  return (
    <DisciplineModule
      variant={Variant.TEACHER}
      variantId={teacherId}
      pages={[
        {
          path: 'insights',
          name: 'Insights',
          element: <DisciplineInsights />,
          showOnlyActivity: true
        },
        {
          path: 'report',
          name: 'Relatório',
          element: <ActivityReport />,
          showOnlyWhenTeacher: true,
          showOnlyActivity: true
        }
      ]}
    />
  );
};

export default Discipline;
