import React, { useEffect, useState } from 'react';
import { useFollowUpPageContext } from '../../../../../../../../../../../context';
import { useFollowUpMainTableContext } from '../../../../../../../../../context';
import { borderShouldBeDashed, borderShouldBeRed } from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/components/Rows/helpers/borders';
import { calculateTopAndBottom } from './helpers';
import { StyledBorder } from './styles';
const RightBorder = (props) => {
    const [pieces, setPieces] = useState([]);
    const { selectedGrade, selectedSegment, selectedDisciplines } = useFollowUpPageContext((store) => ({
        selectedGrade: store.curriculumComponents.selectedGrade,
        selectedSegment: store.curriculumComponents.selectedSegment,
        selectedDisciplines: store.curriculumComponents.selectedDisciplines
    }));
    const planningsAndInfos = useFollowUpMainTableContext((store) => store.planningsAndInfos);
    useEffect(() => {
        if (!props.groupInfos)
            return null;
        if (!props.group?.plannings?.length)
            return null;
        setPieces(props.group.plannings.map((planning) => {
            const planningInfos = planningsAndInfos.get(planning.id);
            if (!planningInfos)
                return null;
            const isDashed = borderShouldBeDashed({
                relatedPlanning: planning
            });
            const isRed = borderShouldBeRed({
                selectedGrade,
                selectedSegment,
                selectedDisciplines,
                relatedPlanning: planning
            });
            const topAndBottom = calculateTopAndBottom(props.groupInfos, planningInfos);
            return (React.createElement(StyledBorder, { key: planning.id, "$isRed": isRed, "$isDashed": isDashed, "$top": topAndBottom.top, "$bottom": topAndBottom.bottom }));
        }));
    }, [
        props.groupInfos,
        props.group?.plannings,
        planningsAndInfos,
        selectedGrade,
        selectedSegment,
        selectedDisciplines
    ]);
    return React.createElement(React.Fragment, null, pieces);
};
export default RightBorder;
