import styled from 'styled-components';
import { zIndices } from '../stylesRelated';
export const StyledWrapper = styled.div `
  z-index: ${zIndices.blueLayer};

  opacity: 0.1;

  background-color: ${(props) => props.theme.colors.blue1};

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: ${(props) => props.$right}px;
`;
export const StyledLine = styled.div `
  z-index: ${zIndices.todaysLine};

  width: 2px;

  box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.05);

  border-right: 2px solid ${(props) => props.theme.colors.blue1};

  position: absolute;
  top: 0;
  bottom: 0;
  right: ${(props) => props.$right}px;
`;
