export default class LearningObjective {
    id;
    code;
    description;
    isEvaluative;
    static fromJson(json) {
        if (!json)
            return;
        const instance = new LearningObjective();
        instance.id = json.Id;
        instance.code = json.Code;
        instance.description = json.Name;
        instance.isEvaluative = json.Evaluative;
        return instance;
    }
}
