import styled from 'styled-components';
export const StyledWrapper = styled.div `
  position: relative;

  display: flex;
  column-gap: 8px;
  align-items: flex-start;

  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};

  padding-top: 3px;
  padding-left: 8px;
  padding-right: 6px;
  padding-bottom: ${(props) => (props.$addPaddingBottom ? '10px' : '0')};
`;
