import { createStore } from 'zustand';
export function createFollowUpMainTableStore() {
    return createStore()((set, get) => ({
        groupsAndInfos: new Map(),
        planningsAndInfos: new Map(),
        activitiesAndInfos: new Map(),
        setGroupInfos(groupNumber, infos) {
            if (infos?.height && Object.keys(infos).length === 1) {
                const groupInfos = get().groupsAndInfos.get(groupNumber);
                if (groupInfos?.height === infos.height)
                    return;
            }
            set((store) => {
                const oldMap = store.groupsAndInfos;
                const newMap = new Map(oldMap);
                const oldInfos = oldMap.get(groupNumber);
                newMap.set(groupNumber, { ...oldInfos, ...infos });
                return { ...store, groupsAndInfos: newMap };
            });
        },
        setPlanningInfos(planningId, infos) {
            set((store) => {
                const oldMap = store.planningsAndInfos;
                const newMap = new Map(oldMap);
                newMap.set(planningId, infos);
                return { ...store, planningsAndInfos: newMap };
            });
        },
        setActivityInfos(activityId, infos) {
            set((store) => {
                const oldMap = store.activitiesAndInfos;
                const newMap = new Map(oldMap);
                newMap.set(activityId, infos);
                return { ...store, activitiesAndInfos: newMap };
            });
        }
    }));
}
