import { createBaseCommonDataSlice } from '../../../../common/stores/slices';
import { RequestStatus } from '../../../../common/types/dataFetching';
export function createCommonDataSlice(events) {
    return (set, ...args) => ({
        ...createBaseCommonDataSlice(events)(set, ...args),
        stages: { data: [], status: RequestStatus.NotStarted },
        userPermissions: { data: null, status: RequestStatus.NotStarted },
        setStages(updates) {
            set((store) => {
                return {
                    commonData: {
                        ...store.commonData,
                        stages: { ...store.commonData.stages, ...updates }
                    }
                };
            });
        },
        setUserPermissions(updates) {
            set((store) => {
                return {
                    commonData: {
                        ...store.commonData,
                        userPermissions: { ...store.commonData.userPermissions, ...updates }
                    }
                };
            });
        }
    });
}
