import React from 'react';
import VisualizeActivityPopup from '../../../../../../../../../../Popups/VisualizeActivityPopup';
import { usePortal } from '../../../../../../../../../../../contexts/portals';
import { StyledButton } from './styles';
const ExpandButton = (props) => {
    const { create, destroy } = usePortal(document.body);
    function onClick() {
        create(React.createElement(VisualizeActivityPopup, { canBeDisplayed: true, sequence: props.sequence, handleExternalClick: destroy, handleCloseButtonClicked: destroy }));
    }
    return React.createElement(StyledButton, { onClick: onClick }, "AMPLIAR");
};
export default ExpandButton;
