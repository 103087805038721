import { StyleSheet } from '@react-pdf/renderer';
import { paddings, margins, fontSizes } from '../../../../../../../sizes';
import commonStyles from '../commonStyles';
const styles = StyleSheet.create({
    wrapper: {
        ...commonStyles,
        backgroundColor: 'rgba(242, 242, 242, 0.5)',
        paddingTop: paddings.table.columns.skill.top,
        paddingBottom: paddings.table.columns.skill.bottom,
        paddingLeft: paddings.table.columns.skill.others,
        paddingRight: paddings.table.columns.skill.others
    },
    codeAndDescription: {
        lineHeight: '130%',
        fontSize: fontSizes.table.columnContent
    },
    description: {
        color: '#4D4D4D'
    },
    observationWrapper: {
        marginTop: margins.table.columns.skill.observation.top
    },
    observation: {
        fontWeight: 600,
        fontSize: fontSizes.table.skillObservation,
        color: '#828282'
    }
});
export default styles;
