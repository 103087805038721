import React, { useState } from 'react';
import { useAddSkillPopupContext } from '../../context';
import OtherSkillsTab from './OtherSkillsTab';
import ScheduleSkillsTab from './ScheduleSkillsTab';
import { StyledTabTitle, StyledTabTitleWrapper, StyledTabTitlesWrapper, StyledWrapper } from './styles';
const tabs = [
    {
        title: 'HABILIDADES DO CRONOGRAMA',
        component: React.createElement(ScheduleSkillsTab, null)
    },
    {
        title: 'OUTRAS HABILIDADES',
        component: React.createElement(OtherSkillsTab, null)
    }
];
const Tabs = () => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const resetFilters = useAddSkillPopupContext((store) => store.filters.resetFilters);
    function changeTab(tabIndex) {
        return () => {
            resetFilters();
            setSelectedTabIndex(tabIndex);
        };
    }
    function renderTitles() {
        return tabs.map((infos, index) => (React.createElement(StyledTabTitleWrapper, { key: infos.title, onClick: changeTab(index), "$isTheSelectedTab": index === selectedTabIndex },
            React.createElement(StyledTabTitle, null, infos.title))));
    }
    return (React.createElement(StyledWrapper, null,
        React.createElement(StyledTabTitlesWrapper, null, renderTitles()),
        tabs[selectedTabIndex].component));
};
export default Tabs;
