import PedagogicalPlanningGroup from '../../../../../../models/PedagogicalPlanningGroup';
import { applyFilters as applyBaseFilters } from '../../../../../common/stores/slices/mainTable/filters';
function isClassInActivity(classId, activity) {
    return (activity.classrooms?.some((c) => {
        return c.classroom?.class?.id === classId;
    }) ?? false);
}
function applyClassFilter(classId, content) {
    if (!classId)
        return content;
    const newContent = [];
    for (const group of content) {
        const skipGroup = !group.didacticSequence?.some((a) => {
            return isClassInActivity(classId, a);
        });
        if (skipGroup)
            continue;
        const newGroup = Object.assign(new PedagogicalPlanningGroup(), group);
        newGroup.didacticSequence = group.didacticSequence.filter((a) => {
            return isClassInActivity(classId, a);
        });
        newContent.push(newGroup);
    }
    return newContent;
}
function applyOnlyEvaluativeSkillsFilter(value, content) {
    if (!value)
        return content;
    const newContent = [];
    for (const group of content) {
        const skipGroup = !group.plannings.some((p) => {
            return p.areaSkill.evaluative;
        });
        if (skipGroup)
            continue;
        const newGroup = Object.assign(new PedagogicalPlanningGroup(), group);
        newGroup.plannings = group.plannings.filter((p) => {
            return p.areaSkill.evaluative;
        });
        newContent.push(newGroup);
    }
    return newContent;
}
function applyOnlySkillsWithoutDidacticSequenceFilter(value, content) {
    if (!value)
        return content;
    return content.filter((g) => {
        return !g.didacticSequence || g.didacticSequence.length === 0;
    });
}
export function applyFilters(mainTableContent, slice) {
    let newContent = [...mainTableContent];
    newContent = applyBaseFilters(newContent, slice);
    newContent = applyClassFilter(slice.selectedClass?.id, newContent);
    newContent = applyOnlyEvaluativeSkillsFilter(slice.onlyEvaluativeSkills, newContent);
    newContent = applyOnlySkillsWithoutDidacticSequenceFilter(slice.onlySkillsWithoutDidacticSequence, newContent);
    return newContent;
}
