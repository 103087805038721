import styled from 'styled-components';
export const StyledWrapper = styled.div `
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledText = styled.i `
  color: ${(props) => props.theme.colors.gray500};
`;
