import React from 'react';
import { useTheme } from 'styled-components';
import { CustomClassicCKEditor } from '@campus/components';
import { StyledCircle, StyledWrapper } from './styles';
const Content = (props) => {
    const theme = useTheme();
    const circleColor = props.sequence.impactTheMDC === false
        ? theme.colors.gray4
        : props.sequence.evaluationType?.color;
    return (React.createElement(StyledWrapper, { "$textColor": props.textColor, "$maxHeight": props.maxHeight, "$isDeleted": props.sequence.isDeleted },
        circleColor ? (React.createElement(StyledCircle, { color: circleColor, borderColor: circleColor })) : null,
        React.createElement(CustomClassicCKEditor.Editor, { readOnly: true, content: props.sequence.description })));
};
export default Content;
