import React from 'react';
import { getDates } from './helpers';
import { formatDate } from '../common/helpers/dates';
import { StyledInfoContent, StyledInfoLabel, StyledInfoWrapper } from '../common/styles';
const Accomplished = (props) => {
    const { startDate, endDate } = getDates(props.sequences);
    const start = formatDate(startDate.date);
    const end = formatDate(endDate.date);
    return (React.createElement(StyledInfoWrapper, null,
        React.createElement(StyledInfoLabel, null, "Per\u00EDodo Realizado: "),
        React.createElement(StyledInfoContent, null,
            React.createElement(StyledInfoContent, { "$orange": startDate?.isLate ?? false }, start),
            ' ',
            "at\u00E9",
            ' ',
            React.createElement(StyledInfoContent, { "$orange": endDate?.isLate ?? false }, end))));
};
export default Accomplished;
