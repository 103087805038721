export default class Stage {
    id;
    number;
    shortName;
    fullName;
    static splitFullName(fullName) {
        if (!fullName)
            return { number: 0, name: '' };
        const pieces = fullName.split(' ');
        const asNumber = Number(pieces[0].charAt(0));
        const number = isNaN(asNumber) ? 0 : asNumber;
        const name = pieces[1]?.trim() ?? '';
        return { number, name };
    }
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new Stage();
        instance.id = json.Id;
        instance.fullName = json.Name;
        const { number, name } = Stage.splitFullName(json.Name);
        instance.number = number;
        instance.shortName = name;
        return instance;
    }
}
