import styled from 'styled-components';
export const StyledWrapper = styled.div `
  display: flex;
  flex-direction: column;
  row-gap: 8.5px;
`;
export const StyledSubtitle = styled.p `
  margin: 0;

  font-size: 12px;
  font-weight: 300;

  color: ${(props) => props.theme.colors.gray300};
`;
