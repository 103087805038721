import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
import { hovering } from '../../../commonStyles';
export const Container = styled.div `
  .react-date-picker__wrapper {
    height: 32px;
    padding: 0 15px 0 8px;

    flex-direction: row-reverse;
    column-gap: 5px;
  }

  .react-date-picker__wrapper,
  .react-calendar {
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.gray200};
  }

  .react-date-picker__inputGroup {
    padding: 0;
  }

  .react-date-picker__inputGroup__input--hasLeadingZero {
    padding-left: 1px;
    margin-left: revert;
  }

  .react-date-picker__inputGroup__input,
  .react-date-picker__inputGroup__divider,
  .react-date-picker__inputGroup__leadingZero,
  .react-calendar {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-family: Gilroy;
    color: ${({ theme }) => theme.colors.gray700};
  }

  .react-date-picker__inputGroup__input:invalid {
    background: none;
    color: ${({ theme }) => theme.colors.gray700};
  }

  .react-date-picker__clear-button,
  .react-date-picker__calendar-button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-date-picker__clear-button:focus,
  .react-date-picker__calendar-button:focus,
  .react-date-picker__inputGroup__input:focus {
    outline: none;
  }

  .react-date-picker__calendar {
    z-index: 5;
  }

  .react-date-picker__calendar--open {
    top: calc(100% + 5px) !important;
  }

  .react-calendar__navigation {
    height: auto;
    margin-bottom: 0;

    button:first-child {
      border-top-left-radius: 6px;
    }

    button:last-child {
      border-top-right-radius: 6px;
    }

    button {
      padding: 7px 0;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .react-calendar__navigation__arrow {
    font-size: 18px;
  }

  .react-calendar__month-view__days {
    button:nth-last-child(7) {
      border-bottom-left-radius: 6px;
    }

    button:last-child {
      border-bottom-right-radius: 6px;
    }
  }

  .react-calendar__tile {
    padding: 7px;
  }

  .react-calendar__month-view__days__day--weekend {
    color: ${({ theme }) => theme.colors.CSA.blue};
  }
`;
export const CalendarIcon = styled(CampusIcon).attrs({
    name: 'calendarGray'
}) `
  display: block;

  path {
    transition: stroke ${hovering.transitionMs};
    stroke: ${({ theme }) => theme.colors.gray3};
  }

  &:hover path {
    stroke: ${hovering.color};
  }
`;
export const ClearIcon = styled(CampusIcon).attrs({
    name: 'close'
}) `
  display: block;

  path {
    fill-opacity: 1;
    fill: ${({ theme }) => theme.colors.gray3};
    transition: fill ${hovering.transitionMs};
  }

  &:hover path {
    fill: ${hovering.color};
  }
`;
