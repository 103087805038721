import React from 'react';
import Col from './components/Column';
import { useControlLastColumnRightBorder } from './hooks/useControlLastColumnRightBorder';
import { StyledDivWrapper, StyledTrWrapper } from './styles';
const Row = (props) => {
    const { columnGroup, allColumns } = props;
    const { removeBorder } = useControlLastColumnRightBorder({
        tableElement: props.tableElement
    });
    const rowProps = columnGroup.getHeaderGroupProps();
    if (props.asTr) {
        rowProps.style.display = 'table-row';
    }
    function renderColumns() {
        const columns = columnGroup.headers;
        return columns.map((column, index) => {
            const isTheLastColumn = index === columns.length - 1;
            return (React.createElement(Col, { key: column.id, asTh: props.asTr, columnInfos: column, allColumns: allColumns, removeRightBorder: isTheLastColumn && removeBorder }));
        });
    }
    return props.asTr ? (React.createElement(StyledTrWrapper, { ...rowProps }, renderColumns())) : (React.createElement(StyledDivWrapper, { ...rowProps }, renderColumns()));
};
export default Row;
