import React from 'react';
import { useDisable } from '../../hooks/useDisable';
import { useControlDeletionModal } from '../../hooks/useControlDeletionModal';
import { StyledPopup } from './styles';
const Popup = (props) => {
    const { createModal } = useControlDeletionModal();
    const disable = useDisable({ planning: props.planning });
    function onRemoveClick() {
        return new Promise((resolve, reject) => {
            if (disable.remove) {
                reject();
                return;
            }
            createModal(props.planning);
            resolve();
        });
    }
    const items = [
        {
            label: 'Remover',
            onClick: onRemoveClick,
            disabled: disable.remove
        },
        {
            label: 'Duplicar',
            onClick: null,
            disabled: disable.duplicateAndAddToBlock
        },
        {
            label: 'Mover para cima',
            onClick: null,
            disabled: disable.moveAndSeparation
        },
        {
            label: 'Mover para baixo',
            onClick: null,
            disabled: disable.moveAndSeparation
        },
        {
            label: 'Adicionar habilidade ao bloco',
            onClick: null,
            disabled: disable.duplicateAndAddToBlock
        },
        {
            label: 'Separar habilidade do bloco',
            onClick: null,
            disabled: disable.moveAndSeparation
        }
    ];
    return (React.createElement(StyledPopup, { items: items, canBeDisplayed: props.canBeDisplayed, handleExternalClick: props.handleExternalClick }));
};
export default Popup;
