import styled from 'styled-components';
import { StyledRowWrapper } from '../common/styles';
export const StyledWrapper = styled(StyledRowWrapper) `
  height: 36px;

  padding-right: 2px;

  position: relative;

  border-style: solid;
  border-width: 2px 0;
  border-color: ${(props) => props.theme.colors.gray300};
`;
export const StyledWeeksWrapper = styled.div `
  display: flex;

  border-style: solid;
  border-width: 0;
  border-left-width: 1px;
  border-color: ${(props) => props.theme.colors.gray300};
`;
export const StyledWeekAndSeparatorWrapper = styled.div `
  display: flex;
`;
export const StyledWeekTextWrapper = styled.div `
  width: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledWeekText = styled.span `
  font-size: 11px;
  font-weight: 500;

  text-align: center;

  color: ${(props) => props.theme.colors.gray600};
`;
