import { ScoreInput, Spinner } from '@campus/components';
import styled from 'styled-components';

export const Root = styled.div`
  padding: 26px 28px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TableContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const Table = styled.div`
  width: 100%;
  overflow: auto;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray5};

  table {
    border-collapse: separate !important;
    border-spacing: 0;
  }

  tr {
    flex: none !important;
    display: table-row !important;
  }

  th,
  td {
    flex: none !important;
    padding: 5px 8px;
    vertical-align: middle;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
  }

  tr > td:nth-child(2) {
    padding: 0px !important;
  }

  td:has(> .left-border) {
    border-left: 2px solid ${(props) => props.theme.colors.CSA.darkBlue} !important;
  }
  th:has(> .left-border) {
    border-left: 2px solid ${(props) => props.theme.colors.CSA.darkBlue} !important;
  }

  tr:last-child td:has(> .left-border),
  tr:last-child td:has(> .right-border) {
    border-bottom: 2px solid ${(props) => props.theme.colors.CSA.darkBlue} !important;
  }

  td:has(> .right-border) {
    border-right: 2px solid ${(props) => props.theme.colors.CSA.darkBlue} !important;
  }
  th:has(> .right-border) {
    border-right: 2px solid ${(props) => props.theme.colors.CSA.darkBlue} !important;
  }
  th:has(> .top-border) {
    border-top: 2px solid ${(props) => props.theme.colors.CSA.darkBlue} !important;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  th div {
    margin-right: 0;
  }

  th:has(> .top-border) {
    border-top: 2px solid ${(props) => props.theme.colors.CSA.darkBlue} !important;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  thead > tr:first-child {
    border: 0px;
  }

  thead > tr:nth-child(2) {
    background: #f2f2f2;
  }

  thead > tr:first-child > th {
    border: 0px;
    border-bottom: 2px solid #b3b3b3;
  }

  th:last-child,
  td:last-child {
    width: 100% !important;
  }

  tr.tr-selected > td {
    border-top: 2px solid ${(props) => props.theme.colors.CSA.darkBlue} !important;
    border-bottom: 2px solid ${(props) => props.theme.colors.CSA.darkBlue} !important;
  }

  tr.tr-selected > td:last-child {
    border-right: 2px solid ${(props) => props.theme.colors.CSA.darkBlue} !important;
  }

  tr.tr-selected > td:first-child {
    border-left: 2px solid ${(props) => props.theme.colors.CSA.darkBlue} !important;
  }
`;

export const Title = styled.h1`
  font-family: 'Gilroy';
  color: ${(props) => props.theme.colors.gray2};
  font-weight: ${(props) => props.theme.weight.semiBold};
  font-size: 20px;
  margin-bottom: 1rem;
`;

export const TableContent = styled.div`
  border: 1px solid ${(props) => props.theme.colors.gray5};
  padding: 20px 20px 30px 20px;
  display: flex;
  gap: 20px;
`;

export const CenterElement = styled.div<{ width?: string }>`
  text-align: center;
  font-weight: ${(props) => props.theme.weight.bold};
  color: ${(p) => p.color};
  width: ${(props) => props.width};
  max-width: ${(props) => props.width};
`;

export const Divider = styled.hr`
  color: ${(props) => props.theme.colors.gray5};
  opacity: 1;
  height: 1px;
  margin: 22px 0px;
`;

export const TableScoreInput = styled(ScoreInput)<{
  name: string;
  placeholder: string;
  value: any;
  onBlur: any;
  questionWeight?: number;
}>`
  width: 60px;
  text-align: center;
  padding: 0px;
  border-radius: 2px;
  font-weight: ${(props) => props.theme.weight.bold};
  font-size: 14px;
  color: ${(props) =>
    props.value
      ? props.value / props.questionWeight < 0.6
        ? '#EB5757'
        : '#6FCF97'
      : 'inherit'};
  text-overflow: ellipsis;
`;

export const HTMLReader = styled.div`
  height: 1.5rem;

  & b,
  strong {
    font-weight: ${(props) => props.theme.weight.bold};
  }

  & p:last-child {
    margin-bottom: 0;
  }

  p {
    margin: 0px;
  }
`;

export const EllipsedText = styled(HTMLReader)`
  flex: 1;
  margin: 0px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  display: flex;
  margin-bottom: -3px;
`;

export const ActionsButton = styled.button<{ $width?: number; ref?: any }>`
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 6px;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #4d4d4d;
  padding: 2px;
  width: ${(props) => props.$width ?? '210'}px;

  :disabled {
    background: ${(props) => props.theme.colors.gray50};
    border: 1px solid ${(props) => props.theme.colors.gray200};
    color: ${(props) => props.theme.colors.gray200};
    cursor: not-allowed;
  }
`;

export const SelectContent = styled.div`
  padding: 1px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LabelCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const LabelWeight = styled.strong`
  margin-left: 3px;
`;

export const SpinnerStyled = styled(Spinner)`
  margin-top: auto;
  margin-bottom: auto;
`;
