import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const StyledColumnItemWrapper = styled.div `
  height: 24px;
  cursor: pointer;
  padding: 5px 12px;
  border-radius: 4px;

  display: flex;
  column-gap: 9px;
  align-items: center;
  justify-content: space-between;

  ${(props) => props.$isSelected ? `background-color: ${props.theme.colors.gray50};` : ''}

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }
`;
export const StyledColumnItem = styled.span `
  font-size: 14px;
  font-weight: 500;
  font-style: normal;

  user-select: none;
  white-space: nowrap;

  color: ${(props) => props.theme.colors.gray3};
`;
export const StyledArrowIcon = styled(CampusIcon) `
  width: 8px;
`;
