import { useMemo } from 'react';
import { getMainClassroomAndSequenceFrom } from '../../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/common/helpers/sequenceRelated';
import { useWeeklyPlanningPageContext } from '../../../../../../../../../../../../context';
export function useSelectedClassroom({ sequence }) {
    const selectedClass = useWeeklyPlanningPageContext((store) => {
        return store.mainTable.filters.selectedClass;
    });
    const selectedClassroom = useMemo(() => {
        let classrooms = sequence?.classrooms ?? [];
        if (selectedClass) {
            classrooms = classrooms.filter((c) => {
                return c.classroom?.class?.id === selectedClass.id;
            });
        }
        const main = getMainClassroomAndSequenceFrom(classrooms);
        return main?.classroom ?? null;
    }, [selectedClass, sequence?.classrooms]);
    return selectedClassroom;
}
