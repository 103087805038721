import { Response } from '@campus/commons';
import { addAreaSkillAndStage, deleteAreaSkillAndStage } from '../areaSkillAndStage';
import { handleApiCallError } from '../common';
function addStages(params) {
    return params.stagesToAdd.map((s) => addAreaSkillAndStage({
        stageId: s.id,
        abortSignal: params.abortSignal,
        areaSkillId: params.areaSkillId
    }));
}
function deleteAreaSkillAndStages(params) {
    return params.areaSkillAndStagesToDelete.map((as) => deleteAreaSkillAndStage({
        id: as.id,
        abortSignal: params.abortSignal
    }));
}
export async function addStagesAndDeleteAreaSkillAndStages(params) {
    try {
        const addPromises = addStages(params);
        const deletePromises = deleteAreaSkillAndStages(params);
        await Promise.all([...addPromises, ...deletePromises]);
        return Response.fromData(null, 200);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
