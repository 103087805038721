import { useCallback, useEffect, useState } from 'react';
import { waitForElementToRender } from '../../../utils/elements';
const containerId = 'blip-chat-container';
export function useControlBlipChatVisibility() {
    const [container, setContainer] = useState(null);
    useEffect(() => {
        waitForElementToRender(containerId).then(setContainer);
    }, []);
    const showChat = useCallback(() => {
        if (container)
            container.style.display = '';
    }, [container]);
    const hideChat = useCallback(() => {
        if (container)
            container.style.display = 'none';
    }, [container]);
    return { showChat, hideChat };
}
