import styled from 'styled-components';
export const StyledFullScreenWrapper = styled.div `
  z-index: 1000;

  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.8);
`;
export const StyledPopupBox = styled.div `
  z-index: 10;

  background-color: white;

  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);

  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
`;
