import React, { useEffect, useState } from 'react';
import { allStatuses } from '@campus/commons';

import {
  ContainerText,
  FilterColumn,
  FilterContainer,
  StyledText
} from './styles';
import { Checkbox } from '@campus/components';
import { useActivityReport } from 'pages/ActivityReport/context/ActivityReportContext';

const QuestionFilter: React.FC = () => {
  const { changeStatusFilter, uniqueStatuses } = useActivityReport();
  const [statusChecked, setStatusChecked] = useState([]);

  useEffect(() => {
    setStatusChecked(uniqueStatuses);
  }, [setStatusChecked, uniqueStatuses]);

  const handleChangeStatus = (status: string) => {
    if (statusChecked.includes(status)) {
      const newStatusChecked = statusChecked.filter(
        (identifier) => identifier !== status
      );
      setStatusChecked(newStatusChecked);
      changeStatusFilter(newStatusChecked);
    } else {
      setStatusChecked([...statusChecked, status]);
      changeStatusFilter([...statusChecked, status]);
    }
  };

  return (
    <>
      {uniqueStatuses.length >= 1 ? (
        <FilterContainer>
          <ContainerText>
            <StyledText>Filtrar por status:</StyledText>
          </ContainerText>
          <FilterColumn>
            {allStatuses
              .filter((t) => uniqueStatuses.includes(t.identifier))
              .map((value) => {
                return (
                  <Checkbox
                    key={value.identifier}
                    name={value.identifier}
                    label={value.text}
                    onChange={() => handleChangeStatus(value.identifier)}
                    checked={!!statusChecked.includes(value.identifier)}
                    icon={value.icon}
                    color={value.color}
                    marginLeft={5}
                  />
                );
              })}
          </FilterColumn>
        </FilterContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default QuestionFilter;
