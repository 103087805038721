import LaunchReportEmptyClassListClassroom from './LaunchReportEmptyClassListClassroom';
export default class LaunchReportEmptyClassListClass {
    id;
    name;
    classroom;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new LaunchReportEmptyClassListClass();
        instance.id = json.ClassId;
        instance.name = json.ClassName;
        instance.classroom = LaunchReportEmptyClassListClassroom.fromJson(json);
        return instance;
    }
}
