import styled from 'styled-components';
import Select, { elementClassNames } from '../../../../../../../Basics/Select';
export const StyledSelect = styled(Select) `
  &
    ${elementClassNames.option},
    &
    ${elementClassNames.wrapper} {
    height: 32px;
    min-width: 280px;

    padding: 0 10px;
  }

  &
    ${elementClassNames.option},
    &
    ${elementClassNames.placeholder},
    &
    ${elementClassNames.selectedValue} {
    font-size: 14px;
    font-weight: 500;

    color: ${(props) => props.theme.colors.gray700};
  }
`;
export const StyledOptionLabelAndIconWrapper = styled.div `
  display: flex;
  align-items: center;
  column-gap: 7px;
`;
export const StyledOptionLabel = styled.span `
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  cursor: pointer;

  color: ${(props) => props.theme.colors.gray700};
`;
