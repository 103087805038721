import { Container, Header, Body } from './styles';
import { commonStyles, Class, CurriculumComponent } from './Columns';
export const Table = {
    Container,
    Header,
    Body
};
export const Columns = {
    commonStyles,
    Class,
    CurriculumComponent
};
