import { AlignmentType, ImageRun, Paragraph, TableCell, VerticalAlign, WidthType } from 'docx';
import SaintAugustineLogo from '../../../../../../../../assets/images/saint-augustine-logo.png';
import { emptyTableCellBorders } from '../../../../common/borderDocx';
import { widths } from '../../../../common/sizesDocx';
export default async function Logo() {
    try {
        const file = await fetch(SaintAugustineLogo);
        const blob = await file.blob();
        const arrayBuffer = await blob.arrayBuffer();
        return new TableCell({
            borders: emptyTableCellBorders,
            verticalAlign: VerticalAlign.CENTER,
            width: { size: widths.header.logo, type: WidthType.PERCENTAGE },
            children: [
                new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [
                        new ImageRun({
                            data: arrayBuffer,
                            transformation: { width: 140, height: 35 }
                        })
                    ]
                })
            ]
        });
    }
    catch {
        return new TableCell({ children: [] });
    }
}
