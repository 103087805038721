import styled from 'styled-components';
import { CustomClassicCKEditor } from '@campus/components';
import PopupBox from '../PopupBox';
const { elementClassNames } = CustomClassicCKEditor;
export const StyledPopup = styled(PopupBox) `
  padding: 17px 20px;
`;
export const StyledTitleWrapper = styled.div `
  padding-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
`;
export const StyledContentWrapper = styled.div `
  margin-top: 17px;

  width: 75dvw;
  height: 60dvh;

  overflow: auto;

  & ${elementClassNames.editableArea} {
    font-size: 14px;
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
  }

  & ${elementClassNames.toolbar} {
    display: none;
  }

  & ${elementClassNames.holeEditor} p {
    margin: 0 !important;
  }

  & ${elementClassNames.holeEditor} a {
    cursor: pointer;
    color: ${(props) => props.theme.colors.blue1};

    :hover {
      text-decoration: underline;
      color: ${(props) => props.theme.colors.CSA.darkBlue};
    }
  }

  & ${elementClassNames.holeEditor} p,
  & ${elementClassNames.holeEditor} a {
    font-weight: normal;
  }

  & ${elementClassNames.holeEditor} b,
  & ${elementClassNames.holeEditor} strong {
    font-weight: ${(props) => props.theme.weight.bold};
  }

  & ${elementClassNames.editableArea} img {
    margin: 0;
  }

  & ${elementClassNames.editableArea} figure {
    min-width: 0;
    margin: 0.9em 0;
  }

  & ${elementClassNames.widget},
  & ${elementClassNames.editableArea} img,
  & ${elementClassNames.editableArea} figure {
    outline: none !important;
  }
`;
