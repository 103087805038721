import React, { useState } from 'react';
import { StyledWrapper, StyledPopup, Button, CalendarIcon } from './styles';
const FilterSkillsButton = (props) => {
    const [canShowPopup, setCanShowPopup] = useState(false);
    const showPopup = () => setCanShowPopup(true);
    const hidePopup = () => setCanShowPopup(false);
    return (React.createElement(StyledWrapper, null,
        React.createElement(Button, { onClick: () => {
                showPopup();
                props.setActiveFilter();
            }, "$isActive": props.isActive },
            React.createElement(CalendarIcon, { "$isActive": props.isActive })),
        React.createElement(StyledPopup, { ...props, canBeDisplayed: canShowPopup, handleExternalClick: hidePopup })));
};
export default FilterSkillsButton;
