import React, { useState } from 'react';
import { useLaunchReportContext } from '../../../../../../../../context';
import { EmptyClassListModal } from '../../../../../../../Modals/EmptyClassListModal';
import { Button } from './styles';
export const ShowModalButton = ({ teacherId }) => {
    const [showModal, setShowModal] = useState(false);
    const { getFilters } = useLaunchReportContext();
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: () => setShowModal(true) }),
        showModal ? (React.createElement(EmptyClassListModal, { teacherId: teacherId, filters: getFilters(), hide: () => setShowModal(false) })) : null));
};
