import styled from 'styled-components';
import PopupBox from '../PopupBox';
export const StyledPopupBox = styled(PopupBox) `
  display: flex;
  flex-direction: column;

  padding: 17px 20px 12px 20px;
`;
export const StyledContentWrapper = styled.div `
  overflow: auto;

  padding: 9px 0;
  margin-top: 9px;

  display: grid;
  column-gap: 10px;
  grid-template-columns: 1fr 1fr 2fr;

  border-top: 1px solid ${(props) => props.theme.colors.gray5};
`;
