import React from 'react';
import { StyledColumnItem, StyledColumnItemWrapper } from '../commonStyles';
import { StyledWrapper } from './styles';
const StagesColumn = (props) => {
    if (!props.selectedStudyUnitVersion)
        return null;
    return (React.createElement(StyledWrapper, null, props.allStages.map((s) => {
        const onClick = () => props.onStageClick(s.fullName, props.type);
        const generatingStudyUnit = props.reportBeingGenerated !== 'annualTimeline';
        const generatingWithThisStage = props.stageBeingUsed === s.fullName;
        const text = generatingStudyUnit && generatingWithThisStage ? '...' : s.fullName;
        return (React.createElement(StyledColumnItemWrapper, { key: s.id, onClick: onClick },
            React.createElement(StyledColumnItem, null, text)));
    })));
};
export default StagesColumn;
