import styled from 'styled-components';
export const StyledWrapper = styled.div `
  position: relative;

  isolation: isolate;

  display: flex;
  width: fit-content;

  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  height: ${(props) => (props.$height > 0 ? `${props.$height}px` : 'auto')};
  min-height: ${(props) => (props.$height > 0 ? `${props.$height}px` : 'auto')};
  max-height: ${(props) => (props.$height > 0 ? `${props.$height}px` : 'auto')};

  ${(props) => {
    const red = props.theme.colors.error;
    const gray = props.theme.colors.gray200;
    const currentRowBorder = `
      ${props.$currentRowBorder.isDashed ? '2px' : '1px'}
      ${props.$currentRowBorder.isDashed ? 'dashed' : 'solid'}
      ${props.$currentRowBorder.isRed ? red : gray}
    `;
    const lastRowBorder = `
      ${props.$lastRowBorder.isDashed ? '2px' : '1px'}
      ${props.$lastRowBorder.isDashed ? 'dashed' : 'solid'}
      ${props.$lastRowBorder.isRed ? red : gray}
    `;
    return `
      border-top: ${currentRowBorder};
      border-bottom: ${lastRowBorder};
    `;
}}
`;
