import React from 'react';
import { useAddOrEditActivityPopupContext } from '../../context';
import { RequestStatus } from '../../../../../../types/dataFetching';
import { FilterContainer, StyledFilterButton, StyledList, StyledPlaceholder } from './styles';
const statusAndPlaceholders = {
    [RequestStatus.Loading]: 'Carregando dados das turmas...',
    [RequestStatus.Failed]: 'Falha ao carregar dados das turmas',
    [RequestStatus.NotStarted]: 'Nada a ser exibido',
    [RequestStatus.Succeeded]: 'Nada a ser exibido',
    [RequestStatus.NotAllowed]: 'Sem permissão para visualizar as turmas'
};
const ClassLessonAndDateList = () => {
    const { classesAndInfos, updateClassInfos, updateSelectedClassroomForEveryClass } = useAddOrEditActivityPopupContext((store) => ({
        classesAndInfos: store.classesAndInfos,
        updateClassInfos: store.updateClassInfos,
        updateSelectedClassroomForEveryClass: store.updateSelectedClassroomForEveryClass
    }));
    const { data, status } = classesAndInfos;
    function firstSelectedClassroomChanged(classroomNumber) {
        updateSelectedClassroomForEveryClass(classroomNumber);
    }
    function toggleAllCheckboxes(value) {
        for (const item of data.values()) {
            updateClassInfos(item.associatedClassName, { isChecked: value });
        }
    }
    if (data?.size > 0) {
        return (React.createElement(React.Fragment, null,
            React.createElement(FilterContainer, null,
                React.createElement(StyledFilterButton, { onClick: () => {
                        toggleAllCheckboxes(true);
                    } }, "Marcar todas"),
                React.createElement(StyledFilterButton, { onClick: () => {
                        toggleAllCheckboxes(false);
                    } }, "Desmarcar todas")),
            React.createElement(StyledList, { classInfos: [...data.values()], updateClassInfos: updateClassInfos, firstSelectedClassroomChanged: firstSelectedClassroomChanged })));
    }
    const placeholder = statusAndPlaceholders[status];
    return React.createElement(StyledPlaceholder, { text: placeholder });
};
export default ClassLessonAndDateList;
