export default class Class {
    id;
    name;
    gradeId;
    disciplineIds;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new Class();
        instance.id = json.Id;
        instance.name = json.Name;
        instance.gradeId = json.GradeId;
        instance.disciplineIds = Array.isArray(json.DisciplineIds)
            ? json.DisciplineIds
            : [];
        return instance;
    }
}
