import { useFollowUpPageContext } from '../../../../../../../../../../context';
import { borderShouldBeDashed, borderShouldBeRed } from '../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/components/Rows/helpers/borders';
export function useBorders(props) {
    const { selectedGrade, selectedSegment, selectedDisciplines } = useFollowUpPageContext((store) => ({
        selectedGrade: store.curriculumComponents.selectedGrade,
        selectedSegment: store.curriculumComponents.selectedSegment,
        selectedDisciplines: store.curriculumComponents.selectedDisciplines
    }));
    const firstPlanning = props.group.plannings.at(0);
    const lastPlanning = props.group.plannings.at(-1);
    const currentRowBorderShouldBeDashed = borderShouldBeDashed({
        relatedPlanning: firstPlanning
    });
    const currentRowBorderShouldBeRed = borderShouldBeRed({
        selectedGrade,
        selectedSegment,
        selectedDisciplines,
        relatedPlanning: firstPlanning
    });
    const lastRowBorderShouldBeDashed = borderShouldBeDashed({
        relatedPlanning: lastPlanning
    });
    const lastRowBorderShouldBeRed = borderShouldBeRed({
        selectedGrade,
        selectedSegment,
        selectedDisciplines,
        relatedPlanning: lastPlanning
    });
    return {
        currentRowBorderShouldBeDashed,
        currentRowBorderShouldBeRed,
        lastRowBorderShouldBeDashed,
        lastRowBorderShouldBeRed
    };
}
