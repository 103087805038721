import { HeightRule, TableRow } from 'docx';
import KnowledgeObjects from './Columns/KnowledgeObjects';
import LearningObjectives from './Columns/LearningObjectives';
import Skill from './Columns/Skill';
import Stage from './Columns/Stage';
import ThemeUnit from './Columns/ThemeUnit';
import { heights } from '../../../../common/sizesDocx';
export default function Row(props) {
    const heightValue = props.isTheLastRowInTheTable
        ? heights.table.lastRow
        : heights.table.normalRow;
    return new TableRow({
        height: { value: heightValue, rule: HeightRule.ATLEAST },
        children: [
            ThemeUnit(props?.areaSkill?.themeUnit),
            Skill(props?.areaSkill),
            LearningObjectives(props?.areaSkill?.learningObjectives),
            KnowledgeObjects(props?.areaSkill?.knowledgeObjects),
            Stage(props?.stage)
        ]
    });
}
