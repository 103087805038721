import React from 'react';
import { NotAllCurriculumComponentsSelectedIcon } from './icons';
export const loadingData = {
    title: 'Carregando...',
    description: 'Os dados da tabela estão sendo carregados, por favor aguarde'
};
export const errorHappened = {
    title: 'Houve uma falha',
    description: 'Os dados da tabela não puderam ser carregados corretamente'
};
export const notAllCurriculumComponentsHaveBeenSelected = {
    icon: React.createElement(NotAllCurriculumComponentsSelectedIcon, null),
    title: 'Nenhum componente curricular foi selecionado',
    description: 'Selecione os componentes curriculares clicando no',
    highlightedText: ' seletor acima'
};
export const noContentToShow = {
    title: 'Nada a ser exibido',
    description: 'Nenhuma habilidade para ser exibida'
};
