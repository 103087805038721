import styled from 'styled-components';
export const StyledWrapper = styled.div `
  position: relative;
`;
export const StyledContentWrapper = styled.div `
  height: inherit;

  overflow: auto;

  display: flex;
  row-gap: 10px;
  flex-direction: column;
`;
