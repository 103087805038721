export function createOptionFrom(classroomNumber) {
    const value = classroomNumber.toString();
    return { value, label: value };
}
function getGroupWithMoreClassrooms(groups) {
    return groups
        .slice(1)
        .reduce((groupWithMoreOptions, currentGroup) => {
        const greaterLength = groupWithMoreOptions.classrooms.length;
        const currentLength = currentGroup.classrooms.length;
        return currentLength > greaterLength
            ? currentGroup
            : groupWithMoreOptions;
    }, groups.at(0));
}
export function createSelectOptionsFrom(classroomGroups) {
    if (!classroomGroups)
        return [];
    if (classroomGroups.length === 0)
        return [];
    const groupWithMoreClassrooms = getGroupWithMoreClassrooms(classroomGroups);
    const otherGroups = classroomGroups.filter((group) => {
        return group.className !== groupWithMoreClassrooms.className;
    });
    return groupWithMoreClassrooms.classrooms
        .map((classroom) => classroom.number)
        .filter((classroomNumber) => {
        return otherGroups.every((group) => {
            return group.classrooms.some((classroom) => {
                return classroom.number === classroomNumber;
            });
        });
    })
        .map((classroomNumber) => createOptionFrom(classroomNumber));
}
