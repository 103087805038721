import React from 'react';
import { useTheme } from 'styled-components';
import { areArraysEqual } from '../../../../../../../../../utils/arrays';
import BaseSelect from '../../../common/stagesRelated/components/Select';
import { useUpdate } from './hooks/useUpdate';
import { useOptionsManager } from '../../../common/stagesRelated/hooks/useOptionsManager';
import { getColorAndText } from '../../../common/stagesRelated/textsAndColors';
const Select = (props) => {
    const theme = useTheme();
    const { requestStatus, update } = useUpdate();
    const { allOptions, selectedOptions, handleOptionClicked } = useOptionsManager({
        areaSkill: props.skill,
        allStages: props.allStages,
        readOnly: props.readOnly
    });
    const hasOrangeColor = !!selectedOptions.length &&
        !areArraysEqual(selectedOptions.map((o) => o.stage.id), props.initialStagesIds);
    function handleClickOutsideMenu() {
        if (props.readOnly)
            return;
        update({
            areaSkill: props.skill,
            allStages: allOptions.map((o) => o.stage),
            selectedStages: selectedOptions.map((o) => o.stage)
        }).then(props.handleStageUpdated);
    }
    const { color, text } = getColorAndText({
        requestStatus: requestStatus,
        selectedStages: selectedOptions.map((o) => o.stage)
    });
    return (React.createElement(BaseSelect, { readOnly: props.readOnly, text: text, textColor: hasOrangeColor ? theme.colors.orange1 : color, options: allOptions, selectedOptions: selectedOptions, handleOptionClicked: handleOptionClicked, handleExternalClick: handleClickOutsideMenu }));
};
export default Select;
