import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import EvaluationType from '../models/EvaluationType';
import { handleApiCallError } from './common';
const endpoints = {
    get: {
        all: '/evaluationtype/types',
        forDisciplines(disciplineIds) {
            const idsJoined = disciplineIds?.join(',');
            return `discipline/${idsJoined}/evaluationtypes`;
        }
    }
};
export async function getAllEvaluationTypes(params = null) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.get.all, {
            signal: params?.abortSignal
        });
        const types = response.data.map((item) => EvaluationType.fromJson(item));
        return Response.fromData(types, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function getEvaluationTypesForDisciplines(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpoint = endpoints.get.forDisciplines(params.disciplineIds);
        const response = await axiosInstance.get(endpoint, {
            signal: params?.abortSignal
        });
        const data = response.data.map((item) => EvaluationType.fromJson(item));
        return Response.fromData(data, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
