import React from 'react';
import { useCalendarContext } from '../../../../../context';
import { getMonthNameFrom } from '../../../../../../../../../../../common/utils/dates';
import { StyledMonthName, StyledMonthWrapper, StyledWrapper } from './styles';
const MonthsRow = () => {
    const months = useCalendarContext((store) => store.months);
    function renderMonths() {
        return months.map((month) => {
            const monthName = getMonthNameFrom(month.number);
            const width = month.weeks.length * 70 + 1;
            return (React.createElement(StyledMonthWrapper, { "$width": width, key: month.number },
                React.createElement(StyledMonthName, null, monthName)));
        });
    }
    return React.createElement(StyledWrapper, null, renderMonths());
};
export default MonthsRow;
