import React from 'react';
import { StyledText, StyledWrapper } from './styles';
const Item = (props) => {
    function onClick() {
        props.onClick(props.option);
    }
    return (React.createElement(StyledWrapper, { onClick: onClick, "$isSelected": props.isSelected },
        React.createElement(StyledText, { "$isSelected": props.isSelected }, props.option.label)));
};
export default Item;
