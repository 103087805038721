import { Api } from '@campus/auth';
import { Response, PaginationModel, Discipline } from '@campus/commons';

export const getAllDisciplines = async (
  name?: string
): Promise<Response<PaginationModel<Discipline>>> => {
  try {
    const response = await Api.INSTANCE.getApi().get('/discipline', {
      params: { MaxResultCount: 1000, Name: name }
    });

    return Response.fromData(
      PaginationModel.fromJson(Discipline, response.data),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};

export const getAllDisciplinesByGrade = async (
  gradeId: string
): Promise<Response<PaginationModel<Discipline>>> => {
  try {
    const response = await Api.INSTANCE.getApi().get('/discipline', {
      params: {
        MaxResultCount: 1000,
        GradeId: gradeId
      }
    });

    return Response.fromData(
      PaginationModel.fromJson(Discipline, response.data),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};

export const getDiscipline = async (
  id?: string
): Promise<Response<Discipline>> => {
  try {
    const response = await Api.INSTANCE.getApi().get(`/discipline/${id}`);

    return Response.fromData(
      Discipline.fromJson(response.data),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};

export const listClassDiscipline = async (
  classId: string,
  stageId?: string
): Promise<Response<Discipline[]>> => {
  try {
    const response = await Api.INSTANCE.getApi().get(
      `/class/${classId}/disciplines`,
      { params: { stageId } }
    );

    return Response.fromData(
      response.data
        .map((item) => Discipline.fromJson(item))
        .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name)),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};

export const listTeacherDiscipline = async (
  teacherId: string,
  classId: string
): Promise<Response<Discipline[]>> => {
  try {
    const response = await Api.INSTANCE.getApi().get(
      `/teacher/${teacherId}/class/${classId}/discipline`
    );

    return Response.fromData(
      response.data
        .map((item) => Discipline.fromJson(item))
        .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name)),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};
