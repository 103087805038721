import React, { useCallback, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import { useFollowUpMainTableContext } from '../../../../../../../../../context';
import ClassBox from './components/ClassBox';
import TrashIcon from './components/TrashIcon';
import Content from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/DidacticSequenceRelated/components/ActivityRelated/components/Content';
import ExpandButton from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/DidacticSequenceRelated/components/ActivityRelated/components/ExpandButton';
import { useExpandButton } from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/DidacticSequenceRelated/components/ActivityRelated/hooks/useExpandButton';
import { getActivityState } from './helpers';
import { getActivityContentTextColor } from './colors';
import { StyledWrapper } from './styles';
const contentHeight = 300;
const Activity = (props) => {
    const wrapperRef = useRef(null);
    const classBoxRef = useRef(null);
    const theme = useTheme();
    const setActivityInfos = useFollowUpMainTableContext((store) => store.setActivityInfos);
    const { canShowExpandButton } = useExpandButton({
        elementRef: wrapperRef,
        sequence: props.sequence,
        showButtonIfHeightIsGreaterThan: contentHeight
    });
    const state = getActivityState(props.sequence);
    const getClassBoxBoundingClientRect = useCallback(() => {
        return classBoxRef.current.getBoundingClientRect();
    }, []);
    useEffect(() => {
        if (!props.sequence)
            return;
        setActivityInfos(props.sequence.id, { getClassBoxBoundingClientRect });
    }, [props.sequence, setActivityInfos, getClassBoxBoundingClientRect]);
    return (React.createElement(StyledWrapper, { ref: wrapperRef, "$addPaddingBottom": !canShowExpandButton },
        React.createElement(TrashIcon, { sequence: props.sequence, planning: props.planning }),
        React.createElement(Content, { sequence: props.sequence, maxHeight: contentHeight, textColor: getActivityContentTextColor(state, theme) }),
        React.createElement(ClassBox, { ref: classBoxRef, sequence: props.sequence, planning: props.planning }),
        canShowExpandButton ? React.createElement(ExpandButton, { sequence: props.sequence }) : null));
};
export default Activity;
