import format from 'date-fns/format';

class StudentAnswerHistory {
  id: string;

  isPrimary: boolean;

  startTime: Date;

  startTimeFormatted: string;

  state: string;

  score: number;

  teacherScore: number;

  systemScore: number

  static fromJson(data: any): StudentAnswerHistory {
    const answer = new StudentAnswerHistory();
    answer.id = data.Id;
    answer.isPrimary = data.IsPrimary;

    answer.state = data.State;
    answer.score = data.Score;
    answer.teacherScore = data.TeacherScore;
    answer.systemScore = data.SystemScore;

    const startTimeDate = new Date(data.StartTime);

    const formattedDate = format(startTimeDate, 'dd/MM/yyyy HH:mm:ss');
    answer.startTime = startTimeDate;
    answer.startTimeFormatted = formattedDate;

    return answer;
  }
}

export default StudentAnswerHistory;
