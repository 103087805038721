import React, { useMemo } from 'react';
import styled from 'styled-components';

import { QuestionType } from '@campus/commons';

import { useContent } from '@campus/discipline';

import AnswerQuestion from 'models/AnswerQuestion';
import StudentAnswerReport from 'models/StudentAnswerReport';

import { changeAnswerScore } from 'services/answers';
import { useActivityReport } from '../context/ActivityReportContext';

import { TableScoreInput } from '../styles';
import AnswerText from './AnswerText';
import { toast } from '@campus/components';

const AnswerContent = styled.div`
  display: flex;
  gap: 0.5rem;
`;

type StudentQuestionProps = {
  question: AnswerQuestion;
  data: StudentAnswerReport;
  currentQuestionIndex?: number;
  currentStudentIndex?: number;
  inputRef?: any;
  onEnter?: (
    currentStudentIndex: number,
    shiftKey: boolean,
    event: React.KeyboardEvent<HTMLInputElement>,
    currentQuestionIndex?: number
  ) => void;
  onTab?: (
    currentStudentIndex: number,
    shiftKey: boolean,
    event: React.KeyboardEvent<HTMLInputElement>,
    closedQuestionsPreviousCount?: number,
    currentQuestionIndex?: number
  ) => void;
  closedQuestionsPreviousCount?: number;
};

const StudentQuestion: React.FC<StudentQuestionProps> = ({
  question,
  data,
  inputRef,
  currentQuestionIndex,
  currentStudentIndex,
  onEnter,
  onTab,
  closedQuestionsPreviousCount
}) => {
  const { contentId } = useContent();
  const answers = useMemo(() => data?.answers ?? [], [data?.answers]);

  const answer = useMemo(
    () => answers.find((a) => a.contentItemId === question.contentItemId),
    [answers, question.contentItemId]
  );

  const { updateStudents } = useActivityReport();

  const teacherScore = answer?.teacherScore;

  const submitScore = async (score: number) => {
    if (teacherScore !== score) {
      const response = await changeAnswerScore(contentId, {
        score: score,
        studentId: [data.student.id],
        contentItemId: question.contentItemId
      });

      if (response.data) {
        updateStudents(response.data);
      } else if (response.error) {
        toast.error(response.error.message);
      }
    }
  };

  const showInput = [QuestionType.OPEN, QuestionType.ATTACHMENT].includes(
    question?.questionType
  );

  const isOpenQuestion =
    question.questionType === QuestionType.OPEN ||
    question.questionType === QuestionType.ATTACHMENT;
  const answerText = answer?.getText(isOpenQuestion);

  const answerColor = isOpenQuestion
    ? null
    : answerText === question.answerText
    ? '#6FCF97'
    : '#EB5757';

  return (
    <AnswerContent>
      <div
        style={{
          display: 'flex',
          maxWidth: isOpenQuestion ? 220 : 100,
          width: '100%'
        }}
      >
        <AnswerText
          color={answerColor}
          answerText={answerText}
          isOpenQuestion={isOpenQuestion}
        />

        {showInput && (
          <TableScoreInput
            name="score"
            placeholder="-"
            value={teacherScore}
            onBlur={submitScore}
            inputRef={inputRef}
            currentQuestionIndex={currentQuestionIndex}
            currentStudentIndex={currentStudentIndex}
            onEnter={onEnter}
            onTab={onTab}
            closedQuestionsPreviousCount={closedQuestionsPreviousCount}
            maxScore={question.weight}
          />
        )}
      </div>
    </AnswerContent>
  );
};

export default StudentQuestion;
