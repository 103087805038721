import { QuestionType } from '@campus/commons';
import React, { createRef, useMemo, useRef } from 'react';

import { DataGrid, DataGridProps, TextOverlay } from '@campus/components';

import StudentName from './components/StudentName';
import QuestionReview from './components/QuestionReview';
import StudentQuestion from './components/StudentQuestion';
import ReportTableHeader from './components/ReportTableHeader';
import TeacherScore from './components/TeacherScore';

import { QuestionReviewProvider } from './context/QuestionReviewContext';
import {
  useActivityReport,
  ActivityReportProvider
} from './context/ActivityReportContext';
import {
  useReportActions,
  ReportActionsProvider
} from './context/ReportActionsContext';

import {
  LabelCenter,
  LabelWeight,
  Root,
  CenterElement,
  TableContent,
  Title,
  Container,
  TableContainer,
  Table,
  EllipsedText
} from './styles';
import QuestionFilter from './components/QuestionFilter';

import QuestionStatus from './components/QuestionStatus';

const ActivityTable: React.FC = () => {
  const containerRef = useRef(null);
  const MEDIA = 0.6;

  const {
    loading,
    students,
    questionWeight,
    selectedStudent,
    viewStudent,
    questions
  } = useActivityReport();

  const openQuestionsCount = questions.filter(
    (q) =>
      q.questionType !== QuestionType.UNIQUE &&
      q.questionType !== QuestionType.MULTIPLE
  ).length;

  const inputsRef = useRef([]);
  const inputsStudentsQuestionsRef = useRef([[]]);

  inputsRef.current = Array(students.length)
    .fill(0)
    .map((_, i) => inputsRef.current[i] || createRef());

  inputsStudentsQuestionsRef.current = Array(students.length)
    .fill(0)
    .map((_, i) =>
      Array(openQuestionsCount)
        .fill(0)
        .map(
          (_, j) =>
            (inputsStudentsQuestionsRef.current[i] &&
              inputsStudentsQuestionsRef.current[i][j]) ||
            createRef()
        )
    );

  const { setSelectedStudents } = useReportActions();

  const columns = useMemo<DataGridProps['columns']>(() => {
    const onEnterQuestion = (
      currentStudentIndex: number,
      shiftKey: boolean,
      _: React.KeyboardEvent<HTMLInputElement>,
      currentQuestionIndex?: number
    ) => {
      let studentIndexToMove = currentStudentIndex + 1;
      if (shiftKey) {
        studentIndexToMove = currentStudentIndex - 1;
      }
      const moveToUpOrDown =
        studentIndexToMove >= 0 && studentIndexToMove < students.length;
      const moveToRight =
        studentIndexToMove >= students.length &&
        currentQuestionIndex + 1 < openQuestionsCount;
      const moveToLeft = shiftKey && studentIndexToMove <= 0;
      if (moveToUpOrDown) {
        const elementRef =
          inputsStudentsQuestionsRef.current[studentIndexToMove][
            currentQuestionIndex
          ];
        elementRef.current.focus();
      } else if (moveToRight) {
        focusQuestion(currentQuestionIndex + 1, 0);
      } else if (moveToLeft) {
        if (currentQuestionIndex - 1 >= 0) {
          focusQuestion(currentQuestionIndex - 1, students.length - 1);
        } else {
          const elementRef = inputsRef.current[students.length - 1];
          elementRef.current.focus();
        }
      }
    };

    const focusQuestion = (questionIndex?: number, studentIndex?: number) => {
      if (questionIndex + 1 >= 0 && questionIndex + 1 <= openQuestionsCount) {
        const elementRef =
          inputsStudentsQuestionsRef.current[studentIndex][questionIndex];
        elementRef.current.focus();
      }
    };

    const onEnterScore = (
      currentIndex: number,
      shiftKey: boolean,
      _: React.KeyboardEvent<HTMLInputElement>,
      __?: number
    ) => {
      let studentIndexToMove = currentIndex + 1;
      if (shiftKey) {
        studentIndexToMove = currentIndex - 1;
      }
      if (
        studentIndexToMove + 1 >= 0 &&
        studentIndexToMove + 1 <= students.length
      ) {
        const elementRef = inputsRef.current[studentIndexToMove];
        elementRef.current.focus();
      } else if (studentIndexToMove + 1 > students.length) {
        focusQuestion(0, 0);
      }
    };

    const onTab = (
      studentIndex: number,
      shiftKey: boolean,
      _: React.KeyboardEvent<HTMLInputElement>,
      closedQuestionsPreviousCount: number = 0,
      questionIndex: number = -1
    ) => {
      let questionIndexToMove =
        questionIndex + 1 - closedQuestionsPreviousCount;
      if (shiftKey) {
        questionIndexToMove = questionIndex - 1 - closedQuestionsPreviousCount;
      }

      const moveToRightOrLeft =
        questionIndexToMove >= -1 && questionIndexToMove < openQuestionsCount;
      const moveToDown =
        questionIndexToMove >= openQuestionsCount &&
        studentIndex + 1 < students.length;
      const moveToUp =
        questionIndexToMove === -2 && shiftKey && studentIndex - 1 >= 0;
      if (moveToRightOrLeft) {
        if (questionIndexToMove === -1) {
          const elementRef = inputsRef.current[studentIndex];
          elementRef.current.focus();
        } else {
          const elementRef =
            inputsStudentsQuestionsRef.current[studentIndex][
              questionIndexToMove
            ];
          elementRef.current.focus();
        }
      } else if (moveToDown) {
        const elementRef = inputsRef.current[studentIndex + 1];
        elementRef.current.focus();
      } else if (moveToUp) {
        if (openQuestionsCount >= 1) {
          focusQuestion(openQuestionsCount - 1, studentIndex - 1);
        } else {
          const elementRef = inputsRef.current[studentIndex - 1];
          elementRef.current.focus();
        }
      }
    };

    const defaultColumns: any = [
      {
        Header: 'Estudante',
        accessor: 'student',
        minWidth: 220,
        padding: 0,
        Cell: ({ value, onClick }) => (
          <StudentName student={value} onClick={onClick} />
        )
      },
      {
        Header: 'Status',
        accessor: 'state',
        width: 62,
        Cell: ({ row }) => (
          <QuestionStatus containerRef={containerRef} data={row.original} />
        )
      },
      {
        id: 'score',
        Header: (
          <div className="left-border right-border top-border">
            <LabelCenter>Nota</LabelCenter>
          </div>
        ),
        columns: [
          {
            id: 'score',
            Header: (
              <div className="left-border right-border">
                {questionWeight && (
                  <LabelCenter>
                    Valor Total: <LabelWeight>{questionWeight}</LabelWeight>
                  </LabelCenter>
                )}
              </div>
            ),
            columns: [
              {
                Header: () => <div className="left-border">Sistema</div>,
                accessor: 'score',
                width: 70,
                Cell: ({ value, row }) => {
                  return (
                    <CenterElement
                      className="left-border"
                      color={`${
                        value / questionWeight < MEDIA || value != null
                          ? '#EB5757'
                          : '#6FCF97'
                      }`}
                    >
                      {row.original.teacherScore !== null || value == null
                        ? '-'
                        : value?.toString().replace('.', ',')}
                    </CenterElement>
                  );
                }
              },
              {
                Header: () => <div className="right-border">Professor</div>,
                accessor: 'teacherScore',
                width: 78,
                style: {
                  borderRight: '2px solid #003C71 !important'
                },
                Cell: ({ row }) => {
                  const inputRef = inputsRef.current[row.index];

                  return (
                    <div className="right-border">
                      <TeacherScore
                        data={row.original}
                        questionWeight={questionWeight}
                        inputRef={inputRef}
                        currentStudentIndex={row.index}
                        onEnter={onEnterScore}
                        onTab={onTab}
                      />
                    </div>
                  );
                }
              }
            ]
          }
        ]
      }
    ];

    if (!selectedStudent) {
      let closedQuestionsIndexes = [];
      questions?.forEach((q, index) => {
        if (
          q.questionType === QuestionType.UNIQUE ||
          q.questionType === QuestionType.MULTIPLE
        ) {
          closedQuestionsIndexes.push(index);
        }
        defaultColumns.push({
          id: `header-${q.contentItemId}`,
          Header: (
            <TextOverlay tooltip={q.title}>
              <CenterElement
                width={
                  q.questionType === 'Open'
                    ? '220px !important'
                    : '100px !important'
                }
              >
                <EllipsedText>{index + 1 + '. ' + q.title}</EllipsedText>
              </CenterElement>
            </TextOverlay>
          ),
          columns: [
            {
              Header: <CenterElement>{q ? q.answerText : '-'}</CenterElement>,
              id: q.contentItemId,
              accessor: 'answers',
              width: 100,
              Cell: ({ row }) => {
                if (
                  q.questionType !== QuestionType.UNIQUE &&
                  q.questionType !== QuestionType.MULTIPLE
                ) {
                  let closedQuestionsPreviousCount =
                    closedQuestionsIndexes.filter((i) => i < index).length;

                  const inputRef =
                    inputsStudentsQuestionsRef.current[row.index][
                      index - closedQuestionsPreviousCount
                    ];
                  return (
                    <StudentQuestion
                      question={q}
                      data={row.original}
                      inputRef={inputRef}
                      currentQuestionIndex={index}
                      currentStudentIndex={row.index}
                      closedQuestionsPreviousCount={
                        closedQuestionsPreviousCount
                      }
                      onEnter={onEnterQuestion}
                      onTab={onTab}
                    />
                  );
                } else {
                  return (
                    <StudentQuestion
                      question={q}
                      data={row.original}
                      currentQuestionIndex={index}
                      currentStudentIndex={row.index}
                    />
                  );
                }
              }
            }
          ]
        });
      });
    }

    defaultColumns.push({
      id: 'blank',
      Header: '',
      columns: [{ id: 'que' }]
    });

    return defaultColumns;
  }, [
    questionWeight,
    selectedStudent,
    questions,
    openQuestionsCount,
    students.length
  ]);

  return (
    <Container ref={containerRef}>
      <ReportTableHeader />
      <QuestionFilter />

      <TableContainer>
        <Table>
          <DataGrid
            data={students}
            columns={columns}
            loading={loading}
            onClickRow={questions?.length > 0 ? viewStudent : null}
            onRowsSelected={(rows) => setSelectedStudents(rows)}
            hasScoreBorder
          />
        </Table>

        {selectedStudent && (
          <QuestionReviewProvider>
            <QuestionReview />
          </QuestionReviewProvider>
        )}
      </TableContainer>
    </Container>
  );
};

const ActivityReport: React.FC = () => {
  return (
    <Root>
      <Title>Relatório da Atividade</Title>
      <TableContent>
        <ActivityReportProvider>
          <ReportActionsProvider>
            <ActivityTable />
          </ReportActionsProvider>
        </ActivityReportProvider>
      </TableContent>
    </Root>
  );
};

export default ActivityReport;
