import React, { useState } from 'react';
import ArrowIcon from './components/ArrowIcon';
import Menu from './components/Menu';
import { StyledMenuTrigger, StyledText, StyledWrapper } from './styles';
const Select = (props) => {
    const [showMenu, setShowMenu] = useState(false);
    function toggleShowMenu() {
        if (props.readOnly)
            return;
        setShowMenu((show) => !show);
    }
    function handleExternalClick() {
        toggleShowMenu();
        props.handleExternalClick();
    }
    return (React.createElement(StyledWrapper, null,
        React.createElement(StyledMenuTrigger, { onClick: toggleShowMenu, "$color": props.textColor, "$disabled": props.readOnly },
            React.createElement(StyledText, { "$color": props.textColor }, props.text),
            props.readOnly ? null : React.createElement(ArrowIcon, { color: props.textColor })),
        React.createElement(Menu, { options: props.options, canBeDisplayed: showMenu, selectedOptions: props.selectedOptions, handleExternalClick: handleExternalClick, handleOptionClicked: props.handleOptionClicked })));
};
export default Select;
