export const elementClassNames = {
    selectedValue: '.select__single-value',
    selectedValueWrapper: '.select__value-container',
    input: '.select__input',
    inputWrapper: '.select__input-container',
    wrapper: '.select__control',
    focusedWrapper: '.select__control--is-focused',
    menuWrapper: '.select__menu',
    menu: '.select__menu-list',
    separator: '.select__indicator-separator',
    option: '.select__option',
    dropdown: '.select__dropdown-indicator',
    dropdownWrapper: '.select__indicators',
    placeholder: '.select__placeholder',
    loadingMessage: '.select__menu-notice--loading',
    noOptionsMessage: '.select__menu-notice--no-options',
    clearOptionsWrapper: '.select__clear-indicator'
};
