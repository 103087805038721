export function formatDate(date) {
    return date?.toLocaleDateString('pt-br', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
}
export function getWeekDayFrom(date) {
    const weekday = date?.toLocaleDateString('pt-br', { weekday: 'long' });
    if (!weekday)
        return '';
    return weekday[0].toUpperCase() + weekday.substring(1);
}
