import React from 'react';

import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import { Tooltip, OverlayTrigger } from '@campus/components';
import { QuestionType } from '@campus/commons';
import AnswerRangesLabels from './AnswerRangesLabels';

import InsightAnswer from 'models/InsightAnswer';
import ProgressBar from './ProgressBar';
import { ChartBarContainer } from '../styles';

type QuestionAnswersProps = {
  questionType: QuestionType;
  answers: InsightAnswer[];
};

const QuestionAnswers: React.FC<QuestionAnswersProps> = ({
  questionType,
  answers
}) => {
  const renderTooltip = (
    props: OverlayInjectedProps,
    answer: InsightAnswer
  ) => (
    <Tooltip {...props}>
      {answer.percentage}% dos estudantes marcaram a opção&nbsp;
      <u>{answer.correct ? 'correta' : 'errada'}</u> ({answer.optionText})
    </Tooltip>
  );

  const renderUniqueTooltip = (
    props: OverlayInjectedProps,
    answer: InsightAnswer
  ) => (
    <Tooltip {...props}>
      {answer.count}&nbsp;
      {answer.count === 1 ? 'estudante obteve ' : 'estudantes obtiveram '}
      {answer.range === 1 || answer.range === 4
        ? `${AnswerRangesLabels[answer.range]} de desempenho na questão`
        : `o desempenho ${AnswerRangesLabels[answer.range]} na questão`}
    </Tooltip>
  );

  const getBorderRadiusFromIndex = (index: number, length: number) => {
    if (index === 0 && index === length - 1) {
      return '2px';
    } else if (index === 0) {
      return '2px 0px 0px 2px';
    } else if (index === length - 1) {
      return '0px 2px 2px 0px';
    }
  };

  const renderAnswers = () => {
    switch (questionType) {
      case QuestionType.ATTACHMENT:
      case QuestionType.OPEN: {
        const answersWithPercentage = answers.filter((a) => a.percentage > 0);
        return answersWithPercentage.map((a, i) => (
          <OverlayTrigger
            key={`${i}${a?.id}`}
            overlay={(p) => renderUniqueTooltip(p, a)}
          >
            <ProgressBar
              percentage={a?.percentage}
              correct={a?.correct}
              alignRight={a?.correct}
              isOpenQuestion
              range={a?.range}
              borderRadius={getBorderRadiusFromIndex(
                i,
                answersWithPercentage.length
              )}
            >
              {a?.correct && <p>{a?.percentage}%</p>}
            </ProgressBar>
          </OverlayTrigger>
        ));
      }

      case QuestionType.MULTIPLE:
      case QuestionType.UNIQUE: {
        const answersWithPercentage = answers.filter((a) => a.percentage > 0);
        return answersWithPercentage.map((a, i) => (
          <OverlayTrigger
            key={`${i}${a?.id}`}
            overlay={(p) => renderTooltip(p, a)}
          >
            <ProgressBar
              percentage={a?.percentage}
              correct={a?.correct}
              isOpenQuestion={false}
              borderRadius={getBorderRadiusFromIndex(
                i,
                answersWithPercentage.length
              )}
            >
              <p>{a?.optionText}</p>
            </ProgressBar>
          </OverlayTrigger>
        ));
      }

      default:
        return null;
    }
  };

  return <ChartBarContainer>{renderAnswers()}</ChartBarContainer>;
};

export default QuestionAnswers;
