import React from 'react';
import { StyledInfoContent, StyledInfoLabel, StyledInfoWrapper } from '../common/styles';
const Stages = (props) => {
    const stages = props.areaSkill?.unitStages?.length > 0
        ? props.areaSkill?.unitStages
        : props.areaSkill?.programStages;
    const content = stages?.map((s) => s.stage.fullName)?.join(', ') ?? '---';
    return (React.createElement(StyledInfoWrapper, null,
        React.createElement(StyledInfoLabel, null, "Etapas: "),
        React.createElement(StyledInfoContent, null, content)));
};
export default Stages;
