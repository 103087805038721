import styled from 'styled-components';
export const StyledButton = styled.button `
  height: 24px;

  font-size: 12px;
  font-weight: 500;
  font-style: normal;

  line-height: 16px;

  color: white;

  border-radius: 3px;

  border: 1px solid ${(props) => props.theme.colors.blue1};

  background-color: ${(props) => props.theme.colors.blue1};

  &:not(:disabled):hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.7;
  }
`;
