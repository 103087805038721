import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
import { StyledBasicButton } from '../styles';
export const StyledButton = styled(StyledBasicButton) `
  padding: 10px 12px;

  background-color: white;
  color: ${(props) => props.theme.colors.gray3};
  border-color: ${(props) => props.theme.colors.gray3};

  &,
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  column-gap: 8px;
`;
export const StyledExpandIcon = styled(CampusIcon).attrs({
    name: 'expandScreen'
}) ``;
