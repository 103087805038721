import { useEffect, useState } from 'react';

import { SelectOption } from '@campus/components';
import { Discipline, useContextManager } from '@campus/commons';
import { getGrade } from 'services/grade';
import Grade from 'models/Grade';

export type LibraryObjectListFilters = {
  text?: string;
  discipline?: Discipline;
  grade?: Grade;
  type?: SelectOption;
  page?: number;
  pageSize?: number;
};

const useLibraryObjectsFilters = () => {
  const { classroom } = useContextManager();
  const [filters, setFilters] = useState<LibraryObjectListFilters>({
    page: 1,
    pageSize: 15
  });

  useEffect(() => {
    const getDefaultGrade = async () => {
      const response = await getGrade(classroom?.gradeId);
      if (response.data) handleFilters('grade', response.data);
    };

    getDefaultGrade();
  }, [classroom?.gradeId]);

  const handleFilters = (
    key: keyof any,
    value: any,
    pageZeroButtonRef?: any
  ) => {
    setFilters((f) => ({ ...f, [key]: value }));

    if (pageZeroButtonRef?.current) {
      pageZeroButtonRef.current?.click();
    }
  };

  return {
    filters,
    handleFilters
  };
};

export default useLibraryObjectsFilters;
