export default class LaunchReportEmptyClassListClassroom {
    date;
    number;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new LaunchReportEmptyClassListClassroom();
        instance.number = json.ClassroomNumber;
        instance.date = new Date(json.ClassroomDate);
        return instance;
    }
}
