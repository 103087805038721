import React, { useRef } from 'react';
import { useExternalClick } from '../../../hooks';
import { StyledFullScreenWrapper, StyledPopupBox } from './styles';
const PopupBox = (props) => {
    const contentRef = useRef(null);
    useExternalClick(contentRef, props.handleExternalClick);
    const content = (React.createElement(StyledPopupBox, { ref: contentRef, className: props.className }, props.children));
    const popup = props.isFullScreen ? (React.createElement(StyledFullScreenWrapper, null, content)) : (content);
    return props.canBeDisplayed ? popup : null;
};
export default PopupBox;
