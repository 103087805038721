export function shouldBoxBeDisabled(infos, planning, userPermissions) {
    const isPlanningDeleted = planning?.status?.isDeleted() ?? false;
    const isPlanningFinished = planning?.status?.isFinished() ?? false;
    const userNotAllowedToUpdate = !userPermissions?.userCan.updateTheTeacherColumns();
    return (userNotAllowedToUpdate ||
        isPlanningDeleted ||
        isPlanningFinished ||
        (infos?.disabled ?? false));
}
export function getText(infos, selectedOption) {
    return (infos?.content ?? (selectedOption ? `Aula ${selectedOption.label}` : '---'));
}
const dateFormatter = new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
});
export function getFormattedClassroomDate(infos, classroom) {
    if (infos?.content || !classroom)
        return '';
    return dateFormatter.format(classroom.date);
}
