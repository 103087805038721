import React from 'react';
import { RequestStatus } from '../../../../../../../../../../../../../../types/dataFetching';
import { useAddSkill } from './hooks/useAddSkill';
import { useEventHandlers } from './hooks/useEventHandlers';
import { StyledWrapper, StyledButton } from './styles';
export const statusRelatedStates = {
    [RequestStatus.NotStarted]: {
        disabled: false,
        text: 'ADICIONAR'
    },
    [RequestStatus.Loading]: {
        disabled: true,
        text: 'ADICIONANDO...'
    },
    [RequestStatus.Failed]: {
        disabled: true,
        text: 'FALHA AO ADICIONAR'
    },
    [RequestStatus.Succeeded]: {
        disabled: true,
        text: 'ADICIONADO!'
    },
    [RequestStatus.NotAllowed]: {
        disabled: true,
        text: 'ADIÇÃO NÃO PERMITIDA'
    }
};
const AddSkillButton = (props) => {
    const { handleClick } = useEventHandlers(props.infos);
    const { addSkill, requestStatus } = useAddSkill(props.infos);
    const statusRelatedState = statusRelatedStates[requestStatus];
    const disabled = statusRelatedState.disabled;
    return props.canBeDisplayed ? (React.createElement(StyledWrapper, null,
        React.createElement(StyledButton, { disabled: disabled, "$addFailed": requestStatus === RequestStatus.Failed, "$addSucceeded": requestStatus === RequestStatus.Succeeded, onClick: disabled ? null : () => handleClick(addSkill) }, statusRelatedState.text))) : null;
};
export default AddSkillButton;
