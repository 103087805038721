import React, { useState } from 'react';
import { getMaximumValueFrom } from '../../utils/arrays';
import Context from './context';
const Provider = (props) => {
    const [portals, setPortals] = useState({});
    function generatePortalId() {
        const ids = Object.keys(portals).map((id) => Number(id));
        const max = getMaximumValueFrom(ids) ?? 0;
        return (max + 1) % 10;
    }
    function addPortal(portal) {
        const id = generatePortalId();
        setPortals((portals) => {
            return { ...portals, [id]: portal };
        });
        return id;
    }
    function removePortal(portalId) {
        setPortals((portals) => {
            const newValue = {};
            for (const id in portals) {
                if (!!portals[id]) {
                    newValue[id] = portals[id];
                }
            }
            newValue[portalId] = null;
            return newValue;
        });
    }
    function renderPortals() {
        return Object.entries(portals)
            .filter(([_, portal]) => !!portal)
            .map(([id, portal]) => (React.createElement(React.Fragment, { key: id }, portal)));
    }
    return (React.createElement(Context.Provider, { value: { portals, addPortal, removePortal } },
        renderPortals(),
        props.children));
};
export default Provider;
