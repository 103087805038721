import AreaSkill from './AreaSkill';
import Stage from './Stage';
export default class AreaSkillAndStage {
    id;
    stage;
    createdAt;
    areaSkillId;
    areaSkill;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new AreaSkillAndStage();
        instance.id = json.Id;
        instance.areaSkillId = json.AreaSkillId;
        instance.createdAt = json.CreateDate ? new Date(json.CreateDate) : null;
        instance.stage = Stage.fromJson(json.PlanningStage);
        instance.areaSkill = AreaSkill.fromJson(json.AreaSkill);
        return instance;
    }
}
