import { isDateInvalid } from '../../../../../../../../../../utils/dates';
const dateFormatter = new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: '2-digit'
});
export function formatDate(date) {
    if (isDateInvalid(date))
        return '--/--';
    return dateFormatter.format(date);
}
