import React, { useCallback } from 'react';
import TextArea from '../common/components/TextArea';
import { updateObservedLags } from './updates';
import { getPlaceholder } from '../common/components/TextArea/placeholders';
import { StyledWrapper } from './styles';
const Observedlags = (props) => {
    const isPlanningDeleted = props.planning?.status?.isDeleted() ?? false;
    const readOnly = props.doNotAllowUpdates || isPlanningDeleted;
    const placeholder = getPlaceholder({
        readOnly,
        isPlanningDeleted
    });
    const updateContent = useCallback(async (content) => {
        return updateObservedLags(props.planning, content).then(() => {
            props.planning.observedLags = content;
            props.handleObservedLagsUpdated(props.planning);
        });
    }, [props.planning, props.handleObservedLagsUpdated]);
    const onChange = useCallback((content) => {
        props.planning.observedLags = content;
        props.handleObservedLagsUpdated(props.planning);
    }, [props.planning, props.handleObservedLagsUpdated]);
    return (React.createElement(StyledWrapper, null,
        React.createElement(TextArea, { key: props.planning.id, readOnly: readOnly, placeholder: placeholder, initialValue: props.planning.observedLags, onChange: onChange, updateContent: updateContent })));
};
export default Observedlags;
