import React, { useEffect } from 'react';
import { useSticky } from 'react-table-sticky';
import { useBlockLayout, useTable } from 'react-table';
import { DragDropContext } from 'react-beautiful-dnd';
import { WeeklyPlanningPageMainTableContext } from './context';
import { useWeeklyPlanningPageContext } from '../../../../context';
import TableBody from './components/TableBody';
import TableHeader from './components/TableHeader';
import TablePlaceholder from '../../../../../common/components/PageRelated/Body/components/Table/components/TablePlaceholder';
import { columns } from './columns';
import { useDragAndDrop } from './hooks/useDragAndDrop';
import { useFetchPlannings } from './hooks/useFetchPlannings';
import { useTrackElement } from '../../../../../common/components/PageRelated/Body/components/Table/hooks/useTrackElement';
import { useHideDisciplineColumn } from '../../../../../common/components/PageRelated/Body/components/Table/hooks/useHideDisciplineColumn';
import { getPlaceholderContent } from './placeholders';
import { StyledWrapper, StyledScrollableArea } from './styles';
export const Table = () => {
    const { getContent, setContent, selectedGrade, selectedSegment, selectedDisciplines } = useWeeklyPlanningPageContext((store) => ({
        stages: store.commonData.stages,
        setContent: store.mainTable.setContent,
        getContent: store.mainTable.getFlatPlannings,
        selectedGrade: store.curriculumComponents.selectedGrade,
        selectedSegment: store.curriculumComponents.selectedSegment,
        selectedDisciplines: store.curriculumComponents.selectedDisciplines
    }));
    const content = getContent();
    const table = useTrackElement();
    const fetch = useFetchPlannings({
        grade: selectedGrade,
        segment: selectedSegment,
        disciplines: selectedDisciplines
    });
    const tableInstance = useTable({
        columns,
        data: content,
        initialState: { hiddenColumns: ['disciplineName'] },
        getRowId: (planning) => planning.id
    }, useBlockLayout, useSticky);
    useHideDisciplineColumn({
        tableInstance,
        selectedDisciplines,
        columnId: 'disciplineName'
    });
    const dragAndDropRelated = useDragAndDrop();
    useEffect(() => {
        setContent(fetch.data);
    }, [fetch.data, setContent]);
    return content.length > 0 ? (React.createElement(WeeklyPlanningPageMainTableContext.Provider, { value: { columns, tableInstance } },
        React.createElement(DragDropContext, { onDragEnd: dragAndDropRelated.onDragEnd },
            React.createElement(StyledWrapper, { ...tableInstance.getTableProps() },
                React.createElement(StyledScrollableArea, { ref: table.ref },
                    React.createElement(TableHeader, { tableElement: table.element }),
                    React.createElement(TableBody, null)))))) : (React.createElement(TablePlaceholder, { content: getPlaceholderContent({
            selectedGrade,
            selectedSegment,
            selectedDisciplines,
            requestStatus: fetch.requestStatus
        }) }));
};
