import styled from 'styled-components';
import { LetterIconButton } from '../../../../../LetterIconButton';
import { hovering } from '../../../../../../../commonStyles';
export const Container = styled.div `
  position: relative;

  > :last-child {
    opacity: 0;

    z-index: -1;

    transition: opacity ${hovering.transitionMs};
  }

  &:hover > :last-child {
    opacity: 1;

    z-index: 5;
  }
`;
export const Button = styled(LetterIconButton) `
  display: block;
`;
