import styled from 'styled-components';
import { StyledColumnWrapper } from '../common/styles';
export const StyledWrapper = styled(StyledColumnWrapper) `
  padding: 12px 16px 0 16px;

  border-radius: 4px 0 0 4px;

  background-color: ${(props) => props.$backgroundColor ? props.$backgroundColor : 'white'};
`;
export const StyledDisciplineName = styled.p `
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  line-height: 18px;

  color: ${(props) => (props.$color ? props.$color : 'black')};
`;
