import { Response } from '@campus/commons';
import { Api } from '@campus/auth';
import UserPermissions from '../models/UserPermissions';
import { handleApiCallError } from './common';
const baseEndpointPath = 'userpermissionsinpedagogicalplanning';
const endpoints = {
    get: baseEndpointPath
};
export async function getUserPermissions(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.get, {
            signal: params?.abortSignal,
            params: {
                teacherId: params.teacherId,
                managerId: params.managerId,
                gradeId: params.gradeId,
                loggedAs: params.loggedAs,
                disciplineIds: params.disciplineIds.join(',')
            }
        });
        const data = UserPermissions.fromJson(response.data);
        return Response.fromData(data, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
