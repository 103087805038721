import styled from 'styled-components';
export const StyledBorder = styled.div `
  position: absolute;
  right: 0;
  top: ${(props) => props.$top}px;
  bottom: ${(props) => props.$bottom}px;

  ${(props) => {
    const red = props.theme.colors.error;
    const gray = props.theme.colors.gray200;
    const border = `
      ${props.$isDashed ? '2px' : '1px'}
      ${props.$isDashed ? 'dashed' : 'solid'}
      ${props.$isRed ? red : gray}
    `;
    return `border-right: ${border};`;
}};
`;
