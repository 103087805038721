import React from 'react';
import Selector from './components/Selector';
import { StyledTitle } from '../common/styles';
import { StyledWrapper } from './styles';
const ActivityType = () => {
    return (React.createElement(StyledWrapper, null,
        React.createElement(StyledTitle, null, "Tipo da Atividade"),
        React.createElement(Selector, null)));
};
export default ActivityType;
