import { useState } from 'react';
import { toast } from '@campus/components';
import { useAnnualTimelineReport, useAnnualTimelineDocxReport } from '../../../../../../../../../../../../../pages/common/components/Reports/hooks/annualTimeline';
import { useStudyUnitReport } from '../../../../../../../../../../../../../pages/common/components/Reports/hooks/studyUnit';
import { useClassDiaryReport } from '../../../../../../../../../../../../../pages/common/components/Reports/hooks/classDiary';
import { getDataToDiary } from '../../../../../../../../../../../../../services/pedagogicalPlanning';
export function useHandlers(props) {
    const [stageBeingUsed, setStageBeingUsed] = useState();
    const [reportBeingGenerated, setReportBeingGenerated] = useState();
    const annualTimeline = useAnnualTimelineReport({
        skills: props.skills,
        selectedGrade: props.selectedGrade,
        selectedSegment: props.selectedSegment,
        selectedDisciplines: props.selectedDisciplines
    });
    const annualTimelineDocx = useAnnualTimelineDocxReport({
        skills: props.skills,
        selectedGrade: props.selectedGrade,
        selectedSegment: props.selectedSegment,
        selectedDisciplines: props.selectedDisciplines
    });
    const studyUnit = useStudyUnitReport({
        skills: props.skills,
        selectedGrade: props.selectedGrade,
        selectedSegment: props.selectedSegment,
        selectedDisciplines: props.selectedDisciplines,
        type: 'pdf'
    });
    const classDiary = useClassDiaryReport({
        skills: props.skills,
        selectedGrade: props.selectedGrade,
        selectedSegment: props.selectedSegment,
        selectedDisciplines: props.selectedDisciplines,
        type: 'pdf'
    });
    const studyUnitDocx = useStudyUnitReport({
        skills: props.skills,
        selectedGrade: props.selectedGrade,
        selectedSegment: props.selectedSegment,
        selectedDisciplines: props.selectedDisciplines,
        type: 'docx'
    });
    function resetReportAndStage() {
        setStageBeingUsed(null);
        setReportBeingGenerated(null);
    }
    async function generateAnnualTimelineReport() {
        if (!!reportBeingGenerated)
            return;
        setReportBeingGenerated('annualTimeline');
        annualTimeline.download().finally(resetReportAndStage);
    }
    async function generateAnnualTimelineDocxReport() {
        if (!!reportBeingGenerated)
            return;
        setReportBeingGenerated('annualTimelineDocx');
        annualTimelineDocx.download().finally(resetReportAndStage);
    }
    async function generateStudyUnitReport(version, stage) {
        if (!!reportBeingGenerated)
            return;
        const shortVersion = version === 'short';
        const report = shortVersion ? 'shortStudyUnit' : 'completeStudyUnit';
        setStageBeingUsed(stage);
        setReportBeingGenerated(report);
        studyUnit.download({ shortVersion, stage }).finally(resetReportAndStage);
    }
    async function generateStudyUnitReportDocx(version, stage) {
        if (!!reportBeingGenerated)
            return;
        const shortVersion = version === 'short';
        const report = shortVersion ? 'shortStudyUnit' : 'completeStudyUnit';
        setStageBeingUsed(stage);
        setReportBeingGenerated(report);
        studyUnitDocx
            .download({ shortVersion, stage })
            .finally(resetReportAndStage);
    }
    async function generateClassDiaryReport(content, disciplineId, selectedClass, gradeId, segmentId) {
        if (reportBeingGenerated)
            return;
        if (!segmentId) {
            toast.error('Selecione um segmento para baixar seu diário de classe');
            return;
        }
        if (!gradeId) {
            toast.error('Selecione uma série para baixar seu diário de classe');
            return;
        }
        if (!disciplineId) {
            toast.error('Selecione uma disciplina para baixar seu diário de classe');
            return;
        }
        if (!selectedClass?.id) {
            toast.error('Selecione uma turma para baixar seu diário de classe');
            return;
        }
        setReportBeingGenerated('classDiary');
        const result = await getDataToDiary({
            segmentId: segmentId,
            gradeId: gradeId,
            disciplineId: disciplineId,
            classId: selectedClass?.id
        });
        classDiary
            .download(content, result.data, selectedClass)
            .finally(resetReportAndStage);
    }
    return {
        generateAnnualTimelineReport,
        generateAnnualTimelineDocxReport,
        generateStudyUnitReport,
        generateStudyUnitReportDocx,
        generateClassDiaryReport,
        stageBeingUsed,
        reportBeingGenerated
    };
}
