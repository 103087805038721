import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const StyledArrowDownIconWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledArrowDownIcon = styled(CampusIcon).attrs({
    name: 'selectArrowDown'
}) `
  & svg {
    width: 20px;
    height: 20px;
  }

  & path {
    stroke-width: 0;
    stroke: ${(props) => props.$color ? props.$color : props.theme.colors.gray700};
  }
`;
