import styled from 'styled-components';
export const StyledComponentName = styled.span `
  font-size: 20px;
  font-weight: 700;
  font-style: normal;

  white-space: nowrap;

  vertical-align: middle;
`;
export const StyledNoComponentSelectedMessage = styled.span `
  font-style: italic;

  overflow-x: hidden;
  text-overflow: ellipsis;

  vertical-align: middle;

  color: ${(props) => props.theme.colors.gray500};
`;
