export function joinNumbers(numbers) {
    if (!numbers || numbers.length === 0)
        return '';
    let result = numbers[0].toString();
    if (numbers.length === 1)
        return result;
    for (let i = 1; i < numbers.length - 1; ++i) {
        result += ', ' + numbers[i];
    }
    return result + ' e ' + numbers[numbers.length - 1];
}
