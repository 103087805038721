export function combineRefs(...refs) {
    return (element) => {
        for (const ref of refs) {
            if (!ref)
                continue;
            if (typeof ref === 'function') {
                ref(element);
            }
            else {
                const asMutableRef = ref;
                asMutableRef.current = element;
            }
        }
    };
}
