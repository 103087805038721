import styled from 'styled-components';
import { zIndices } from '../stylesRelated';
export const StyledWrapper = styled.div `
  display: flex;
  flex-direction: column;

  z-index: ${zIndices.calendarHeader};

  position: sticky;
  top: 0;

  overflow-x: hidden;

  width: min(28dvw, 600px);

  @media (min-width: 1470px) {
    width: 650px;
  }
`;
