import React from 'react';
import styled from 'styled-components';
import { RequestStatus } from '../../../../../../../../types/dataFetching';
const StyledMessage = styled.p `
  margin: 0;

  font-size: 12px;
  font-style: italic;

  color: ${(props) => props.$isError ? props.theme.colors.error : props.theme.colors.blue1};
`;
const statusAndMessages = {
    [RequestStatus.Loading]: 'Atualizando...',
    [RequestStatus.Failed]: 'Falha ao atualizar'
};
export function renderMessage(status) {
    const message = statusAndMessages[status];
    const failed = status === RequestStatus.Failed;
    return message ? (React.createElement(StyledMessage, { "$isError": failed }, message)) : null;
}
