import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const StyledWrapper = styled.div `
  .react-date-picker__wrapper {
    height: 32px;

    padding-inline: 12px;

    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.colors.gray200};
  }

  .react-date-picker__inputGroup__input,
  .react-date-picker__inputGroup__divider,
  .react-date-picker__inputGroup__leadingZero,
  .react-calendar {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-family: Gilroy;
  }

  .react-date-picker__inputGroup__input,
  .react-date-picker__inputGroup__divider,
  .react-date-picker__inputGroup__leadingZero {
    color: ${(props) => props.theme.colors.blackAlpha500};
  }

  .react-date-picker__clear-button:focus,
  .react-date-picker__calendar-button:focus,
  .react-date-picker__inputGroup__input:focus {
    outline: none;
  }

  .react-date-picker__inputGroup__input:invalid {
    background: none;
    color: ${(props) => props.theme.colors.error};
  }

  .react-date-picker__inputGroup__year {
    width: 40px !important;
  }

  .react-date-picker__clear-button,
  .react-date-picker__calendar-button {
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-date-picker__clear-button,
  .react-date-picker__calendar-button {
    margin-left: 2px;
  }

  .react-date-picker__calendar--open {
    position: absolute !important;
    top: calc(100% + 5px) !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  .react-calendar {
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.colors.gray200};
  }

  .react-calendar__navigation {
    height: 35px;
    margin-bottom: 0;
  }

  .react-calendar__navigation button,
  .react-calendar__tile {
    border-radius: 6px;
  }

  .react-calendar__navigation button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-calendar__navigation__arrow {
    font-size: 18px;
  }

  .react-calendar__tile {
    padding: 9px 5px;
  }
`;
export const StyledArrowIcon = styled(CampusIcon).attrs({
    name: 'selectArrowDown'
}) `
  stroke-width: 0;
  color: ${(props) => props.theme.colors.blackAlpha500};

  &:hover {
    color: ${(props) => props.theme.colors.blue1};
  }
`;
export const StyledCloseIcon = styled(CampusIcon).attrs({
    name: 'close'
}) `
  stroke-width: 0;

  &,
  & path {
    fill-opacity: 1;
    fill: ${(props) => props.theme.colors.blackAlpha500};
  }

  &:hover,
  &:hover path {
    fill-opacity: 1;
    fill: ${(props) => props.theme.colors.blue1};
  }
`;
