import { useCallback, useEffect, useState } from 'react';
import { useAddSkillPopupContext } from '../../../../../../../../context';
import { getDistinct } from '../../../../../../../../../../../utils/arrays';
import { createOptionsFrom } from '../../../../../../common/helpers';
const defaultOption = {
    label: 'Todas as disciplinas',
    value: null
};
export function useOptionsManager(props) {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const { filteredGradeId, setFilteredDisciplineId } = useAddSkillPopupContext((store) => ({
        filteredGradeId: store.filters.filteredGradeId,
        setFilteredDisciplineId: store.filters.setFilteredDisciplineId
    }));
    const createOptions = useCallback((disciplines) => {
        if (!disciplines)
            return [];
        if (disciplines.length === 0)
            return [];
        const distinct = getDistinct(disciplines, (d) => d.id);
        return createOptionsFrom({
            defaultOption,
            items: distinct,
            getItemValue: (d) => d.id,
            getItemLabel: (d) => d.name.toUpperCase()
        });
    }, []);
    useEffect(() => {
        let disciplines = props.disciplines;
        if (!!filteredGradeId) {
            disciplines = disciplines.filter((d) => {
                return d.gradeId === filteredGradeId;
            });
        }
        const options = createOptions(disciplines);
        setOptions(options);
        if (options.every((option) => option.value !== selectedOption.value)) {
            setSelectedOption(defaultOption);
        }
    }, [props.disciplines, filteredGradeId]);
    function onOptionChange(option) {
        setSelectedOption(option);
        setFilteredDisciplineId(option.value);
    }
    return { options, selectedOption, onOptionChange };
}
