import React from 'react';
import { useTheme } from 'styled-components';
import { useFollowUpPageContext } from '../../../../../../../../../../../../../context';
import { useGroupContext } from '../../../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import FinishActivityPopup from '../../../../../../../../../../../../../../common/components/Popups/FinishActivityPopup';
import { usePortal } from '../../../../../../../../../../../../../../common/contexts/portals';
import { times } from '../../../../../../../../../../../../../../common/constants';
import { getText, shouldBorderBeDashed, shouldBoxBeDisabled } from './helpers';
import { getActivityState } from '../../helpers';
import { getClassBoxBorderColor, getClassBoxIconAndTextColor } from '../../colors';
import { StyledCheckIcon, StyledIconWrapper, StyledPenIcon, StyledText, StyledWrapper } from './styles';
function renderIcon(state, color) {
    if (state.deleted)
        return null;
    return (React.createElement(StyledIconWrapper, null, state.wasExecuted ? (React.createElement(StyledCheckIcon, { "$color": color })) : (React.createElement(StyledPenIcon, { "$color": color }))));
}
const ClassBox = React.forwardRef((props, ref) => {
    const theme = useTheme();
    const { create, destroy } = usePortal(document.body);
    const { availableClassrooms, userPermissions } = useFollowUpPageContext((store) => ({
        availableClassrooms: store.commonData.availableClassrooms.data,
        userPermissions: store.commonData.userPermissions.data
    }));
    const { updateActivity } = useGroupContext((store) => ({
        updateActivity: store.sequenceOperations.update
    }));
    const text = getText(props.sequence);
    const state = getActivityState(props.sequence);
    const iconAndTextColor = getClassBoxIconAndTextColor(state, theme);
    const disabled = shouldBoxBeDisabled(props.sequence, props.planning, userPermissions);
    function handleActivityFinished(updatedSequence) {
        updateActivity(updatedSequence);
        destroy({ afterMillis: times.toHideAModalOrPopupOnSuccess });
    }
    function onClick() {
        if (disabled)
            return;
        create(React.createElement(FinishActivityPopup, { canBeDisplayed: true, sequence: props.sequence, planning: props.planning, classroomGroups: availableClassrooms, handleExternalClick: destroy, handleCancelButtonClicked: destroy, handleActivityFinished: handleActivityFinished }));
    }
    return (React.createElement(StyledWrapper, { ref: ref, "$disabled": disabled, "$borderColor": getClassBoxBorderColor(state, theme), "$useDashedBorder": shouldBorderBeDashed(props.sequence), onClick: onClick },
        renderIcon(state, iconAndTextColor),
        React.createElement(StyledText, { "$color": iconAndTextColor }, text)));
});
export default ClassBox;
