import { errorHappened, loadingData, notAllCurriculumComponentsHaveBeenSelected } from '../../../../../common/components/PageRelated/Body/components/Table/components/TablePlaceholder';
import { RequestStatus } from '../../../../../common/types/dataFetching';
const noContentPlaceholder = {
    title: 'Nenhuma habilidade a ser exibida',
    description: 'Nenhuma habilidade foi encontrada para os ' +
        'componentes curriculares selecionados'
};
export function getPlaceholderContent(params) {
    const componentsPlaceholderAlias = notAllCurriculumComponentsHaveBeenSelected;
    if (!params.selectedGrade)
        return componentsPlaceholderAlias;
    if (!params.selectedSegment)
        return componentsPlaceholderAlias;
    if (!params.selectedDisciplines)
        return componentsPlaceholderAlias;
    if (params.selectedDisciplines.length === 0)
        return componentsPlaceholderAlias;
    if (params.requestStatus === RequestStatus.Loading)
        return loadingData;
    if (params.requestStatus === RequestStatus.Failed)
        return errorHappened;
    return noContentPlaceholder;
}
