import styled from 'styled-components';
export const Container = styled.section `
  margin-top: 20px;

  display: flex;
  row-gap: 5px;
  column-gap: 11px;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`;
export const Group = styled.div `
  display: flex;
  column-gap: 11px;
  flex-wrap: wrap;
  align-items: flex-start;
`;
