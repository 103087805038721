import React, { useMemo } from 'react';
import { FollowUpMainTableContext } from '.';
import { createFollowUpMainTableStore } from '../store';
const Provider = (props) => {
    const store = useMemo(() => {
        return createFollowUpMainTableStore();
    }, []);
    return (React.createElement(FollowUpMainTableContext.Provider, { value: store }, props.children));
};
export default Provider;
