import React from 'react';

import { Controller } from 'react-hook-form';
import { Button, Textarea } from '@campus/components';

import useCorrection from '../hooks/useCorrection';
import {
  TextareaContainer,
  Container,
  ContainerFlex,
  ScoreInput,
  Title,
  ScoreContainer,
  CommonAnswerContainer,
  Divider,
  CommonAnswerHeader,
  CommonAnswerContent,
  CommonAnswerCard
} from '../styles';
import { useShowCorrection } from '../context/ShowCorrectionContext';
import { useActivityReport } from 'pages/ActivityReport/context/ActivityReportContext';
import StudentAnswerList, { FrequentAnswer } from 'models/StudentsList';

const CorrectionForm: React.FC = () => {
  const { control, onSubmit, weight, loading, setValue } = useCorrection();
  const { isEditing, onEdit } = useShowCorrection();
  const { students } = useActivityReport();

  const getFrequentAnswers = React.useMemo(
    () => new StudentAnswerList(students).getFrequentAnswers().splice(0, 3),
    [students]
  );

  const handleFrequentAnswerClick = (answer: FrequentAnswer) => {
    setValue('correction', answer.comment);
    setValue('score', answer.score.toString());
  };

  return (
    <form onSubmit={onSubmit} noValidate>
      <Container>
        <Title>Correção</Title>
        {isEditing && (
          <Button variant="outline-secondary" onClick={onEdit}>
            Cancelar
          </Button>
        )}
      </Container>
      <TextareaContainer>
        <Controller
          control={control}
          name="correction"
          render={({ field, fieldState: { error } }) => (
            <Textarea
              {...field}
              placeholder="Clique aqui para adicionar um comentário que aparecerá para o aluno após a sua correção"
              isError={!!error}
              errorMessage={error?.message}
            />
          )}
        />
      </TextareaContainer>
      <ContainerFlex>
        <ScoreContainer>
          <p>Nota:</p>
          <Controller
            control={control}
            name="score"
            rules={{
              required: 'Campo obrigatório',
              max: {
                value: weight,
                message: 'Nota não pode ser maior que a nota máxima'
              }
            }}
            render={({ field, fieldState: { error } }) => (
              <ScoreInput
                {...field}
                required
                type="number"
                isError={!!error}
                errorMessage={error?.message}
              />
            )}
          />
          <span>{`/ ${weight} ponto`}</span>
        </ScoreContainer>
        <Button
          type="submit"
          disabled={loading}
          style={{ maxHeight: 38, minWidth: 170 }}
        >
          {loading ? 'Carregando...' : 'Adicionar Correção'}
        </Button>
      </ContainerFlex>
      {getFrequentAnswers.length > 0 && (
        <>
          <Divider className="my-4" />
          <Container>
            <CommonAnswerCard>
              {getFrequentAnswers.map((answer, index) => {
                return (
                  <>
                    <CommonAnswerContainer
                      onClick={() => handleFrequentAnswerClick(answer)}
                    >
                      <CommonAnswerHeader $level={index}>
                        APLICAR {answer.score} PONTO{answer.score > 1 && 'S'} -
                        UTILIZADO {answer.count} VEZ{answer.count > 1 && 'ES'}
                      </CommonAnswerHeader>
                      <CommonAnswerContent>
                        {answer.comment}
                      </CommonAnswerContent>
                    </CommonAnswerContainer>
                    {getFrequentAnswers.length > index + 1 && <Divider />}
                  </>
                );
              })}
            </CommonAnswerCard>
          </Container>
        </>
      )}
    </form>
  );
};

export default CorrectionForm;
