import React from 'react';
import { View } from '@react-pdf/renderer';
import styles from './styles';
const Column = (props) => {
    const borderTopWidth = props.isInTheFirstRow ? '1px' : 0;
    const borderLeftWidth = props.isTheFirstColumn ? '1px' : 0;
    const borderTopLeftRadius = props.isTheFirstColumn && props.isInTheFirstRow ? 2 : 0;
    const borderTopRightRadius = props.isTheLastColumn && props.isInTheFirstRow ? 2 : 0;
    const borderBottomLeftRadius = props.isTheFirstColumn && props.isInTheLastRow ? 2 : 0;
    const borderBottomRightRadius = props.isTheLastColumn && props.isInTheLastRow ? 2 : 0;
    return (React.createElement(View, { key: props.instance.label, style: {
            ...styles.wrapper,
            width: props.instance.width,
            maxWidth: props.instance.width,
            borderTopWidth,
            borderLeftWidth,
            borderTopLeftRadius,
            borderTopRightRadius,
            borderBottomLeftRadius,
            borderBottomRightRadius
        } }, props.instance.content));
};
export default Column;
