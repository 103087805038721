export function createClassesAndInfos(params) {
    const classesAndInfos = new Map();
    if (!params.classroomGroups?.length)
        return classesAndInfos;
    const sortedGroups = params.classroomGroups.sort((a, b) => {
        return a.className?.localeCompare(b.className);
    });
    const sequenceClassrooms = params.existingSequence?.classrooms;
    for (const classroomGroup of sortedGroups) {
        const { className, classrooms } = classroomGroup;
        const found = sequenceClassrooms?.find((c) => {
            return c.classroom?.class?.name === className;
        });
        classesAndInfos.set(className, {
            allClassrooms: classrooms,
            associatedClassName: className,
            selectedClassroom: found?.classroom ?? classrooms.at(0),
            isChecked: !params.existingSequence ? true : !!found ? true : false
        });
    }
    return classesAndInfos;
}
