import { useEffect } from 'react';
export const useHideDisciplineColumn = (props) => {
    const { tableInstance, selectedDisciplines, columnId } = props;
    useEffect(() => {
        if (selectedDisciplines.length > 1) {
            tableInstance.setHiddenColumns([]);
        }
        else {
            tableInstance.setHiddenColumns([columnId]);
        }
    }, [columnId, selectedDisciplines, tableInstance]);
};
