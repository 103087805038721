import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const StyledWrapper = styled.div `
  margin-left: auto;

  height: 24px;
  min-width: 66px;

  padding: 0 7px 0 8px;

  display: flex;
  column-gap: 4px;
  align-items: center;
  justify-content: space-evenly;

  border-width: 1px;
  border-radius: 4px;
  border-style: ${(props) => (props.$useDashedBorder ? 'dashed' : 'solid')};
  border-color: ${(props) => props.$borderColor ? props.$borderColor : props.theme.colors.gray200};

  ${(props) => (props.$disabled ? '' : 'cursor: pointer;')}
`;
export const StyledIconWrapper = styled.div `
  & div {
    display: block;
  }
`;
const StyledBaseIcon = styled(CampusIcon) `
  display: block;

  color: ${(props) => props.$color ? props.$color : props.theme.colors.gray500};
`;
export const StyledCheckIcon = styled(StyledBaseIcon).attrs({
    name: 'check'
}) `
  width: 14px;
  height: auto;

  & path {
    stroke-width: 3px;
  }
`;
export const StyledPenIcon = styled(StyledBaseIcon).attrs({
    name: 'pen'
}) `
  width: 13px;
  height: auto;
`;
export const StyledText = styled.span `
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;

  color: ${(props) => props.$color ? props.$color : props.theme.colors.gray500};
`;
