import React from 'react';
import { dateFormatter } from '../../../../../../utils/dates';
import { Checkbox, CopyAlertMessageButton, SendEmailButton } from './components';
import { Actions, Column, Content, NameAndLastUpdate, Photo } from './styles';
export const TeacherInformation = ({ teacher }) => {
    const lastUpdateDate = teacher.lastUpdateDate
        ? dateFormatter.format(teacher.lastUpdateDate)
        : null;
    return (React.createElement(Column, { rowSpan: teacher.totalNumberOfClasses },
        React.createElement(Content, null,
            React.createElement(Checkbox, { teacherId: teacher.id }),
            React.createElement(Photo, { "$url": teacher.photoUrl }),
            React.createElement(NameAndLastUpdate, null,
                React.createElement("span", null, teacher.name),
                React.createElement("span", null, lastUpdateDate
                    ? `Última atualização: ${lastUpdateDate}`
                    : 'Nenhuma atualização até o momento')),
            React.createElement(Actions, null,
                React.createElement(SendEmailButton, { teacherId: teacher.id }),
                React.createElement(CopyAlertMessageButton, { teacherId: teacher.id })))));
};
