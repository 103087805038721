import React from 'react';
import { useMovePlanning } from '../../hooks/useMovePlanning';
import { useDuplicatePlanning } from '../../hooks/useDuplicatePlanning';
import { useControlAddSkillPopup } from '../../hooks/useControlAddSkillPopup';
import { useControlDeletionModal } from '../../hooks/useControlDeletionModal';
import { useSeparatePlanningFromGroup } from '../../hooks/useSeparatePlanningFromGroup';
import { useDisable } from './hooks/useDisable';
import { StyledPopup } from './styles';
const Popup = (props) => {
    const { duplicatePlanning } = useDuplicatePlanning();
    const { separatePlanning } = useSeparatePlanningFromGroup();
    const { movePlanningDown, movePlanningUp } = useMovePlanning();
    const { createPopup } = useControlAddSkillPopup();
    const { createModal } = useControlDeletionModal();
    const disable = useDisable({ planning: props.planning });
    function onDuplicateClick() {
        return new Promise((resolve, reject) => {
            if (disable.duplicate) {
                reject();
                return;
            }
            duplicatePlanning(props.planning, props.planningIndex)
                .then(() => {
                props.closePopup();
                resolve();
            })
                .catch(reject);
        });
    }
    function onMoveUpClick() {
        return new Promise((resolve, reject) => {
            if (disable.move) {
                reject();
                return;
            }
            movePlanningUp(props.planning)
                .then(() => {
                props.closePopup();
                resolve();
            })
                .catch(reject);
        });
    }
    function onMoveDownClick() {
        return new Promise((resolve, reject) => {
            if (disable.move) {
                reject();
                return;
            }
            movePlanningDown(props.planning)
                .then(() => {
                props.closePopup();
                resolve();
            })
                .catch(reject);
        });
    }
    function onSeparateSkillFromBlockClick() {
        return new Promise((resolve, reject) => {
            if (disable.separate) {
                reject();
                return;
            }
            separatePlanning(props.planning)
                .then(() => {
                props.closePopup();
                resolve();
            })
                .catch(reject);
        });
    }
    function onAddSkillToBlockClick() {
        return new Promise((resolve, reject) => {
            if (disable.addToBlock) {
                reject();
                return;
            }
            createPopup(props.planning);
            props.closePopup();
            resolve();
        });
    }
    function onRemoveClick() {
        return new Promise((resolve, reject) => {
            if (disable.remove) {
                reject();
                return;
            }
            createModal(props.planning);
            props.closePopup();
            resolve();
        });
    }
    const items = [
        {
            label: 'Remover',
            onClick: onRemoveClick,
            disabled: disable.remove
        },
        {
            label: 'Duplicar',
            onClick: onDuplicateClick,
            disabled: disable.duplicate
        },
        {
            label: 'Mover para cima',
            onClick: onMoveUpClick,
            disabled: disable.move
        },
        {
            label: 'Mover para baixo',
            onClick: onMoveDownClick,
            disabled: disable.move
        },
        {
            label: 'Adicionar habilidade ao bloco',
            onClick: onAddSkillToBlockClick,
            disabled: disable.addToBlock
        },
        {
            label: 'Separar habilidade do bloco',
            onClick: onSeparateSkillFromBlockClick,
            disabled: disable.separate
        }
    ];
    return (React.createElement(StyledPopup, { items: items, canBeDisplayed: props.canBeDisplayed, handleExternalClick: props.closePopup }));
};
export default Popup;
