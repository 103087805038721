import Stage from './Stage';
import AreaSkill from './AreaSkill';
import Discipline from './Discipline';
import DidaticSequence from './DidaticSequence';
import PedagogicalPlanningStatus from './PedagogicalPlanningStatus';
import PedagogicalPlanningObject from './PedagogicalPlanningObject';
import PedagogicalPlanningObjective from './PedagogicalPlanningObjective';
export default class PedagogicalPlanning {
    id;
    order;
    observedLags;
    teacherNotes;
    analystNotes;
    supervisorNotes;
    associatedGroup;
    gradeId;
    segmentId;
    teacherId;
    stage;
    areaSkill;
    discipline;
    status;
    didaticSequences;
    objects;
    objectives;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new PedagogicalPlanning();
        instance.id = json.Id;
        instance.order = json.Order;
        instance.associatedGroup = json.Group;
        instance.observedLags = json.ObservedLags;
        instance.teacherNotes = json.TeacherObservation;
        instance.analystNotes = json.AnalystObservation;
        instance.supervisorNotes = json.SupervisorObservation;
        instance.gradeId = json.GradeId;
        instance.segmentId = json.SegmentId;
        instance.teacherId = json.TeacherId;
        instance.stage = Stage.fromJson(json.Semester);
        instance.areaSkill = AreaSkill.fromJson(json.AreaSkill);
        instance.discipline = Discipline.fromJson(json.Discipline);
        instance.status = PedagogicalPlanningStatus.fromJson(json.PedagogicalPlanningStatus);
        instance.didaticSequences =
            json.DidacticSequences?.map((item) => DidaticSequence.fromJson(item)) ?? [];
        instance.objects =
            json.PedagogicalPlanningObjects?.map((item) => PedagogicalPlanningObject.fromJson(item)) ?? [];
        instance.objectives =
            json.PedagogicalPlanningObjectives?.map((item) => PedagogicalPlanningObjective.fromJson(item)) ?? [];
        return instance;
    }
}
