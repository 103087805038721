import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const StyledWrapper = styled.div `
  & div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const StyledIcon = styled(CampusIcon).attrs({
    name: 'miniArrowRight'
}) `
  width: auto;
  height: 15px;

  stroke: ${(props) => props.theme.colors.gray300};

  ${(props) => (props.$pointingToBottom ? 'rotate: 90deg;' : '')}

  & path {
    stroke-width: 1px;
  }
`;
