import { Response } from '@campus/commons';
import { handleApiCallError } from '../common';
import { updatePlanning, deletePlanning as deletePlanningPhysically, } from '../pedagogicalPlanning';
import { deletePlanningObject } from '../pedagogicalPlanningObject';
import { deletePlanningObjective } from '../pedagogicalPlanningObjective';
import { deleteDidaticSequenceAndItsClassrooms } from './deleteDidacticSequenceAndItsClassrooms';
async function deletePlanningByUpdatingItsStatus(params) {
    const response = await updatePlanning({
        id: params.planning.id,
        order: params.planning.order,
        abortSignal: params.abortSignal,
        statusId: params.deletedStatusId,
        stageId: params.planning.stage?.id,
        group: params.associatedGroup.number,
        teacherNotes: params.planning.teacherNotes,
        observedLags: params.planning.observedLags,
        analystNotes: params.planning.analystNotes,
        supervisorNotes: params.planning.supervisorNotes
    });
    return Response.fromData({ wasReallyDeleted: false }, response.statusCode);
}
function deletePlanningObjectives(params) {
    return params.planning.objectives?.map((objective) => {
        return deletePlanningObjective({
            objectiveId: objective.id,
            abortSignal: params.abortSignal
        });
    });
}
function deletePlanningObjects(params) {
    return params.planning.objects?.map((object) => {
        return deletePlanningObject({
            objectId: object.id,
            abortSignal: params.abortSignal
        });
    });
}
function deletePlanningDidacticSequences(params) {
    const isTheOnlyPlanningOnGroup = params.associatedGroup.plannings.length === 1 &&
        params.associatedGroup.plannings[0].id === params.planning.id;
    if (!isTheOnlyPlanningOnGroup)
        return [];
    return params.associatedGroup.didacticSequence.map((s) => {
        return deleteDidaticSequenceAndItsClassrooms({
            sequence: s,
            planning: params.planning,
            abortSignal: params.abortSignal
        });
    });
}
async function deletePlanningAndRelatedThings(params) {
    try {
        await Promise.all([
            ...deletePlanningObjects(params),
            ...deletePlanningObjectives(params),
            ...deletePlanningDidacticSequences(params)
        ]);
        const response = await deletePlanningPhysically({
            planning: params.planning,
            abortSignal: params.abortSignal
        });
        return Response.fromData({ wasReallyDeleted: true }, response.statusCode);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function deletePlanningAndAssociatedThings(params) {
    try {
        if (params.planning?.status?.isBeingPlanned()) {
            return deletePlanningAndRelatedThings(params);
        }
        return deletePlanningByUpdatingItsStatus(params);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
