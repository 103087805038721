import { StyleSheet } from '@react-pdf/renderer';
import { fontSizes, logo, margins, paddings } from '../../sizes';
const styles = StyleSheet.create({
    wrapper: {
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
    },
    titlesWrapper: {
        paddingRight: paddings.header.title.right,
        borderRight: '1px solid black'
    },
    title: {
        fontSize: fontSizes.header.title,
        fontWeight: 700,
        width: 225,
        lineHeight: '100%'
    },
    subTitle: {
        marginTop: margins.header.subTitle.top,
        display: 'flex',
        flexDirection: 'row'
    },
    subTitleRight: {
        marginLeft: 15
    },
    curriculumComponentWrapper: {
        columnGap: 3,
        display: 'flex',
        flexDirection: 'row'
    },
    curriculumComponentTitle: {
        fontSize: fontSizes.header.information,
        fontWeight: 800
    },
    curriculumComponentName: {
        fontSize: fontSizes.header.information,
        fontWeight: 500,
        overflowWrap: 'break-word',
        marginLeft: 2
    },
    infosWrapper: {
        marginLeft: margins.header.infos.left
    },
    infosEmphasizedText: {
        fontSize: fontSizes.header.information,
        fontWeight: 800
    },
    infosNormalText: {
        fontSize: fontSizes.header.information,
        fontWeight: 500,
        textAlign: 'left',
        overflowWrap: 'break-word',
        marginLeft: 2
    },
    teacherNameText: {
        maxWidth: 170
    },
    curriculumText: {
        maxWidth: 170,
        overflowWrap: 'break-word',
        textAlign: 'left'
    },
    logoWrapper: {
        marginLeft: 'auto'
    },
    logo: {
        width: logo.width,
        height: logo.height,
        padding: 0,
        marginRight: 2,
        marginLeft: 2
    }
});
export default styles;
