import React from 'react';
import ListItem from './components/ListItem';
import { StyledPopupBox } from './styles';
const PopupWithList = (props) => {
    function renderItems() {
        return props.items.map((item) => (React.createElement(ListItem, { key: item.label, label: item.label, onClick: item.onClick, disabled: item.disabled })));
    }
    return React.createElement(StyledPopupBox, { ...props }, renderItems());
};
export default PopupWithList;
