import styled from 'styled-components';
import SelectCurriculumComponentsPopup from '../../../../../../Popups/SelectCurriculumComponentsPopup';
export const StyledPopup = styled(SelectCurriculumComponentsPopup) `
  position: absolute;
  top: 98%;
  left: 50%;
  transform: translateX(-50%);

  width: 95%;
  max-height: 350px;
`;
export const StyledContentWrapper = styled.div `
  display: flex;
  flex-wrap: wrap;
  column-gap: 9px;
`;
