import { updatePlanning } from '../../../../../../../../../services/pedagogicalPlanning';
export function updateTeacherNotes(planning, notes) {
    return updatePlanning({
        teacherNotes: notes,
        id: planning.id,
        order: planning.order,
        stageId: planning.stage?.id,
        statusId: planning.status?.id,
        group: planning.associatedGroup,
        observedLags: planning.observedLags,
        analystNotes: planning.analystNotes,
        supervisorNotes: planning.supervisorNotes
    });
}
