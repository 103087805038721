import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import Stage from '../models/Stage';
import { handleApiCallError } from './common';
const baseEndpointsPath = '/planningstage';
const endpoints = {
    get: {
        all: baseEndpointsPath,
        thoseThatHaveSkillsRelated: baseEndpointsPath + '/thatHaveAreaSkill'
    }
};
export async function getAllStages(params = null) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.get.all, {
            signal: params?.abortSignal
        });
        const content = response.data.map((item) => Stage.fromJson(item));
        return Response.fromData(content, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function getStagesThatHaveAreaSkillsRelatedToIt(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.get.thoseThatHaveSkillsRelated, { signal: params?.abortSignal });
        const data = response.data?.map(Stage.fromJson);
        return Response.fromData(data, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
