class InsightAnswer {
  id?: string;

  correct?: boolean;

  count?: number;

  percentage?: number;

  optionText?: string;

  text?: string;

  range?: number;

  static fromJson(data: any): InsightAnswer {
    const question = new InsightAnswer();

    question.id = data?.Id;

    question.correct = data?.Correct;

    question.count = data?.Count;

    question.percentage = data?.Percentage;

    question.optionText = data?.OptionText;

    question.text = data?.Text;

    question.range = data?.Range;

    return question;
  }
}

export default InsightAnswer;
