import React, { useState } from 'react';
import AddActivityPopup from '../../../../../../../../Popups/AddActivityPopup';
import { times } from '../../../../../../../../../constants';
import { StyledButton } from './styles';
const AddActivityButton = (props) => {
    const [canShowPopup, setCanShowPopup] = useState(false);
    const hidePopup = () => setCanShowPopup(false);
    function handleActivityAdded(sequence) {
        props.handleActivityAdded(sequence);
        setTimeout(() => {
            setCanShowPopup(false);
        }, times.toHideAModalOrPopupOnSuccess);
    }
    function onClick() {
        if (!props.disabled) {
            setCanShowPopup(true);
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledButton, { onClick: onClick, disabled: props.disabled }, "Adicionar atividade"),
        React.createElement(AddActivityPopup, { canBeDisplayed: canShowPopup, planning: props.planning, disciplines: props.disciplines, classroomGroups: props.classroomGroups, newActivityIsUnforeseen: props.newActivityIsUnforeseen, handleExternalClick: hidePopup, handleCancelButtonClicked: hidePopup, handleActivityAdded: handleActivityAdded })));
};
export default AddActivityButton;
