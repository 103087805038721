import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { hslToRgb } from './colors';
import styles from './styles';
const Discipline = (props) => {
    const { discipline } = props;
    const [r, g, b] = hslToRgb(discipline.color);
    const backgroundColor = `rgba(${r}, ${g}, ${b}, 0.1)`;
    return (React.createElement(View, { style: { ...styles.wrapper, backgroundColor } },
        React.createElement(Text, { widows: 1, style: { ...styles.discipline, color: discipline?.color } }, discipline?.name)));
};
export default Discipline;
