import React, { useState } from 'react';
import { toast } from '@campus/components';
import { sendEmailToTeacher } from '../../../../../../../../../../services/launchReport';
import { useLaunchReportContext } from '../../../../../../../../context';
import { Tooltip } from '../../../../../Tooltip';
import { Button, Container } from './styles';
export const SendEmailButton = ({ teacherId }) => {
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const { managerId } = useLaunchReportContext();
    async function onClick() {
        if (isSendingEmail)
            return;
        try {
            setIsSendingEmail(true);
            const response = await sendEmailToTeacher({
                teacherId,
                managerId
            });
            if (response.error) {
                toast.error('Falha ao enviar e-mail');
            }
            else {
                toast.info('E-mail enviado!');
            }
        }
        catch {
            toast.error('Falha ao enviar e-mail');
        }
        finally {
            setIsSendingEmail(false);
        }
    }
    return (React.createElement(Container, null,
        React.createElement(Button, { onClick: onClick, disabled: isSendingEmail, "$isBusy": isSendingEmail }),
        React.createElement(Tooltip, null, isSendingEmail
            ? 'Enviando e-mail...'
            : 'Enviar alerta por e-mail')));
};
