import { useCallback, useEffect } from 'react';
import { useFetch } from '../../../../../../common/hooks/dataFetching/useFetch';
import { getAllPlanningsGrouped } from '../../../../../../../services/pedagogicalPlanning';
export function useFetchPlannings(props) {
    const { grade, segment, disciplines } = props;
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess, resetDataAndStatus } = useFetch({ initialData: [] });
    const allComponentsAreSelected = useCallback(() => {
        return !!grade && !!segment && disciplines?.length > 0;
    }, [grade, segment, disciplines]);
    const fetchPlannings = useCallback(async (abortSignal) => {
        fetchStarted();
        if (!allComponentsAreSelected()) {
            resetDataAndStatus();
            return;
        }
        getAllPlanningsGrouped({
            gradeId: grade?.id,
            segmentId: segment?.id,
            abortSignal: abortSignal,
            disciplineId: disciplines?.map((d) => d.id).join(',')
        })
            .then((response) => handleSuccess(response.data))
            .catch((response) => handleFailure(response.error.error));
    }, [
        allComponentsAreSelected,
        fetchStarted,
        handleFailure,
        handleSuccess,
        resetDataAndStatus,
        disciplines,
        grade?.id,
        segment?.id
    ]);
    useEffect(() => {
        const abortController = new AbortController();
        fetchPlannings(abortController.signal);
        return () => abortController.abort();
    }, [fetchPlannings]);
    return { data, requestStatus };
}
