import React, { useContext, useEffect, useMemo, useState } from 'react';
import { QuestionType } from '@campus/commons';

import { useActivityReport } from '../../../context/ActivityReportContext';
import { useQuestionReview } from '../../../context/QuestionReviewContext';

type ShowCorrectionProps = {
  isEditing: boolean;
  showCorrection: boolean;
  showCorrectionForm: boolean;
  onEdit: () => void;
  cancelEdit: () => void;
};

const ShowCorrectionContext = React.createContext<ShowCorrectionProps>(null);

export const useShowCorrection = () => useContext(ShowCorrectionContext);

export const ShowCorrectionProvider: React.FC = ({ children }) => {
  const { selectedStudent } = useActivityReport();
  const { question, answer } = useQuestionReview();

  const [editing, setEditing] = useState<string>(null);

  const cancelEdit = () => setEditing(null);

  const handleEdit = () => {
    const id = question.contentItemId;
    setEditing((p) => (p !== id ? id : null));
  };

  useEffect(() => {
    cancelEdit();
  }, [selectedStudent]);

  const showCorrection = useMemo(() => {
    const allowedCorrection = [QuestionType.OPEN, QuestionType.ATTACHMENT];
    return allowedCorrection.includes(question?.questionType);
  }, [question?.questionType]);

  const isEditing = useMemo(
    () => editing === question.contentItemId,
    [question.contentItemId, editing]
  );

  const showCorrectionForm = useMemo(
    () => isEditing || !answer?.teacherScoreTime || answer?.teacherScore === null,
    [isEditing, answer?.teacherScoreTime, answer?.teacherScore]
  );

  return (
    <ShowCorrectionContext.Provider
      value={{
        isEditing,
        cancelEdit,
        onEdit: handleEdit,
        showCorrection,
        showCorrectionForm
      }}
    >
      {children}
    </ShowCorrectionContext.Provider>
  );
};
