import styled from 'styled-components';
export const StyledInfoWrapper = styled.p `
  margin: 0;
  font-size: 12px;
  line-height: 120%;
`;
export const StyledInfoLabel = styled.span `
  color: #1a1a1a;
  font-weight: 700;
`;
export const StyledInfoContent = styled.span `
  font-weight: 600;
  color: ${(props) => props.$orange ? props.theme.colors.orange1 : props.theme.colors.gray700};
`;
export const StyledHorizontalSeparator = styled.hr `
  margin: 10px 0;
  width: 25px;
  height: 1px;
  background-color: ${(props) => props.theme.colors.gray200};
`;
