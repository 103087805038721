import styled from 'styled-components';
export const StyledWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const WeekdayAndDateCommon = styled.span `
  margin: 0;

  font-size: 12px;
  font-weight: 500;
  font-style: normal;
`;
export const StyledWeekday = styled(WeekdayAndDateCommon) `
  color: ${(props) => props.theme.colors.gray400};
`;
export const StyledDate = styled(WeekdayAndDateCommon) `
  color: ${(props) => props.theme.colors.gray600};
`;
