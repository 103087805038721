import { sortPlanningGroups } from '../../../../common/utils/plannings';
import { createFiltersSliceCreator } from './filters/slice';
import { createOperationsSliceCreator } from './operations/slice';
export function createMainTableSlice(events) {
    return (set, get, ...args) => ({
        filteredContent: [],
        nonFilteredContent: [],
        filters: createFiltersSliceCreator(events)(set, get, ...args),
        operations: createOperationsSliceCreator(events)(set, get, ...args),
        getContent() {
            return get().mainTable.filteredContent;
        },
        getFlatPlannings() {
            return get().mainTable.filteredContent.flatMap((group) => group.plannings);
        },
        getPlanningGroup(planning) {
            return get().mainTable.filteredContent.find((group) => group.number === planning.associatedGroup);
        },
        setContent(content) {
            set((store) => {
                const sorted = sortPlanningGroups(content);
                return {
                    mainTable: {
                        ...store.mainTable,
                        filteredContent: sorted,
                        nonFilteredContent: sorted
                    }
                };
            });
            events.contentSet.emit();
        }
    });
}
