import React, { useEffect, useRef, useState } from 'react';
import { useLaunchReportContext } from '../../../../context';
import { Selector } from './styles';
export const PageSelector = () => {
    const { events } = useLaunchReportContext();
    const [pageIndex, setPageIndex] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [isLoadingContent, setIsLoadingContent] = useState(false);
    const previousPageIndex = useRef(null);
    function onChange(newIndex) {
        if (newIndex === pageIndex)
            return;
        previousPageIndex.current = pageIndex;
        setPageIndex(newIndex);
        setIsLoadingContent(true);
        events.table.pageIndexChanged.emit({ newIndex });
    }
    useEffect(() => {
        return events.table.dataFetched.subscribe((payload) => {
            setIsLoadingContent(false);
            setNumberOfPages(payload?.numberOfPages ?? 1);
        });
    }, [events.table.dataFetched]);
    useEffect(() => {
        return events.table.dataFetchFailed.subscribe((payload) => {
            setIsLoadingContent(false);
            if (pageIndex === payload?.pageIndex) {
                setPageIndex(previousPageIndex.current);
            }
        });
    }, [events.table.dataFetchFailed, pageIndex]);
    useEffect(() => {
        function handler() {
            setPageIndex(0);
        }
        const unsubscribe1 = events.filters.gradeIdChanged.subscribe(handler);
        const unsubscribe2 = events.filters.disciplineIdsChanged.subscribe(handler);
        const unsubscribe3 = events.filters.classIdsChanged.subscribe(handler);
        const unsubscribe4 = events.filters.startDateChanged.subscribe(handler);
        const unsubscribe5 = events.filters.endDateChanged.subscribe(handler);
        return () => {
            unsubscribe1();
            unsubscribe2();
            unsubscribe3();
            unsubscribe4();
            unsubscribe5();
        };
    }, [
        events.filters.gradeIdChanged,
        events.filters.disciplineIdsChanged,
        events.filters.classIdsChanged,
        events.filters.startDateChanged,
        events.filters.endDateChanged
    ]);
    return numberOfPages <= 1 ? null : (React.createElement(Selector, { "$loading": isLoadingContent, pageIndex: pageIndex, setPageIndex: onChange, numberOfPages: numberOfPages, hidePreviousAndNextButtonsIfDisabled: true }));
};
