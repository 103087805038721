import styled from 'styled-components';
export const StyledButton = styled.button `
  all: unset;

  z-index: 1;

  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 18px;

  padding: 0 6px;

  cursor: pointer;

  font-size: 11px;
  font-weight: 700;

  background-color: white;
  color: ${(props) => props.theme.colors.gray300};

  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.colors.gray200};

  &:hover {
    color: ${(props) => props.theme.colors.gray400};
  }
`;
