import { useTheme } from 'styled-components';
import { RequestStatus } from '../../../../../../../../types/dataFetching';
import { joinNumbers } from '../../../../../../../../utils/numbers';
export const statusAndTexts = {
    [RequestStatus.Failed]: 'Falha ao atualizar',
    [RequestStatus.Loading]: 'Atualizando...'
};
export function getColorAndText(params) {
    const { selectedStages, requestStatus } = params;
    const theme = useTheme();
    const statusAndColors = {
        [RequestStatus.Failed]: theme.colors.error,
        [RequestStatus.Loading]: theme.colors.blue500
    };
    let text = '---';
    let color = null;
    if (requestStatus === RequestStatus.Failed ||
        requestStatus === RequestStatus.Loading) {
        text = statusAndTexts[requestStatus];
        color = statusAndColors[requestStatus];
    }
    else if (selectedStages.length === 1) {
        text = selectedStages[0]?.fullName;
    }
    else if (selectedStages.length > 1) {
        const numbers = selectedStages.map((s) => s.number);
        text = joinNumbers(numbers.sort());
    }
    return { text, color };
}
