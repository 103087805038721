import styled from 'styled-components';
export const StyledWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
`;
export const StyledMenuTrigger = styled.div `
  width: 115px;
  height: 25px;

  padding: 0 8px;

  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;

  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray200};

  ${(props) => (props.$color ? `border-color: ${props.$color};` : '')}

  ${(props) => {
    if (props.$disabled) {
        return 'opacity: 0.8;';
    }
    return 'cursor: pointer;';
}}
`;
export const StyledText = styled.span `
  font-size: 12px;
  font-weight: 600;
  font-style: normal;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: ${(props) => props.$color ? props.$color : props.theme.colors.gray700};
`;
