export function borderShouldBeDashed(params) {
    return params.relatedPlanning?.status?.isDeleted();
}
export function borderShouldBeRed(params) {
    const { areaSkill } = params.relatedPlanning;
    const isPlanningRelatedWithTheSelectedGrade = params.selectedGrade?.id === areaSkill?.gradeId;
    const isPlanningRelatedWithTheSelectedSegment = params.selectedSegment?.id === areaSkill?.segmentId;
    const isPlanningRelatedWithTheSelectedDisciplines = params.selectedDisciplines?.some((d) => {
        return d.id === areaSkill?.discipline?.id;
    });
    const allComponentsAreSelected = params.selectedGrade &&
        params.selectedSegment &&
        params.selectedDisciplines?.length > 0;
    return (allComponentsAreSelected &&
        (!isPlanningRelatedWithTheSelectedGrade ||
            !isPlanningRelatedWithTheSelectedSegment ||
            !isPlanningRelatedWithTheSelectedDisciplines));
}
