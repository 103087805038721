import React, { useMemo } from 'react';
import { createAnnualTimelinePageStore } from './store';
import { AnnualTimelinePageContext } from './context';
import { PortalsContextProvider } from '../common/contexts/portals';
import { MainTableFocusContextProvider } from '../common/contexts/mainTableFocus';
import Header from './components/Header';
import Body from './components/Body';
import Subtitle from './components/Subtitle';
import { useFetchCommonData } from './hooks/useFetchCommonData';
import { StyledPageWrapper } from '../common/components/PageRelated/styles';
const AnnualTimeline = () => {
    const storeApi = useMemo(() => {
        return createAnnualTimelinePageStore();
    }, []);
    useFetchCommonData(storeApi);
    return (React.createElement(AnnualTimelinePageContext.Provider, { value: storeApi },
        React.createElement(PortalsContextProvider, null,
            React.createElement(MainTableFocusContextProvider, null,
                React.createElement(StyledPageWrapper, null,
                    React.createElement(Header, null),
                    React.createElement(Body, null),
                    React.createElement(Subtitle, null))))));
};
export default AnnualTimeline;
