import React, { useMemo } from 'react';
import { Document, Page } from '@react-pdf/renderer';
import Header from '../common/components/Header';
import Body from '../common/components/Body';
import Footer from '../common/components/Footer';
import { paddings } from '../common/sizes';
import { isThereMoreThanOneDisciplineIn, setup } from '../common/helpers';
import { createHeaders, createRow } from './table';
import styles from '../common/styles';
setup();
const AnnualTimelineReport = (props) => {
    const displayDisciplineColumn = useMemo(() => {
        return isThereMoreThanOneDisciplineIn(props.skills);
    }, [props.skills]);
    const tableHeaders = createHeaders({ displayDisciplineColumn });
    const tableRows = useMemo(() => {
        return props.skills.map((skill) => createRow({ areaSkill: skill, displayDisciplineColumn }));
    }, [displayDisciplineColumn, props.skills]);
    return (React.createElement(Document, null,
        React.createElement(Page, { size: "A4", orientation: "landscape", style: {
                ...styles.page,
                paddingBottom: paddings.page.bottom.withoutEvaluationSection
            } },
            React.createElement(Header, { title: 'Cronograma Anual', information: props.information }),
            React.createElement(Body, { tableHeaders: tableHeaders, tableRows: tableRows }),
            React.createElement(Footer, { skills: props.skills }))));
};
export default AnnualTimelineReport;
