import styled from 'styled-components';
export const StyledWrapper = styled.div `
  margin-bottom: 15px;

  width: 100%;

  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: flex-start;
`;
export const StyledLabel = styled.span `
  font-size: 16px;
  font-weight: 600;

  line-height: 18px;

  color: #4d4d4d;
`;
