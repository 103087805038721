import React from 'react';
import { useMainTableFocusContext } from '../../../contexts/mainTableFocus';
import Label from './components/Label';
import Circle from './components/Circle';
import SubtitleItem from './components/Item';
import { usePieces } from './hooks/usePieces';
import { StyledWrapper, StyledTitle, StyledLineWrapper, StyledLinesWrapper } from './styles';
const Subtitle = (props) => {
    const { content } = props;
    const { isActive } = useMainTableFocusContext();
    const { pieces } = usePieces();
    function renderEvaluationTypes(evaluationTypes) {
        if (!evaluationTypes)
            return null;
        if (evaluationTypes.length === 0)
            return null;
        return evaluationTypes.map((type) => (React.createElement(SubtitleItem, { key: type.id },
            React.createElement(Circle, { color: type.color, borderColor: type.color }),
            React.createElement(Label, null, type.name))));
    }
    function renderItem(item) {
        if (Array.isArray(item)) {
            return renderEvaluationTypes(item);
        }
        const component = pieces[item];
        return React.createElement(React.Fragment, { key: item }, component);
    }
    function renderContent() {
        let index = 0;
        const lines = [];
        while (index < content.length) {
            const line = [];
            while (index < content.length && content[index] !== 'br') {
                line.push(renderItem(content[index]));
                index += 1;
            }
            index += 1;
            lines.push(React.createElement(StyledLineWrapper, { key: index }, line));
        }
        return lines;
    }
    return isActive ? null : (React.createElement(StyledWrapper, null,
        React.createElement(StyledTitle, null, "Legenda"),
        React.createElement(StyledLinesWrapper, null, renderContent())));
};
export default Subtitle;
