const keysPrefix = '@campus:curriculumComponents:';
export const keys = {
    grade: keysPrefix + 'lastSelectedGrade',
    segment: keysPrefix + 'lastSelectedSegment',
    disciplines: keysPrefix + 'lastSelectedDisciplines'
};
export function serializeDisciplines(disciplines) {
    return disciplines.map((d) => d.id).join(',');
}
export function deserializeDisciplines(str) {
    return str.split(',');
}
export function setOnStorage(key, value) {
    sessionStorage.setItem(key, value);
}
export function getFromStorage(key) {
    return sessionStorage.getItem(key);
}
