import styled from 'styled-components';
export const Container = styled.table `
  width: 100%;

  border-spacing: 0;
  border-collapse: separate;
`;
export const Header = styled.thead `
  position: sticky;
  top: 0;
  left: 0;

  z-index: 1;

  background: ${({ theme }) => theme.colors.gray50};

  th {
    padding: 6px 0 7px 0;

    text-align: center;

    font-size: 14px;
    font-weight: 600;

    line-height: 20px;

    color: ${({ theme }) => theme.colors.gray600};

    border-width: 2px 1px 2px 0;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.gray300};
  }

  th:first-child {
    text-align: start;
    padding-left: 8px;
  }

  th:last-child {
    border-right: none;
  }
`;
export const Body = styled.tbody `
  tr {
    border: none;
  }
`;
