import styled from 'styled-components';
import { ButtonWithIcon } from '../../../../../../../ButtonWithIcon';
import { hovering } from '../../../../../../../commonStyles';
export const Button = styled(ButtonWithIcon).attrs({
    iconNameInAssets: 'paperSheet'
}) `
  :not(:disabled) svg {
    transition: color ${hovering.transitionMs};

    :hover {
      color: ${hovering.color};
    }
  }

  svg {
    width: 18px;
    height: 18px;

    color: ${({ theme }) => theme.colors.gray3};
  }
`;
