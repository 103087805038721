export const widths = {
    holeDocument: 100,
    header: {
        titleAndSubtitle: 35,
        informations: 55,
        logo: 10
    },
    table: {
        columns: {
            studyUnit: {
                skill: 35,
                knowledgeObjects: 30,
                learningObjectives: 35
            },
            annualTimeline: {
                themeUnit: 15,
                skill: 25,
                stage: 15,
                knowledgeObjects: 20,
                learningObjectives: 25
            }
        }
    },
    footer: {
        pageAndDates: {
            pageNumber: 20,
            dates: 80
        }
    }
};
export const heights = {
    table: {
        header: 400,
        lastRow: 2000,
        normalRow: 1000
    }
};
export const margins = {
    header: {
        informations: { left: 220 }
    },
    table: {
        top: 300,
        header: { leftAligned: 50 },
        body: {
            top: 180,
            columns: {
                top: 100,
                bottom: 100,
                left: 100,
                right: 100
            }
        }
    },
    footer: {
        top: 150,
        pageNumberAndDates: { top: 300 },
        evaluationSection: {
            message: { top: 50, bottom: 200 }
        }
    }
};
const fontScale = 1.2;
const withFontScale = (num) => Math.round(num * fontScale);
export const fontSizes = {
    header: {
        title: withFontScale(42),
        subtitle: withFontScale(18),
        information: withFontScale(18)
    },
    table: {
        headerLabel: withFontScale(16),
        columnContent: withFontScale(18)
    },
    footer: {
        evaluationSection: withFontScale(18),
        pageNumberAndDates: withFontScale(16)
    }
};
