import { useCallback, useEffect, useState } from 'react';
import { useAddSkillPopupContext } from '../../../../../../../../context';
import { createOptionsFrom } from '../../../../../../common/helpers';
const defaultOption = {
    label: 'Todas as etapas',
    value: null
};
export function useOptionsManager() {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const { allStages, setFilteredStageId } = useAddSkillPopupContext((store) => ({
        allStages: store.allStages,
        setFilteredStageId: store.filters.setFilteredStageId
    }));
    const createOptions = useCallback(() => {
        if (!allStages)
            return [];
        if (allStages.length === 0)
            return [];
        return createOptionsFrom({
            defaultOption,
            items: allStages,
            getItemValue: (s) => s.id,
            getItemLabel: (s) => s.fullName
        });
    }, []);
    useEffect(() => {
        setOptions(createOptions());
    }, []);
    function onOptionChange(option) {
        setSelectedOption(option);
        setFilteredStageId(option.value);
    }
    return { options, selectedOption, onOptionChange };
}
