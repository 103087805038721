import { Segment } from '@campus/commons';

class Grade {
  id?: string;

  name?: string;

  segment?: Segment;

  static fromJson(data: any): Grade {
    const grade = new Grade();

    grade.id = data.Id;
    grade.name = data.Name;
    grade.segment = data.Segment && Segment.fromJson(data.Segment);

    return grade;
  }
}

export default Grade;
