import { createStore } from 'zustand';
import { Event } from '@campus/commons';
import { createCurriculumComponentsSlice } from '../../common/stores/slices';
import { createMainTableSlice } from './slices/mainTable';
import { createCommonDataSlice } from './slices/commonData/slice';
const commonDataEvents = {
    gradesChanged: new Event(),
    segmentsChanged: new Event(),
    disciplinesChanged: new Event()
};
const mainTableEvents = {
    contentSet: new Event(),
    contentChangedAfterOperation: new Event()
};
export function createFollowUpPageStore() {
    return createStore()((...args) => ({
        mainTable: createMainTableSlice(mainTableEvents)(...args),
        commonData: createCommonDataSlice(commonDataEvents)(...args),
        curriculumComponents: createCurriculumComponentsSlice(commonDataEvents)(...args)
    }));
}
