import { StyleSheet } from '@react-pdf/renderer';
import commonStyles from '../commonStyles';
import { fontSizes, paddings } from '../../../../../../../sizes';
const styles = StyleSheet.create({
    wrapper: {
        ...commonStyles,
        backgroundColor: 'rgba(242, 242, 242, 0.5)',
        paddingTop: paddings.table.columns.themeUnit.top,
        paddingBottom: paddings.table.columns.themeUnit.bottom,
        paddingLeft: paddings.table.columns.themeUnit.left,
        paddingRight: paddings.table.columns.themeUnit.right
    },
    text: {
        fontWeight: 700,
        lineHeight: '120%',
        fontSize: fontSizes.table.columnContent,
        color: '#4D4D4D'
    }
});
export default styles;
