import Class from './Class';
export default class Classroom {
    id;
    number;
    date;
    class;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new Classroom();
        instance.id = json.Id;
        instance.number = json.Classroom;
        instance.date = json.ClassroomDate ? new Date(json.ClassroomDate) : null;
        instance.class = json.Class ? Class.fromJson(json.Class) : null;
        return instance;
    }
}
