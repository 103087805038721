import styled from 'styled-components';
import PopupBox from '../PopupBox';
export const StyledPopupBox = styled(PopupBox) `
  z-index: 10;

  padding: 10px 0;

  width: fit-content;

  display: flex;
  flex-direction: column;
`;
