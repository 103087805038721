import { StyleSheet } from '@react-pdf/renderer';
import commonStyles from '../commonStyles';
import { paddings, fontSizes } from '../../../../../../../sizes';
const styles = StyleSheet.create({
    wrapper: {
        ...commonStyles,
        paddingTop: paddings.table.columns.knowledgeObjects.top,
        paddingBottom: paddings.table.columns.knowledgeObjects.bottom,
        paddingLeft: paddings.table.columns.knowledgeObjects.left,
        paddingRight: paddings.table.columns.knowledgeObjects.right,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 5
    },
    description: {
        fontWeight: 600,
        lineHeight: '120%',
        fontSize: fontSizes.table.columnContent,
        color: '#4D4D4D'
    }
});
export default styles;
