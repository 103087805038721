import styled from 'styled-components';
import { StyledComponentName } from '../common/styles';
export const StyledWrapper = styled.div `
  cursor: pointer;

  width: fit-content;
  height: fit-content;

  display: flex;
  align-items: center;

  & > :last-child {
    margin-left: 9px;
  }
`;
export const StyledName = styled(StyledComponentName) `
  color: ${(props) => props.theme.colors.gray2};
`;
export const StyledSeparator = styled.span `
  margin: 0 4px;
  font-size: 20px;
  color: ${(props) => props.$darker ? props.theme.colors.gray2 : props.theme.colors.gray500};
`;
