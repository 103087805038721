export function debounce(callback, delay) {
    let timeout = null;
    return (...args) => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            callback(...args);
        }, delay);
    };
}
export function debouncePromisified(callback, delay) {
    let timeout = null;
    return (...args) => {
        return new Promise((resolve, reject) => {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                callback(...args)
                    .then(resolve)
                    .catch(reject)
                    .finally(() => {
                    timeout = null;
                });
            }, delay);
        });
    };
}
