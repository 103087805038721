import styled from 'styled-components';
export const StyledRightSideWrapper = styled.div `
  width: fit-content;

  display: flex;
  flex-wrap: wrap;
  row-gap: 3px;
  column-gap: 11.5px;
  align-items: center;

  @media ${(props) => props.theme.mediaQuery.desktopDown} {
    justify-content: flex-end;
  }
`;
