import { Api } from '@campus/auth';
import { PaginationModel, Response } from '@campus/commons';
import Class from '../models/Class';
import { handleApiCallError } from './common';
const endpoints = {
    getAll: '/class'
};
export async function getAllClasses(params = null) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.getAll, {
            signal: params?.abortSignal,
            params: { MaxResultCount: 1000 }
        });
        const paginated = PaginationModel.fromJson(Class, response.data);
        return Response.fromData(paginated.items, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function getFilteredClasses(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.getAll, {
            signal: params?.abortSignal,
            params: {
                gradeId: params.gradeId,
                disciplinesIds: params.disciplineIds.join(','),
                MaxResultCount: 1000
            }
        });
        const paginated = PaginationModel.fromJson(Class, response.data);
        return Response.fromData(paginated.items, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
