import styled from 'styled-components';
export const StyledWrapper = styled.div `
  margin-top: 10px;
  margin-bottom: 10px;

  display: flex;
  row-gap: 20px;
  flex-direction: column;

  overflow-y: clip;
  overflow-x: scroll;

  width: min(28dvw, 600px);

  @media (min-width: 1470px) {
    width: 650px;
  }
`;
