import React from 'react';
import Cell from './components/Cell';
import { StyledWrapper } from './styles';
const Row = (props) => {
    function renderCells() {
        return props.rowInfos.cells.map((cell) => (React.createElement(Cell, { key: cell.column.id, cellInfos: cell })));
    }
    return React.createElement(StyledWrapper, null, renderCells());
};
export default Row;
