import React from 'react';
import { getContent } from './helpers';
import { StyledInfoContent, StyledInfoLabel, StyledInfoWrapper } from '../common/styles';
const Classes = (props) => {
    const content = getContent(props.sequences);
    return (React.createElement(StyledInfoWrapper, null,
        React.createElement(StyledInfoLabel, null, "N\u00BA de Aulas: "),
        React.createElement(StyledInfoContent, null, content)));
};
export default Classes;
