function sortClassroomGroups(groups) {
    const options = { sensitivity: 'accent' };
    const sorted = groups?.sort((a, b) => a.className?.localeCompare(b.className, undefined, options));
    return sorted ?? [];
}
function mapGroupClassNamesToClassroomAndSequence(groups, sequence) {
    const map = new Map();
    for (const group of groups) {
        const classroomAndSequence = sequence.classrooms?.find((c) => {
            return c.classroom?.class?.name === group.className;
        });
        map.set(group.className, classroomAndSequence);
    }
    return map;
}
export function createClassesAndInfos(params) {
    if (!params.classroomGroups)
        return;
    if (params.classroomGroups.length === 0)
        return;
    const classesAndInfos = new Map();
    const groups = sortClassroomGroups(params.classroomGroups);
    const map = mapGroupClassNamesToClassroomAndSequence(groups, params.sequence);
    for (const group of groups) {
        const classroomAndSequence = map.get(group.className);
        if (!classroomAndSequence)
            continue;
        if (classroomAndSequence.wasExecuted)
            continue;
        classesAndInfos.set(group.className, {
            isChecked: true,
            allClassrooms: group.classrooms,
            associatedClassName: group.className,
            selectedClassroom: classroomAndSequence.classroom,
            associatedClassroomAndSequence: classroomAndSequence
        });
    }
    return classesAndInfos;
}
