import React from 'react';
import Select from './components/Select';
import { StyledProgramSection, StyledProgramStageName, StyledSectionTitle, StyledWrapper } from './styles';
const StageWithProgram = (props) => {
    function getProgramStagesText() {
        const stages = props.areaSkill?.programStages
            ?.map((s) => s?.stage?.fullName)
            ?.sort();
        if (stages?.length > 0) {
            return stages.join(', ');
        }
        return 'Indefinida';
    }
    const initialStagesIds = props.areaSkill?.programStages
        ?.filter((s) => !!s?.stage?.id)
        .map((s) => s?.stage?.id);
    return (React.createElement(StyledWrapper, null,
        React.createElement(StyledProgramSection, null,
            React.createElement(StyledSectionTitle, null, "Programa:"),
            React.createElement(StyledProgramStageName, null, getProgramStagesText())),
        React.createElement("div", null,
            React.createElement(StyledSectionTitle, null, "Unidade:"),
            React.createElement(Select, { initialStagesIds: initialStagesIds, readOnly: props.doNotAllowUpdates, skill: props.areaSkill, allStages: props.allStages, handleStageUpdated: props.handleStageUpdated }))));
};
export default StageWithProgram;
