import { getMainClassroomAndSequenceFrom } from '../../../common/helpers/sequenceRelated';
export function getContent(sequences) {
    const numbers = sequences
        ?.filter((s) => !s.isDeleted)
        ?.map((s) => {
        const main = getMainClassroomAndSequenceFrom(s?.classrooms);
        return main?.classroom?.number ?? null;
    })
        ?.filter((number) => number !== null);
    return numbers ? new Set(numbers).size : 0;
}
