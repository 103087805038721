import React from 'react';
import StageFilter from './components/StageFilter';
import SkillCodeFilter from './components/SkillCodeFilter';
import { StyledWrapper } from './styles';
const Filters = () => {
    return (React.createElement(StyledWrapper, null,
        React.createElement(StageFilter, null),
        React.createElement(SkillCodeFilter, null)));
};
export default Filters;
