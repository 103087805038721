import { StyleSheet } from '@react-pdf/renderer';
import { fontSizes, margins } from '../../sizes';
const styles = StyleSheet.create({
    wrapper: {
        flexShrink: 0
    },
    pageAndDatesWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: margins.footer.pageAndDates.top
    },
    text: {
        fontSize: fontSizes.footer.pageNumberAndDates,
        fontWeight: 600,
        color: 'rgba(0, 0, 0, 0.48)'
    }
});
export default styles;
