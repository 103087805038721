import { useWeeklyPlanningPageContext } from '../../../../../../../../../../../../context';
export function useDisable(props) {
    const userPermissions = useWeeklyPlanningPageContext((store) => store.commonData.userPermissions.data);
    const isSequenceDeleted = props.sequence?.isDeleted ?? false;
    const isPlanningDeleted = props.planning?.status?.isDeleted() ?? false;
    const isPlanningFinished = props.planning?.status?.isFinished() ?? false;
    const isPlanningFinishedOrDeleted = isPlanningFinished || isPlanningDeleted;
    const userNotAllowedToUpdate = !userPermissions?.userCan.updateTheTeacherColumns();
    const disableRemove = userNotAllowedToUpdate || isSequenceDeleted || isPlanningDeleted;
    const disableThingsExceptRemove = userNotAllowedToUpdate || isSequenceDeleted || isPlanningFinishedOrDeleted;
    return {
        remove: disableRemove,
        edit: disableThingsExceptRemove,
        move: disableThingsExceptRemove,
        duplicate: disableThingsExceptRemove
    };
}
