import { generateColor } from '@campus/commons';
import Grade from './Grade';
export default class Discipline {
    id;
    name;
    color;
    gradeId;
    grade;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new Discipline();
        instance.id = json.Id ?? json.Discipline?.Id;
        instance.name = json.Name ?? json.Discipline?.Name;
        instance.grade = !!json.Grade ? Grade.fromJson(json.Grade) : null;
        instance.gradeId = instance.grade?.id ?? json.GradeId;
        instance.color = Discipline.createColor(instance.name);
        return instance;
    }
    static createColor(disciplineName) {
        return generateColor(`${disciplineName}${new Date().getDate()}${new Date().getMonth()}`);
    }
}
