import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  useContextManager,
  Discipline,
  useTeacherId,
  MenuHeader
} from '@campus/commons';

import { SideMenu } from '@campus/components';

import { PedagogicalPlanningLink } from '@campus/pedagogical-planning';

import {
  listClassDiscipline,
  listTeacherDiscipline
} from 'services/discipline';

import { Lists } from './styles';

const Menu: React.FC = (props) => {
  const { search } = useLocation();

  const teacherId = useTeacherId();
  const { classroom, stage } = useContextManager();

  const [teacherDisciplines, setTeacherDisciplines] = useState<Discipline[]>(
    []
  );
  const [loadingTeacher, setLoadingTeacher] = useState(true);

  const [classDisciplines, setClassDisciplines] = useState<Discipline[]>([]);
  const [loadingClass, setLoadingClass] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setLoadingClass(true);

      const result = await listClassDiscipline(classroom.id, stage.id);
      if (result.data) setClassDisciplines(result.data);

      setLoadingClass(false);
    };

    getData();
  }, [classroom.id, stage.id, teacherId]);

  useEffect(() => {
    const getData = async () => {
      setLoadingTeacher(true);

      const result = await listTeacherDiscipline(teacherId, classroom.id);
      if (result.data) setTeacherDisciplines(result.data);

      setLoadingTeacher(false);
    };

    getData();
  }, [classroom.id, teacherId]);

  return (
    <SideMenu {...props}>
      <MenuHeader />
      <Link to={{ pathname: '/', search: search }}>
        <SideMenu.Option icon="home">Página inicial</SideMenu.Option>
      </Link>
      <Lists>
        <SideMenu.List title="Meus componentes curriculares">
          {loadingTeacher ? (
            <>
              <SideMenu.ListItemSkeleton />
              <SideMenu.ListItemSkeleton />
              <SideMenu.ListItemSkeleton />
            </>
          ) : (
            <>
              {teacherDisciplines.map((discipline) => (
                <Link
                  key={discipline.id}
                  to={{
                    pathname: `/discipline/${discipline.id}`,
                    search: search
                  }}
                >
                  <SideMenu.ListItem
                    title={discipline.name}
                    color={discipline.color}
                  />
                </Link>
              ))}
            </>
          )}
        </SideMenu.List>
        <SideMenu.List title="Outros componentes curriculares">
          {loadingClass ? (
            <>
              <SideMenu.ListItemSkeleton />
              <SideMenu.ListItemSkeleton />
              <SideMenu.ListItemSkeleton />
              <SideMenu.ListItemSkeleton />
            </>
          ) : (
            <>
              {classDisciplines.map((discipline) => (
                <Link
                  key={discipline.id}
                  to={{
                    pathname: `/discipline/${discipline.id}`,
                    search: search
                  }}
                >
                  <SideMenu.ListItem
                    title={discipline.name}
                    color={discipline.color}
                  />
                </Link>
              ))}
            </>
          )}
        </SideMenu.List>
      </Lists>

      {/*isTeacher && <SideMenu.Option icon="forum">Fórum</SideMenu.Option> */}
      <PedagogicalPlanningLink />
    </SideMenu>
  );
};

export default Menu;
