import React from 'react';
import Checkbox from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/common/objectsAndObjectives/components/Checkbox';
import Description from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/common/objectsAndObjectives/components/Description';
import { StyledWrapper } from './styles';
const Object = (props) => {
    const knowledgeObject = props.object?.knowledgeObject;
    return (React.createElement(StyledWrapper, null,
        React.createElement(Checkbox, { disabled: true, checked: props.object.wasProgrammed }),
        React.createElement(Description, { disabled: true, content: knowledgeObject?.description }, knowledgeObject.description)));
};
export default Object;
