import { teacherTheme } from '@campus/components';
import { AlignmentType, Paragraph, Table, TableCell, TableRow, TextRun, WidthType } from 'docx';
import { emptyTableCellBorders } from '../../../../common/borderDocx';
import { toBrazilianDateString } from '../../../../../../utils/dates';
import { fontSizes, widths } from '../../../../common/sizesDocx';
function prettyTimeFrom(date) {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return hours + 'h' + minutes;
}
function createPageNumberParagraph(pageNumber, numberOfPages) {
    return new Paragraph({
        alignment: AlignmentType.LEFT,
        children: [
            new TextRun({
                color: teacherTheme.colors.gray500,
                size: fontSizes.footer.pageNumberAndDates,
                text: 'Página ' + pageNumber + ' de ' + numberOfPages
            })
        ]
    });
}
function createInformationParagraph() {
    const generationDate = new Date();
    const prettyTime = prettyTimeFrom(generationDate);
    const prettyDate = toBrazilianDateString(generationDate);
    return new Paragraph({
        alignment: AlignmentType.RIGHT,
        children: [
            new TextRun({
                color: teacherTheme.colors.gray500,
                size: fontSizes.footer.pageNumberAndDates,
                text: 'Documento gerado no dia ' +
                    prettyDate +
                    ' às ' +
                    prettyTime
            })
        ]
    });
}
export default function PageAndInformation(pageNumber, numberOfPages) {
    return new Table({
        width: { size: widths.holeDocument, type: WidthType.PERCENTAGE },
        columnWidths: [
            widths.footer.pageAndDates.pageNumber,
            widths.footer.pageAndDates.dates
        ],
        rows: [
            new TableRow({
                children: [
                    new TableCell({
                        borders: emptyTableCellBorders,
                        children: [createPageNumberParagraph(pageNumber, numberOfPages)],
                        width: {
                            type: WidthType.PERCENTAGE,
                            size: widths.footer.pageAndDates.pageNumber
                        }
                    }),
                    new TableCell({
                        borders: emptyTableCellBorders,
                        children: [createInformationParagraph()],
                        width: {
                            type: WidthType.PERCENTAGE,
                            size: widths.footer.pageAndDates.dates
                        }
                    })
                ]
            })
        ]
    });
}
