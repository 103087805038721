import PedagogicalPlanning from '../../../../../../../../../../../../models/PedagogicalPlanning';
import PedagogicalPlanningObject from '../../../../../../../../../../../../models/PedagogicalPlanningObject';
export function createUpdatedPlanning(params) {
    const newPlanning = new PedagogicalPlanning();
    Object.assign(newPlanning, params.planning);
    const newObjects = [...newPlanning.objects];
    const index = newObjects.findIndex((object) => {
        return object.id === params.object.id;
    });
    const newObject = new PedagogicalPlanningObject();
    Object.assign(newObject, newObjects[index]);
    newObject.wasProgrammed = params.programmed;
    newObjects[index] = newObject;
    newPlanning.objects = newObjects;
    return newPlanning;
}
