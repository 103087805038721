import { Student, ContentState } from '@campus/commons';
import format from 'date-fns/format';

import StudentAnswer from './StudentAnswer';

class StudentAnswerReport {
  id: string;

  score: number;

  teacherScore: number;

  teacherCommnent: string;

  student: Student;

  AnswerCount: number;

  answered: boolean;

  answers: StudentAnswer[];

  state?: ContentState;

  stateText?: string;

  stateTitle?: string;

  stateDate?: string;

  multiAnswers?: boolean;

  static fromJson(data: any): StudentAnswerReport {
    const student = new StudentAnswerReport();

    student.score = data.Score;
    student.teacherScore = data.TeacherScore;
    student.teacherCommnent = data.TeacherCommnent;
    student.AnswerCount = data.AnswerCount;
    student.answered = data.Answered;
    student.state = data.State;
    student.stateText = data?.StateText;
    student.stateTitle = data.StateTitle;
    const formattedDate = data.StateDate
      ? format(new Date(data.StateDate), 'dd/MM/yyyy HH:mm:ss')
      : null;
    student.stateDate = formattedDate;
    student.multiAnswers = data?.MultiAnswers;

    if (data.Student) {
      student.student = Student.fromJson(data.Student);
    }

    student.id = student?.student?.id ?? data.StudentId;

    const answers = data.Answers;
    student.answers = answers?.map((a) => StudentAnswer.fromJson(a)) ?? [];

    return student;
  }

  private mergeAnswers(updatedAnswers: StudentAnswer[]): StudentAnswer[] {
    if (!updatedAnswers || updatedAnswers.length === 0) return [];

    const answers = this.answers.filter(
      (a) => !updatedAnswers.find((u) => u.contentItemId === a.contentItemId)
    );

    return [...answers, ...updatedAnswers];
  }

  mergeStudent(updatedStudent?: StudentAnswerReport): StudentAnswerReport {
    if (updatedStudent) {
      this.id = updatedStudent.id;
      this.state = updatedStudent.state;
      this.score = updatedStudent.score;
      this.teacherScore = updatedStudent.teacherScore;
      this.teacherCommnent = updatedStudent.teacherCommnent;
      this.answers = this.mergeAnswers(updatedStudent.answers);
    }

    return this;
  }
}

export default StudentAnswerReport;
