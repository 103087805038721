import React from 'react';
import { TeacherInformation, CurriculumComponent, Class, EmptyClasses, GeneralTotal } from './Columns';
import { Container, Header, Body } from './styles';
export const Table = ({ content }) => {
    return (React.createElement(Container, null,
        React.createElement(Header, null,
            React.createElement("tr", null,
                React.createElement("th", null, "Nome do Professor"),
                React.createElement("th", null, "Componente Curricular"),
                React.createElement("th", null, "Turma"),
                React.createElement("th", null, "Aulas Vazias"),
                React.createElement("th", null, "Total Geral"))),
        React.createElement(Body, null, content?.map((teacher) => teacher.disciplines.map((discipline, disciplineIndex) => discipline.classes.map((clasz, classIndex) => (React.createElement("tr", { key: `${teacher.id}-${discipline.id}-${clasz.id}` },
            classIndex === 0 && disciplineIndex === 0 ? (React.createElement(TeacherInformation, { teacher: teacher })) : null,
            classIndex === 0 ? (React.createElement(CurriculumComponent, { discipline: discipline })) : null,
            React.createElement(Class, { class: clasz }),
            React.createElement(EmptyClasses, { class: clasz }),
            classIndex === 0 && disciplineIndex === 0 ? (React.createElement(GeneralTotal, { teacher: teacher })) : null))))))));
};
