import React, { useMemo, useState } from 'react';
import { useCalendarContext } from '../../../../context';
import { useFollowUpMainTableContext } from '../../../../../../../context';
import BlueLayer from '../../../BlueLayer';
import Lines from './components/Lines';
import Column from './components/Column';
import Rectangles from './components/Rectangles';
import RightBorder from './components/RightBorder';
import { createBodyRowId } from '../../../../elements';
import { useBorders } from './hooks/useBorders';
import { StyledWrapper } from './styles';
const Row = (props) => {
    const [wrapper, setWrapper] = useState();
    const groupsAndInfos = useFollowUpMainTableContext((store) => store.groupsAndInfos);
    const months = useCalendarContext((store) => store.months);
    const borders = useBorders({ group: props.group });
    const rowId = createBodyRowId(props.index);
    const groupInfos = useMemo(() => {
        return groupsAndInfos.get(props.group.number);
    }, [props.group.number, groupsAndInfos]);
    function renderContent() {
        return months.map((month, index) => (React.createElement(Column, { month: month, group: props.group, key: month.number + index })));
    }
    return (React.createElement(StyledWrapper, { id: rowId, ref: setWrapper, "$height": groupInfos?.height ?? 0, "$currentRowBorder": {
            isRed: borders.currentRowBorderShouldBeRed,
            isDashed: borders.currentRowBorderShouldBeDashed
        }, "$lastRowBorder": {
            isRed: borders.lastRowBorderShouldBeRed,
            isDashed: borders.lastRowBorderShouldBeDashed
        } },
        renderContent(),
        React.createElement(RightBorder, { group: props.group, groupInfos: groupInfos }),
        React.createElement(BlueLayer, { parentElement: wrapper }),
        React.createElement(Rectangles, { group: props.group, groupInfos: groupInfos, parentElement: wrapper }),
        React.createElement(Lines, { group: props.group, groupInfos: groupInfos, parentElement: wrapper })));
};
export default Row;
