import styled from 'styled-components';
export const StyledWrapper = styled.div `
  position: sticky;
  top: 0;
  left: 0;

  z-index: 5;

  min-width: 100%;
  width: fit-content;

  display: flex;
  flex-direction: column;
`;
