import { useCallback } from 'react';
import { createUpdatedPlanning } from './helpers';
import { debounce } from '../../../../../../../../../../utils/debounce';
import { useUpdate as baseUseUpdate } from '../../../../common/objectsAndObjectives/hooks/useUpdate';
import { debounceDelayInMillis } from '../../../../common/objectsAndObjectives/times';
import { updatePlanningObjective } from '../../../../../../../../../../../../services/pedagogicalPlanningObjective';
let abortController = new AbortController();
export function useUpdate(props) {
    const { requestStatus, updateStarted, updateFailed, updateSuceeded, resetStatus } = baseUseUpdate();
    const debouncedUpdate = useCallback(debounce((params) => {
        abortController.abort();
        abortController = new AbortController();
        updateStarted();
        updatePlanningObjective({
            objectiveId: props.objective.id,
            wasProgrammed: params.isCheckboxNowChecked,
            abortSignal: abortController.signal
        })
            .then(updateSuceeded)
            .catch((response) => {
            updateFailed(response, props.handleUpdateFailed);
        })
            .finally(resetStatus);
    }, debounceDelayInMillis), [
        abortController,
        updateStarted,
        updateSuceeded,
        updateFailed,
        resetStatus,
        props.objective.id,
        props.handleUpdateFailed
    ]);
    function onCheckboxToggle(params) {
        const updatedPlanning = createUpdatedPlanning({
            planning: props.planning,
            objective: props.objective,
            programmed: params.isCheckboxNowChecked
        });
        props.handleObjectiveUpdated(updatedPlanning);
        debouncedUpdate(params);
    }
    return { requestStatus, onCheckboxToggle };
}
