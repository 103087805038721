import styled from 'styled-components';
export const Container = styled.div `
  padding: 28px 10px;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 2px;

  svg {
    display: block;
  }

  span {
    font-size: 14px;

    font-weight: 600;
    line-height: 20px;

    color: ${({ theme }) => theme.colors.gray600};
  }
`;
