import styled from 'styled-components';
import Checkbox from '../../../../../../Basics/Checkbox/Checkbox';
export const StyledWrapper = styled.div `
  padding: 5px 12px;

  cursor: pointer;

  border-radius: 4px;

  display: flex;
  column-gap: 5px;
  align-items: flex-start;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }
`;
export const StyledLabel = styled.label `
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  color: ${(props) => props.theme.colors.gray3};
`;
export const StyledCheckbox = styled(Checkbox).attrs({
    thickenBorder: true
}) `
  cursor: pointer;
`;
