export const classNames = {
    bodyColumnWeek: 'table-body-column-week'
};
export function createBodyRowId(rowIndex) {
    return `table-body-row-${rowIndex}`;
}
export function createBodyColumnWeekId(week) {
    const startDay = week.startDate.getDate();
    const endDay = week.endDate.getDate();
    const monthNumber = week.monthNumber;
    return `table-body-column-week--${startDay}-${endDay}_${monthNumber}`;
}
