import styled from 'styled-components';
import { StyledColumnWrapper } from '../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/common/styles';
export const StyledWrapper = styled(StyledColumnWrapper) `
  height: 100%;

  padding: 12px 14px;

  background-color: white;

  display: flex;
  column-gap: 8.3px;

  border-right-width: 0;

  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.25);
`;
export const StyledIconsAndPopupWrapper = styled.div `
  height: fit-content;
  position: relative;
`;
