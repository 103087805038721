import React from 'react';
import { pdf } from '@react-pdf/renderer';
import AnnualTimelineReport from '../AnnualTimelineReport/AnnualTimelineReport';
import AnnualTimelineReportDocx from '../AnnualTimelineReport/AnnualTimelineReportDocx';
import { downloadFile } from './common';
import { useReportInformation } from './useReportInformation';
export function useAnnualTimelineReport(props) {
    const { getInformation } = useReportInformation(props);
    async function download() {
        const information = await getInformation();
        const report = pdf(React.createElement(AnnualTimelineReport, { skills: props.skills, information: information }));
        const blob = await report.toBlob();
        const filename = 'Cronograma Anual';
        await downloadFile(blob, filename);
    }
    return { download };
}
export function useAnnualTimelineDocxReport(props) {
    const { getInformation } = useReportInformation(props);
    async function download() {
        const information = await getInformation();
        const filename = 'Cronograma Anual';
        const blob = await new AnnualTimelineReportDocx({
            information,
            skills: props.skills
        }).createAndSaveDocumentToFile(filename);
        await downloadFile(blob, filename);
    }
    return { download };
}
