import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: ${(props) => props.theme.weight.semiBold};
  color: ${(props) => props.theme.colors.gray600};
  margin-bottom: 10px;
`;

export const Number = styled.span`
  display: flex;
  align-items: center;
  padding: 4px 4px 2px 4px;
  text-transform: uppercase;
  background-color: ${(props) => props.theme.colors.blue1}1A;
  color: ${(props) => props.theme.colors.blue1};
  font-weight: ${(props) => props.theme.weight.bold};
  font-size: 10px;
  line-height: 110%;
  margin-bottom: 7px;
`;

export const QuestionLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
`;

export const QuestionTitle = styled.div`
  font-weight: ${(props) => props.theme.weight.semiBold};
  font-family: 'Gilroy';
  font-style: normal;
  font-size: 14px;
  line-height: 143%;
  color: ${(props) => props.theme.colors.gray600};
  margin-top: 15px;
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
`;

export const QuestionContent = styled.div<{ shortQuestion?: boolean }>`
  ${(props) =>
    props.shortQuestion
      ? ''
      : `
    height: 98px;
    min-height: 68px;
    overflow: hidden;
  `}
`;

export const QuestionContainer = styled.div`
  width: 100%;
`;

export const QuestionOpen = styled.div`
  display: flex;
  margin-top: 15px;
  align-items: center;
`;

export const QuestionOpenButton = styled.div`
  height: 22px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 2px;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 12px;
  color: #b3b3b3;
  padding: 4px 6px;
  cursor: pointer;
`;

export const QuestionLine = styled.hr<{ ml?: Number; mr?: Number }>`
  width: 100%;
  border: 1px solid #e0e0e0;

  ${(props) => (props.ml ? `margin-left: ${props.ml}px;` : '')}
  ${(props) => (props.mr ? `margin-right: ${props.mr}px;` : '')}
`;
