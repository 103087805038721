import React, { useState } from 'react';
import { useOptionsManager } from './hooks/useOptionsManager';
import { shouldBoxBeDisabled } from './helpers';
import { StyledWrapper, StyledMenu, StyledBox } from './styles';
const ClassBox = () => {
    const [canMenuBeDisplayed, setCanMenuBeDisplayed] = useState(false);
    const showMenu = () => setCanMenuBeDisplayed(true);
    const hideMenu = () => setCanMenuBeDisplayed(false);
    const { options, selectedOption, optionClicked } = useOptionsManager();
    const disabled = shouldBoxBeDisabled(options);
    function handleOptionClicked(option) {
        optionClicked(option);
        hideMenu();
    }
    return (React.createElement(StyledWrapper, null,
        React.createElement(StyledBox, { onClick: disabled ? null : showMenu, "$disabled": disabled }, selectedOption ? `Aula ${selectedOption.label}` : '---'),
        React.createElement(StyledMenu, { options: options, selectedOption: selectedOption, canBeDisplayed: canMenuBeDisplayed, handleExternalClick: hideMenu, handleOptionClicked: handleOptionClicked })));
};
export default ClassBox;
