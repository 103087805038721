export default class LaunchReportFiltersDiscipline {
    id;
    name;
    gradeIds;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new LaunchReportFiltersDiscipline();
        instance.id = json.Discipline?.Id;
        instance.name = json.Discipline?.Name;
        instance.gradeIds = Array.isArray(json.GradeIds) ? json.GradeIds : [];
        return instance;
    }
}
