import React, { useRef, useState } from 'react';
import BaseDatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { useFixCalendarPosition } from './hooks';
import { Container, CalendarIcon, ClearIcon } from './styles';
export const DatePicker = ({ value, onChange }) => {
    const containerRef = useRef(null);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    useFixCalendarPosition(containerRef);
    return (React.createElement(Container, { ref: containerRef },
        React.createElement(BaseDatePicker, { locale: "pt-br", maxDetail: "month", minDetail: "decade", format: "dd/MM/yyyy", dayPlaceholder: "dd", monthPlaceholder: "mm", yearPlaceholder: "aaaa", value: value, onChange: onChange, onCalendarOpen: () => setIsCalendarOpen(true), onCalendarClose: () => setIsCalendarOpen(false), calendarIcon: React.createElement(CalendarIcon, null), clearIcon: isCalendarOpen || value ? React.createElement(ClearIcon, null) : null })));
};
