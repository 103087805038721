import React from 'react';
import { useFollowUpPageContext } from '../../../../../../../../../context';
import BaseColumn from '../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/TeacherNotes';
const TeacherNotes = (props) => {
    const { updatePlanning, userPermissions } = useFollowUpPageContext((slice) => ({
        updatePlanning: slice.mainTable.operations.updatePlanning,
        userPermissions: slice.commonData.userPermissions.data
    }));
    const doNotAllowUpdates = !userPermissions?.userCan.updateTheTeacherColumns();
    return (React.createElement(BaseColumn, { planning: props.planning, handleTeacherNotesUpdated: updatePlanning, doNotAllowUpdates: doNotAllowUpdates }));
};
export default TeacherNotes;
