import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import styles from './styles';
const Stage = (props) => {
    const { areaSkillAndStages } = props;
    const stages = areaSkillAndStages?.map((s) => s?.stage);
    const names = stages?.map((s) => s?.fullName)?.sort();
    const text = names?.join(', ') ?? '';
    return (React.createElement(View, { style: styles.wrapper },
        React.createElement(Text, { widows: 1, style: styles.text, wrap: false }, text)));
};
export default Stage;
