import React, { useMemo } from 'react';
import { FinishActivityPopupContext } from '.';
import { createFinishActivityPopupStore } from '../store';
export const Provider = (props) => {
    const store = useMemo(() => {
        return createFinishActivityPopupStore({
            sequence: props.sequence,
            planning: props.planning,
            abortSignal: props.abortSignal,
            classroomGroups: props.classroomGroups
        });
    }, [
        props.abortSignal,
        props.planning,
        props.sequence,
        props.classroomGroups
    ]);
    return (React.createElement(FinishActivityPopupContext.Provider, { value: store }, props.children));
};
