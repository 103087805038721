function isTheInstanceDefinition(definition, instance) {
    return (definition.id === instance.id ||
        definition.Header.toString() === instance.Header.toString());
}
export function getColumnDefinition(params) {
    for (const column of params.allColumns) {
        if (isTheInstanceDefinition(column, params.columnInstance)) {
            return column;
        }
        const found = column.columns?.find((c) => {
            return isTheInstanceDefinition(c, params.columnInstance);
        });
        if (!!found)
            return found;
    }
    return null;
}
