import React from 'react';
import { StyledItemTitle, StyledDisciplineName, StyledHeaderWrapper, StyledArrowRightIcon, StyledArrowRightIconWrapper } from './styles';
const Header = (props) => {
    const { themeUnit, discipline } = props.infos;
    return (React.createElement(StyledHeaderWrapper, { "$backgroundColor": discipline?.color },
        React.createElement(StyledDisciplineName, null, discipline?.name),
        React.createElement(StyledArrowRightIconWrapper, null,
            React.createElement(StyledArrowRightIcon, null)),
        React.createElement(StyledItemTitle, null, themeUnit?.name)));
};
export default Header;
