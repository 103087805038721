import React, { useState } from 'react';
import { StyledCheckbox, StyledLabel, StyledWrapper } from './styles';
const Item = (props) => {
    const [isChecked, setIsChecked] = useState(props.checkedByDefault ?? false);
    function onClick() {
        const isNowChecked = !isChecked;
        setIsChecked(isNowChecked);
        props.onClick({ isNowChecked });
    }
    return (React.createElement(StyledWrapper, { onClick: onClick },
        React.createElement(StyledCheckbox, { checked: isChecked }),
        React.createElement(StyledLabel, null, props.label)));
};
export default Item;
