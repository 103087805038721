import styled from 'styled-components';

import { CampusIcon, Input } from '@campus/components';

import {
  DigitalObject as BaseDigitalObject,
  HeaderMobile
} from '@campus/commons';

export const HeaderContainer = styled.header.attrs({
  className: 'full-width subgrid'
})`
  background-color: ${(props) => props.theme.colors.blue1};

  > * {
    grid-column: content;
  }
`;

export const Header = styled.div`
  height: 95px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media ${(props) => props.theme.mediaQuery.mobile} {
    display: none;
  }
`;

export const HeaderInMobile = styled(HeaderMobile)`
  display: none;

  padding: 20px 0;

  align-items: center;

  @media ${(props) => props.theme.mediaQuery.mobile} {
    display: flex;
  }
`;

export const LibraryObjectsContainer = styled.div<{
  $bgColor?: string;
  $scrollInResponsive?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 36px;
  background-color: ${(props) =>
    props.$bgColor ? `${props.$bgColor}` : '#d6e2f2'};
`;

export const MyMaterials = styled(LibraryObjectsContainer).attrs({
  className: 'full-width subgrid'
})`
  min-height: 360px;
  row-gap: 14px;

  > * {
    grid-column: content;
  }
`;

export const Content = styled(LibraryObjectsContainer).attrs({
  className: 'content'
})``;

export const ContentTitle = styled.div<{
  $fontSize?: number;
  $fontWeight?: number;
  $lineHeight?: number;
  $mb?: number;
}>`
  color: ${(props) => props.theme.colors.gray2};
  font-size: ${(props) => (props.$fontSize ? `${props.$fontSize}px` : '20px')};
  font-style: normal;
  font-weight: ${(props) =>
    props.$fontWeight ? `${props.$fontWeight}` : '700'};
  line-height: ${(props) =>
    props.$lineHeight ? `${props.$lineHeight}%` : '110%'};
  margin-bottom: ${(props) => (props.$mb ? `${props.$mb}px;` : '')};
`;

export const LibraryObjectsDiv = styled.div`
  width: 100%;
`;

export const AllDigitalObjects = styled.div`
  overflow-x: hidden;

  display: flex;
  gap: 10px;
`;

export const DigitalObjects = styled.div`
  display: flex;
  column-gap: 10px;

  overflow-x: auto;
  overflow-y: hidden;

  scroll-snap-type: x mandatory;

  > * {
    scroll-snap-align: start;
  }

  .carousel__empty-space {
    display: none;
  }

  .carousel__item {
    z-index: 5;

    position: revert;

    &:hover {
      z-index: 10;
    }
  }

  .carousel__go-next,
  .carousel__go-back {
    display: flex;
    align-items: center;

    svg {
      display: block;
    }
  }
`;

export const DigitalObject = styled(BaseDigitalObject)`
  margin: 0;

  position: revert;

  .digital-object__image-container {
    margin: 0;

    position: relative;

    width: 165px;
    height: 221px;
  }

  .digital-object__image {
    display: block;

    width: auto;
    max-width: 100%;
    height: 221.18px;
  }

  .digital-object__video-container {
    top: 50%;
    left: 0;
    right: 0;
    translate: 0 -50%;
  }

  .digital-object__video {
    width: 100%;
    max-height: 92.4px;

    border-radius: 0;
  }

  .digital-object__play {
    all: unset;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
  }

  .digital-object__name {
    width: auto;
    max-width: 165px;
  }
`;

export const EmptyDigitalObjects = styled.div`
  flex: 1;

  height: 221px;

  overflow: clip;

  display: grid;
  gap: 10px;
  grid-auto-rows: 0;
  grid-template-rows: 221px;
  grid-template-columns: repeat(auto-fit, 165px);
`;

export const EmptyDigitalObject = styled.div`
  background: #fbfbfb;

  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray5};
`;

export const LibraryObjectsList = styled.div`
  min-height: 190px;

  margin-bottom: 50px !important;

  display: grid;
  gap: 31px 10px;
  grid-template-columns: repeat(auto-fit, 165px);

  @media ${(props) => props.theme.mediaQuery.mobile} {
    justify-content: center;
  }
`;

export const Title = styled.h1`
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  margin-bottom: 0px;
`;

export const Subtitle = styled.h1`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 11px;
`;

export const LineBreak = styled.hr`
  background-color: ${(props) => props.theme.colors.gray5};
  width: 100%;
  margin-bottom: 27px;
  margin-top: 19px;
`;

export const LibraryObjectsScreen = styled.div`
  min-height: 100svh;
  background-color: #fff;
  padding-bottom: 180px;

  --_inline-space: max(1rem, (100% - 1040px) / 2);

  display: grid;
  align-content: start;
  grid-template-columns:
    [full-width-start] var(--_inline-space)
    [content-start] 1fr [content-end]
    var(--_inline-space) [full-width-end];

  .full-width {
    grid-column: full-width;
  }

  .content {
    grid-column: content;
  }

  .subgrid {
    display: grid;
    grid-template-columns: inherit;
  }
`;

export const SearchIcon = styled(CampusIcon).attrs({
  name: 'search'
})`
  display: block;
`;

export const InputStyled = styled(Input)`
  height: 40px;
  max-height: 40px;

  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.gray4};

  .input-group {
    height: 100%;

    padding: 8px 16px 8px 15px;

    > * {
      padding: 0;
      border: none;
      min-height: revert;
    }

    > input#search {
      margin-left: 11px;
    }
  }
`;

export const DivFilters = styled.div`
  width: 100%;

  display: grid;
  gap: 10px;
  grid-template-rows: 40px;
  grid-template-columns:
    minmax(268px, max-content)
    minmax(205px, max-content)
    minmax(268px, max-content);

  @media (max-width: 950px) {
    grid-template-rows: 40px;
    grid-template-columns: repeat(3, 1fr);

    > * {
      min-width: 0;
    }
  }

  @media ${(props) => props.theme.mediaQuery.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 40px);
  }
`;

export const DivFiltersContent = styled.div`
  display: flex;

  @media ${(props) => props.theme.mediaQuery.greaterTabletDown} {
    flex-direction: column;
    justify-content: space-between;
    height: 90px;
  }
`;
