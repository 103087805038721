import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';
import SaintAugustineLogo from '../../../../../../../assets/images/saint-augustine-logo.png';
import styles from './styles';
const Header = (props) => {
    return (React.createElement(View, { fixed: true, style: styles.wrapper },
        React.createElement(View, { style: styles.titlesWrapper },
            React.createElement(Text, { style: styles.title }, props.title),
            React.createElement(View, { style: styles.subTitle },
                React.createElement(Text, null,
                    React.createElement(Text, { style: styles.infosEmphasizedText }, "PER\u00CDODO LETIVO: "),
                    React.createElement(Text, { style: styles.infosNormalText }, props.period)),
                React.createElement(Text, { style: styles.subTitleRight },
                    React.createElement(Text, { style: styles.infosEmphasizedText }, "TURMA: "),
                    React.createElement(Text, { style: styles.infosNormalText }, props.information.selectedClass
                        ? props.information.selectedClass.name
                        : `${props.information.selectedGrade.name} - TODAS`)))),
        React.createElement(View, { style: styles.infosWrapper },
            React.createElement(Text, { style: styles.curriculumText },
                React.createElement(Text, { style: styles.curriculumComponentTitle }, "COMPONENTE CURRICULAR:"),
                React.createElement(Text, { style: styles.curriculumComponentName },
                    ' ',
                    props.information.discipline)),
            props.information.teacherName && (React.createElement(Text, { style: styles.teacherNameText },
                React.createElement(Text, { style: styles.infosEmphasizedText }, "PROFESSOR(A):"),
                React.createElement(Text, { style: styles.infosNormalText },
                    ' ',
                    props.information.teacherName)))),
        React.createElement(View, { style: styles.logoWrapper },
            React.createElement(Image, { style: styles.logo, source: SaintAugustineLogo }))));
};
export default Header;
