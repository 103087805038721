import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useGroupContext } from '../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import Activity from './components/Activity';
import AddActivityButton from './components/AddActivityButton';
import { StyledActivityButtonWrapper, StyledEmptyButtonSpace, StyledWrapper } from './styles';
const DidacticSequence = (props) => {
    const { reorderActivities, sequences } = useGroupContext((store) => ({
        sequences: store.group.didacticSequence,
        reorderActivities: store.sequenceOperations.reorder
    }));
    const isPlanningDeleted = props.planning?.status?.isDeleted() ?? false;
    const isPlanningFinished = props.planning?.status?.isFinished() ?? false;
    const isPlanningFinishedOrDeleted = isPlanningDeleted || isPlanningFinished;
    function handleSequenceDrag(result) {
        if (!result.destination)
            return;
        const { source, destination } = result;
        reorderActivities(source.index, destination.index);
    }
    function renderActivities() {
        return sequences?.map((sequence, index) => (React.createElement(Activity, { key: sequence.id, sequence: sequence, sequenceIndex: index, planning: props.planning })));
    }
    return (React.createElement(StyledWrapper, null,
        isPlanningFinishedOrDeleted ? (renderActivities()) : (React.createElement(DragDropContext, { onDragEnd: handleSequenceDrag },
            React.createElement(Droppable, { droppableId: 'didactic-sequence' }, (provided) => (React.createElement("div", { ref: provided.innerRef, ...provided.droppableProps },
                renderActivities(),
                provided.placeholder))))),
        isPlanningFinishedOrDeleted ? (React.createElement(StyledEmptyButtonSpace, null)) : (React.createElement(StyledActivityButtonWrapper, null,
            React.createElement(AddActivityButton, { planning: props.planning })))));
};
export default DidacticSequence;
