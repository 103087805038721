import styled from 'styled-components';
export const StyledBodyWrapper = styled.main `
  flex: 1;

  min-height: 0;

  padding: 21px;

  @media (max-height: 840px) {
    padding: 15px 21px;
  }

  @media (max-height: 600px) {
    padding: 10px 21px;
  }
`;
export const StyledContentWrapper = styled.div `
  height: 100%;

  display: flex;
  flex-direction: column;

  background-color: ${(props) => props.theme.colors.white};

  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 4px;

  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);
`;
