import React from 'react';
import { Input } from '@campus/components';
import { ContainerSkillCode, StyledTitle } from './styles';
import useSkillCodeFilter from './hooks/useSkillCodeFilter';
const StageFilter = () => {
    const { handleInputChange } = useSkillCodeFilter();
    return (React.createElement(ContainerSkillCode, null,
        React.createElement(StyledTitle, null, "Buscar por c\u00F3digo:"),
        React.createElement(Input, { name: "skill-code-filter", height: 40, size: "sm", placeholder: "Digite o c\u00F3digo...", onChange: handleInputChange })));
};
export default StageFilter;
