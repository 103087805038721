import styled from 'styled-components';
export const StyledText = styled.p `
  margin: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  line-height: 100%;

  color: ${(props) => props.theme.colors.gray700};
`;
