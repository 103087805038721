import { useEffect, useCallback } from 'react';
import { useAddSkillPopupContext } from '../../../../../../context';
import { getAreaSkillsGrouped } from '../../../../../../../../../../../services/areaSkill';
import { useFetchTableContentCommon } from '../../../../common/components/Table/hooks/useFetchTableContentCommon';
const pageSize = 40;
export function useFetchTableContent() {
    const { selectedGrade, selectedSegment, selectedDisciplines, filteredGradeId, filteredStageId, filteredSegmentId, filteredDisciplineId } = useAddSkillPopupContext((store) => ({
        selectedGrade: store.selectedGrade,
        selectedSegment: store.selectedSegment,
        selectedDisciplines: store.selectedDisciplines,
        filteredGradeId: store.filters.filteredGradeId,
        filteredStageId: store.filters.filteredStageId,
        filteredSegmentId: store.filters.filteredSegmentId,
        filteredDisciplineId: store.filters.filteredDisciplineId
    }));
    const { content, requestStatus, pageIndex, handleFetchFailed, handleFetchStarted, handleFetchSucceeded, canFetchMoreContent, advancePageIndex, resetContentAndPageIndex, resetStatusAfterDelay } = useFetchTableContentCommon();
    const fetchSkills = useCallback((abortSignal) => {
        if (!selectedGrade)
            return;
        if (!selectedSegment)
            return;
        if (!selectedDisciplines)
            return;
        if (selectedDisciplines.length === 0)
            return;
        handleFetchStarted();
        getAreaSkillsGrouped({
            abortSignal,
            pageSize,
            pageIndex: pageIndex(),
            gradeId: filteredGradeId,
            stageId: filteredStageId,
            segmentId: filteredSegmentId,
            disciplineId: filteredDisciplineId
        })
            .then(handleFetchSucceeded)
            .catch(handleFetchFailed)
            .finally(resetStatusAfterDelay);
    }, [
        selectedGrade,
        selectedSegment,
        selectedDisciplines,
        filteredGradeId,
        filteredStageId,
        filteredSegmentId,
        filteredDisciplineId,
        pageIndex,
        handleFetchFailed,
        handleFetchStarted,
        handleFetchSucceeded,
        resetStatusAfterDelay
    ]);
    const fetchMoreSkills = useCallback((abortSignal) => {
        if (canFetchMoreContent()) {
            advancePageIndex();
            fetchSkills(abortSignal);
        }
    }, [fetchSkills, advancePageIndex, canFetchMoreContent]);
    useEffect(() => {
        resetContentAndPageIndex();
        const abortController = new AbortController();
        fetchSkills(abortController.signal);
        return () => abortController.abort();
    }, [fetchSkills, resetContentAndPageIndex]);
    return { data: content, requestStatus, fetchMoreSkills };
}
