import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { toBrazilianDateString } from '../../../../../../../pages/common/utils/dates';
import { createFormatedTimeFrom } from './dates';
import { margins } from '../../sizes';
import styles from './styles';
const Footer = (props) => {
    const today = new Date();
    const generationTime = createFormatedTimeFrom(today);
    const generationDate = toBrazilianDateString(today);
    const marginTop = props.showEvaluationSection
        ? margins.footer.top.withEvaluationSection
        : margins.footer.top.withoutEvaluationSection;
    const marginBottom = props.showEvaluationSection
        ? margins.footer.bottom.withEvaluationSection
        : margins.footer.bottom.withoutEvaluationSection;
    return (React.createElement(View, { fixed: true, style: { ...styles.wrapper, marginTop, marginBottom } },
        React.createElement(View, { style: styles.pageAndDatesWrapper },
            React.createElement(Text, { style: styles.text, render: (textProps) => `Página ${textProps.pageNumber} de ${textProps.totalPages}` }),
            props.lastModificationDate ? (React.createElement(Text, { style: styles.text },
                "\u00DAltima atualiza\u00E7\u00E3o do planejamento: ",
                props.lastModificationDate,
                " - Documento gerado no dia ",
                generationDate,
                ", \u00E0s ",
                generationTime)) : (React.createElement(Text, { style: styles.text },
                "Documento gerado no dia ",
                generationDate,
                ", \u00E0s ",
                generationTime)))));
};
export default Footer;
