import React, { useState } from 'react';
import AddSkillButton from './components/AddSkillButton';
import { StyledCode, StyledDescription, SpanHighlighted, StyledWrapper } from './styles';
const Skill = (props) => {
    const [canShowButton, setCanShowButton] = useState(false);
    const showButton = () => setCanShowButton(true);
    const hideButton = () => setCanShowButton(false);
    const areaSkill = props.infos.skill;
    const code = areaSkill?.code || '';
    const highlightedCodeText = props.highlightedCodeText?.toUpperCase() || '';
    const matchedCode = code.split(new RegExp(`(${highlightedCodeText})`, 'gi'));
    return (React.createElement(StyledWrapper, { onMouseEnter: showButton, onMouseLeave: hideButton },
        React.createElement(StyledCode, { "$color": props.discipline?.color },
            "(",
            matchedCode.map((part) => (React.createElement(React.Fragment, null, part === highlightedCodeText ? (React.createElement(SpanHighlighted, null, part)) : (part)))),
            ")"),
        React.createElement(StyledDescription, null, areaSkill?.description),
        React.createElement(AddSkillButton, { canBeDisplayed: canShowButton, infos: props.infos })));
};
export default Skill;
