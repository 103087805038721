import PedagogicalPlanning from '../../../../../../../../../../../../models/PedagogicalPlanning';
import PedagogicalPlanningObjective from '../../../../../../../../../../../../models/PedagogicalPlanningObjective';
export function createUpdatedPlanning(params) {
    const newPlanning = new PedagogicalPlanning();
    Object.assign(newPlanning, params.planning);
    const newObjectives = [...newPlanning.objectives];
    const index = newObjectives.findIndex((objective) => {
        return objective.id === params.objective.id;
    });
    const newObjective = new PedagogicalPlanningObjective();
    Object.assign(newObjective, newObjectives[index]);
    newObjective.wasProgrammed = params.programmed;
    newObjectives[index] = newObjective;
    newPlanning.objectives = newObjectives;
    return newPlanning;
}
