import { useEffect } from 'react';
import { useFetch } from '@campus/commons';
import { getGrades } from '../../../../../services/launchReport';
import { useLaunchReportContext } from '../../../context';
export function useFetchGrades() {
    const { managerId } = useLaunchReportContext();
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess } = useFetch({
        initialData: null
    });
    useEffect(() => {
        async function fetchData() {
            try {
                fetchStarted();
                const response = await getGrades({
                    managerId
                });
                handleSuccess(response.data);
            }
            catch (error) {
                handleFailure(error);
            }
        }
        fetchData();
    }, [fetchStarted, handleFailure, handleSuccess, managerId]);
    return { data, requestStatus };
}
