export function createHeaders(creators, params) {
    const headers = [];
    for (const columnName in creators) {
        headers.push(creators[columnName](params));
    }
    return headers;
}
export function createRow(creators, params) {
    const columns = [];
    for (const columnName in creators) {
        columns.push(creators[columnName](params));
    }
    return columns;
}
