import React from 'react';
import { ReactComponent as TeacherIcon } from '@campus/components/assets/icons/alert-icon.svg';

import {
  ContentDeleteTrailBody,
  LabelDeleteTrail,
  SubLabelDeleteTrail
} from './styles';

const DescriptionResetGrades: React.FC = () => (
  <ContentDeleteTrailBody>
    <TeacherIcon />
    <LabelDeleteTrail>
      Você tem certeza que deseja <b>resetar a entrega do(s) aluno(s)</b>?{' '}
    </LabelDeleteTrail>
    <SubLabelDeleteTrail>
      Lembre-se que esta ação é{' '}
      <b>irreversível e irá apagar as respostas do(s) aluno(s)</b>.
    </SubLabelDeleteTrail>
  </ContentDeleteTrailBody>
);

export default DescriptionResetGrades;
