import PedagogicalPlanningGroup from '../../../../../../models/PedagogicalPlanningGroup';
import { addItem, getNewItemIndexIfItMoves, isIndexValid, reorderItemsInPlace, swapItemsInPlace } from '../../../../../common/utils/arrays';
import { updateContentInStore, updateIndicesOfGroupsAndTheirPlannings } from './helpers';
export function createOperationsSliceCreator(events) {
    return (set, get) => {
        return {
            getGroupFromNonFilteredContent(groupNumber) {
                const content = get().mainTable.nonFilteredContent;
                return content.find((g) => g.number === groupNumber);
            },
            add(planning, atIndex) {
                set((store) => {
                    const oldContent = store.mainTable.nonFilteredContent;
                    const newContent = [...oldContent];
                    const groupIndex = newContent.findIndex((group) => group.number === planning.associatedGroup);
                    const newGroup = new PedagogicalPlanningGroup();
                    if (groupIndex === -1) {
                        newGroup.plannings = [planning];
                        newGroup.number = planning.associatedGroup;
                        newGroup.didacticSequence = planning.didaticSequences;
                        newContent.push(newGroup);
                    }
                    else {
                        const oldGroup = newContent[groupIndex];
                        Object.assign(newGroup, oldGroup);
                        let allPlannings = oldContent.flatMap((group) => group.plannings);
                        allPlannings = addItem(planning, allPlannings, atIndex);
                        newGroup.plannings = allPlannings.filter((p) => p.associatedGroup === oldGroup.number);
                        newContent[groupIndex] = newGroup;
                    }
                    return updateContentInStore(store, newContent);
                });
                events.contentChangedAfterOperation.emit();
            },
            updateGroup(updatedGroup) {
                if (!updatedGroup)
                    return;
                set((store) => {
                    const firstPlanning = updatedGroup.plannings?.at(0);
                    const content = store.mainTable.nonFilteredContent;
                    const index = content.findIndex((group) => group.number === firstPlanning?.associatedGroup);
                    if (index === -1)
                        return store;
                    const oldGroup = content[index];
                    if (oldGroup.number !== updatedGroup.number)
                        return store;
                    const newContent = [...content];
                    newContent[index] = updatedGroup;
                    return updateContentInStore(store, newContent);
                });
                events.contentChangedAfterOperation.emit();
            },
            updatePlanning(updatedPlanning) {
                set((store) => {
                    const oldContent = store.mainTable.nonFilteredContent;
                    const groupIndex = oldContent.findIndex((group) => group.number === updatedPlanning.associatedGroup);
                    if (groupIndex === -1)
                        return store;
                    const newContent = [...oldContent];
                    const oldGroup = newContent[groupIndex];
                    const newGroup = new PedagogicalPlanningGroup();
                    Object.assign(newGroup, oldGroup);
                    const planningIndex = oldGroup.plannings.findIndex((p) => p.id === updatedPlanning.id);
                    newGroup.plannings = [...oldGroup.plannings];
                    newGroup.plannings[planningIndex] = updatedPlanning;
                    newContent[groupIndex] = newGroup;
                    return updateContentInStore(store, newContent);
                });
                events.contentChangedAfterOperation.emit();
            },
            delete(planning) {
                set((store) => {
                    const oldContent = store.mainTable.nonFilteredContent;
                    const groupIndex = oldContent.findIndex((group) => group.number === planning.associatedGroup);
                    if (groupIndex === -1)
                        return store;
                    let newContent = [...oldContent];
                    const oldGroup = newContent[groupIndex];
                    if (oldGroup.plannings.length === 1) {
                        newContent = newContent.filter((group) => {
                            return group.number !== oldGroup.number;
                        });
                    }
                    else {
                        const newGroup = new PedagogicalPlanningGroup();
                        Object.assign(newGroup, oldGroup);
                        newGroup.plannings = oldGroup.plannings.filter((p) => {
                            return p.id !== planning.id;
                        });
                        newContent[groupIndex] = newGroup;
                    }
                    return updateContentInStore(store, newContent);
                });
                events.contentChangedAfterOperation.emit();
            },
            move(planning, direction) {
                set((store) => {
                    const newContent = [...store.mainTable.nonFilteredContent];
                    const groupIndex = newContent.findIndex((g) => {
                        return g.number === planning.associatedGroup;
                    });
                    const newGroupIndex = getNewItemIndexIfItMoves(newContent, groupIndex, direction);
                    swapItemsInPlace(newContent, groupIndex, newGroupIndex);
                    updateIndicesOfGroupsAndTheirPlannings(newContent);
                    return updateContentInStore(store, newContent);
                });
                events.contentChangedAfterOperation.emit();
            },
            reorder(firstGroupIndex, secondGroupIndex) {
                set((store) => {
                    if (firstGroupIndex === secondGroupIndex)
                        return store;
                    const oldContent = store.mainTable.nonFilteredContent;
                    if (!isIndexValid(firstGroupIndex, oldContent))
                        return store;
                    if (!isIndexValid(secondGroupIndex, oldContent))
                        return store;
                    const newContent = [...oldContent];
                    reorderItemsInPlace(newContent, firstGroupIndex, secondGroupIndex);
                    updateIndicesOfGroupsAndTheirPlannings(newContent);
                    return updateContentInStore(store, newContent);
                });
                events.contentChangedAfterOperation.emit();
            },
            separateFromGroup(planning) {
                set((store) => {
                    const oldContent = store.mainTable.nonFilteredContent;
                    const groupIndex = oldContent.findIndex((group) => group.number === planning.associatedGroup);
                    if (groupIndex === -1)
                        return store;
                    const oldGroup = oldContent[groupIndex];
                    if (oldGroup.plannings.length === 1)
                        return store;
                    const oldGroupUpdated = new PedagogicalPlanningGroup();
                    Object.assign(oldGroupUpdated, oldGroup);
                    oldGroupUpdated.plannings = oldGroup.plannings.filter((p) => p.id !== planning.id);
                    const newGroup = new PedagogicalPlanningGroup();
                    newGroup.plannings = [planning];
                    newGroup.didacticSequence = [];
                    let newContent = [...oldContent];
                    newContent[groupIndex] = oldGroupUpdated;
                    newContent = addItem(newGroup, newContent, groupIndex + 1);
                    updateIndicesOfGroupsAndTheirPlannings(newContent);
                    return updateContentInStore(store, newContent);
                });
                events.contentChangedAfterOperation.emit();
            }
        };
    };
}
