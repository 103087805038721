import React, { useEffect, useState } from 'react';
import { useCalendarContext } from '../../../../../../../../context';
import Circle from './components/Circle';
import { calculateDistanceFromTop, calculateDistanceFromLeft } from './helpers/distances';
import { StyledWrapper } from './styles';
const Rectangle = (props) => {
    const [left, setLeft] = useState(-1);
    const months = useCalendarContext((store) => store.months);
    useEffect(() => {
        if (!props.startDate)
            return;
        if (!props.parentElement)
            return;
        const left = calculateDistanceFromLeft(props.startDate, months, props.parentElement);
        if (left >= 0) {
            setLeft(left);
        }
    }, [props.startDate, props.parentElement, months]);
    if (!props.startDate)
        return null;
    if (!props.groupInfos)
        return null;
    if (!props.activityInfos)
        return null;
    if (!props.parentElement)
        return null;
    if (left < 0)
        return null;
    const top = calculateDistanceFromTop(props.groupInfos, props.activityInfos);
    if (top < 0)
        return null;
    const classBoxRect = props.activityInfos?.getClassBoxBoundingClientRect();
    return (React.createElement(StyledWrapper, { "$top": top, "$left": left, "$borderColor": props.border?.color, "$useDashedBorder": props.border?.dashed, "$backgroundColor": props.backgroundColor, "$height": classBoxRect?.height ?? 0 }, props.circleColor ? React.createElement(Circle, { color: props.circleColor }) : null));
};
export default Rectangle;
