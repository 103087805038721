import styled from 'styled-components';
export const StyledWrapper = styled.p `
  margin: 0;

  line-height: 18px;

  font-size: 14px;
  font-weight: ${(props) => (props.$isEvaluative ? 700 : 500)};
`;
export const StyledCode = styled.span `
  color: ${(props) => props.$color};
`;
export const StyledDescription = styled.span `
  color: ${(props) => props.theme.colors.gray700};

  ${(props) => {
    if (!props.$addLineThrough)
        return;
    return 'text-decoration-line: line-through;';
}}
`;
