import React from 'react';
import { getColumnDefinition } from '../../../../../../../helpers/columns';
import { generateClassName } from './helpers';
import { StyledWrapper } from './styles';
const Cell = (props) => {
    const columnDefinition = getColumnDefinition({
        allColumns: props.allColumns,
        columnInstance: props.cellInfos.column
    });
    const spanAcrossAllRows = columnDefinition?.spanAcrossAllRows;
    if (spanAcrossAllRows && !props.isInTheFirstRowOfTheGroup) {
        return null;
    }
    const rowSpan = spanAcrossAllRows && props.numberOfPlanningsInGroup > 0
        ? props.numberOfPlanningsInGroup
        : 1;
    const width = Number(columnDefinition?.width);
    const cellProps = props.cellInfos.getCellProps();
    cellProps.style.display = 'table-cell';
    const className = generateClassName(columnDefinition);
    return (React.createElement(StyledWrapper, { ...cellProps, className: className, rowSpan: rowSpan, "$width": width }, props.cellInfos.render('Cell', {
        draggableProvided: props.draggableProvided
    })));
};
export default Cell;
