import LearningObjective from './LearningObjective';
export default class PedagogicalPlanningObjective {
    id;
    wasProgrammed;
    pedagogicalPlanningId;
    learningObjective;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new PedagogicalPlanningObjective();
        instance.id = json.Id;
        instance.wasProgrammed = json.Programmed;
        instance.pedagogicalPlanningId = json.PedagogicalPlanningId;
        instance.learningObjective = LearningObjective.fromJson(json.LearningObjective);
        return instance;
    }
}
