import Classroom from './Classroom';
export default class ClassroomAndDidacticSequence {
    id;
    foreseen;
    wasExecuted;
    classroom;
    executedClassroom;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new ClassroomAndDidacticSequence();
        instance.id = json.Id;
        instance.foreseen = json.Foreseen;
        instance.wasExecuted = json.Executed;
        instance.classroom = Classroom.fromJson(json.Classroom);
        instance.executedClassroom = Classroom.fromJson(json.ExecutedClassroom);
        return instance;
    }
}
