import React, { useRef } from 'react';
import Item from './components/Item';
import { useExternalClick } from '../../../hooks';
import { combineRefs } from '../../../utils/refs';
import { StyledTitle, StyledNoOptionsMessage, StyledWrapper, StyledContentWrapper } from './styles';
const ClassroomSelectorMenu = React.forwardRef((props, ref) => {
    const wrapperRef = useRef(null);
    const combinedRefs = combineRefs(wrapperRef, ref);
    useExternalClick(wrapperRef, props.handleExternalClick);
    function renderOptions() {
        return props.options.map((option) => {
            const isSelected = !!props.selectedOption && option.value === props.selectedOption.value;
            return (React.createElement(Item, { key: option.value, option: option, isSelected: isSelected, onClick: props.handleOptionClicked }));
        });
    }
    function renderContent() {
        if (props.options?.length > 0) {
            return React.createElement(StyledContentWrapper, null, renderOptions());
        }
        return (React.createElement(StyledNoOptionsMessage, null, "Nenhuma op\u00E7\u00E3o dispon\u00EDvel"));
    }
    return props.canBeDisplayed ? (React.createElement(StyledWrapper, { ref: combinedRefs, className: props.className },
        React.createElement(StyledTitle, null, "Selecione a Aula"),
        renderContent())) : null;
});
export default ClassroomSelectorMenu;
