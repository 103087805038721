import { getMainClassroomAndSequenceFrom } from '../../../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/common/helpers/sequenceRelated';
export function shouldBorderBeDashed(sequence) {
    return sequence?.isDeleted ?? false;
}
export function shouldBoxBeDisabled(sequence, planning, userPermissions) {
    const isSequenceDeleted = sequence?.isDeleted ?? false;
    const isPlanningDeleted = planning?.status?.isDeleted() ?? false;
    const userNotAllowedToUpdate = !userPermissions?.userCan.updateTheTeacherColumns();
    return userNotAllowedToUpdate || isPlanningDeleted || isSequenceDeleted;
}
export function getText(sequence) {
    const classrooms = sequence?.classrooms;
    const main = getMainClassroomAndSequenceFrom(classrooms);
    const executed = main?.executedClassroom?.number;
    const planned = main?.classroom?.number;
    const number = executed ?? planned;
    return number >= 0 ? `Aula ${number}` : '---';
}
