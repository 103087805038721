import React from 'react';
import TableItem from './components/TableItem';
import { StyledWrapper } from './styles';
const Table = (props) => {
    function renderItem(itemInfos, index) {
        const key = itemInfos.themeUnit?.id + index;
        const isTheLastItem = index === props.content.length - 1;
        if (!props.handleLastItemReached || !isTheLastItem) {
            return (React.createElement(TableItem, { key: key, infos: itemInfos, highlightedCodeText: props.highlightedCodeText }));
        }
        return (React.createElement(TableItem, { key: key, infos: itemInfos, handleLastItemReached: props.handleLastItemReached, highlightedCodeText: props.highlightedCodeText }));
    }
    function renderItems() {
        return props.content?.map((item, index) => {
            return renderItem(item, index);
        });
    }
    return (React.createElement(StyledWrapper, null,
        renderItems(),
        props.children));
};
export default Table;
