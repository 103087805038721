import { copyDateWithoutTimePortion } from '../../../../../common/utils/dates';
export default class WeeksGenerator {
    year;
    currentDate;
    constructor(year) {
        if (year < 1970) {
            throw new Error('Invalid year. Must be greater than 1970');
        }
        this.year = year;
    }
    isCurrentDateInWeekend() {
        const dayOfWeek = this.currentDate.getDay();
        return dayOfWeek === 6 || dayOfWeek === 0;
    }
    initCurrentDate(monthNumber) {
        this.currentDate = new Date(this.year, monthNumber);
        while (this.isCurrentDateInWeekend()) {
            this.currentDate.setDate(this.currentDate.getDate() + 1);
        }
    }
    advanceCurrentDateToTheDayBeforeNextWeekend() {
        const initialMonth = this.currentDate.getMonth();
        let currentMonth = initialMonth;
        while (currentMonth === initialMonth && !this.isCurrentDateInWeekend()) {
            this.currentDate.setDate(this.currentDate.getDate() + 1);
            currentMonth = this.currentDate.getMonth();
        }
        this.currentDate.setDate(this.currentDate.getDate() - 1);
    }
    advanceCurrentDateIn3Days() {
        this.currentDate.setDate(this.currentDate.getDate() + 3);
    }
    createWeekFromCurrentDateAndAdvanceIt() {
        const startDate = copyDateWithoutTimePortion(this.currentDate);
        this.advanceCurrentDateToTheDayBeforeNextWeekend();
        const endDate = copyDateWithoutTimePortion(this.currentDate);
        const monthNumber = this.currentDate.getMonth();
        return { startDate, endDate, monthNumber };
    }
    generate(monthNumber) {
        const weeks = [];
        this.initCurrentDate(monthNumber);
        do {
            weeks.push(this.createWeekFromCurrentDateAndAdvanceIt());
            this.advanceCurrentDateIn3Days();
        } while (this.currentDate.getMonth() === monthNumber);
        return weeks;
    }
}
