import React, { useMemo } from 'react';
import { GroupContext } from '.';
import { createGroupStore } from '../store';
export const Provider = (props) => {
    const store = useMemo(() => {
        return createGroupStore({
            groupFromFilteredContent: props.groupFromFilteredContent,
            groupFromNonFilteredContent: props.groupFromNonFilteredContent,
            updateGroupOnMainTable: props.updateGroupOnMainTable
        });
    }, [
        props.groupFromFilteredContent,
        props.groupFromNonFilteredContent,
        props.updateGroupOnMainTable
    ]);
    return (React.createElement(GroupContext.Provider, { value: store }, props.children));
};
