import { useEffect } from 'react';
export function useResize(elementOrRef, handler) {
    useEffect(() => {
        if (!elementOrRef)
            return;
        const element = 'current' in elementOrRef
            ? elementOrRef.current
            : elementOrRef;
        if (!element)
            return;
        const observer = new ResizeObserver(handler);
        observer.observe(element, { box: 'border-box' });
        return () => observer.disconnect();
    }, [elementOrRef, handler]);
}
