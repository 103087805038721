import React from 'react';
import { StyledDescription, StyledIconWrapper, StyledHighlightedText, StyledMessageWrapper, StyledTitle } from './styles';
const TablePlaceholder = (props) => {
    const { content } = props;
    return (React.createElement(StyledMessageWrapper, null,
        content.icon ? (React.createElement(StyledIconWrapper, null, content.icon)) : null,
        React.createElement(StyledTitle, null, content.title),
        React.createElement(StyledDescription, null,
            content.description,
            content.highlightedText ? (React.createElement(StyledHighlightedText, null, content.highlightedText)) : null)));
};
export default TablePlaceholder;
