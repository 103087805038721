import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import StudentAnswerHistory from 'models/StudentAnswerHistory';

import { getStudentAnswerHistory, changeAnswerPrimary } from 'services/answers';

interface IStudentAnswerHistory {
  history: StudentAnswerHistory[] | [];
  isLoading: boolean;
  error?: any;
}

const dfStateStudent: IStudentAnswerHistory = {
  isLoading: true,
  error: null,
  history: []
};

const useStudentAnswerHistory = (studentId?: string) => {
  const [{ history, error, isLoading }, setState] =
    useState<IStudentAnswerHistory>(dfStateStudent);

  const { contentId } = useParams();

  const fetchData = useCallback(
    async (studentId: string) => {
      setState(dfStateStudent);
      try {
        const response = await getStudentAnswerHistory(contentId, studentId);

        setState({ history: response.data, isLoading: false });
      } catch (error) {
        setState({ history: null, error: error, isLoading: false });
      }
    },
    [contentId]
  );

  const updateData = useCallback(
    async (studentId, row) =>
      await changeAnswerPrimary(contentId, studentId, row.id),
    [contentId]
  );

  useEffect(() => {
    if (studentId) fetchData(studentId);
  }, [fetchData, studentId]);

  return { isLoading, history, error, fetchData, updateData };
};

export default useStudentAnswerHistory;
