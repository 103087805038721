import React, { useCallback, useRef, useState } from 'react';
import { useIsMounted } from '../../../../../../../../../hooks';
import { debounce } from '../../../../../../../../../utils/debounce';
import { StyledWrapper, StyledTextArea } from './styles';
const changeDebounceDelay = 500;
const updateDebounceDelay = 3000;
const TextArea = (props) => {
    const [isUpdating, setIsUpdating] = useState(false);
    const [value, setValue] = useState(props.initialValue);
    const areTherePendingChangesRef = useRef(false);
    const { isMounted } = useIsMounted();
    const update = useCallback((content) => {
        if (!areTherePendingChangesRef.current)
            return;
        if (isMounted()) {
            setIsUpdating(true);
        }
        props
            .updateContent(content)
            .then(() => {
            areTherePendingChangesRef.current = false;
        })
            .finally(() => {
            if (isMounted()) {
                setIsUpdating(false);
            }
        });
    }, [props.updateContent]);
    const debouncedOnChange = useCallback(debounce(props.onChange, changeDebounceDelay), [props.onChange]);
    const debouncedUpdate = useCallback(debounce(update, updateDebounceDelay), [
        update
    ]);
    function onBlur(event) {
        if (props.readOnly)
            return;
        update(event.target.value);
    }
    function onChange(event) {
        if (props.readOnly)
            return;
        areTherePendingChangesRef.current = true;
        setValue(event.target.value);
        debouncedOnChange(event.target.value);
        debouncedUpdate(event.target.value);
    }
    return (React.createElement(StyledWrapper, null,
        React.createElement(StyledTextArea, { value: value ?? '', disabled: props.readOnly, placeholder: props.placeholder, onBlur: onBlur, onChange: onChange, "$useBlueBorder": isUpdating })));
};
export default TextArea;
