import styled from 'styled-components';
import ClassBox from '../../../../../ClassBox';
export const StyledWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const StyledBox = styled(ClassBox) `
  cursor: pointer;
`;
