import React from 'react';
import Object from './components/Object';
import { StyledWrapper } from '../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/KnowledgeObjects/styles';
const KnowledgeObjects = (props) => {
    function renderObjects() {
        const sorted = props.objects.sort((a, b) => {
            const aDescription = a.knowledgeObject?.description;
            const bDescription = b.knowledgeObject?.description;
            return aDescription?.localeCompare(bDescription);
        });
        return sorted?.map((object) => React.createElement(Object, { key: object.id, object: object }));
    }
    return React.createElement(StyledWrapper, null, renderObjects());
};
export default KnowledgeObjects;
