import React from 'react';
import { useAddOrEditActivityPopupContext } from '../../../common/addOrEditActivity/context';
import { RequestStatus } from '../../../../../types/dataFetching';
import { useEditActivity } from './hooks/useEditActivity';
import { shouldButtonBeDisabled } from './helpers';
import { StyledBlueButton } from '../../../common/styles';
const statusesAndButtonContent = {
    [RequestStatus.NotStarted]: 'Editar Atividade',
    [RequestStatus.Loading]: 'Salvando alterações...',
    [RequestStatus.Succeeded]: 'Alterações salvas!',
    [RequestStatus.Failed]: 'Falha ao editar'
};
const EditActivityButton = (props) => {
    const { impactTheMDC, classesAndInfos, sequenceDescription, selectedEvaluationType, anImageIsBeingUploaded } = useAddOrEditActivityPopupContext((store) => ({
        impactTheMDC: store.impactTheMDC,
        classesAndInfos: store.classesAndInfos.data,
        sequenceDescription: store.sequenceDescription,
        selectedEvaluationType: store.selectedEvaluationType,
        anImageIsBeingUploaded: store.anImageIsBeingUploaded
    }));
    const { edit, requestStatus } = useEditActivity({
        handleActivityUpdated: props.handleActivityUpdated
    });
    function onClick() {
        edit(props.sequence);
    }
    const disabled = shouldButtonBeDisabled({
        impactTheMDC,
        classesAndInfos,
        anImageIsBeingUploaded,
        sequence: props.sequence,
        description: sequenceDescription,
        evaluationType: selectedEvaluationType
    });
    return (React.createElement(StyledBlueButton, { onClick: onClick, disabled: disabled }, statusesAndButtonContent[requestStatus]));
};
export default EditActivityButton;
