import React from 'react';
import { useAnnualTimelinePageContext } from '../../../../../../../../../context';
import { StageWithProgram } from '../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns';
const Stage = (props) => {
    const { stages, userPermissions, updateRow } = useAnnualTimelinePageContext((store) => ({
        stages: store.commonData.stages.data,
        userPermissions: store.commonData.userPermissions.data,
        updateRow: store.mainTable.operations.update
    }));
    const doNotAllowUpdates = !userPermissions?.userCan?.updateTheTeacherColumns();
    return (React.createElement(StageWithProgram, { allStages: stages, areaSkill: props.areaSkill, handleStageUpdated: updateRow, doNotAllowUpdates: doNotAllowUpdates }));
};
export default Stage;
