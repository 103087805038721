import { useState } from 'react';
import { useAddOrEditActivityPopupContext } from '../../../../common/addOrEditActivity/context';
import { useIsMounted } from '../../../../../../hooks';
import { RequestStatus } from '../../../../../../types/dataFetching';
import { times } from '../../../../../../constants';
import { updateDidacticSequenceAndItsClassrooms } from '../../../../../../../../services/agregators';
export function useEditActivity(props) {
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const { abortSignal, impactTheMDC, sequenceDescription, selectedEvaluationType, getAllSelectedClassrooms } = useAddOrEditActivityPopupContext((store) => ({
        abortSignal: store.abortSignal,
        impactTheMDC: store.impactTheMDC,
        sequenceDescription: store.sequenceDescription,
        selectedEvaluationType: store.selectedEvaluationType,
        getAllSelectedClassrooms: store.getAllSelectedClassrooms
    }));
    const { isMounted } = useIsMounted();
    function resetStatusAfterDelay() {
        setTimeout(() => {
            if (isMounted()) {
                setRequestStatus(RequestStatus.NotStarted);
            }
        }, times.toResetARequestStatus);
    }
    function handleUpdateSequenceSucceeded(response) {
        props.handleActivityUpdated(response.data);
        setRequestStatus(RequestStatus.Succeeded);
    }
    function handleUpdateSequenceFailed() {
        setRequestStatus(RequestStatus.Failed);
    }
    function edit(sequence) {
        setRequestStatus(RequestStatus.Loading);
        updateDidacticSequenceAndItsClassrooms({
            abortSignal,
            id: sequence.id,
            newIndex: sequence.order,
            impactTheMDC,
            description: sequenceDescription,
            isDeleted: sequence.isDeleted,
            oldClassrooms: sequence.classrooms,
            newClassrooms: getAllSelectedClassrooms(),
            evaluationTypeId: selectedEvaluationType?.id
        })
            .then(handleUpdateSequenceSucceeded)
            .catch(handleUpdateSequenceFailed)
            .finally(resetStatusAfterDelay);
    }
    return { edit, requestStatus };
}
