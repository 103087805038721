import { getDidaticSequenceById } from '../didaticSequence';
import { handleApiCallError } from '../common';
import { addClassroomAndDidacticSequence, deleteClassroomAndSequence } from '../classroomAndDidaticSequence';
function getNewSequenceClassrooms(params) {
    return params.allClassroomGroups
        .filter((group) => {
        return group.classrooms?.some((classroom) => {
            return params.sequence.classrooms.some((c) => {
                return classroom.id === c?.classroom?.id;
            });
        });
    })
        .flatMap((group) => {
        return group.classrooms?.filter((c) => {
            return c.number === params.classroomNumber;
        });
    });
}
function getCorrespondingClassroomAndDidacticSequence(params, classroom) {
    return params.sequence?.classrooms?.find((c) => {
        return c?.classroom?.id === classroom?.id;
    });
}
function addNewClassrooms(params) {
    const classrooms = getNewSequenceClassrooms(params);
    return classrooms.map((classroom) => {
        const classroomAndDidaticSequence = getCorrespondingClassroomAndDidacticSequence(params, classroom);
        return addClassroomAndDidacticSequence({
            executed: false,
            executedClassroomId: null,
            classroomId: classroom.id,
            sequenceId: params.sequence.id,
            abortSignal: params.abortSignal,
            foreseen: classroomAndDidaticSequence?.foreseen ?? true
        });
    });
}
function deleteSequenceClassrooms(params) {
    return params.sequence.classrooms.map((c) => {
        return deleteClassroomAndSequence({
            abortSignal: params.abortSignal,
            classroomAndSequenceId: c.id
        });
    });
}
export async function updateDidacticSequenceClassrooms(params) {
    try {
        await Promise.all(deleteSequenceClassrooms(params));
        await Promise.all(addNewClassrooms(params));
        return getDidaticSequenceById({
            id: params.sequence.id,
            abortSignal: params.abortSignal
        });
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
