import { updateDidaticSequence } from '../../../../../../../../../../../../../services/didaticSequence';
import { getMainClassroomAndSequenceFrom } from '../../../../../../Columns/common/helpers/sequenceRelated';
export function getSequenceIndex(sequences, sequenceId) {
    return sequences.findIndex((item) => {
        return item?.id === sequenceId;
    });
}
export async function updateSequenceIndex(sequence, newIndex) {
    await updateDidaticSequence({
        newIndex,
        id: sequence.id,
        isDeleted: sequence.isDeleted,
        description: sequence.description,
        impactTheMDC: sequence.impactTheMDC,
        evaluationTypeId: sequence.evaluationType?.id
    });
}
export function updateSequencesIndices(updated, oldValue) {
    return updated
        .map((item, index) => [item, index])
        .filter((_, index) => {
        const foundInOldValue = oldValue.at(index);
        return !foundInOldValue || updated.at(index)?.id !== foundInOldValue.id;
    })
        .map(([item, index]) => updateSequenceIndex(item, index));
}
export function sortSequences(sequences) {
    return (sequences?.sort((a, b) => {
        const aMainClassroom = getMainClassroomAndSequenceFrom(a?.classrooms);
        const bMainClassroom = getMainClassroomAndSequenceFrom(b?.classrooms);
        const aMainClassroomNumber = aMainClassroom?.classroom?.number;
        const bMainClassroomNumber = bMainClassroom?.classroom?.number;
        return aMainClassroomNumber - bMainClassroomNumber;
    }) ?? []);
}
