import styled from 'styled-components';
export const StyledWrapper = styled.div `
  margin-top: 10px;

  position: relative;

  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
