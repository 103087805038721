import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback
} from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  RCharts,
  ChartData,
  ChartOptions,
  ChartArea
} from '@campus/components/charts';
import { PerformanceStatus } from '@campus/commons';

import Score from 'models/Score';
import { RowContent, StyledCard } from '../styles';
import { useTheme } from 'styled-components';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

type PerformaceGraphProps = {
  scores: Score[];
};

const PerformaceGraph: React.FC<PerformaceGraphProps> = ({ scores }) => {
  const chartRef = useRef<ChartJS<'bar'>>(null);
  const { colors } = useTheme();

  const [chartData, setChartData] = useState<ChartData<'bar'>>({
    datasets: []
  });

  const createGradient = useCallback(
    (ctx: CanvasRenderingContext2D, area: ChartArea, score: Score) => {
      const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

      switch (score.performanceStatus) {
        case PerformanceStatus.LOW:
          gradient.addColorStop(0, colors.darkPink);
          gradient.addColorStop(1, '#FF6A00');
          break;
        case PerformanceStatus.HIGH:
        case PerformanceStatus.REGULAR:
          gradient.addColorStop(0, colors.CSA.green);
          gradient.addColorStop(1, colors.CSA.cyan);
          break;
      }

      return gradient;
    },
    [colors]
  );

  const handleChartData = useCallback(() => {
    const chart = chartRef.current;

    const chartData = {
      labels: scores.map((s) => s.text.replace(/\s/, '')),
      datasets: [
        {
          data: scores.map((s) => s.count),
          borderRadius: 2,
          backgroundColor: scores.map((score) =>
            createGradient(chart.ctx, chart.chartArea, score)
          )
        }
      ]
    };

    setChartData(chartData);
  }, [createGradient, scores]);

  useEffect(() => {
    const chart = chartRef.current;

    if (chart) {
      handleChartData();
    }
  }, [handleChartData]);

  const options = useMemo<ChartOptions<'bar'>>(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: { legend: { display: false } },
      scales: {
        x: {
          grid: { display: false },
          ticks: {
            font: { family: 'Gilroy', size: 12, weight: '500' },
            color: '#999999',
            padding: 0
          }
        },
        y: { display: false }
      },
      layout: {
        padding: {
          bottom: 0,
          top: 0,
        }
      }
    }),
    []
  );

  return (
    <RowContent $marginTop={37}>
      <h1>Distribuição de Desempenho</h1>
      <h2>
        Entenda como está o desempenho dos estudantes distribuídas por faixas.
        Barras verdes maiores, melhor desempenho da turma, barras laranjas
        maiores, menor desempenho.
      </h2>
      <RowContent $marginTop={12}>
        <StyledCard $height={120}>
          {<RCharts.Bar ref={chartRef} options={options} data={chartData} />}
        </StyledCard>
      </RowContent>
    </RowContent>
  );
};

export default PerformaceGraph;
