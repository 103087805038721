import { ListFileUploaded } from '@campus/commons';
import { ContentEditor } from '@campus/components';
import React from 'react';

import { useActivityReport } from '../../context/ActivityReportContext';
import { useQuestionReview } from '../../context/QuestionReviewContext';
import Avatar from '@campus/components/assets/icons/avatarGray.svg';
import { capitalizeFirstLetter } from 'services/student';

import {
  Content,
  Answer,
  Tile,
  ProfileImage,
  LinkButton,
  StyledSpan,
  StyledStudentName
} from './styles';

const QuestionAnswer: React.FC = () => {
  const { selectedStudent } = useActivityReport();
  const { answer } = useQuestionReview();

  const student = selectedStudent.student;
  const answerText = answer?.getText();

  const answerUrl = answer?.answer?.url;
  const answerFiles = answer?.answer?.files;

  return (
    <>
      <Content>
        <ProfileImage
          roundedCircle
          src={student.imageUrl ?? Avatar}
          $hasImage={!!student.imageUrl}
        />
        <Tile>
          <StyledSpan>{answerText ? 'Respondido por' : 'Aluno:'}</StyledSpan>
          <StyledStudentName>
            {capitalizeFirstLetter(student.name)}
          </StyledStudentName>
        </Tile>
      </Content>

      <Answer>
        {answerText ? (
          <ContentEditor readOnly content={answerText} />
        ) : (
          'Sem resposta'
        )}
      </Answer>
      {answerUrl && (
        <LinkButton href={answerUrl} download>
          Download: {answer?.answer?.fileName}
        </LinkButton>
      )}

      {answerFiles && <ListFileUploaded files={answerFiles} readOnly />}
    </>
  );
};

export default QuestionAnswer;
