export default class ThemeUnit {
    id;
    name;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new ThemeUnit();
        instance.id = json.Id;
        instance.name = json.Name;
        return instance;
    }
}
