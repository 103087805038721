import { PerformanceStatus } from '@campus/commons';

class Score {
  count?: number;

  text?: string;

  performanceStatus?: PerformanceStatus;

  static fromJson(data: any): Score {
    const score = new Score();

    score.count = data.Count;

    score.text = data.Text;

    score.performanceStatus = data.PerformanceStatus;

    return score;
  }
}

export default Score;
