import { serializeDisciplines, keys, setOnStorage } from './storage';
import { setupEventHandlers } from './eventHandlers';
import { tryFillAvailableDisciplines, tryFillAvailableGrades } from './availableComponents';
export function updateSlice(store, updates) {
    const oldSlice = store.curriculumComponents;
    const updated = { ...oldSlice, ...updates };
    return { ...store, curriculumComponents: updated };
}
export function createCurriculumComponentsSlice(commonDataEvents) {
    return (set, _, api) => {
        setupEventHandlers(api, commonDataEvents);
        return {
            availableGrades: [],
            availableSegments: [],
            availableDisciplines: [],
            selectedGrade: null,
            selectedSegment: null,
            selectedDisciplines: [],
            selectedGradeChanged(gradeId) {
                set((store) => {
                    setOnStorage(keys.grade, gradeId);
                    setOnStorage(keys.disciplines, null);
                    const grades = store.commonData.grades.data;
                    const selectedGrade = grades?.find((g) => g.id === gradeId);
                    return updateSlice(store, {
                        selectedGrade,
                        selectedDisciplines: []
                    });
                });
                tryFillAvailableDisciplines(api);
            },
            selectedSegmentChanged(segmentId) {
                set((store) => {
                    setOnStorage(keys.grade, null);
                    setOnStorage(keys.disciplines, null);
                    setOnStorage(keys.segment, segmentId);
                    const segments = store.commonData.segments.data;
                    const selectedSegment = segments?.find((s) => s.id === segmentId);
                    return updateSlice(store, {
                        selectedSegment,
                        selectedGrade: null,
                        selectedDisciplines: []
                    });
                });
                tryFillAvailableGrades(api);
            },
            addToSelectedDisciplines(disciplineId) {
                set((store) => {
                    let disciplines = store.commonData.disciplines.data;
                    const discipline = disciplines?.find((d) => d.id === disciplineId);
                    const { selectedDisciplines } = store.curriculumComponents;
                    disciplines = [...selectedDisciplines, discipline];
                    const serialized = serializeDisciplines(disciplines);
                    setOnStorage(keys.disciplines, serialized);
                    return updateSlice(store, {
                        selectedDisciplines: disciplines
                    });
                });
            },
            removeFromSelectedDisciplines(disciplineId) {
                set((store) => {
                    const { selectedDisciplines } = store.curriculumComponents;
                    const disciplines = selectedDisciplines.filter((d) => d.id !== disciplineId);
                    const serialized = serializeDisciplines(disciplines);
                    setOnStorage(keys.disciplines, serialized);
                    return updateSlice(store, {
                        selectedDisciplines: disciplines
                    });
                });
            }
        };
    };
}
