import styled from 'styled-components';
import { Columns } from '../../../../../TableCommons';
export const Column = styled.td `
  ${Columns.commonStyles}

  border-right: none;
  text-align: center;
`;
export const Content = styled.div `
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: center;
`;
