import { useFollowUpPageContext } from '../../../../../../../../../../context';
export function useDisable(props) {
    const userPermissions = useFollowUpPageContext((store) => store.commonData.userPermissions.data);
    const isPlanningDeleted = props.planning.status?.isDeleted() ?? false;
    const disableEverything = !userPermissions?.userCan.updateTheTeacherColumns();
    return {
        moveAndSeparation: true,
        duplicateAndAddToBlock: true,
        remove: disableEverything || isPlanningDeleted
    };
}
