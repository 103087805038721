import styled from 'styled-components';
export const StyledTitle = styled.h6 `
  color: ${(props) => props.theme.colors.gray700};
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  width: max-content;
  margin-right: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
`;
export const ContainerSkillCode = styled.div `
  display: flex;
  width: max-content !important;
`;
