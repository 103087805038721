import { updateSlice } from './helpers';
import { setupEventHandlers } from './eventHandlers';
export function createFiltersSliceCreator(events) {
    return (set, _, api) => {
        setupEventHandlers(api, events);
        return {
            stage: null,
            justStartedSkills: false,
            justUsedObjectives: false,
            minimumClassroomsDate: null,
            maximumClassroomsDate: null,
            setStage(stage) {
                set((store) => updateSlice(store, { stage }));
            },
            setJustStartedSkills(isSet) {
                set((store) => updateSlice(store, { justStartedSkills: isSet }));
            },
            setJustUsedObjectives(isSet) {
                set((store) => updateSlice(store, { justUsedObjectives: isSet }));
            },
            setMinimumClassroomsDate(date) {
                set((store) => updateSlice(store, { minimumClassroomsDate: date }));
            },
            setMaximumClassroomsDate(date) {
                set((store) => updateSlice(store, { maximumClassroomsDate: date }));
            }
        };
    };
}
