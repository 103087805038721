import React, { useState } from 'react';
import { useFinishActivityPopupContext } from '../../context';
import { useIsMounted } from '../../../../../hooks';
import { RequestStatus } from '../../../../../types/dataFetching';
import { updateClassroomAndSequences } from '../../../../../../../services/agregators';
import { times } from '../../../../../constants';
import { convertClassesAndInfos, shouldButtonBeDisabled } from './helpers';
import { StyledBlueButton } from '../../../common/styles';
const statusAndText = {
    [RequestStatus.NotStarted]: 'Finalizar',
    [RequestStatus.Loading]: 'Finalizando...',
    [RequestStatus.Succeeded]: 'Finalizado!',
    [RequestStatus.Failed]: 'Falha ao finalizar'
};
const FinishActivityButton = (props) => {
    const { classesAndInfos, abortSignal } = useFinishActivityPopupContext((store) => ({
        abortSignal: store.abortSignal,
        classesAndInfos: store.classesAndInfos
    }));
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const { isMounted } = useIsMounted();
    function resetStatusAfterDelay() {
        setTimeout(() => {
            if (isMounted()) {
                setRequestStatus(RequestStatus.NotStarted);
            }
        }, times.toResetARequestStatus);
    }
    function handleFinishSucceeded(response) {
        props.handleFinishSucceeded(response.data);
        if (isMounted()) {
            setRequestStatus(RequestStatus.Succeeded);
        }
    }
    function handleFinishFailure() {
        if (isMounted()) {
            setRequestStatus(RequestStatus.Failed);
        }
    }
    function onClick() {
        setRequestStatus(RequestStatus.Loading);
        updateClassroomAndSequences({
            abortSignal,
            sequenceId: props.sequence.id,
            items: convertClassesAndInfos(classesAndInfos)
        })
            .then(handleFinishSucceeded)
            .catch(handleFinishFailure)
            .finally(resetStatusAfterDelay);
    }
    const disabled = shouldButtonBeDisabled(classesAndInfos);
    return (React.createElement(StyledBlueButton, { onClick: onClick, disabled: disabled }, statusAndText[requestStatus]));
};
export default FinishActivityButton;
