import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import Insight from 'models/Insight';

export const getContentInsight = async (
  contentid: string
): Promise<Response<Insight>> => {
  try {
    const response = await Api.INSTANCE.getApi().get(
      `/content/${contentid}/insights`
    );

    return Response.fromData(Insight.fromJson(response.data), response.status);
  } catch (e: any) {
    return Response.error(e);
  }
};
