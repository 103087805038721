import styled from 'styled-components';
export const StyledWrapper = styled.div `
  flex-grow: 1;

  overflow: hidden;

  display: flex;
  flex-direction: column;
`;
export const StyledTabTitlesWrapper = styled.div `
  padding: 0 30px;

  display: flex;
  column-gap: 7px;
`;
export const StyledTabTitleWrapper = styled.div `
  height: 30px;

  padding: 0 10px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px 4px 0px 0px;

  background-color: ${(props) => props.$isTheSelectedTab ? 'white' : '#ECECEC'};

  box-shadow: ${(props) => props.$isTheSelectedTab
    ? 'box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1)'
    : 'box-shadow: inset 0px -2px 4px rgba(0, 0, 0, 0.07)'};
`;
export const StyledTabTitle = styled.span `
  font-size: 12px;
  font-weight: 700;

  color: #4a4a4a;
`;
