export async function downloadFile(blob, filename) {
    return new Promise((resolve, reject) => {
        try {
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
            resolve(null);
        }
        catch {
            reject();
        }
    });
}
