import React, { useContext, useMemo, useState } from 'react';

import AnswerQuestion from 'models/AnswerQuestion';
import StudentAnswer from 'models/StudentAnswer';

import { useActivityReport } from './ActivityReportContext';

type QuestionReviewProps = {
  questionsQuantity: number;
  questionNumber: number;
  answer: StudentAnswer;
  question: AnswerQuestion;
  goNextQuestion: () => void;
  goPrevQuestion: () => void;
};

const QuestionReviewContext = React.createContext<QuestionReviewProps>(null);

export const useQuestionReview = () => useContext(QuestionReviewContext);

export const QuestionReviewProvider: React.FC = ({ children }) => {
  const { selectedStudent, questions } = useActivityReport();

  const questionsQuantity = questions.length;
  const lastIndex = questionsQuantity - 1;
  const [questionIndex, setQuestionIndex] = useState(0);

  const goPrevQuestion = () => {
    setQuestionIndex((p) => {
      const prevIndex = p - 1;
      return prevIndex < 0 ? lastIndex : prevIndex;
    });
  };

  const goNextQuestion = () => {
    setQuestionIndex((p) => {
      const nextIndex = p + 1;
      return nextIndex > lastIndex ? 0 : nextIndex;
    });
  };

  const question = useMemo(
    () => questions[questionIndex],
    [questions, questionIndex]
  );
  const answers = selectedStudent?.answers;

  const answer = useMemo(
    () => answers?.find((a) => a.contentItemId === question?.contentItemId),
    [question?.contentItemId, answers]
  );

  return (
    <QuestionReviewContext.Provider
      value={{
        answer,
        question,
        questionNumber: questionIndex + 1,
        goNextQuestion,
        goPrevQuestion,
        questionsQuantity
      }}
    >
      {children}
    </QuestionReviewContext.Provider>
  );
};
