function getGroupWithMoreClassrooms(groups) {
    return groups
        .slice(1)
        .reduce((groupWithMoreOptions, currentGroup) => {
        const greaterLength = groupWithMoreOptions.classrooms.length;
        const currentLength = currentGroup.classrooms.length;
        return currentLength > greaterLength
            ? currentGroup
            : groupWithMoreOptions;
    }, groups.at(0));
}
export function createSelectOptionsFrom(classroomGroups) {
    if (!classroomGroups)
        return [];
    if (classroomGroups.length === 0)
        return [];
    const groupWithMoreClassrooms = getGroupWithMoreClassrooms(classroomGroups);
    const otherGroups = classroomGroups.filter((group) => {
        return group.className !== groupWithMoreClassrooms.className;
    });
    return groupWithMoreClassrooms.classrooms
        .map((classroom) => classroom.number)
        .filter((classroomNumber) => {
        return otherGroups.every((group) => {
            return group.classrooms.some((classroom) => {
                return classroom.number === classroomNumber;
            });
        });
    })
        .map((classroomNumber) => {
        const value = classroomNumber.toString();
        return { value, label: value };
    });
}
export function getInitialValue(classroomGroups, classesAndInfos, allOptions) {
    const firstGroup = classroomGroups?.at(0);
    const className = firstGroup?.className;
    if (!className)
        return null;
    const infos = classesAndInfos?.get(className);
    const selectedClassroom = infos?.selectedClassroom;
    if (!selectedClassroom)
        return null;
    return selectedClassroom
        ? allOptions.find((option) => {
            const value = Number(option.value);
            return value === selectedClassroom.number;
        })
        : allOptions.at(0);
}
