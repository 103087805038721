import React from 'react';

import { Skeleton } from '@campus/components';

import Insights from './components/Insights';
import QuestionAnalyze from './components/QuestionAnalyze';
import PerformaceGraph from './components/PerformaceGraph';

import useInsights from './hooks/useInsights';
import { Content } from './styles';
const DisciplineInsights: React.FC = () => {
  const { insight, loading } = useInsights();
  return (
    <Content>
      <Insights insight={insight} loading={loading} />
      {!loading && insight.answerCount === 0 ? (
        ''
      ) : (
        <>
          {loading ? (
            <Skeleton width="100%" height="120px" marginBottom="10px" />
          ) : (
            <PerformaceGraph scores={insight.scores} />
          )}

          {loading ? (
            <Skeleton width="100%" height="300px" marginBottom="10px" />
          ) : (
            <QuestionAnalyze
              questions={insight?.questions}
              insigths={insight}
            />
          )}
        </>
      )}
    </Content>
  );
};
export default DisciplineInsights;
