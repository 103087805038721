import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import queryString from 'query-string';

import { ContextType } from '@campus/auth';
import {
  AppContainer,
  ContextManagerProvider,
  PeriodBanner
} from '@campus/commons';
import { teacherTheme } from '@campus/components';
import {
  Classroom,
  StudentMonitoring,
  Feedback,
  Variant
} from '@campus/classroom';

import Menu from 'containers/Menu';
import Discipline from 'pages/Discipline';
import { createVestibularRoutes } from '@campus/vestibular';

import LibraryObject from 'pages/LibraryObject';

import { createPedagogicalPlanningRoutes } from '@campus/pedagogical-planning';

import LibraryObjectsList from 'pages/LibraryObjectsList';

const App: React.FC = () => {
  const [contextType, setContextType] = useState(ContextType.TEACHER);

  useEffect(() => {
    const search = queryString.parse(window.location.search);
    setContextType(
      search?.teacherId ? ContextType.MANAGER : ContextType.TEACHER
    );
  }, []);

  return (
    <AppContainer
      theme={teacherTheme}
      contextType={contextType}
      components={{
        MainProvider: ContextManagerProvider,
        Menu: Menu,
        PageHeader: PeriodBanner
      }}
    >
      <Route path="" element={<Classroom variant={Variant.TEACHER} />} />
      <Route
        path="student/:studentId"
        element={<StudentMonitoring variant={Variant.TEACHER} />}
      >
        <Route
          index
          element={
            <StudentMonitoring variant={Variant.TEACHER} tab="indicator" />
          }
        />
        <Route
          path="skills"
          element={<StudentMonitoring variant={Variant.TEACHER} tab="skill" />}
        />
        <Route path="feedback" element={<Feedback />} />
      </Route>
      <Route path="discipline/:DisciplineId/*" element={<Discipline />} />

      {createPedagogicalPlanningRoutes()}

      <Route path="library-objects" element={<LibraryObjectsList />} />
      <Route
        path="library-object/:libraryObjectId"
        element={<LibraryObject />}
      />
      <Route
        path="library-object/:libraryObjectId/chapter/:chapterId"
        element={<LibraryObject />}
      />

      {createVestibularRoutes({
        pages: {
          literaryWorks: {
            isReadOnly: true
          },
          literaryWork: {
            isReadOnlyOnGeneral: true,
            questionsCanBe: 'viewed'
          }
        }
      })}
    </AppContainer>
  );
};

export default App;
