import { createUpdatedPlanning } from './helpers';
import { debounce } from '../../../../../../../../../../utils/debounce';
import { useUpdate as baseUseUpdate } from '../../../../common/objectsAndObjectives/hooks/useUpdate';
import { debounceDelayInMillis } from '../../../../common/objectsAndObjectives/times';
import { updatePlanningObject } from '../../../../../../../../../../../../services/pedagogicalPlanningObject';
let abortController = new AbortController();
export function useUpdate(props) {
    const { requestStatus, updateStarted, updateFailed, updateSuceeded, resetStatus } = baseUseUpdate();
    const debouncedUpdate = debounce((params) => {
        abortController.abort();
        abortController = new AbortController();
        updateStarted();
        updatePlanningObject({
            objectId: props.object.id,
            wasProgrammed: params.isCheckboxNowChecked,
            abortSignal: abortController.signal
        })
            .then(updateSuceeded)
            .catch((response) => {
            updateFailed(response, props.handleUpdateFailed);
        })
            .finally(resetStatus);
    }, debounceDelayInMillis);
    function onCheckboxToggle(params) {
        const updatedPlanning = createUpdatedPlanning({
            object: props.object,
            planning: props.planning,
            programmed: params.isCheckboxNowChecked
        });
        props.handleObjectUpdated(updatedPlanning);
        debouncedUpdate(params);
    }
    return { requestStatus, onCheckboxToggle };
}
