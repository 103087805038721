import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import StudentAnswerHistory from 'models/StudentAnswerHistory';
import ContentAnswer from 'models/ContentAnswer';
import ReportActionType from 'models/ReportActionType';
import StudentAnswerReport from 'models/StudentAnswerReport';

export const getContentAnswer = async (
  contentid: string,
  classId: string
): Promise<Response<ContentAnswer>> => {
  try {
    const response = await Api.INSTANCE.getApi().get(
      `/content/${contentid}/answer`,
      { params: { classId } }
    );

    return Response.fromData(
      ContentAnswer.fromJson(response.data),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};

export const getStudentAnswerHistory = async (
  contentId: string,
  studentId: string
): Promise<Response<StudentAnswerHistory[]>> => {
  try {
    const response = await Api.INSTANCE.getApi().get(
      `content/${contentId}/answer/${studentId}`
    );

    return Response.fromData(
      response.data.map((answer) => StudentAnswerHistory.fromJson(answer)),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};

export const changeAnswerPrimary = async (
  contentId: string,
  studentId: string,
  answerId: string
): Promise<Response<StudentAnswerHistory[]>> => {
  try {
    await Api.INSTANCE.getApi().put(
      `content/${contentId}/answer/${studentId}/primary/${answerId}`
    );
  } catch (e: any) {
    return Response.error(e);
  }
};

type AnswerScoreParams = {
  contentItemId: string;
  studentId: string[];
  score: number;
  commnent?: string;
};

export const changeAnswerScore = async (
  contentid: string,
  params: AnswerScoreParams
): Promise<Response<StudentAnswerReport[]>> => {
  try {
    const response = await Api.INSTANCE.getApi().put(
      `/content/${contentid}/answer/score`,
      {
        ContentItemId: params.contentItemId,
        StudentId: params.studentId,
        TeacherScore: params.score,
        TeacherCommnent: params.commnent
      }
    );

    const answers = response.data.map((d: any) =>
      StudentAnswerReport.fromJson(d)
    );

    return Response.fromData(answers, response.status);
  } catch (e: any) {
    return Response.error(e);
  }
};

export const changeTeacherAnswerScore = async (
  contentid: string,
  params: Omit<AnswerScoreParams, 'commnent' | 'contentItemId'>
): Promise<Response<StudentAnswerReport[]>> => {
  try {
    const response = await Api.INSTANCE.getApi().put(
      `/content/${contentid}/answer/teacherScore`,
      {
        StudentId: params.studentId,
        TeacherScore: params.score
      }
    );

    const answers = response.data.map((d: any) =>
      StudentAnswerReport.fromJson(d)
    );

    return Response.fromData(answers, response.status);
  } catch (e: any) {
    return Response.error(e);
  }
};

type ActionProps = {
  studentsIds: string[];
  classId: string;
};

export const launchStudentsZero = async (
  contentid: string,
  studentsIds: string[]
): Promise<Response<StudentAnswerReport[]>> => {
  try {
    const response = await Api.INSTANCE.getApi().put(
      `/content/${contentid}/answer/action`,
      { ActionType: ReportActionType.ZERO, StudentsIds: studentsIds }
    );

    const answers = response.data.map((d: any) =>
      StudentAnswerReport.fromJson(d)
    );

    return Response.fromData(answers, response.status);
  } catch (e: any) {
    return Response.error(e);
  }
};

export const resetStudentGrades = async (
  contentid: string,
  params: ActionProps
): Promise<Response<StudentAnswerReport[]>> => {
  try {
    const response = await Api.INSTANCE.getApi().put(
      `/content/${contentid}/answer/action`,
      {
        ActionType: ReportActionType.RESET,
        StudentsIds: params.studentsIds
      }
    );

    const answers = response.data.map((d: any) =>
      StudentAnswerReport.fromJson(d)
    );

    return Response.fromData(answers, response.status);
  } catch (e: any) {
    return Response.error(e);
  }
};
