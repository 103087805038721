import { AffectIndex } from '@campus/commons';
export default class EvaluationType {
    id;
    name;
    color;
    pluralName;
    description;
    affectIndex;
    canOrCannotImpactTheMDC() {
        return this.affectIndex === AffectIndex.OPTIONAL;
    }
    alwaysImpactTheMDC() {
        return this.affectIndex === AffectIndex.YES;
    }
    neverImpactTheMDC() {
        return this.affectIndex === AffectIndex.NO;
    }
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new EvaluationType();
        instance.id = json.Id;
        instance.name = json.Name;
        instance.color = json.Color;
        instance.pluralName = json.PluralName;
        instance.description = json.Description;
        instance.affectIndex = json.AffectIndex;
        return instance;
    }
}
