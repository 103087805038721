import React from 'react';
import { Selector } from './styles';
export const PageSelector = ({ pageIndex, setPageIndex, numberOfPages, isLoadingPageContent }) => {
    function onChange(newIndex) {
        if (newIndex !== pageIndex) {
            setPageIndex(newIndex);
        }
    }
    return (React.createElement(Selector, { "$loading": isLoadingPageContent, pageIndex: pageIndex, setPageIndex: onChange, numberOfPages: numberOfPages, hidePreviousAndNextButtonsIfDisabled: true }));
};
