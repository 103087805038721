import styled from 'styled-components';
export const StyledWrapper = styled.div `
  padding-left: 98px;
  padding-right: 17px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  background: linear-gradient(
    270deg,
    #dae4fd 61.98%,
    rgba(218, 228, 253, 0) 100%
  );
`;
export const StyledButton = styled.button `
  height: 15px;
  min-width: 62px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 10px;
  font-weight: 600;
  font-style: normal;

  border: none;
  border-radius: 2px;

  color: white;

  background-color: ${(props) => props.$addSucceeded
    ? props.theme.colors.gray200
    : props.$addFailed
        ? props.theme.colors.error
        : '#457AF5'};
`;
