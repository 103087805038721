import { getReportRelatedInformation } from '../../../../../services/classroom';
export function useReportInformation(props) {
    async function getInformation() {
        const response = await getReportRelatedInformation({
            gradeId: props.selectedGrade?.id,
            segmentId: props.selectedSegment?.id,
            disciplineId: props.selectedDisciplines?.at(0)?.id
        });
        return {
            ...response?.data,
            grade: props.selectedGrade?.name,
            segment: props.selectedSegment?.name,
            discipline: props.selectedDisciplines?.at(0)?.name
        };
    }
    return { getInformation };
}
