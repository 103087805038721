function getStartDate() {
    const date = new Date();
    date.setMonth(0, 1);
    return date;
}
function getEndDate() {
    return new Date();
}
export function useFilterDates() {
    const startDate = getStartDate();
    const endDate = getEndDate();
    return { startDate, endDate };
}
