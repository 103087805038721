import { createStore } from 'zustand';
import { createFiltersSlice } from './slices/filters';
import { createTableContent } from './helpers';
export function createAddSkillPopupStore(params) {
    return createStore()((set, ...args) => ({
        mainTableContent: params.mainTableContent,
        allGrades: params.allGrades,
        allStages: params.allStages,
        allSegments: params.allSegments,
        allDisciplines: params.allDisciplines,
        allPlanningStatuses: params.allPlanningStatuses,
        selectedGrade: params.selectedGrade,
        selectedSegment: params.selectedSegment,
        selectedDisciplines: params.selectedDisciplines,
        handleAddSkillSucceeded: params.handleAddSkillSucceeded,
        addPlanningToGroupWithNumber: params.addPlanningToGroupWithNumber,
        tableContent: [],
        newSkillDisciplineId: params.selectedDisciplines?.at(0)?.id,
        filters: createFiltersSlice(set, ...args),
        setNewSkillDisciplineId(id) {
            set((store) => ({ ...store, newSkillDisciplineId: id }));
        },
        setTableContent(content) {
            set((store) => {
                const tableContent = createTableContent(content, store);
                return { ...store, tableContent };
            });
        }
    }));
}
