import { getMaximumDateFrom } from '../../../../../utils/arrays';
export function getUpdateDateFrom(skills) {
    const dates = skills.flatMap((s) => {
        return s.unitStages?.map((us) => us.createdAt);
    });
    return getMaximumDateFrom(dates);
}
export function createFormatedTimeFrom(date) {
    if (!date)
        return '';
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return hours + 'h' + minutes;
}
