import React, { useCallback, useEffect, useState } from 'react';
import { StyledArrowIcon, StyledColumnWrapper, StyledContentWrapper, StyledItemLabel, StyledItemWrapper, StyledNoContentMessage, StyledNoContentMessageWrapper } from './styles';
const Column = (props) => {
    const findDefaultSelectedItem = useCallback(() => {
        return props.items?.find((item) => item.label === props.labelOfDefaultSelectedItem);
    }, [props.items, props.labelOfDefaultSelectedItem]);
    const [selectedItem, setSelectedItem] = useState(() => {
        if (!props.labelOfDefaultSelectedItem)
            return;
        return findDefaultSelectedItem();
    });
    useEffect(() => {
        if (props.labelOfDefaultSelectedItem) {
            setSelectedItem(findDefaultSelectedItem());
        }
        else {
            setSelectedItem(null);
        }
    }, [findDefaultSelectedItem, props.labelOfDefaultSelectedItem]);
    function handleItemClick(item) {
        if (!item)
            return;
        setSelectedItem(item);
        if (props.onChange) {
            props.onChange(item);
        }
    }
    function renderItemList() {
        return props.items.map((item, index) => (React.createElement(StyledItemWrapper, { key: index, onClick: () => handleItemClick(item), "$isSelected": selectedItem?.value === item?.value || false },
            React.createElement(StyledItemLabel, null, item.label),
            React.createElement(StyledArrowIcon, null))));
    }
    function renderNoContentMessage() {
        return (React.createElement(StyledNoContentMessageWrapper, null,
            React.createElement(StyledNoContentMessage, null, "Nada a ser exibido.")));
    }
    function render() {
        if (props.items && props.items.length > 0) {
            return renderItemList();
        }
        return renderNoContentMessage();
    }
    return (React.createElement(StyledColumnWrapper, null,
        React.createElement(StyledContentWrapper, null, render())));
};
export default Column;
