import React, { useEffect, useMemo } from 'react';
import { FinishActivityPopupContextProvider } from './context';
import FinishActivityButton from './components/FinishActivityButton';
import ChangeAllShortcut from './components/ChangeAllShortcut';
import ClassLessonAndDateList from './components/ClassLessonAndDateList/ClassLessonAndDateList';
import { StyledCancelButton } from '../common/styles';
import { StyledTitle, StyledButtonsWrapper, StyledPopup } from './styles';
const FinishActivityPopup = (props) => {
    const abortController = useMemo(() => new AbortController(), []);
    function onCancelButtonClick() {
        abortController.abort();
        props.handleCancelButtonClicked();
    }
    useEffect(() => {
        return () => abortController.abort();
    }, [abortController]);
    return (React.createElement(FinishActivityPopupContextProvider, { sequence: props.sequence, planning: props.planning, classroomGroups: props.classroomGroups, abortSignal: abortController.signal },
        React.createElement(StyledPopup, { isFullScreen: true, canBeDisplayed: props.canBeDisplayed, handleExternalClick: props.handleExternalClick },
            React.createElement(StyledTitle, null, "Finalizar Atividade"),
            React.createElement(ChangeAllShortcut, null),
            React.createElement(ClassLessonAndDateList, null),
            React.createElement(StyledButtonsWrapper, null,
                React.createElement(StyledCancelButton, { onClick: onCancelButtonClick }, "Cancelar"),
                React.createElement(FinishActivityButton, { sequence: props.sequence, handleFinishSucceeded: props.handleActivityFinished })))));
};
export default FinishActivityPopup;
