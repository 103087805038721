import React, { useState } from 'react';
import { Button } from '@campus/components';
import { RequestStatus } from '../../../../../../../../../../../types/dataFetching';
import { useIsMounted } from '../../../../../../../../../../../hooks';
import { times } from '../../../../../../../../../../../constants';
const statusAndButtonContent = {
    [RequestStatus.NotStarted]: 'Remover',
    [RequestStatus.Loading]: 'Removendo...',
    [RequestStatus.Failed]: 'Falha ao remover',
    [RequestStatus.Succeeded]: 'Removido!'
};
const DeleteButton = (props) => {
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const { isMounted } = useIsMounted();
    function resetStatusAfterDelay() {
        setTimeout(() => {
            if (isMounted()) {
                setRequestStatus(RequestStatus.NotStarted);
            }
        }, times.toResetARequestStatus);
    }
    function onSuccess() {
        if (isMounted()) {
            setRequestStatus(RequestStatus.Succeeded);
        }
    }
    function onFailure() {
        if (isMounted()) {
            setRequestStatus(RequestStatus.Failed);
        }
    }
    function onClick() {
        setRequestStatus(RequestStatus.Loading);
        props
            .onClick()
            .then(onSuccess)
            .catch(onFailure)
            .finally(resetStatusAfterDelay);
    }
    return (React.createElement(Button, { variant: 'danger', onClick: onClick }, statusAndButtonContent[requestStatus]));
};
export default DeleteButton;
