import { classNames } from './classNames';
export function generateClassName(columnDefinition) {
    const classes = [classNames.cell];
    const spanAcrossAllRows = columnDefinition?.spanAcrossAllRows;
    if (spanAcrossAllRows) {
        classes.push(classNames.cellThatSpanAcrossAllRows);
    }
    if (columnDefinition?.doNotAddRightBorderToCell) {
        classes.push(classNames.cellWithoutRightBorder);
    }
    return classes.join(' ');
}
