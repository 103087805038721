import React from 'react';
import Week from './components/Week';
import { StyledWrapper } from './styles';
const Column = (props) => {
    function renderWeeks() {
        return props.month.weeks.map((week, index) => (React.createElement(Week, { week: week, month: props.month, key: week.monthNumber + index })));
    }
    return React.createElement(StyledWrapper, null, renderWeeks());
};
export default Column;
