import ThemeUnit from './ThemeUnit';
import Discipline from './Discipline';
import KnowledgeObject from './KnowledgeObject';
import LearningObjective from './LearningObjective';
import AreaSkillAndStage from './AreaSkillAndStage';
export default class AreaSkill {
    id;
    code;
    evaluative;
    description;
    gradeId;
    segmentId;
    themeUnit;
    discipline;
    unitStages;
    programStages;
    knowledgeObjects;
    learningObjectives;
    static getStages(json) {
        let unitStages = [];
        let programStages = [];
        if (!json || !Array.isArray(json)) {
            return { unitStages, programStages };
        }
        programStages = json
            ?.filter((o) => o.Type === 'P')
            ?.map((o) => AreaSkillAndStage.fromJson(o));
        unitStages = json
            ?.filter((o) => o.Type === 'U')
            ?.map((o) => AreaSkillAndStage.fromJson(o));
        return { programStages, unitStages };
    }
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new AreaSkill();
        instance.id = json.Id;
        instance.code = json.Code;
        instance.description = json.Name;
        instance.themeUnit = json.ThemeUnit;
        instance.evaluative = json.Evaluative;
        instance.gradeId = json.GradeId;
        instance.segmentId = json.SegmentId;
        instance.themeUnit = ThemeUnit.fromJson(json.ThemeUnit);
        instance.discipline = Discipline.fromJson(json.Discipline);
        const stages = AreaSkill.getStages(json.PlanningStages);
        instance.unitStages = stages.unitStages;
        instance.programStages = stages.programStages;
        instance.learningObjectives =
            json.LearningObjectives?.map(LearningObjective.fromJson) ?? [];
        instance.knowledgeObjects =
            json.ObjectsKnowledge?.map(KnowledgeObject.fromJson) ?? [];
        return instance;
    }
}
