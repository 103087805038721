import { toast } from '@campus/components';
import { times } from '../../../../../../../../../../../../../../../constants';
export function useEventHandlers(infos) {
    function showToastWithAddedMessage() {
        const skill = infos.skill;
        const disciplineColor = skill?.discipline?.color;
        toast(`Habilidade ${skill?.code} adicionada com sucesso!`, {
            autoClose: times.toHideAModalOrPopupOnSuccess,
            progressStyle: { background: disciplineColor }
        });
    }
    function handleClick(addSkill) {
        addSkill(infos.skill).then(showToastWithAddedMessage);
    }
    return { handleClick };
}
