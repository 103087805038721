import styled from 'styled-components';
export const Container = styled.div `
  padding: 17px 16px 18px;

  display: flex;
  flex-wrap: wrap;
  gap: 8px 19px;
`;
export const Button = styled.button.attrs((props) => ({
    className: props.$isActive ? 'active' : ''
})) `
  height: 24px;

  padding: 10px 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  white-space: nowrap;

  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blackAlpha500};

  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.gray200};

  &:focus-visible {
    border-color: transparent;
  }

  &.active {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.blue1};
    border-color: ${({ theme }) => theme.colors.blue1};
  }
`;
export const FilterGroup = styled.section `
  display: inline-flex;
  flex-wrap: wrap;
  gap: 3px 9px;
  align-items: center;

  > span {
    font-size: 14px;
    font-weight: 600;
    line-height: 110%;
    color: ${({ theme }) => theme.colors.gray3};
  }

  > div {
    display: inline-flex;
    column-gap: 4px;
  }
`;
