import React from 'react';

import { useContextManager } from '@campus/commons';

import CorrectionForm from './components/CorrectionForm';
import TeacherCorrection from './components/TeacherCorrection';
import {
  ShowCorrectionProvider,
  useShowCorrection
} from './context/ShowCorrectionContext';

import { Content, Divider } from './styles';

const QuestionCorrection: React.FC = () => {
  return (
    <ShowCorrectionProvider>
      <Correction />
    </ShowCorrectionProvider>
  );
};

const Correction: React.FC = () => {
  const { showCorrection, showCorrectionForm } = useShowCorrection();
  const { isCurrentPeriod } = useContextManager();

  if (!showCorrection || !isCurrentPeriod) {
    return null;
  }

  return (
    <>
      <Divider />
      <Content>
        {showCorrectionForm ? <CorrectionForm /> : <TeacherCorrection />}
      </Content>
    </>
  );
};

export default QuestionCorrection;
