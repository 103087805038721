import React from 'react';
import PageBody from '../../../common/components/PageRelated/Body';
import Header from './components/Header';
import Table from './components/Table';
const Body = () => {
    return (React.createElement(PageBody, null,
        React.createElement(Header, null),
        React.createElement(Table, null)));
};
export default Body;
