import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useClassLessonAndDateListContext } from '../../../../../../context';
import ClassroomSelectorMenu from '../../../../../../../../../../Basics/ClassroomSelectorMenu';
import { useDebounce } from '../../../../../../../../../../../hooks/useDebounce';
import { calculateMenuPosition, createMenuOption } from './helpers';
import { StyledWrapper } from './styles';
const Menu = (props) => {
    const { listElement } = useClassLessonAndDateListContext();
    const [height, setHeight] = useState(0);
    const [position, setPosition] = useState(null);
    const options = useMemo(() => {
        return props.infos.allClassrooms.map((classroom) => {
            return createMenuOption({ classroom });
        });
    }, [props.infos.allClassrooms]);
    const selectedOption = props.infos.selectedClassroom
        ? createMenuOption({
            addClassPrefix: true,
            classroom: props.infos.selectedClassroom
        })
        : null;
    function menuRefCallback(menuElement) {
        if (menuElement && height === 0) {
            setHeight(menuElement.clientHeight);
        }
    }
    const calculateAndSetPosition = useCallback(() => {
        if (height === 0)
            return;
        if (!listElement)
            return;
        if (!props.parentBoundingRect)
            return;
        const position = calculateMenuPosition({
            listElement,
            menuHeight: height,
            nearestParentBoundingRect: props.parentBoundingRect
        });
        setPosition(position);
    }, [height, listElement, props.parentBoundingRect]);
    const handleScroll = useDebounce(calculateAndSetPosition, 500);
    useEffect(() => {
        calculateAndSetPosition();
    }, [calculateAndSetPosition]);
    useEffect(() => {
        listElement?.addEventListener('scroll', handleScroll);
        return () => listElement?.removeEventListener('scroll', handleScroll);
    }, [listElement, handleScroll]);
    return (React.createElement(StyledWrapper, { "$top": position?.top, "$bottom": position?.bottom, "$hideChildren": !position },
        React.createElement(ClassroomSelectorMenu, { ref: menuRefCallback, options: options, selectedOption: selectedOption, canBeDisplayed: props.canBeDisplayed, handleExternalClick: props.handleExternalClick, handleOptionClicked: props.handleOptionClicked })));
};
export default Menu;
