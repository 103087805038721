import React from 'react';
import BaseHeader from '../../../../../common/components/PageRelated/Body/components/Header';
import { useWeeklyPlanningPageContext } from '../../../../context';
import ToggleFullScreenButton from '../../../../../common/components/PageRelated/Body/components/Header/components/Buttons/ToggleFullScreenButton';
import SegmentGradeAndDisciplines from '../../../../../common/components/PageRelated/Body/components/Header/components/SegmentGradeAndDisciplines';
import AddSkillButton from './components/Buttons/AddSkill/AddSkillButton';
import MenuActions from './components/Buttons/MenuActions/MenuActions';
import { StyledRightSideWrapper } from './styles';
const Header = () => {
    const { components } = useWeeklyPlanningPageContext((store) => ({
        components: store.curriculumComponents
    }));
    return (React.createElement(BaseHeader, null,
        React.createElement("div", null,
            React.createElement(BaseHeader.Title, null),
            React.createElement(SegmentGradeAndDisciplines, { grades: components.availableGrades, segments: components.availableSegments, disciplines: components.availableDisciplines, selectedGrade: components.selectedGrade, selectedSegment: components.selectedSegment, selectedDisciplines: components.selectedDisciplines, setSelectedGrade: components.selectedGradeChanged, setSelectedSegment: components.selectedSegmentChanged, addToSelectedDisciplines: components.addToSelectedDisciplines, removeFromSelectedDisciplines: components.removeFromSelectedDisciplines })),
        React.createElement(StyledRightSideWrapper, null,
            React.createElement(AddSkillButton, null),
            React.createElement(ToggleFullScreenButton, null),
            React.createElement(MenuActions, null))));
};
export default Header;
