import React from 'react';
import DeletionModal from '../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/common/components/DeletionModal';
import { usePortal } from '../../../../../../../../../../../common/contexts/portals';
import { useRemovePlanning } from './useRemovePlanning';
import { times } from '../../../../../../../../../../../common/constants';
export function useControlDeletionModal() {
    const { create, destroy } = usePortal(document.body);
    const { removePlanning } = useRemovePlanning();
    function handleConfirmButtonClicked(planning) {
        return new Promise((resolve, reject) => {
            removePlanning(planning)
                .then(() => {
                destroy({ afterMillis: times.toHideAModalOrPopupOnSuccess });
                resolve();
            })
                .catch(reject);
        });
    }
    function createModal(planning) {
        create(React.createElement(DeletionModal, { canBeDisplayed: true, title: 'Confirmação de remoção', description: 'Deseja realmente remover a habilidade?', handleExternalClick: destroy, handleCancelButtonClicked: destroy, handleConfirmButtonClicked: () => handleConfirmButtonClicked(planning) }));
    }
    return { createModal };
}
