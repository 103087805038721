import styled from 'styled-components';
import { StyledBasicButton } from '../../../../../../../../common/components/PageRelated/Body/components/Header/components/Buttons/styles';
export const StyledAddSkillButton = styled(StyledBasicButton) `
  color: white;
  border-color: ${(props) => props.theme.colors.green3};
  background-color: ${(props) => props.theme.colors.green3};
`;
export const StyledFinishPlanningButton = styled(StyledBasicButton) `
  color: white;
  border-color: ${(props) => props.theme.colors.error};
  background-color: ${(props) => props.theme.colors.error};
`;
