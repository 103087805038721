import React from 'react';
import { pdf } from '@react-pdf/renderer';
import StudyUnitReport from '../StudyUnitReport';
import StudyUnitReportDocx from '../StudyUnitReport/StudyUnitReportDocx';
import { downloadFile } from './common';
import { useReportInformation } from './useReportInformation';
export function useStudyUnitReport(props) {
    const { getInformation } = useReportInformation(props);
    async function download(params) {
        const information = await getInformation();
        const filename = params?.shortVersion
            ? 'Unidade de Estudo Resumida'
            : 'Unidade de Estudo Completa';
        let blob;
        if (props.type === 'docx') {
            blob = await new StudyUnitReportDocx({
                stage: params.stage,
                information,
                shortVersion: params?.shortVersion,
                skills: props.skills
            }).createAndSaveDocumentToFile(filename);
        }
        else if (props.type === 'pdf') {
            const report = pdf(React.createElement(StudyUnitReport, { stage: params.stage, skills: props.skills, information: information, shortVersion: params?.shortVersion }));
            blob = await report.toBlob();
        }
        await downloadFile(blob, filename);
    }
    return { download };
}
