import styled from 'styled-components';
import { StyledRowWrapper } from '../common/styles';
export const StyledWrapper = styled(StyledRowWrapper) `
  display: flex;
  height: 34px;
`;
export const StyledMonthWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;

  border-style: solid;
  border-width: 2px 1px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-color: ${(props) => props.theme.colors.gray300};

  background-color: ${(props) => props.theme.colors.gray50};

  width: ${(props) => (props.$width > 0 ? `${props.$width}px` : 'auto')};
  min-width: ${(props) => (props.$width > 0 ? `${props.$width}px` : 'auto')};
`;
export const StyledMonthName = styled.span `
  font-size: 14px;
  font-weight: 600;

  color: ${(props) => props.theme.colors.gray600};
`;
