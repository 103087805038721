import styled from 'styled-components';
export const StyledTable = styled.table `
  table-layout: fixed;

  border-spacing: 0;
  border-collapse: collapse;

  left: auto !important;
`;
export const StyledTableBody = styled.tbody ``;
