import React from 'react';
import { StyledArrowIcon, StyledColumnItem, StyledColumnItemWrapper } from '../commonStyles';
import { StyledWrapper } from './styles';
const DocumentsColumn = (props) => {
    const handleSetStudyUnitAndType = (version, type) => {
        props.setSelectedType(type);
        props.setSelectedStudyUnitVersion(version);
    };
    return (React.createElement(StyledWrapper, null,
        React.createElement(StyledColumnItemWrapper, { onClick: props.onAnnualTimelineItemClick },
            React.createElement(StyledColumnItem, null, props.reportBeingGenerated === 'annualTimeline'
                ? '...'
                : 'Cronograma Anual (.pdf)')),
        React.createElement(StyledColumnItemWrapper, { onClick: props.onAnnualTimelineItemDocxClick },
            React.createElement(StyledColumnItem, null, props.reportBeingGenerated === 'annualTimelineDocx'
                ? '...'
                : 'Cronograma Anual (.docx)')),
        React.createElement(StyledColumnItemWrapper, { onClick: () => handleSetStudyUnitAndType('complete', 'pdf'), "$isSelected": props.selectedStudyUnitVersion === 'complete' && props.type === 'pdf' },
            React.createElement(StyledColumnItem, null, "Unidade de Estudo Completa (.pdf)"),
            React.createElement(StyledArrowIcon, null)),
        React.createElement(StyledColumnItemWrapper, { onClick: () => handleSetStudyUnitAndType('complete', 'docx'), "$isSelected": props.selectedStudyUnitVersion === 'complete' && props.type === 'docx' },
            React.createElement(StyledColumnItem, null, "Unidade de Estudo Completa (.docx)"),
            React.createElement(StyledArrowIcon, null)),
        React.createElement(StyledColumnItemWrapper, { onClick: () => handleSetStudyUnitAndType('short', 'pdf'), "$isSelected": props.selectedStudyUnitVersion === 'short' && props.type === 'pdf' },
            React.createElement(StyledColumnItem, null, "Unidade de Estudo Resumida (.pdf)"),
            React.createElement(StyledArrowIcon, null)),
        React.createElement(StyledColumnItemWrapper, { onClick: () => handleSetStudyUnitAndType('short', 'docx'), "$isSelected": props.selectedStudyUnitVersion === 'short' && props.type === 'docx' },
            React.createElement(StyledColumnItem, null, "Unidade de Estudo Resumida (.docx)"),
            React.createElement(StyledArrowIcon, null))));
};
export default DocumentsColumn;
