import { useState } from 'react';
import { useAddOrEditActivityPopupContext } from '../../../../common/addOrEditActivity/context';
import { useIsMounted } from '../../../../../../hooks';
import { RequestStatus } from '../../../../../../types/dataFetching';
import { times } from '../../../../../../constants';
import { addDidacticSequenceAndRelatedClassrooms } from '../../../../../../../../services/agregators';
export function useAddSequenceAndClassrooms(props) {
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const { isMounted } = useIsMounted();
    const { abortSignal, impactTheMDC, sequenceDescription, selectedEvaluationType, setSequenceDescription, getAllSelectedClassrooms, setSelectedEvaluationType } = useAddOrEditActivityPopupContext((store) => ({
        abortSignal: store.abortSignal,
        impactTheMDC: store.impactTheMDC,
        sequenceDescription: store.sequenceDescription,
        selectedEvaluationType: store.selectedEvaluationType,
        setSequenceDescription: store.setSequenceDescription,
        getAllSelectedClassrooms: store.getAllSelectedClassrooms,
        setSelectedEvaluationType: store.setSelectedEvaluationType
    }));
    function resetStatusAfterDelay() {
        setTimeout(() => {
            if (isMounted()) {
                setRequestStatus(RequestStatus.NotStarted);
            }
        }, times.toResetARequestStatus);
    }
    function handleAddSequenceSuccess(response) {
        props.handleActivityAdded(response.data);
        setSequenceDescription('');
        setSelectedEvaluationType(null);
        setRequestStatus(RequestStatus.Succeeded);
    }
    function handleAndSequenceFailure() {
        setRequestStatus(RequestStatus.Failed);
    }
    function add(params) {
        setRequestStatus(RequestStatus.Loading);
        addDidacticSequenceAndRelatedClassrooms({
            abortSignal,
            planningId: params.planningId,
            impactTheMDC,
            description: sequenceDescription,
            classrooms: getAllSelectedClassrooms(),
            evaluationTypeId: selectedEvaluationType?.id,
            areClassroomsUnforeseen: params.areClassroomsUnforeseen
        })
            .then(handleAddSequenceSuccess)
            .catch(handleAndSequenceFailure)
            .finally(resetStatusAfterDelay);
    }
    return { add, requestStatus };
}
