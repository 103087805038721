import { useState, useEffect } from 'react';

import { useContent } from '@campus/discipline';

import Insight from 'models/Insight';
import { getContentInsight } from 'services/insights';

const useInsights = () => {
  const { contentId } = useContent();
  const [loading, setLoading] = useState<boolean>(true);
  const [insight, setInsight] = useState<Insight>();

  useEffect(() => {
    setInsight(undefined);

    const getData = async () => {
      setLoading(true);
      const response = await getContentInsight(contentId);

      if (response.data) {
        setInsight(response.data);
      }

      setLoading(false);
    };

    if (contentId) {
      getData();
    }
  }, [contentId]);

  return { loading, insight };
};

export default useInsights;
