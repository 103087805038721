import styled from 'styled-components';
import { Columns } from '../../../../../TableCommons';
export const Column = styled.td `
  ${Columns.commonStyles}

  padding: 8px 17px 8px 8px;
`;
export const Content = styled.div `
  display: flex;
  align-items: center;
`;
export const Photo = styled.img `
  margin-left: 12px;

  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;

  border-radius: 24px;

  background: url(${({ $url }) => $url}) lightgray 50% / cover no-repeat;
`;
export const NameAndLastUpdate = styled.div `
  margin-left: 5px;
  margin-right: 5px;

  display: flex;
  flex-direction: column;

  span:nth-child(1) {
    font-size: 14px;
    font-weight: 700;
    line-height: 120%;
    color: ${({ theme }) => theme.colors.gray600};
  }

  span:nth-child(2) {
    font-size: 12px;
    font-weight: 500;
    line-height: 120%;
    color: ${({ theme }) => theme.colors.gray400};
  }
`;
export const Actions = styled.div `
  margin-left: auto;

  display: flex;
  column-gap: 7px;
  align-items: center;
`;
