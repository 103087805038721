import styled from 'styled-components';
import Select, { elementClassNames } from '../../../../Basics/Select';
export const StyledSelect = styled(Select) `
  & ${elementClassNames.wrapper} {
    border: none;
    height: auto;

    padding-left: 0;
    padding-right: 0;

    column-gap: 5px;
  }

  & ${elementClassNames.menuWrapper} {
    border-radius: 4px;
  }

  & ${elementClassNames.option} {
    height: 24px;

    padding-left: 12px;

    font-size: 14px;
    font-weight: 600;

    color: ${(props) => props.theme.colors.gray3};
  }

  & ${elementClassNames.option}:hover {
    color: ${(props) => props.theme.colors.gray3};
    background-color: ${(props) => props.theme.colors.gray50};
  }

  & ${elementClassNames.selectedValue} {
    color: white;

    font-size: 24px;
    font-weight: 600;
    font-style: normal;

    line-height: 26px;
  }

  & ${elementClassNames.dropdown} svg {
    width: 25px;
    height: auto;
    color: white;
  }
`;
