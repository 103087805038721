import { useCallback, useEffect, useMemo } from 'react';
import { useFetch } from '../../../../../hooks/dataFetching/useFetch';
import { getEvaluationTypesForDisciplines } from '../../../../../../../services/evaluationType';
export function useFetchEvaluationTypes(disciplines) {
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess } = useFetch({
        initialData: []
    });
    const fetchEvaluationTypes = useCallback((abortSignal) => {
        if (!disciplines?.length)
            return;
        fetchStarted();
        const disciplineIds = disciplines.map((d) => d.id);
        getEvaluationTypesForDisciplines({ abortSignal, disciplineIds })
            .then((response) => handleSuccess(response.data))
            .catch((response) => handleFailure(response.error?.error));
    }, [fetchStarted, handleFailure, handleSuccess, disciplines]);
    useEffect(() => {
        const abortController = new AbortController();
        fetchEvaluationTypes(abortController.signal);
        return () => abortController.abort();
    }, [fetchEvaluationTypes]);
    return useMemo(() => ({ data, status: requestStatus }), [data, requestStatus]);
}
