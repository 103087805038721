import React from 'react';
import Object from './components/Object/Object';
import { StyledWrapper } from './styles';
const KnowledgeObjects = (props) => {
    function renderObjects() {
        const objects = props.planning.objects.sort((a, b) => {
            const aDescription = a.knowledgeObject.description;
            const bDescription = b.knowledgeObject.description;
            return aDescription?.localeCompare(bDescription);
        });
        return objects.map((object) => (React.createElement(Object, { object: object, key: object.id, planning: props.planning, readOnly: props.doNotAllowUpdates, handleObjectUpdated: props.handleObjectUpdated })));
    }
    return React.createElement(StyledWrapper, null, renderObjects());
};
export default KnowledgeObjects;
