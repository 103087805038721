import styled from 'styled-components';
export const StyledWrapper = styled.div `
  position: relative;

  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;

  border-width: 1px;
  border-radius: 50%;
  border-style: solid;
  border-color: ${(props) => props.$borderColor ?? 'black'};
`;
export const StyledInnerCircle = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 50%;

  width: 9px;
  height: 9px;
  min-width: 9px;
  min-height: 9px;

  background-color: ${(props) => props.$backgroundColor || 'black'};
`;
