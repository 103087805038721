import { AlignmentType, BorderStyle, HeightRule, Paragraph, Table, TableCell, TableRow, TextRun, VerticalAlign, WidthType } from 'docx';
import { teacherTheme } from '@campus/components';
import { emptyBorder, emptyTableCellBorders } from '../../../../common/borderDocx';
import { fontSizes, heights, margins, widths } from '../../../../common/sizesDocx';
const columnBorder = {
    style: BorderStyle.SINGLE,
    color: teacherTheme.colors.gray300,
    size: 2
};
function createColumn(params) {
    return new TableCell({
        verticalAlign: VerticalAlign.CENTER,
        width: { size: params.width, type: WidthType.PERCENTAGE },
        borders: {
            left: emptyBorder,
            top: columnBorder,
            bottom: columnBorder,
            right: params.removeRightBorder ? emptyBorder : columnBorder
        },
        children: [
            new Paragraph({
                alignment: params.alignOnStart
                    ? AlignmentType.START
                    : AlignmentType.CENTER,
                indent: {
                    left: params.alignOnStart ? margins.table.header.leftAligned : 0
                },
                children: [
                    new TextRun({
                        bold: true,
                        text: params.label,
                        size: fontSizes.table.headerLabel,
                        color: teacherTheme.colors.gray600
                    })
                ]
            })
        ]
    });
}
export default function Header() {
    return new TableRow({
        height: { value: heights.table.header, rule: HeightRule.EXACT },
        children: [
            new TableCell({
                borders: emptyTableCellBorders,
                width: { size: widths.holeDocument, type: WidthType.PERCENTAGE },
                children: [
                    new Table({
                        columnWidths: [
                            widths.table.columns.annualTimeline.themeUnit,
                            widths.table.columns.annualTimeline.skill,
                            widths.table.columns.annualTimeline.learningObjectives,
                            widths.table.columns.annualTimeline.knowledgeObjects,
                            widths.table.columns.annualTimeline.stage
                        ],
                        width: { size: widths.holeDocument, type: WidthType.PERCENTAGE },
                        rows: [
                            new TableRow({
                                height: { value: heights.table.header, rule: HeightRule.EXACT },
                                children: [
                                    createColumn({
                                        alignOnStart: true,
                                        label: 'Unidade Temática',
                                        width: widths.table.columns.annualTimeline.themeUnit
                                    }),
                                    createColumn({
                                        alignOnStart: true,
                                        label: 'Habilidade',
                                        width: widths.table.columns.annualTimeline.skill
                                    }),
                                    createColumn({
                                        label: 'Objetivos de Aprendizagem',
                                        width: widths.table.columns.annualTimeline.learningObjectives
                                    }),
                                    createColumn({
                                        removeRightBorder: true,
                                        label: 'Objetos de Conhecimento',
                                        width: widths.table.columns.annualTimeline.knowledgeObjects
                                    }),
                                    createColumn({
                                        removeRightBorder: true,
                                        label: 'Etapa',
                                        width: widths.table.columns.annualTimeline.stage
                                    })
                                ]
                            })
                        ]
                    })
                ]
            })
        ]
    });
}
