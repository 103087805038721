import { RequestStatus } from '../../../../../../../../types/dataFetching';
export const placeholders = {
    loading: 'Carregando...',
    nothingToShow: 'Nada a ser exibido.',
    errorHappened: 'Um erro ocorreu ao recuperar as habilidades.',
    notAllComponentsSelected: 'É necessário selecionar todos os componentes curriculares.'
};
function definedButFalse(thing) {
    const isDefined = thing !== undefined;
    return isDefined && !thing;
}
export function getPlaceholderMessage(params) {
    if (definedButFalse(params.isGradeSelected) ||
        definedButFalse(params.isSegmentSelected) ||
        definedButFalse(params.isThereSomeDisciplineSelected)) {
        return placeholders.notAllComponentsSelected;
    }
    return params.requestStatus === RequestStatus.Loading
        ? placeholders.loading
        : params.requestStatus === RequestStatus.Failed
            ? placeholders.errorHappened
            : placeholders.nothingToShow;
}
