import React, { useEffect, useRef, useState } from 'react';
import { ClassLessonAndDateListContext } from './context';
import ClassLessonAndDate from './components/ClassLessonAndDate';
import { StyledContentWrapper, StyledWrapper } from './styles';
const ClassLessonAndDateList = (props) => {
    const [contentWrapperElement, setContentWrapperElement] = useState(null);
    const contentWrapperRef = useRef(null);
    function updateIsChecked(infos, isChecked) {
        const className = infos.associatedClassName;
        props.updateClassInfos(className, { isChecked });
    }
    function updateSelectedClassroom(infos, selectedClassroom) {
        const className = infos.associatedClassName;
        if (props.firstSelectedClassroomChanged &&
            props.classInfos.length > 0 &&
            className === props.classInfos[0].associatedClassName) {
            props.firstSelectedClassroomChanged(selectedClassroom?.number);
        }
        props.updateClassInfos(className, { selectedClassroom });
    }
    function renderContent() {
        return props.classInfos.map((infos) => (React.createElement(ClassLessonAndDate, { key: infos.associatedClassName, infos: infos, updateIsChecked: (isChecked) => updateIsChecked(infos, isChecked), updateSelectedClassroom: (classroom) => updateSelectedClassroom(infos, classroom) })));
    }
    useEffect(() => {
        setContentWrapperElement(contentWrapperRef.current);
    }, []);
    return (React.createElement(ClassLessonAndDateListContext.Provider, { value: { listElement: contentWrapperElement } },
        React.createElement(StyledWrapper, { className: props.className },
            React.createElement(StyledContentWrapper, { ref: contentWrapperRef }, renderContent()))));
};
export default ClassLessonAndDateList;
