export function createMenuOption(params) {
    const value = params.classroom.id;
    const associatedClassroom = params.classroom;
    const numberAsString = params.classroom.number.toString();
    const label = params.addClassPrefix
        ? `Aula ${numberAsString}`
        : numberAsString;
    return { value, label, associatedClassroom };
}
export function calculateMenuPosition(params) {
    const { listElement, menuHeight } = params;
    const { nearestParentBoundingRect: parentRect } = params;
    const scrollAmount = listElement.scrollTop;
    const listRect = listElement.getBoundingClientRect();
    const top = parentRect.bottom - listRect.top - scrollAmount;
    const bottomOverflow = top + menuHeight - listRect.height;
    if (bottomOverflow <= 0)
        return { top };
    const bottom = listRect.bottom - parentRect.top + scrollAmount;
    const topOverflow = bottom + menuHeight - listRect.height;
    if (topOverflow <= 0)
        return { bottom };
    return bottomOverflow < topOverflow ? { top } : { bottom };
}
