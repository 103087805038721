const fontScale = 0.52;
const asPercentage = (value) => value + '%';
export function withFontScale(value) {
    return Math.round(value * fontScale);
}
export const fontSizes = {
    header: {
        title: withFontScale(42),
        subtitle: withFontScale(18),
        information: withFontScale(18)
    },
    table: {
        headerLabel: withFontScale(16),
        columnContent: withFontScale(18),
        skillObservation: withFontScale(12)
    },
    footer: {
        evaluationSection: withFontScale(18),
        pageNumberAndDates: withFontScale(16)
    }
};
export const widths = {
    annualTimelineTableColumns: {
        discipline: asPercentage(10),
        themeUnit: {
            withDisciplineColumn: asPercentage(14.3),
            withoutDisciplineColumn: asPercentage(16.3)
        },
        skill: {
            withDisciplineColumn: asPercentage(23.84),
            withoutDisciplineColumn: asPercentage(25.84)
        },
        learningObjectives: {
            withDisciplineColumn: asPercentage(21.93),
            withoutDisciplineColumn: asPercentage(23.93)
        },
        knowledgeObjects: {
            withDisciplineColumn: asPercentage(18.63),
            withoutDisciplineColumn: asPercentage(20.63)
        },
        stage: {
            withDisciplineColumn: asPercentage(11.3),
            withoutDisciplineColumn: asPercentage(13.3)
        }
    },
    studyUnitTableColumns: {
        discipline: asPercentage(15),
        skill: {
            withDisciplineColumn: asPercentage(29.3),
            withoutDisciplineColumn: asPercentage(34.3)
        },
        learningObjectives: {
            withDisciplineColumn: asPercentage(30),
            withoutDisciplineColumn: asPercentage(35)
        },
        knowledgeObjects: {
            withDisciplineColumn: asPercentage(25.7),
            withoutDisciplineColumn: asPercentage(30.7)
        }
    },
    classDiaryColumns: {
        date: asPercentage(15),
        skill: asPercentage(85)
    }
};
export const paddings = {
    page: {
        top: withFontScale(33),
        bottom: {
            withEvaluationSection: withFontScale(200),
            withoutEvaluationSection: withFontScale(120)
        },
        left: withFontScale(47),
        right: withFontScale(51)
    },
    header: {
        title: {
            right: withFontScale(21)
        }
    },
    table: {
        headerLabels: {
            top: withFontScale(5),
            bottom: withFontScale(5)
        },
        columns: {
            firstOne: {
                left: withFontScale(15)
            },
            discipline: {
                top: withFontScale(10),
                right: withFontScale(16),
                bottom: withFontScale(20),
                left: withFontScale(16)
            },
            skill: {
                top: withFontScale(10),
                others: withFontScale(14),
                bottom: withFontScale(20)
            },
            stage: {
                top: withFontScale(10),
                right: withFontScale(24),
                bottom: withFontScale(20),
                left: withFontScale(14)
            },
            themeUnit: {
                top: withFontScale(10),
                right: withFontScale(15),
                bottom: withFontScale(20),
                left: withFontScale(17)
            },
            learningObjectives: {
                top: withFontScale(10),
                right: withFontScale(15),
                bottom: withFontScale(20),
                left: withFontScale(16)
            },
            knowledgeObjects: {
                top: withFontScale(10),
                right: withFontScale(15),
                bottom: withFontScale(20),
                left: withFontScale(18)
            }
        }
    },
    footer: {
        evaluationSection: {
            bottom: withFontScale(18)
        }
    }
};
export const margins = {
    header: {
        infos: {
            left: withFontScale(25)
        },
        subTitle: {
            top: withFontScale(10)
        }
    },
    table: {
        header: {
            top: withFontScale(33),
            bottom: withFontScale(20)
        },
        columns: {
            skill: {
                observation: {
                    top: withFontScale(6)
                }
            }
        }
    },
    footer: {
        top: {
            withEvaluationSection: withFontScale(40),
            withoutEvaluationSection: withFontScale(10)
        },
        bottom: {
            withEvaluationSection: withFontScale(50) - paddings.page.bottom.withEvaluationSection,
            withoutEvaluationSection: withFontScale(50) - paddings.page.bottom.withoutEvaluationSection
        },
        pageAndDates: {
            top: withFontScale(26)
        },
        evaluationSection: {
            subtitle: {
                top: withFontScale(10)
            }
        }
    }
};
export const logo = {
    width: withFontScale(216),
    height: withFontScale(56)
};
