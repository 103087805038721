import React from 'react';
import { CustomClassicCKEditor } from '@campus/components';
import { useAddOrEditActivityPopupContext } from '../../../../context';
import { useUploadImage } from './hooks/useUploadImage';
import { StyledWrapper } from './styles';
const Editor = () => {
    const { sequenceDescription, setSequenceDescription } = useAddOrEditActivityPopupContext((store) => ({
        sequenceDescription: store.sequenceDescription,
        setSequenceDescription: store.setSequenceDescription
    }));
    const { uploadImage } = useUploadImage();
    function handleContentChanged(newContent) {
        setSequenceDescription(newContent);
    }
    return (React.createElement(StyledWrapper, null,
        React.createElement(CustomClassicCKEditor.Editor, { content: sequenceDescription, onImageUpload: uploadImage, onChange: handleContentChanged, placeholder: "Ex.: Aula expositiva sobre Ecologia (Objetivo 14C)", customPropertiesAndTheirValues: {
                defaultZIndex: '5000',
                modalZIndex: '5001'
            } })));
};
export default Editor;
