import styled from 'styled-components';

export const FilterContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
  height: 75px;
  width: 100%;
`;
export const FilterColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 0px 15px;
  align-items: start;
  
`;

export const ContainerText = styled.div`
  margin-right: 7px;
  height: 100%;
`;

export const StyledText = styled.p`
  color: ${(props) => props.theme.colors.gray300};
  font-weight: ${(props) => props.theme.weight.semiBold};
  line-height: 20px;
  font-style: normal;
  font-size: 14px;
  font-family: 'Gilroy';
`;
