import { getDateInfos } from '../../../../../../../helpers/dates';
export function calculateDistanceToLeft(startDate, months, parentElement) {
    const infos = getDateInfos(startDate, months, parentElement);
    if (!infos)
        return -1;
    if (infos.dateIsInsideAWeek === true) {
        const { weekElementRect: weekRect } = infos;
        const { parentElementRect: parentRect } = infos;
        const { percentageOfDateInWeek: percentageOfDate } = infos;
        const { percentageOfEachDayInWeek: percentageOfDay } = infos;
        let offset = percentageOfDate * weekRect.width;
        if (offset > 0)
            offset -= percentageOfDay * weekRect.width;
        return Math.round(weekRect.left - parentRect.left + offset);
    }
    const { parentElementRect: parentRect } = infos;
    const { leftWeekElementRect: leftRect } = infos;
    const { rightWeekElementRect: rightRect } = infos;
    const { percentageOfDateBetweenWeeks: percentageOfDate } = infos;
    const { percentageOfEachDayBetweenWeeks: percentageOfDay } = infos;
    const distanceBetweenWeeks = rightRect.left - leftRect.right;
    let offset = percentageOfDate * distanceBetweenWeeks;
    if (offset > 0)
        offset -= percentageOfDay * distanceBetweenWeeks;
    return Math.round(leftRect.right - parentRect.left + offset);
}
export function calculateLineWidth(endDate, months, parentElement, distanceFromLeft) {
    const infos = getDateInfos(endDate, months, parentElement);
    if (!infos)
        return -1;
    if (infos.dateIsInsideAWeek === true) {
        const { weekElementRect: weekRect } = infos;
        const { parentElementRect: parentRect } = infos;
        const { percentageOfDateInWeek: percentageOfDate } = infos;
        const offset = percentageOfDate * weekRect.width;
        const left = weekRect.left - parentRect.left + offset;
        return Math.round(left - distanceFromLeft);
    }
    const { parentElementRect: parentRect } = infos;
    const { leftWeekElementRect: leftRect } = infos;
    const { rightWeekElementRect: rightRect } = infos;
    const { percentageOfDateBetweenWeeks: percentageOfDate } = infos;
    const distanceBetweenWeeks = rightRect.left - leftRect.right;
    const offset = percentageOfDate * distanceBetweenWeeks;
    const left = leftRect.right - parentRect.left + offset;
    return Math.round(left - distanceFromLeft);
}
