import React from 'react';
import { useCalendarContext } from '../../context';
import Row from './components/Row';
import { StyledWrapper } from './styles';
const CalendarBody = React.forwardRef((_, ref) => {
    const groups = useCalendarContext((store) => store.groups);
    function renderContent() {
        return groups.map((group, index) => (React.createElement(Row, { key: group.number, index: index, group: group })));
    }
    return React.createElement(StyledWrapper, { ref: ref }, renderContent());
});
export default CalendarBody;
