import styled from 'styled-components';
export const StyledWrapper = styled.div `
  height: 100%;

  padding: 5px 8px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledTextArea = styled.textarea `
  padding: 8px 12px;

  width: 220px;
  height: 100%;
  min-height: 280px;

  background-color: white;

  color: ${(props) => props.theme.colors.gray700};

  border-width: 1px;
  border-style: solid;
  border-radius: 6px;

  border-color: ${(props) => props.$useBlueBorder
    ? props.theme.colors.blue1
    : props.theme.colors.gray200};

  &,
  &::placeholder {
    font-style: normal;
    font-size: 12px;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.gray4};
  }

  &:focus {
    outline: none;
  }
`;
