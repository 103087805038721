import React, { useMemo } from 'react';
import { AddSkillPopupContext } from '.';
import { createAddSkillPopupStore } from '../store';
export const Provider = (props) => {
    const store = useMemo(() => {
        return createAddSkillPopupStore({
            mainTableContent: props.mainTableContent,
            allStages: props.allStages,
            allGrades: props.allGrades,
            allSegments: props.allSegments,
            allDisciplines: props.allDisciplines,
            allPlanningStatuses: props.allPlanningStatuses,
            selectedGrade: props.selectedGrade,
            selectedSegment: props.selectedSegment,
            selectedDisciplines: props.selectedDisciplines,
            handleAddSkillSucceeded: props.handleAddSkillSucceeded,
            addPlanningToGroupWithNumber: props.addPlanningToGroupWithNumber
        });
    }, [
        props.mainTableContent,
        props.allStages,
        props.allGrades,
        props.allSegments,
        props.allDisciplines,
        props.allPlanningStatuses,
        props.selectedGrade,
        props.selectedSegment,
        props.selectedDisciplines,
        props.addPlanningToGroupWithNumber,
        props.handleAddSkillSucceeded
    ]);
    return (React.createElement(AddSkillPopupContext.Provider, { value: store }, props.children));
};
