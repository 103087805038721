import { handleApiCallError } from '../common';
import { addClassroomAndDidacticSequence } from '../classroomAndDidaticSequence';
import { addDidacticSequence, getDidaticSequenceById } from '../didaticSequence';
export async function addDidacticSequenceAndRelatedClassrooms(params) {
    try {
        const addSequenceResponse = await addDidacticSequence({
            abortSignal: params.abortSignal,
            planningId: params.planningId,
            description: params.description,
            impactTheMDC: params.impactTheMDC,
            evaluationTypeId: params.evaluationTypeId
        });
        await addClassroomsOrClassroomsAndSequences(addSequenceResponse.data?.id, params);
        return getDidaticSequenceById({
            abortSignal: params.abortSignal,
            id: addSequenceResponse.data?.id
        });
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
function isClassroomAndDidacticSequence(item) {
    const casted = item;
    return casted?.classroom !== undefined;
}
async function addClassroomsOrClassroomsAndSequences(sequenceId, params) {
    const promises = params.classrooms.map((c) => {
        const isJustClassroom = !isClassroomAndDidacticSequence(c);
        return addClassroomAndDidacticSequence({
            sequenceId,
            abortSignal: params.abortSignal,
            executed: isJustClassroom ? false : c.wasExecuted,
            classroomId: isJustClassroom ? c.id : c.classroom?.id,
            executedClassroomId: isJustClassroom ? null : c.executedClassroom?.id,
            foreseen: params.areClassroomsUnforeseen
                ? false
                : isJustClassroom
                    ? true
                    : c.foreseen ?? true
        });
    });
    await Promise.all(promises);
}
