import styled from 'styled-components';
export const StyledWrapper = styled.div `
  width: 100%;

  flex-grow: 1;

  overflow: auto;

  display: flex;
  flex-direction: column;

  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.gray300};
`;
export const StyledMessageWrapper = styled.div `
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => {
    return props.$fillSpace ? 'flex-grow: 1;' : 'height: 150px;';
}}
`;
export const StyledMessage = styled.i `
  color: ${(props) => props.theme.colors.gray500};
`;
