import React from 'react';
import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
const StyledIcon = styled(CampusIcon).attrs({
    name: 'padlock'
}) `
  margin: auto;
  display: block;
  color: ${(props) => props.theme.colors.error};
`;
const PadLock = () => {
    return React.createElement(StyledIcon, null);
};
export default PadLock;
