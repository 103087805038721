import styled from 'styled-components';
import { breakpoints } from '../../breakpoints';
export const Container = styled.div `
  flex: 1;

  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 14px;

  @media (max-height: ${breakpoints.smallScreenHeight}) {
    overflow: visible;
  }
`;
export const TableAndNoContent = styled.div `
  flex: 1;

  width: 100%;

  overflow: auto;

  @media (max-height: ${breakpoints.smallScreenHeight}) {
    overflow: visible;
  }
`;
