import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const NotAllCurriculumComponentsSelectedIcon = styled(CampusIcon).attrs({
    name: 'noCurriculumComponentsSelected'
}) `
  & path {
    fill: rgba(0, 0, 0, 0);
    stroke: ${(props) => props.theme.colors.blue1};
  }
`;
export const StyledNoSkillAddedIcon = styled(CampusIcon).attrs({
    name: 'noSkillAdded'
}) `
  & path {
    fill: rgba(0, 0, 0, 0);
    stroke: ${(props) => props.theme.colors.blue1};
  }
`;
