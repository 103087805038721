import React from 'react';

import format from 'date-fns/format';
import { useQuestionReview } from '../../../context/QuestionReviewContext';
import { useShowCorrection } from '../context/ShowCorrectionContext';

import {
  Container,
  Title,
  ScoreTag,
  TeacherComment,
  ActionContainer
} from '../styles';

const TeacherCorrection: React.FC = () => {
  const { answer } = useQuestionReview();
  const { onEdit } = useShowCorrection();

  return (
    <>
      <Container>
        <Title>Correção do Professor</Title>
        <ScoreTag>{`${answer?.teacherScore} Pontos`}</ScoreTag>
      </Container>
      <TeacherComment>{answer?.teacherCommnent}</TeacherComment>
      <ActionContainer>
        {answer?.teacherScoreTime && (
          <p>{format(answer.teacherScoreTime, 'dd/MM/yyyy HH:mm')}</p>
        )}
        &nbsp;|&nbsp;
        <button onClick={onEdit}>Editar</button>
      </ActionContainer>
    </>
  );
};

export default TeacherCorrection;
