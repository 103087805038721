import LaunchReportDiscipline from './LaunchReportDiscipline';
export default class LaunchReportTeacher {
    id;
    name;
    photoUrl;
    lastUpdateDate;
    totalNumberOfEmptyClasses;
    totalNumberOfClasses;
    _disciplines;
    get disciplines() {
        return this._disciplines;
    }
    set disciplines(disciplines) {
        this._disciplines = disciplines;
        this.totalNumberOfClasses = disciplines.reduce((sum, discipline) => sum + discipline.classes.length, 0);
    }
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new LaunchReportTeacher();
        instance.id = json.Id;
        instance.name = json.Name;
        instance.photoUrl = json.ImageUrl;
        instance.totalNumberOfEmptyClasses = json.TotalEmptyClassroomsCount;
        instance.lastUpdateDate = json.LastUpdate
            ? new Date(json.LastUpdate)
            : null;
        instance.disciplines = Array.isArray(json.Disciplines)
            ? json.Disciplines.map((d) => LaunchReportDiscipline.fromJson(d))
            : [];
        return instance;
    }
}
