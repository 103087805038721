import { compareAreaSkills } from './sort';
function sortSequence(sequence) {
    return sequence.sort((a, b) => {
        return a.order - b.order;
    });
}
function sortPlannings(plannings) {
    return plannings.sort((a, b) => {
        if (a.order !== b.order) {
            return a.order - b.order;
        }
        const aSkill = a.areaSkill;
        const bSkill = b.areaSkill;
        return compareAreaSkills(aSkill, bSkill);
    });
}
export function sortPlanningGroups(groups) {
    const sortedGroups = groups?.sort((a, b) => {
        return a.number - b.number;
    });
    for (const group of sortedGroups) {
        const { didacticSequence, plannings } = group;
        group.plannings = sortPlannings(plannings);
        group.didacticSequence = sortSequence(didacticSequence);
    }
    return sortedGroups;
}
