import styled from 'styled-components';
import { zIndices } from '../../../../../../../stylesRelated';
export const StyledWrapper = styled.svg `
  fill: none;

  z-index: ${zIndices.datesRangeLine};

  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;

  position: absolute;
  top: ${(props) => props.$top}px;
  left: ${(props) => props.$left}px;
`;
export const StyledLine = styled.line `
  opacity: 0.5;
  fill: #878787;
  stroke: #878787;
  stroke-width: 1px;
`;
