import { useEffect, useCallback } from 'react';
import { useAddSkillPopupContext } from '../../../../../../context';
import { getAreaSkillsGrouped } from '../../../../../../../../../../../services/areaSkill';
import { useFetchTableContentCommon } from '../../../../common/components/Table/hooks/useFetchTableContentCommon';
const pageSize = 40;
export function useFetchTableContent() {
    const { filteredStageId, filteredSkillCode, selectedDisciplines, selectedGrade, selectedSegment } = useAddSkillPopupContext((store) => ({
        filteredStageId: store.filters.filteredStageId,
        filteredSkillCode: store.filters.filteredSkillCode,
        selectedGrade: store.selectedGrade,
        selectedSegment: store.selectedSegment,
        selectedDisciplines: store.selectedDisciplines
    }));
    const { content, requestStatus, pageIndex, handleFetchFailed, handleFetchStarted, handleFetchSucceeded, canFetchMoreContent, advancePageIndex, resetContentAndPageIndex, resetStatusAfterDelay } = useFetchTableContentCommon();
    const fetchSkills = useCallback((abortSignal) => {
        if (!selectedGrade)
            return;
        if (!selectedSegment)
            return;
        if (!selectedDisciplines)
            return;
        if (selectedDisciplines.length === 0)
            return;
        handleFetchStarted();
        getAreaSkillsGrouped({
            abortSignal,
            pageSize,
            pageIndex: pageIndex(),
            gradeId: selectedGrade.id,
            segmentId: selectedSegment.id,
            stageId: filteredStageId,
            skillCode: filteredSkillCode,
            disciplineId: selectedDisciplines.map((d) => d.id).join(',')
        })
            .then(handleFetchSucceeded)
            .catch(handleFetchFailed)
            .finally(resetStatusAfterDelay);
    }, [
        selectedGrade,
        selectedSegment,
        selectedDisciplines,
        handleFetchStarted,
        pageIndex,
        filteredStageId,
        filteredSkillCode,
        handleFetchSucceeded,
        handleFetchFailed,
        resetStatusAfterDelay
    ]);
    const fetchMoreSkills = useCallback((abortSignal) => {
        if (canFetchMoreContent()) {
            advancePageIndex();
            fetchSkills(abortSignal);
        }
    }, [fetchSkills, advancePageIndex, canFetchMoreContent]);
    useEffect(() => {
        resetContentAndPageIndex();
        const abortController = new AbortController();
        fetchSkills(abortController.signal);
        return () => abortController.abort();
    }, [fetchSkills, resetContentAndPageIndex]);
    return { data: content, requestStatus, fetchMoreSkills };
}
