import React from 'react';
import Description from '../common/objectsAndObjectives/components/Description';
import { StyledWrapper } from '../LearningObjectives/styles';
const LearningObjectives = (props) => {
    function renderObjectives() {
        const sorted = props.areaSkill.learningObjectives?.sort((a, b) => {
            return a?.code?.localeCompare(b?.code);
        });
        return sorted?.map((objective) => (React.createElement(Description, { disabled: true, key: objective.id, content: objective.description, emphasize: objective.isEvaluative, code: {
                text: objective.code,
                color: props.areaSkill?.discipline?.color
            } })));
    }
    return React.createElement(StyledWrapper, null, renderObjectives());
};
export default LearningObjectives;
