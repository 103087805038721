import { Paragraph, TableCell, TextRun, VerticalAlign, WidthType } from 'docx';
import { emptyTableCellBorders } from '../../../../common/borderDocx';
import { fontSizes, margins, widths } from '../../../../common/sizesDocx';
function createLine(boldText, normalText) {
    return new Paragraph({
        indent: { left: margins.header.informations.left },
        children: [
            new TextRun({
                bold: true,
                text: boldText,
                size: fontSizes.header.information
            }),
            new TextRun({
                bold: false,
                text: normalText,
                size: fontSizes.header.information
            })
        ]
    });
}
export default function Information(informationData, stage) {
    return new TableCell({
        rowSpan: 4,
        borders: emptyTableCellBorders,
        verticalAlign: VerticalAlign.CENTER,
        width: { size: widths.header.informations, type: WidthType.PERCENTAGE },
        children: [
            createLine('PERÍODO: ', stage),
            createLine('ANO/ SÉRIE: ', `${informationData.grade} do ${informationData.segment}`),
            createLine('CARGA HORÁRIA SEMANAL: ', `${informationData.weeklyWorkload ?? '---'} horas / aula`),
            createLine('QUANTIDADE APROXIMADA DE AULAS/ SEMESTRE: ', `${informationData.approximateNumberOfClasses ?? '---'} aulas`)
        ]
    });
}
