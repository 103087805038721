import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import { Overlay } from 'react-bootstrap';
import { useContextManager, StatusIcon } from '@campus/commons';
import { DataGrid, DataGridProps, Skeleton } from '@campus/components';

import StudentAnswerHistory from 'models/StudentAnswerHistory';
import StudentAnswerReport from 'models/StudentAnswerReport';
import { capitalizeFirstLetter } from 'services/student';

import useStudentAnswerHistory from '../../../../hooks/useStudentAnswerHistory';
import { useActivityReport } from '../../../../context/ActivityReportContext';

import {
  TriedPopoverContainer,
  TriedContent,
  TriedHeader,
  TriedBody,
  TriedFooter,
  TriedTitle,
  CenterElement,
  ScoreText,
  StyledButton,
  DataText
} from './styles';

type ChildMenuProps = {
  ref: React.MutableRefObject<any>;
  onClick: (e: React.MouseEvent<any>) => void;
};

type TriedPopoverProps = {
  containerRef: React.MutableRefObject<any>;
  children: (menuProps: ChildMenuProps) => React.ReactChild;
  data: StudentAnswerReport;
};

const TriedPopover: React.FC<TriedPopoverProps> = ({
  containerRef,
  children,
  data
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { isCurrentPeriod } = useContextManager();

  const { refetchAnswer, questionWeight } = useActivityReport();

  const buttonRef = useRef(null);
  const MEDIA = 0.6;

  const columns = useMemo<DataGridProps['columns']>(() => {
    const defaultColumns: any = [
      {
        Header: 'Data',
        accessor: 'startTimeFormatted',
        width: 8,
        Cell: ({ value }) => <DataText>{value}</DataText>
      },
      {
        Header: 'Status',
        accessor: 'state',
        width: 3,
        Cell: ({ value, row }) => (
          <CenterElement>
            <StatusIcon
              status={value}
              statusName={row?.original?.stateText}
              statusTitle={row?.original?.statusTitle}
              stateDate={row?.original?.stateDate}
            />
          </CenterElement>
        )
      },
      {
        Header: 'Nota do Sistema',
        accessor: 'systemScore',
        width: 6,
        Cell: ({ value }) => (
          <ScoreText
            color={`${value / questionWeight < MEDIA ? '#EB5757' : '#6FCF97'}`}
          >
            {value}
          </ScoreText>
        )
      },
      {
        Header: 'Nota do Professor',
        accessor: 'teacherScore',
        width: 6,
        Cell: ({ value }) => (
          <ScoreText
            color={`${value / questionWeight < MEDIA ? '#EB5757' : '#6FCF97'}`}
          >
            {value}
          </ScoreText>
        )
      }
    ];

    return defaultColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { history, isLoading, fetchData, updateData } =
    useStudentAnswerHistory();

  useEffect(() => {
    if (isOpen) {
      fetchData(data.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onButtonClick = (e: React.MouseEvent<any>) => {
    e.preventDefault();

    setIsOpen((prev) => !prev);
    if (isDirty) refetchAnswer();
  };

  const getSelectedRowsIds = useCallback(() => {
    const obj = {};
    history?.forEach((element: StudentAnswerHistory, index) => {
      if (element?.isPrimary) {
        obj[index] = true;
      }
    });
    return obj;
  }, [history]);

  const handleSelectedTried = async (rowSelected: any) => {
    if (!rowSelected[0]?.isPrimary && isCurrentPeriod) {
      await updateData(data.id, rowSelected[0]);
      setIsDirty(true);
    }
  };

  return (
    <>
      {children({ ref: buttonRef, onClick: onButtonClick })}
      {isOpen && (
        <Overlay
          rootClose
          target={buttonRef.current}
          show={isOpen}
          placement="right"
          onHide={() => {
            setIsOpen(false);
            if (isDirty) refetchAnswer();
          }}
          container={containerRef.current}
        >
          <TriedPopoverContainer id="popover-contained">
            <TriedContent>
              <TriedHeader>
                <TriedTitle>
                  Tentativas de {capitalizeFirstLetter(data.student.name)}
                </TriedTitle>
                <StyledButton onClick={onButtonClick}>Fechar</StyledButton>
              </TriedHeader>
              <TriedBody>
                {(isLoading && <Skeleton width="100%" height="50px" />) || (
                  <DataGrid
                    data={history}
                    columns={columns}
                    onRowsSelected={(rows) => handleSelectedTried(rows)}
                    onRowsSelectedWidth={1}
                    onRowsSelectedRadio
                    selectedRowIds={getSelectedRowsIds()}
                    disabled={!isCurrentPeriod}
                  />
                )}
              </TriedBody>
              <TriedFooter>
                <span id="title">Como funciona?</span>
                <span>
                  Nesse quadro o professor pode selecionar entre as várias
                  tentativas de resolução da atividade do aluno. Também é
                  possível deixar todas sem seleção, o que representa uma
                  anulação dessa atividade pelo professor para aquele aluno
                  específico.
                </span>
              </TriedFooter>
            </TriedContent>
          </TriedPopoverContainer>
        </Overlay>
      )}
    </>
  );
};
export default TriedPopover;
