import React, { useEffect, useState } from 'react';
import { useLaunchReportContext } from '../../../../context';
import { useTableAndRelatedContext } from '../../context';
import { Checkbox } from '../../../Checkbox';
import { SendEmailButton } from './components';
import { Container, NumberOfSelectedItems, SelectAll } from './styles';
export const SelectionActionsAndIndicators = () => {
    const { events } = useLaunchReportContext();
    const { getTotalNumberOfTeachers, areAllTeachersSelected, getNumberOfSelectedTeachers } = useTableAndRelatedContext();
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [numberOfTeachersSelected, setNumberOfTeachersSelected] = useState(0);
    function onSelectAllChange(event) {
        const isChecked = event.target.checked;
        setIsAllSelected(isChecked);
        if (isChecked) {
            setNumberOfTeachersSelected(getTotalNumberOfTeachers());
        }
        else {
            setNumberOfTeachersSelected(0);
        }
        events.table.selectAllChanged.emit({ isChecked });
    }
    useEffect(() => {
        return events.table.teacherSelectionChanged.subscribe(() => {
            setIsAllSelected(areAllTeachersSelected());
            setNumberOfTeachersSelected(getNumberOfSelectedTeachers());
        });
    }, [
        events.table.teacherSelectionChanged,
        areAllTeachersSelected,
        getNumberOfSelectedTeachers
    ]);
    useEffect(() => {
        return events.filters.someFilterChanged.subscribe(() => {
            setIsAllSelected(areAllTeachersSelected());
            setNumberOfTeachersSelected(getNumberOfSelectedTeachers());
        });
    }, [
        events.filters.someFilterChanged,
        areAllTeachersSelected,
        getNumberOfSelectedTeachers
    ]);
    return (React.createElement(Container, null,
        React.createElement(SelectAll, null,
            React.createElement(Checkbox, { id: "select-all", checked: isAllSelected, onChange: onSelectAllChange }),
            React.createElement("label", { htmlFor: "select-all" }, "Selecionar todos")),
        React.createElement(NumberOfSelectedItems, null,
            React.createElement("span", null, numberOfTeachersSelected === 1
                ? '1 item selecionado'
                : `${numberOfTeachersSelected} itens selecionados`),
            React.createElement(SendEmailButton, null))));
};
