import { getMainClassroomAndSequenceFrom } from './sequenceRelated';
import { getMaximumDateFrom, getMinimumDateFrom } from '../../../../../../../../utils/arrays';
// function getPlannedDatesFrom(sequences: DidaticSequence[]) {
//   return sequences
//     ?.flatMap((s) => {
//       return s.classrooms?.map((c) => {
//         return c.classroom?.date ?? null;
//       });
//     })
//     ?.filter((date) => !!date);
// }
// function getAccomplishedDatesFrom(sequences: DidaticSequence[]) {
//   return sequences
//     ?.flatMap((s) => {
//       return s.classrooms
//         ?.filter((c) => c.wasExecuted)
//         ?.map((c) => {
//           return c.executedClassroom?.date ?? null;
//         });
//     })
//     ?.filter((date) => !!date);
// }
function getPlannedDatesFrom(sequences) {
    return sequences
        ?.filter((s) => !s.isDeleted)
        ?.map((s) => {
        const main = getMainClassroomAndSequenceFrom(s?.classrooms);
        return main?.classroom?.date ?? null;
    })
        ?.filter((date) => !!date);
}
function getAccomplishedDatesFrom(sequences) {
    return sequences
        ?.filter((s) => !s.isDeleted)
        ?.map((s) => {
        const main = getMainClassroomAndSequenceFrom(s?.classrooms);
        if (!main || !main.wasExecuted)
            return null;
        return main.executedClassroom?.date ?? null;
    })
        ?.filter((date) => !!date);
}
export function getPlannedStartDate(sequences) {
    const dates = getPlannedDatesFrom(sequences) ?? [];
    return getMinimumDateFrom(dates);
}
export function getPlannedEndDate(sequences) {
    const dates = getPlannedDatesFrom(sequences) ?? [];
    return getMaximumDateFrom(dates);
}
export function getAccomplishedStartDate(sequences) {
    const dates = getAccomplishedDatesFrom(sequences) ?? [];
    const date = getMinimumDateFrom(dates);
    const expectedDate = getPlannedStartDate(sequences);
    return { date, isLate: date > expectedDate };
}
export function getAccomplishedEndDate(sequences) {
    const dates = getAccomplishedDatesFrom(sequences) ?? [];
    const date = getMaximumDateFrom(dates);
    const expectedDate = getPlannedEndDate(sequences);
    return { date, isLate: date > expectedDate };
}
