import { handleApiCallError } from '../common';
import { getDidaticSequenceById, updateDidaticSequence } from '../didaticSequence';
import { deleteClassroomAndDidacticSequences } from './deleteClassroomAndDidacticSequences';
async function deleteSequence(params) {
    return updateDidaticSequence({
        abortSignal: params.abortSignal,
        isDeleted: true,
        id: params.sequence.id,
        newIndex: params.sequence.order,
        description: params.sequence.description,
        impactTheMDC: params.sequence.impactTheMDC,
        evaluationTypeId: params.sequence.evaluationType?.id
    });
}
function getClassroomAndSequencesToDelete(params) {
    return params.sequence.classrooms
        ?.sort((a, b) => {
        const aClassName = a.classroom?.class?.name;
        const bClassName = b.classroom?.class?.name;
        return aClassName?.localeCompare(bClassName, undefined, {
            sensitivity: 'accent'
        });
    })
        ?.slice(1);
}
export async function deleteDidaticSequenceLogicallyAndItsClassroomsExceptByTheMainOne(params) {
    try {
        await deleteClassroomAndDidacticSequences({
            abortSignal: params.abortSignal,
            classroomAndSequences: getClassroomAndSequencesToDelete(params)
        });
        await deleteSequence(params);
        return getDidaticSequenceById({
            id: params.sequence.id,
            abortSignal: params.abortSignal
        });
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
