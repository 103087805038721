import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const StyledHeaderWrapper = styled.div `
  min-height: 36px;

  padding: 5px 5px 5px 11px;

  display: flex;
  column-gap: 6px;
  align-items: center;

  background-color: ${(props) => props.$backgroundColor
    ? props.$backgroundColor
    : props.theme.colors.gray700};
`;
export const StyledArrowRightIconWrapper = styled.div `
  height: 100%;
  width: fit-content;

  & div {
    display: block;
    height: 100%;
    min-width: 13px;
    width: fit-content;
  }
`;
export const StyledArrowRightIcon = styled(CampusIcon).attrs({
    name: 'fullArrowRight',
    size: 12
}) `
  color: white;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const StyledHeaderText = styled.span `
  font-size: 14px;
  font-style: normal;

  color: white;
`;
export const StyledItemTitle = styled(StyledHeaderText) `
  font-weight: normal;
`;
export const StyledDisciplineName = styled(StyledHeaderText) `
  font-weight: 700;
`;
