import React from 'react';

import { CardBanner, Skeleton } from '@campus/components';

import Insight from 'models/Insight';
import {
  CardContainer,
  StyledCard,
  RowContent,
  InfoText,
  MessageNotInsigts
} from '../styles';

type InsightCardProps = {
  title: string;
  percentage?: string;
};

const InsightCard: React.FC<InsightCardProps> = ({
  title,
  percentage,
  children
}) => {
  return (
    <StyledCard>
      <h1>{title}</h1>
      <h2>{percentage}%</h2>
      <p>{children}</p>
    </StyledCard>
  );
};

type InsightsProps = {
  insight: Insight;
  loading: boolean;
};
const Insights: React.FC<InsightsProps> = ({ insight, loading }) => {
  return (
    <RowContent>
      <h1>Insights</h1>
      {!loading && insight.answerCount === 0 ? (
        <MessageNotInsigts>
          <CardBanner
            variant="danger"
            subTitle={
              <>
                <b>Atenção:</b> A atividade ainda não recebeu nenhuma resposta
                dos estudantes. <br /> Assim que o primeiro estudante responder
                o relatório será habilitado.
              </>
            }
          />
        </MessageNotInsigts>
      ) : (
        <>
          <h2>
            Números rápidos pra você entender o desempenho dos estudantes nesta
            atividade.
          </h2>

          <CardContainer>
            {loading ? (
              <Skeleton width="150px" height="125px" />
            ) : (
              <InsightCard
                title="Respostas"
                percentage={insight?.answerPercentage
                  .toString()
                  .replace('.', ',')}
              >
                {insight?.answerCount} de {insight?.totalStudents}
                &nbsp;estudantes responderam a atividade
              </InsightCard>
            )}

            {loading ? (
              <Skeleton width="150px" height="125px" />
            ) : (
              <InsightCard
                title="Média"
                percentage={insight?.averagePercentage
                  .toString()
                  .replace('.', ',')}
              >
                {insight?.averageScore.toString().replace('.', ',')} é a média
                da turma em {insight?.totalWeight.toString().replace('.', ',')}
                &nbsp;pontos distribuídos
              </InsightCard>
            )}

            {loading ? (
              <Skeleton width="150px" height="125px" />
            ) : (
              <InsightCard
                title="Acima da Média"
                percentage={insight?.aboveAvgStudentPercentage
                  .toString()
                  .replace('.', ',')}
              >
                {insight?.aboveAvgStudentCount} de {insight?.answerCount}
                &nbsp;estudantes ficaram <b>acima da média</b> da turma
              </InsightCard>
            )}

            {loading ? (
              <Skeleton width="150px" height="125px" />
            ) : (
              <InsightCard
                title="Abaixo da Média"
                percentage={insight?.bellowAvgStudentPercentage
                  .toString()
                  .replace('.', ',')}
              >
                {insight?.bellowAvgStudentCount} de {insight?.answerCount}
                &nbsp;estudantes ficaram <b>abaixo da média</b> da turma
              </InsightCard>
            )}
          </CardContainer>

          <InfoText>
            Obs.: Os dados acima consideram respostas dos alunos e lançamentos
            de notas pelo professor.
          </InfoText>
        </>
      )}
    </RowContent>
  );
};

export default Insights;
