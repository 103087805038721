import React from 'react';
import { QuestionType } from '@campus/commons';
import { CardBanner } from '@campus/components';

import InsightQuestion from 'models/InsightQuestion';
import Insight from 'models/Insight';

import QuestionAnswers from './QuestionAnswers';

import {
  BarChartContainerTitles,
  Question,
  BarChartContainer,
  BarChartTitles,
  BarChartRangeColor,
  BarChartContainerAverage,
  BarChartTitleAverage,
  MarginCardBanner,
  RowContent,
  StyledCard
} from '../styles';

type QuestionAnalyzeProps = {
  questions: InsightQuestion[];
  insigths: Insight;
};

const renderType = (questionType: QuestionType) => {
  switch (questionType) {
    case QuestionType.OPEN:
    case QuestionType.ATTACHMENT:
      return 'Aberta';
    case QuestionType.MULTIPLE:
      return 'Múltipla Opção';
    case QuestionType.UNIQUE:
      return 'Opção Única';
  }
};

const QuestionAnalyze: React.FC<QuestionAnalyzeProps> = ({
  questions,
  insigths
}) => {
  return (
    <RowContent $marginTop={28}>
      <h1>Análise por Questão</h1>
      <h2>
        Acompanhe a taxa de desempenho dos estudantes questão por questão e
        entenda quais tiveram maior ou menor dificuldade.
      </h2>
      <RowContent $marginTop={12}>
        {insigths.answerCount > 0 &&
          insigths.teacherAnswerCount === insigths.answerCount && (
            <MarginCardBanner>
              <CardBanner
                variant="danger"
                subTitle={
                  <>
                    <b>Atenção:</b> O relatório de análise por questão foi
                    desabilitado, pois <b>TODAS</b> as notas desta atividade
                    foram lançadas diretamente pelo professor.
                  </>
                }
              />
            </MarginCardBanner>
          )}

        {insigths.teacherAnswerCount > 0 &&
          insigths.teacherAnswerCount !== insigths.answerCount && (
            <MarginCardBanner>
              <CardBanner
                variant="danger"
                subTitle={
                  <>
                    <b>Atenção:</b> Somente{' '}
                    {insigths.answerCount - insigths.teacherAnswerCount} das{' '}
                    {insigths.answerCount} respostas foram consideradas no
                    relatório abaixo, pois
                    <br /> outras {insigths.teacherAnswerCount} tiveram suas
                    notas lançadas diretamente pelo professor.
                  </>
                }
              />
            </MarginCardBanner>
          )}

        {((insigths.answerCount === 0 && insigths.teacherAnswerCount === 0) ||
          insigths.answerCount !== insigths.teacherAnswerCount) && (
          <StyledCard>
            {questions?.map((q, i) => (
              <Question key={i}>
                <p>
                  {i + 1}. {q.title}
                </p>
                <span>{renderType(q.questionType)}</span>
                <QuestionAnswers
                  questionType={q.questionType}
                  answers={q.answers}
                />

                {renderType(q.questionType) === 'Aberta' && (
                  <BarChartContainer>
                    <BarChartContainerTitles>
                      <BarChartRangeColor $range={1} />
                      <BarChartTitles>nota 0%</BarChartTitles>
                      <BarChartRangeColor $range={2} />
                      <BarChartTitles>nota entre 0,01 e 59,99%</BarChartTitles>
                      <BarChartRangeColor $range={3} />
                      <BarChartTitles>nota entre 60 e 99,99%</BarChartTitles>
                      <BarChartRangeColor $range={4} />
                      <BarChartTitles>nota 100%</BarChartTitles>
                    </BarChartContainerTitles>
                    <BarChartContainerAverage>
                      <BarChartTitleAverage>
                        <b>NOTA MÉDIA:</b>{' '}
                        {q.averageScore
                          ? `${q.averageScore.toFixed(1).replace('.', ',')}%`
                          : ''}
                      </BarChartTitleAverage>
                    </BarChartContainerAverage>
                  </BarChartContainer>
                )}
              </Question>
            ))}
          </StyledCard>
        )}
      </RowContent>
    </RowContent>
  );
};

export default QuestionAnalyze;
