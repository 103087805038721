import styled from 'styled-components';
export const StyledWrapper = styled.footer `
  padding: 0 21px 20px 21px;
`;
export const StyledLinesWrapper = styled.div `
  display: flex;
  row-gap: 9px;
  flex-wrap: wrap;
  flex-direction: column;
`;
export const StyledLineWrapper = styled.div `
  display: flex;
  row-gap: 9px;
  column-gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;

  ${(props) => props.theme.breakpoints.down('lg')} {
    display: grid;
    row-gap: 5px;
    column-gap: 0;
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    display: grid;
    row-gap: 5px;
    column-gap: 0;
    grid-template-columns: 1fr 1fr;
  }
`;
export const StyledTitle = styled.h1 `
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  color: ${(props) => props.theme.colors.gray600};
`;
export const StyledHightlightedText = styled.span `
  font-weight: 600;
  color: ${(props) => props.theme.colors.CSA.darkBlue};
`;
export const StyledSquareInnerText = styled.div `
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 11px;
  font-weight: 700;

  line-height: 100%;

  color: ${(props) => props.theme.colors.CSA.darkBlue};
`;
