import { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { timeInMillisToResetFailedStatus } from '../times';
function isPlanningFinishedOrDeleted(planning) {
    const isPlanningDeleted = planning?.status?.isDeleted() ?? false;
    const isPlanningFinished = planning?.status?.isFinished() ?? false;
    return isPlanningDeleted || isPlanningFinished;
}
export function useCheckbox(props) {
    const [color, setColor] = useState(null);
    const [, setIsChecked] = useState(props.checkedByDefault);
    const planningIsFinishedOrDeleted = isPlanningFinishedOrDeleted(props.planning);
    const [isDisabled, setIsDisabled] = useState(props.readOnly || planningIsFinishedOrDeleted);
    const isCheckedRef = useRef(props.checkedByDefault);
    const theme = useTheme();
    const colors = { updateFailed: theme.colors.error };
    function isChecked() {
        return isCheckedRef.current;
    }
    function toggle() {
        if (props.readOnly)
            return;
        isCheckedRef.current = !isCheckedRef.current;
        setIsChecked(isCheckedRef.current);
    }
    function handleUpdateFailed() {
        toggle();
        setIsDisabled(true);
        setColor(colors.updateFailed);
        setTimeout(() => {
            setColor(null);
            setIsDisabled(planningIsFinishedOrDeleted);
        }, timeInMillisToResetFailedStatus);
    }
    useEffect(() => {
        setIsDisabled(props.readOnly);
    }, [props.readOnly]);
    return {
        color,
        isDisabled,
        toggle,
        isChecked,
        handleUpdateFailed
    };
}
