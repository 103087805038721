import React from 'react';
import DatePicker from '../DatePicker';
import { StyledSelectLabel, StyledSelectsWrapper, StyledSelectWithLabelWrapper, StyledTitle, StyledWrapper } from './styles';
const DateIntervalSelector = (props) => {
    return (React.createElement(StyledWrapper, null,
        React.createElement(StyledTitle, null, "Intervalo de datas:"),
        React.createElement(StyledSelectsWrapper, null,
            React.createElement(StyledSelectWithLabelWrapper, null,
                React.createElement(StyledSelectLabel, null, "De:"),
                React.createElement(DatePicker, { value: props.fromDate, onChange: props.onFromDateChange })),
            React.createElement(StyledSelectWithLabelWrapper, null,
                React.createElement(StyledSelectLabel, null, "At\u00E9:"),
                React.createElement(DatePicker, { value: props.toDate, onChange: props.onToDateChange })))));
};
export default DateIntervalSelector;
