export default class Teacher {
    id;
    name;
    imageUrl;
    biography;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new Teacher();
        instance.id = json.Id;
        instance.name = json.Name;
        instance.imageUrl = json.ImageUrl;
        instance.biography = json.Biography;
        return instance;
    }
}
