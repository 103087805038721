import { Font } from '@react-pdf/renderer';
import regular from '../../../../../assets/fonts/gilroy-regular.ttf';
import medium from '../../../../../assets/fonts/gilroy-medium.ttf';
import semiBold from '../../../../../assets/fonts/gilroy-semiBold.ttf';
import bold from '../../../../../assets/fonts/gilroy-bold.ttf';
import extraBold from '../../../../../assets/fonts/gilroy-extraBold.ttf';
export function registerGilroyFontFamily() {
    Font.register({
        family: 'Gilroy',
        fontStyle: 'normal',
        fonts: [
            { src: regular, fontWeight: 400 },
            { src: medium, fontWeight: 500 },
            { src: semiBold, fontWeight: 600 },
            { src: bold, fontWeight: 700 },
            { src: extraBold, fontWeight: 800 }
        ]
    });
}
export function removeHiphenation() {
    Font.registerHyphenationCallback((word) => [word]);
}
