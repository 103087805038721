import { Document, PageOrientation, Packer, SectionType } from 'docx';
import Header from './docx/Header';
import Table from './docx/Table';
import Footer from './docx/Footer';
export default class StudyUnitReportDocx {
    stage;
    skills;
    information;
    sectionProperties = {
        type: SectionType.NEXT_PAGE,
        page: { size: { orientation: PageOrientation.LANDSCAPE } }
    };
    constructor(props) {
        const skillsFilter = props.shortVersion
            ? props.skills.filter((s) => s.evaluative)
            : props.skills;
        this.stage = props.stage;
        this.skills = skillsFilter.filter((areaSkill) => {
            const stages = areaSkill.unitStages?.length > 0
                ? areaSkill.unitStages
                : areaSkill.programStages;
            return stages?.some(({ stage }) => {
                return stage?.fullName === props.stage;
            });
        });
        this.information = props.information;
    }
    async createAndSaveDocumentToFile(name) {
        const document = await this.createDocument();
        const blob = await Packer.toBlob(document);
        return blob;
    }
    async createSection(slice, pageIndex, numberOfPages) {
        return {
            properties: this.sectionProperties,
            children: [
                await Header(this.information, this.stage),
                Table(slice),
                Footer({ pageIndex, numberOfPages })
            ]
        };
    }
    async generateSections() {
        const itemsPerPage = 2;
        const sections = [];
        if (this.skills.length > 0) {
            const numberOfPages = Math.ceil(this.skills.length / itemsPerPage);
            for (let pageIndex = 0; pageIndex < numberOfPages; pageIndex++) {
                const sliceStartIndex = pageIndex * itemsPerPage;
                const sliceEndIndex = sliceStartIndex + itemsPerPage;
                const slice = this.skills.slice(sliceStartIndex, sliceEndIndex);
                sections.push(await this.createSection(slice, pageIndex, numberOfPages));
            }
        }
        else {
            sections.push(await this.createSection([], 0, 1));
        }
        return sections;
    }
    async createDocument() {
        const sections = await this.generateSections();
        return new Document({
            sections,
            styles: { default: { document: { run: { font: 'Gillroy' } } } }
        });
    }
}
