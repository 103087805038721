import LaunchReportEmptyClassListClass from './LaunchReportEmptyClassListClass';
export default class LaunchReportEmptyClassListDiscipline {
    id;
    name;
    classes;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new LaunchReportEmptyClassListDiscipline();
        instance.id = json.DisciplineId;
        instance.name = json.DisciplineName;
        instance.classes = Array.isArray(json.Classes)
            ? json.Classes.map((c) => LaunchReportEmptyClassListClass.fromJson(c))
            : [];
        return instance;
    }
}
