import styled from 'styled-components';
export const StyledDivWrapper = styled.div `
  display: flex;

  min-width: 100%;
  width: fit-content;

  background-color: ${(props) => props.theme.colors.gray50};

  border-style: solid;
  border-width: 2px 0;
  border-color: ${(props) => props.theme.colors.gray300};

  &:not(:first-child) {
    border-top-width: 0;
  }
`;
export const StyledTrWrapper = styled.tr `
  background-color: ${(props) => props.theme.colors.gray50};

  & th > * {
    border-style: solid;
    border-top-width: 2px;
    border-bottom-width: 2px;
    border-color: ${(props) => props.theme.colors.gray300};
  }

  &:not(:first-child) th > * {
    border-top-width: 0;
  }
`;
