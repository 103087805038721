import React from 'react';
import { StyledHeaderWrapper, StyledSmallTitle, StyledContentWrapper } from './styles';
const Header = (props) => {
    return (React.createElement(StyledHeaderWrapper, null,
        React.createElement(StyledContentWrapper, null, props.children)));
};
Header.Title = () => {
    return React.createElement(StyledSmallTitle, null, "Planejamento Pedag\u00F3gico do");
};
export default Header;
