import { RequestStatus } from '../../../../../../../../types/dataFetching';
function sortEvaluationTypes(evaluationTypes) {
    if (!evaluationTypes?.length)
        return [];
    return evaluationTypes.slice().sort((a, b) => a.name.localeCompare(b.name));
}
export function createOptions(runningFetch, defaultValue) {
    const { data, status } = runningFetch;
    if (!data?.length)
        return [];
    if (status === RequestStatus.Failed)
        return [];
    if (status === RequestStatus.Loading)
        return [];
    const options = [defaultValue];
    const sortedEvaluationTypes = sortEvaluationTypes(data);
    for (const evaluationType of sortedEvaluationTypes) {
        if (evaluationType.canOrCannotImpactTheMDC()) {
            options.push({
                evaluationType,
                impactTheMDC: true,
                label: `${evaluationType.name} (impacta o MDC)`
            }, {
                evaluationType,
                impactTheMDC: false,
                label: `${evaluationType.name} (não impacta o MDC)`
            });
        }
        else {
            options.push({
                evaluationType,
                label: evaluationType.name
            });
        }
    }
    return options;
}
export function createValue(selectedEvaluationType, impactTheMDC, placeholder) {
    if (placeholder) {
        return { isAPlaceholder: true, evaluationType: null, label: placeholder };
    }
    if (!selectedEvaluationType)
        return null;
    let label = selectedEvaluationType.name;
    if (impactTheMDC === true) {
        label += ' (impacta o MDC)';
    }
    else if (impactTheMDC === false) {
        label += ' (não impacta o MDC)';
    }
    return {
        label,
        impactTheMDC,
        evaluationType: selectedEvaluationType
    };
}
export function shouldUseGrayColor(option) {
    if (!option.evaluationType || option.isAPlaceholder)
        return false;
    const optionalImpact = option.evaluationType.canOrCannotImpactTheMDC();
    return optionalImpact && option.impactTheMDC === false;
}
export function getSelectorState(requestStatus) {
    const isLoading = requestStatus === RequestStatus.Loading;
    const isDisabled = isLoading || requestStatus === RequestStatus.Failed;
    return { isLoading, isDisabled };
}
