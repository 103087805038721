import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import { handleApiCallError } from './common';
const baseEndpointPath = '/pedagogicalplanningobjective';
const endpoints = {
    add: { objective: baseEndpointPath },
    update: { objective: baseEndpointPath },
    delete: { objective: baseEndpointPath }
};
export async function addPlanningObjective(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        await axiosInstance.post(endpoints.add.objective, {
            Programmed: params.programmed,
            PedagogicalPlanningId: params.planningId,
            LearningObjectiveId: params.learningObjectiveId
        }, { signal: params?.abortSignal });
        return Response.fromData(null, 200);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function updatePlanningObjective(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpointWithId = `${endpoints.update.objective}/${params.objectiveId}`;
        const response = await axiosInstance.put(endpointWithId, {
            Programmed: params.wasProgrammed
        }, { signal: params?.abortSignal });
        return Response.fromData(null, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function deletePlanningObjective(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpointWithId = `${endpoints.delete.objective}/${params.objectiveId}`;
        const response = await axiosInstance.delete(endpointWithId, {
            signal: params?.abortSignal
        });
        return Response.fromData(true, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
