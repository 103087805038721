import React from 'react';
import { AddSkillPopupContextProvider } from './context';
import Tabs from './components/Tabs';
import { StyledPopup, StyledTitleWrapper } from './styles';
import { StyledCloseButton, StyledTitle } from '../common/styles';
const AddSkillPopup = (props) => {
    return (React.createElement(AddSkillPopupContextProvider, { mainTableContent: props.mainTableContent, allStages: props.allStages, allGrades: props.allGrades, allSegments: props.allSegments, allDisciplines: props.allDisciplines, allPlanningStatuses: props.allPlanningStatuses, selectedGrade: props.selectedGrade, selectedSegment: props.selectedSegment, selectedDisciplines: props.selectedDisciplines, handleAddSkillSucceeded: props.handleAddSkillSuccess, addPlanningToGroupWithNumber: props.addPlanningToGroupWithNumber },
        React.createElement(StyledPopup, { isFullScreen: true, canBeDisplayed: props.canBeDisplayed, handleExternalClick: props.handleExternalClick },
            React.createElement(StyledTitleWrapper, null,
                React.createElement(StyledTitle, null, "Adicionar Habilidade"),
                React.createElement(StyledCloseButton, { onClick: props.handleCancelButtonClicked }, "Fechar")),
            React.createElement(Tabs, null))));
};
export default AddSkillPopup;
