import { useWeeklyPlanningPageContext } from '../../../../../../../../context';
export function useMovePlanning() {
    const { movePlanning, getMainTableContent } = useWeeklyPlanningPageContext((store) => ({
        movePlanning: store.mainTable.operations.move,
        getMainTableContent: store.mainTable.getFlatPlannings
    }));
    function movePlanningUp(planning) {
        return new Promise((resolve, reject) => {
            const content = getMainTableContent();
            if (content.length > 1) {
                movePlanning(planning, 'up');
                resolve();
            }
            else {
                reject();
            }
        });
    }
    function movePlanningDown(planning) {
        return new Promise((resolve, reject) => {
            const content = getMainTableContent();
            if (content.length > 1) {
                movePlanning(planning, 'down');
                resolve();
            }
            else {
                reject();
            }
        });
    }
    return { movePlanningUp, movePlanningDown };
}
