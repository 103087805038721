import React from 'react';
import { useTheme } from 'styled-components';
import { useFollowUpPageContext } from '../../../../../../../../../../../../../context';
import { useRemoveActivity } from './hooks/useRemoveActivity';
import { RequestStatus } from '../../../../../../../../../../../../../../common/types/dataFetching';
import { StyledIcon, StyledWrapper } from './styles';
const TrashIcon = (props) => {
    const theme = useTheme();
    const { removeActivity, requestStatus } = useRemoveActivity();
    const userPermissions = useFollowUpPageContext((store) => store.commonData.userPermissions.data);
    const statusAndColors = {
        [RequestStatus.Failed]: theme.colors.error,
        [RequestStatus.Loading]: theme.colors.blue500
    };
    const isSequenceDeleted = props.sequence?.isDeleted ?? false;
    const isPlanningDeleted = props.planning?.status?.isDeleted() ?? false;
    const userIsNotAllowedToUpdate = !userPermissions?.userCan.updateTheTeacherColumns();
    const disabled = userIsNotAllowedToUpdate || isPlanningDeleted || isSequenceDeleted;
    function onClick() {
        if (disabled)
            return;
        removeActivity(props.sequence);
    }
    return (React.createElement(StyledWrapper, { "$disabled": disabled, onClick: onClick },
        React.createElement(StyledIcon, { "$color": statusAndColors[requestStatus] })));
};
export default TrashIcon;
