import styled from 'styled-components';
export const StyledDescription = styled.label `
  font-style: normal;
  font-size: 12px;

  ${(props) => (props.$disabled ? '' : 'cursor: pointer;')}

  font-weight: ${(props) => (props.$emphasize ? 600 : 500)};

  color: ${(props) => props.$useOrangeAsColor
    ? props.theme.colors.orange1
    : props.$emphasize
        ? props.theme.colors.CSA.darkBlue
        : props.theme.colors.gray700};
`;
export const StyledCode = styled.span `
  color: ${(props) => props.$color};
`;
