import { updateSlice } from './helpers';
function handleContentChanged(api) {
    api.setState((store) => {
        return updateSlice(store, {
            stage: null,
            justStartedSkills: false,
            justUsedObjectives: false,
            minimumClassroomsDate: null,
            maximumClassroomsDate: null
        });
    });
}
export function setupEventHandlers(storeApi, mainTableEvents) {
    mainTableEvents.contentSet.subscribe(() => {
        handleContentChanged(storeApi);
    });
    mainTableEvents.contentChangedAfterOperation.subscribe(() => {
        handleContentChanged(storeApi);
    });
}
