import { useEffect, useMemo, useState } from 'react';
export function useExpandButton(props) {
    const [canShowExpandButton, setCanShowExpandButton] = useState(false);
    const resizeObserver = useMemo(() => {
        return new ResizeObserver((entries) => {
            const height = entries[0]?.borderBoxSize[0]?.blockSize;
            setCanShowExpandButton(height > props.showButtonIfHeightIsGreaterThan);
        });
    }, [props.showButtonIfHeightIsGreaterThan]);
    useEffect(() => {
        const element = props.elementRef.current;
        if (element) {
            resizeObserver.observe(element);
        }
        return () => {
            if (element) {
                resizeObserver.unobserve(element);
            }
        };
    }, [resizeObserver, props.elementRef, props.sequence]);
    return { canShowExpandButton };
}
