import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';
import SaintAugustineLogo from '../../../../../../../assets/images/saint-augustine-logo.png';
import styles from './styles';
const Header = (props) => {
    return (React.createElement(View, { fixed: true, style: styles.wrapper },
        React.createElement(View, { style: styles.titlesWrapper },
            React.createElement(Text, { style: styles.title }, props.title),
            React.createElement(View, { style: styles.curriculumComponentWrapper },
                React.createElement(Text, { style: styles.curriculumComponentTitle }, "COMPONENTE CURRICULAR:"),
                React.createElement(Text, { style: styles.curriculumComponentName }, props.information.discipline))),
        React.createElement(View, { style: styles.infosWrapper },
            !!props.period ? (React.createElement(Text, null,
                React.createElement(Text, { style: styles.infosEmphasizedText }, "PER\u00CDODO: "),
                React.createElement(Text, { style: styles.infosNormalText }, props.period))) : null,
            React.createElement(Text, null,
                React.createElement(Text, { style: styles.infosEmphasizedText }, "ANO / S\u00C9RIE: "),
                React.createElement(Text, { style: styles.infosNormalText },
                    props.information.grade,
                    " do ",
                    props.information.segment)),
            React.createElement(Text, null,
                React.createElement(Text, { style: styles.infosEmphasizedText },
                    "CARGA HOR\u00C1RIA SEMANAL:",
                    ' '),
                React.createElement(Text, { style: styles.infosNormalText },
                    props.information.weeklyWorkload ?? '---',
                    " horas / aula")),
            React.createElement(Text, null,
                React.createElement(Text, { style: styles.infosEmphasizedText },
                    "QUANTIDADE APROXIMADA DE AULAS / SEMESTRE:",
                    ' '),
                React.createElement(Text, { style: styles.infosNormalText },
                    props.information.approximateNumberOfClasses ?? '---',
                    " aulas"))),
        React.createElement(View, { style: styles.logoWrapper },
            React.createElement(Image, { style: styles.logo, source: SaintAugustineLogo }))));
};
export default Header;
