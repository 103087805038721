import styled from 'styled-components';
import { StyledBasicButton } from '../styles';
export const StyledWrapper = styled.div `
  position: relative;
`;
export const StyledButton = styled(StyledBasicButton) `
  background-color: white;
  color: ${(props) => props.theme.colors.blue1};
  border-color: ${(props) => props.theme.colors.blue1};
`;
