import { RequestStatus } from '../../../types/dataFetching';
export function createBaseCommonDataSlice(events) {
    return (set) => ({
        grades: { data: [], status: RequestStatus.NotStarted },
        segments: { data: [], status: RequestStatus.NotStarted },
        disciplines: { data: [], status: RequestStatus.NotStarted },
        setSegments(updates) {
            set((store) => {
                return {
                    commonData: {
                        ...store.commonData,
                        segments: { ...store.commonData.segments, ...updates }
                    }
                };
            });
            events.segmentsChanged.emit();
        },
        setGrades(updates) {
            set((store) => {
                return {
                    commonData: {
                        ...store.commonData,
                        grades: { ...store.commonData.grades, ...updates }
                    }
                };
            });
            events.gradesChanged.emit();
        },
        setDisciplines(updates) {
            set((store) => {
                return {
                    commonData: {
                        ...store.commonData,
                        disciplines: { ...store.commonData.disciplines, ...updates }
                    }
                };
            });
            events.disciplinesChanged.emit();
        }
    });
}
