import React from 'react';
import MonthsRow from './components/Rows/MonthsRow';
import WeeksRow from './components/Rows/WeeksRow';
import { StyledWrapper } from './styles';
const CalendarHeader = React.forwardRef((_, ref) => {
    return (React.createElement(StyledWrapper, { ref: ref },
        React.createElement(MonthsRow, null),
        React.createElement(WeeksRow, null)));
});
export default CalendarHeader;
