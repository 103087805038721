import React, { useCallback, useEffect, useRef } from 'react';
import { useSticky } from 'react-table-sticky';
import { useBlockLayout, useTable } from 'react-table';
import { useFollowUpPageContext } from '../../../../../../context';
import { useFollowUpMainTableContext } from '../../../../context';
import { GroupContextProvider } from '../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import Row from './components/Row';
import { columns } from './columns';
import { useResize } from '../../../../../../../common/hooks';
import { useHideDisciplineColumn } from '../../../../../../../common/components/PageRelated/Body/components/Table/hooks/useHideDisciplineColumn';
import { StyledBodyWrapper, StyledWrapper } from './styles';
const Group = (props) => {
    const { group: groupFromFilteredContent } = props;
    const wrapperRef = useRef(null);
    const { selectedDisciplines, updateGroup, getGroupFromNonFilteredContent } = useFollowUpPageContext((store) => ({
        updateGroup: store.mainTable.operations.updateGroup,
        selectedDisciplines: store.curriculumComponents.selectedDisciplines,
        getGroupFromNonFilteredContent: store.mainTable.operations.getGroupFromNonFilteredContent
    }));
    const setGroupInfos = useFollowUpMainTableContext((store) => store.setGroupInfos);
    const tableInstance = useTable({
        columns,
        data: groupFromFilteredContent.plannings,
        initialState: { hiddenColumns: ['disciplineName'] }
    }, useBlockLayout, useSticky);
    useHideDisciplineColumn({
        tableInstance,
        selectedDisciplines,
        columnId: 'disciplineName'
    });
    const onResize = useCallback((entries) => {
        if (!groupFromFilteredContent || !entries.length)
            return;
        const groupNumber = groupFromFilteredContent.number;
        const height = entries[0].borderBoxSize[0].blockSize;
        setGroupInfos(groupNumber, { height });
    }, [groupFromFilteredContent]);
    useResize(wrapperRef, onResize);
    const getBoundingClientRect = useCallback(() => {
        return wrapperRef.current.getBoundingClientRect();
    }, []);
    useEffect(() => {
        if (!groupFromFilteredContent)
            return;
        const groupNumber = groupFromFilteredContent.number;
        const height = wrapperRef.current.clientHeight;
        setGroupInfos(groupNumber, { height, getBoundingClientRect });
    }, [groupFromFilteredContent, setGroupInfos, getBoundingClientRect]);
    function renderRows() {
        return tableInstance.rows.map((row) => {
            tableInstance.prepareRow(row);
            return (React.createElement(Row, { key: row.id, group: groupFromFilteredContent, rowInfos: row, tableInstance: tableInstance }));
        });
    }
    return (React.createElement(GroupContextProvider, { updateGroupOnMainTable: updateGroup, groupFromFilteredContent: groupFromFilteredContent, groupFromNonFilteredContent: getGroupFromNonFilteredContent(groupFromFilteredContent.number) },
        React.createElement(StyledWrapper, { ref: wrapperRef, ...tableInstance.getTableProps() },
            React.createElement(StyledBodyWrapper, { ...tableInstance.getTableBodyProps() }, renderRows()))));
};
export default Group;
