import React, { useEffect, useMemo, useRef } from 'react';
import { CalendarContextProvider } from './context';
import CalendarBody from './components/CalendarBody';
import CalendarHeader from './components/CalendarHeader';
import CalendarGenerator from '../../generators/calendarGenerator';
import { scrollBodyAndHeaderToTodaysDate, setupScrollEventListener } from './helpers/scroll';
import { StyledWrapper } from './styles';
const Calendar = (props) => {
    const headerRef = useRef(null);
    const bodyRef = useRef(null);
    const months = useMemo(() => {
        const generator = new CalendarGenerator();
        return generator.generate();
    }, []);
    useEffect(() => {
        const bodyElement = bodyRef.current;
        const headerElement = headerRef.current;
        return setupScrollEventListener(bodyElement, headerElement);
    }, []);
    useEffect(() => {
        const bodyElement = bodyRef.current;
        const headerElement = headerRef.current;
        scrollBodyAndHeaderToTodaysDate(months, bodyElement, headerElement);
    }, [months]);
    return (React.createElement(CalendarContextProvider, { groups: props.groups, months: months },
        React.createElement(StyledWrapper, null,
            React.createElement(CalendarHeader, { ref: headerRef }),
            React.createElement(CalendarBody, { ref: bodyRef }))));
};
export default Calendar;
