import styled from 'styled-components';
import PopupBox from '../PopupBox';
export const StyledWrapper = styled(PopupBox) `
  min-width: 150px;

  position: absolute;
  top: calc(100% + -13px);
  right: 12px;

  background-color: white;

  padding: 14px;
`;
export const StyledTitleWrapper = styled.div `
  display: flex;
  padding-bottom: 9px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
`;
export const StyledColumnsWrapper = styled.div `
  margin-top: 9px;

  display: flex;
`;
export const StyledMessageWrapper = styled.div `
  margin-top: 9px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledMessage = styled.i `
  font-size: 14px;
  font-weight: 500;
`;
export const StyledTitle = styled.h4 `
  margin: 0;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;

  color: ${(props) => props.theme.colors.gray1};
`;
