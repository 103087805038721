import format from 'date-fns/format';
import { Segment } from '@campus/commons';

import Grade from './Grade';
import Class from './Class';
import Unity from './Unity';
import User from './User';
import Responsibles from './Responsibles';

class StudentClassDetail {
  age?: number;
  unity?: Unity;
  segment?: Segment;
  grade?: Grade;
  class?: Class;
  period?: Grade;
  responsibles?: Responsibles[];
  user?: User;
  birthday?: string;
  imageUrl?: string;
  id?: string;
  birthdayFormated?: string;

  public static fromJson(data: any): StudentClassDetail {
    const studentClassDetail = new StudentClassDetail();

    studentClassDetail.age = data.Age;
    studentClassDetail.unity = Unity.fromJson(data.Unity);
    studentClassDetail.segment = Segment.fromJson(data.Segment);
    studentClassDetail.grade = Grade.fromJson(data.Grade);
    studentClassDetail.class = Class.fromJson(data.Class);
    studentClassDetail.period = Grade.fromJson(data.Period);
    studentClassDetail.responsibles = (data.Responsibles as []).map(
      (responsible) => Responsibles.fromJson(responsible)
    );
    studentClassDetail.user = User.fromJson(data.User);
    studentClassDetail.birthday = data.Birthday;
    studentClassDetail.imageUrl = data.ImageUrl;
    studentClassDetail.id = data.Id;
    studentClassDetail.birthdayFormated = format(
      new Date(data.Birthday),
      'dd/MM/yyyy'
    );

    return studentClassDetail;
  }

  public static toJson(value: StudentClassDetail): string {
    return JSON.stringify(value);
  }
}

export default StudentClassDetail;
