import React from 'react';
import { useCalendarContext } from '../../context';
import { getTodaysDateWithoutTime } from '../../../../../../../../common/utils/dates';
import { StyledSeparator } from './styles';
const Separator = (props) => {
    const months = useCalendarContext((store) => store.months);
    const { month, correspondingWeek: week } = props;
    const today = getTodaysDateWithoutTime();
    const isMonthTheLastOne = month.number === months.at(-1).number;
    const isTheLastWeek = week.startDate === month.weeks.at(-1).startDate;
    const doNotRenderSeparator = isTheLastWeek && isMonthTheLastOne;
    const blueColored = week.endDate < today;
    return doNotRenderSeparator ? null : (React.createElement(StyledSeparator, { "$useLightBlue": blueColored }));
};
export default Separator;
