import { getWeekElementBoundingRect } from './elements';
import { calculatePercentageEachDayInRange, calculatePercentageEachDayInWeek, calculatePercentageOfDateInRange, calculatePercentageOfDateInWeek, getMonthWithDateInside, getWeekOrWeeksThatContainsDate, isWeek } from './weeksAndMonths';
export function getDateInfos(date, months, parentElement) {
    if (!date || !parentElement)
        return null;
    if (!months || months.length === 0)
        return null;
    const month = getMonthWithDateInside(months, date);
    const weekOrWeeks = getWeekOrWeeksThatContainsDate(date, month);
    if (!weekOrWeeks)
        return null;
    const parentRect = parentElement.getBoundingClientRect();
    if (isWeek(weekOrWeeks)) {
        const weekRect = getWeekElementBoundingRect(weekOrWeeks);
        const percentageOfDay = calculatePercentageEachDayInWeek(weekOrWeeks);
        const percentageOfDate = calculatePercentageOfDateInWeek(date, weekOrWeeks);
        return {
            dateIsInsideAWeek: true,
            weekElementRect: weekRect,
            parentElementRect: parentRect,
            percentageOfDateInWeek: percentageOfDate,
            percentageOfEachDayInWeek: percentageOfDay
        };
    }
    if (!weekOrWeeks.left || !weekOrWeeks.right)
        return null;
    const leftWeekRect = getWeekElementBoundingRect(weekOrWeeks.left);
    const rightWeekRect = getWeekElementBoundingRect(weekOrWeeks.right);
    const percentageOfDate = calculatePercentageOfDateInRange(date, weekOrWeeks.left.endDate, weekOrWeeks.right.startDate);
    const percentageOfDay = calculatePercentageEachDayInRange(weekOrWeeks.left.endDate, weekOrWeeks.right.startDate);
    return {
        dateIsInsideAWeek: false,
        parentElementRect: parentRect,
        leftWeekElementRect: leftWeekRect,
        rightWeekElementRect: rightWeekRect,
        percentageOfDateBetweenWeeks: percentageOfDate,
        percentageOfEachDayBetweenWeeks: percentageOfDay
    };
}
