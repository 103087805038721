import styled from 'styled-components';
import Select from 'react-select';
import Dropdown from './components/Dropdown';
import { elementClassNames } from './classNames';
export const StyledSelect = styled(Select) `
  &,
  & > * {
    all: unset;
  }

  & ${elementClassNames.wrapper}:hover, & ${elementClassNames.focusedWrapper} {
    box-shadow: none;
  }

  & ${elementClassNames.option}, & ${elementClassNames.wrapper} {
    padding-left: 8px;
    padding-right: 8px;
  }

  & ${elementClassNames.wrapper} {
    min-height: 0;
    cursor: pointer;

    border-width: 1px;
    border-radius: 6px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.gray200};

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & ${elementClassNames.wrapper}:hover {
    border-color: ${(props) => props.theme.colors.gray400};
  }

  & ${elementClassNames.separator} {
    display: none;
  }

  & ${elementClassNames.input}, & ${elementClassNames.inputWrapper} {
    position: absolute;
    top: auto;
    cursor: pointer;
    caret-color: transparent;
  }

  &
    ${elementClassNames.dropdownWrapper},
    &
    ${elementClassNames.selectedValueWrapper} {
    max-height: 100%;
  }

  &
    ${elementClassNames.dropdown},
    &
    ${elementClassNames.placeholder},
    &
    ${elementClassNames.selectedValue},
    &
    ${elementClassNames.dropdownWrapper},
    &
    ${elementClassNames.clearOptionsWrapper},
    &
    ${elementClassNames.selectedValueWrapper} {
    margin: 0;
    padding: 0;
  }

  &
    ${elementClassNames.option},
    &
    ${elementClassNames.dropdown},
    &
    ${elementClassNames.placeholder},
    &
    ${elementClassNames.selectedValue},
    &
    ${elementClassNames.loadingMessage},
    &
    ${elementClassNames.noOptionsMessage} {
    font-size: 12px;
    color: ${(props) => props.theme.colors.gray700};
  }

  &
    ${elementClassNames.option},
    &
    ${elementClassNames.dropdown},
    &
    ${elementClassNames.selectedValueWrapper} {
    display: flex;
    align-items: center;
  }

  & ${elementClassNames.option} {
    cursor: pointer;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  & ${elementClassNames.option}:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }

  & ${elementClassNames.menuWrapper} {
    margin: 0;
    margin-top: 3px;
    background-color: white;
  }

  & ${elementClassNames.placeholder} > span {
    margin: auto;
  }

  & ${elementClassNames.placeholder} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
StyledSelect.defaultProps = {
    classNamePrefix: 'select',
    components: {
        DropdownIndicator: Dropdown
    },
    styles: {
        option: (_) => ({})
    }
};
