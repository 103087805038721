import React from 'react';
import { CustomClassicCKEditor } from '@campus/components';
import { StyledCloseButton, StyledTitle } from '../common/styles';
import { StyledContentWrapper, StyledPopup, StyledTitleWrapper } from './styles';
const VisualizeActivityPopup = (props) => {
    return (React.createElement(StyledPopup, { isFullScreen: true, canBeDisplayed: props.canBeDisplayed, handleExternalClick: props.handleExternalClick },
        React.createElement(StyledTitleWrapper, null,
            React.createElement(StyledTitle, null, "Visualizando Atividade"),
            React.createElement(StyledCloseButton, { onClick: props.handleCloseButtonClicked }, "Fechar")),
        React.createElement(StyledContentWrapper, null,
            React.createElement(CustomClassicCKEditor.Editor, { readOnly: true, content: props.sequence.description }))));
};
export default VisualizeActivityPopup;
