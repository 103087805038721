import React, { useMemo } from 'react';
import { Document, Page } from '@react-pdf/renderer';
import Header from './components/Header';
import Body from '../common/components/Body';
import Footer from './components/Footer';
import { paddings } from './sizes';
import { setup } from '../common/helpers';
import { createHeaders, createRow } from './table';
import styles from '../common/styles';
setup();
const ClassDiaryReport = (props) => {
    const tableHeaders = createHeaders();
    const tableRows = useMemo(() => {
        const contentsData = props.content
            .flatMap((pedagogicalPlanningGroup) => {
            return pedagogicalPlanningGroup.didacticSequence.flatMap((didaticSequence) => {
                const classRoomData = didaticSequence.classrooms.find((c) => c.classroom.class.gradeId ===
                    props.information.selectedGrade.id &&
                    c.classroom.class.id === props.information.selectedClass.id);
                if (!classRoomData)
                    return null;
                return {
                    date: classRoomData.classroom.date,
                    areasSkills: pedagogicalPlanningGroup.plannings.map((p) => p.areaSkill)
                };
            });
        })
            .filter((data) => data !== null)
            .sort((a, b) => a.date.getTime() - b.date.getTime());
        return contentsData.map((contentData) => createRow({
            areasSkills: contentData.areasSkills,
            date: contentData.date
        }));
    }, [
        props.content,
        props.information.selectedClass.id,
        props.information.selectedGrade.id
    ]);
    return (React.createElement(Document, null,
        React.createElement(Page, { size: "A4", orientation: "portrait", style: {
                ...styles.page,
                paddingBottom: paddings.page.bottom.withoutEvaluationSection
            } },
            React.createElement(Header, { title: 'Relatório de Conteúdos/ Competências/ Habilidades/ Atividades Ministrados', information: props.information, period: props.information.period }),
            React.createElement(Body, { tableHeaders: tableHeaders, tableRows: tableRows }),
            React.createElement(Footer, { lastModificationDate: props.information.lastModificationDate }))));
};
export default ClassDiaryReport;
