import React, { useEffect } from 'react';
import { useSticky } from 'react-table-sticky';
import { useBlockLayout, useTable } from 'react-table';
import { FollowUpMainTableContextProvider } from './context';
import { useFollowUpPageContext } from '../../context';
import TableBody from './components/TableBody';
import TableHeader from '../../../common/components/PageRelated/Body/components/Table/components/TableHeader';
import TablePlaceholder from '../../../common/components/PageRelated/Body/components/Table/components/TablePlaceholder';
import Calendar from './calendarRelated/components/Calendar';
import { columns } from './columns';
import { useFetchPlannings } from './hooks/useFetchPlannings';
import { useHideDisciplineColumn } from '../../../common/components/PageRelated/Body/components/Table/hooks/useHideDisciplineColumn';
import { getPlaceholderContent } from './placeholders';
import { StyledWrapper, StyledContentWrapper } from '../../../common/components/PageRelated/Body/components/Table/commonStyles';
import { StyledScrollableArea } from './styles';
export const Table = () => {
    const { content, setContent, selectedGrade, selectedSegment, selectedDisciplines } = useFollowUpPageContext((store) => ({
        stages: store.commonData.stages,
        content: store.mainTable.filteredContent,
        setContent: store.mainTable.setContent,
        updatePlanning: store.mainTable.operations.updatePlanning,
        selectedGrade: store.curriculumComponents.selectedGrade,
        selectedSegment: store.curriculumComponents.selectedSegment,
        selectedDisciplines: store.curriculumComponents.selectedDisciplines
    }));
    const fetch = useFetchPlannings({
        grade: selectedGrade,
        segment: selectedSegment,
        disciplines: selectedDisciplines
    });
    const tableInstance = useTable({
        columns,
        data: content,
        initialState: { hiddenColumns: ['disciplineName'] }
    }, useBlockLayout, useSticky);
    useHideDisciplineColumn({
        tableInstance,
        selectedDisciplines,
        columnId: 'disciplineName'
    });
    useEffect(() => {
        setContent(fetch.data);
    }, [fetch.data, setContent]);
    function getPlaceholder() {
        return getPlaceholderContent({
            selectedGrade,
            selectedSegment,
            selectedDisciplines,
            requestStatus: fetch.requestStatus
        });
    }
    return content.length > 0 ? (React.createElement(FollowUpMainTableContextProvider, null,
        React.createElement(StyledWrapper, { ...tableInstance.getTableProps() },
            React.createElement(StyledScrollableArea, null,
                React.createElement(StyledContentWrapper, null,
                    React.createElement(TableHeader, { columns: columns, tableInstance: tableInstance }),
                    React.createElement(TableBody, { tableInstance: tableInstance })),
                React.createElement(Calendar, { groups: content }))))) : (React.createElement(TablePlaceholder, { content: getPlaceholder() }));
};
