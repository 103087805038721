import React from 'react';
import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
const StyledIcon = styled(CampusIcon).attrs({
    name: 'circleWithCheckBold'
}) `
  display: block;
  color: ${(props) => props.theme.colors.green2};
`;
const CircleWithCheckBold = () => {
    return React.createElement(StyledIcon, null);
};
export default CircleWithCheckBold;
