import { Api } from '@campus/auth';
import { Response, Student } from '@campus/commons';
import FeedbackItems from 'models/FeedbackItems';

import StudentClassDetail from 'models/StudentClassDetail';
import TopTeacher from 'models/TopTeahers';

export const capitalizeFirstLetter = (string: string) => {
  const stringSplitted = string.trim().toLowerCase().split(' ');
  let newString = '';

  for (let index = 0; index < stringSplitted.length; index++) {
    const word = stringSplitted[index];
    if (word.length > 1) {
      newString += `${word.charAt(0).toUpperCase() + word.slice(1)} `;
    } else {
      newString += `${word} `;
    }
  }

  return newString;
};

export const listClassStudents = async (
  classId: string,
  disciplineId?: string
): Promise<Response<Student[]>> => {
  try {
    const response = await Api.INSTANCE.getApi().get(
      `/class/${classId}/students?disciplineId=${disciplineId || ''}`
    );

    return Response.fromData(
      response.data
        .map((item) => Student.fromJson(item))
        .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name)),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};

export const studentClassDetail = async (
  studentId: string,
  classId: string
): Promise<Response<StudentClassDetail>> => {
  try {
    const response = await Api.INSTANCE.getApi().get(
      `/student/${studentId}/class/${classId}/detail`
    );

    return Response.fromData(
      StudentClassDetail.fromJson(response.data),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};

export const getkeywords = async (): Promise<Response<Array<string>>> => {
  try {
    const response = await Api.INSTANCE.getApi().get(`/feedback/keywords`);

    return Response.fromData(response.data, response.status);
  } catch (e: any) {
    return Response.error(e);
  }
};

export const createFeedback = async (
  studentId: string,
  comment: string,
  emoji: string,
  keywords: string[]
): Promise<Response<Array<string>>> => {
  try {
    const response = await Api.INSTANCE.getApi().post(`/feedback`, {
      StudentId: studentId,
      Comment: comment,
      Emoji: emoji,
      Keywords: keywords
    });

    return Response.fromData(response.data, response.status);
  } catch (e: any) {
    return Response.error(e);
  }
};

export const getFeedbacks = async (
  studentId: string
): Promise<Response<Array<FeedbackItems>>> => {
  try {
    const response = await Api.INSTANCE.getApi().get(
      `/feedback?StudentId=${studentId}`
    );

    return Response.fromData(
      response.data.Items.map((item) => FeedbackItems.fromJson(item)),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};

export const getTopTeachers = async (
  studentId: string
): Promise<Response<Array<TopTeacher>>> => {
  try {
    const response = await Api.INSTANCE.getApi().get(
      `/feedback/${studentId}/top`
    );

    return Response.fromData(
      response.data.map((item) => TopTeacher.fromJson(item)),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};
