import React from 'react';
import ArrowIcon from '../../../common/ArrowIcon';
import { StyledNoComponentSelectedMessage } from '../common/styles';
import { StyledDisciplineName, StyledSeparator, StyledWrapper } from './styles';
const SelectedDisciplines = (props) => {
    return (React.createElement(StyledWrapper, { onClick: props.onClick },
        props.disciplines?.length > 0 ? (props.disciplines.map((discipline, index) => (React.createElement(React.Fragment, { key: discipline.id },
            React.createElement(StyledDisciplineName, { "$color": discipline.color }, discipline.name?.toUpperCase()),
            index !== props.disciplines.length - 1 ? (React.createElement(StyledSeparator, null, "|")) : null)))) : (React.createElement(StyledNoComponentSelectedMessage, null, "Componente(s) Curricular(es) n\u00E3o selecionado(s)")),
        React.createElement(ArrowIcon, { displayVertically: true })));
};
export default SelectedDisciplines;
