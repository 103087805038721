import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const StyledIconsWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
export const StyledHamburgerIconWrapper = styled.div `
  top: auto !important;
  left: auto !important;

  cursor: pointer !important;

  &,
  & div {
    display: block;
    height: 20px;
  }
`;
export const StyledHamburgerIcon = styled(CampusIcon).attrs({
    name: 'hamburger'
}) `
  width: auto;
  height: 18px;
  vertical-align: unset;
`;
export const StyledPadLockIconWrapper = styled.div `
  & div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 6px;
  }
`;
export const StyledPadLockIcon = styled(CampusIcon).attrs({
    name: 'padlock'
}) `
  color: ${(props) => props.theme.colors.error};
`;
export const StyledCircleWithCheck = styled(CampusIcon).attrs({
    name: 'circleWithCheckBold'
}) `
  color: ${(props) => props.theme.colors.green2};
`;
export const StyledCircleWithCheckWrapper = styled.div `
  & div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
  }
`;
