import { useCallback, useEffect } from 'react';
import { getEvaluationTypesForDisciplines } from '../../../../services/evaluationType';
import { useFetch } from './useFetch';
export function useFetchEvaluationTypes({ disciplines }) {
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess } = useFetch({
        initialData: []
    });
    const fetchEvaluationTypes = useCallback((abortSignal) => {
        if (!disciplines || disciplines.length === 0) {
            handleSuccess([]);
            return;
        }
        fetchStarted();
        return getEvaluationTypesForDisciplines({
            disciplineIds: disciplines.map((d) => d.id),
            abortSignal
        })
            .then((response) => handleSuccess(response.data))
            .catch(handleFailure);
    }, [disciplines, fetchStarted, handleFailure, handleSuccess]);
    useEffect(() => {
        const abortController = new AbortController();
        fetchEvaluationTypes(abortController.signal);
        return () => abortController.abort();
    }, [fetchEvaluationTypes]);
    return { data, requestStatus };
}
