import { useState } from 'react';
import { useGroupContext } from '../../../../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import { RequestStatus } from '../../../../../../../../../../../../../../../common/types/dataFetching';
import { times } from '../../../../../../../../../../../../../../../common/constants';
import { deleteDidaticSequenceLogicallyAndItsClassroomsExceptByTheMainOne } from '../../../../../../../../../../../../../../../../services/agregators/deleteDidacticSequenceLogicallyAndItsClassroomsExceptByTheMainOne';
export function useRemoveActivity() {
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const { updateActivity } = useGroupContext((slice) => ({
        updateActivity: slice.sequenceOperations.update
    }));
    function removeActivity(sequence) {
        setRequestStatus(RequestStatus.Loading);
        deleteDidaticSequenceLogicallyAndItsClassroomsExceptByTheMainOne({
            sequence
        })
            .then((response) => {
            updateActivity(response.data);
            setRequestStatus(RequestStatus.Succeeded);
        })
            .catch(() => {
            setRequestStatus(RequestStatus.Failed);
        })
            .finally(() => {
            setTimeout(() => {
                setRequestStatus(RequestStatus.NotStarted);
            }, times.toResetARequestStatus);
        });
    }
    return { removeActivity, requestStatus };
}
