import styled from 'styled-components';
export const StyledWrapper = styled.div `
  position: relative;

  width: 100%;
  height: 100%;
`;
export const StyledCircleWrapper = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const StyledCircle = styled.div `
  position: relative;

  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;

  background-color: white;

  border-radius: 50%;
  border: 1px solid ${(props) => props.$color};
`;
export const StyledInnerCircle = styled.div `
  border-radius: 50%;

  width: 9px;
  height: 9px;
  min-width: 9px;
  min-height: 9px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: ${(props) => props.$color};
`;
