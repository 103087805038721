import { handleApiCallError } from '../common';
import { getDidaticSequenceById } from '../didaticSequence';
import { updateClassroomAndSequence } from '../classroomAndDidaticSequence';
export async function updateClassroomAndSequences(params) {
    try {
        await Promise.all(params.items.map((infos) => {
            return updateClassroomAndSequence({
                executed: infos.executed,
                abortSignal: params.abortSignal,
                didaticSequenceId: params.sequenceId,
                foreseen: infos.classroomAndSequence.foreseen,
                executedClassroomId: infos.executedClassroomId,
                classroomId: infos.classroomAndSequence?.classroom?.id,
                classroomAndSequenceId: infos.classroomAndSequence?.id
            });
        }));
        return getDidaticSequenceById({
            id: params.sequenceId,
            abortSignal: params.abortSignal
        });
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
