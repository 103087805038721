import { equalsIgnoreCase } from '../../utils';
export function matchGradeId(clasz, gradeId) {
    return clasz.gradeId && equalsIgnoreCase(clasz.gradeId, gradeId);
}
export function matchDisciplineIds(clasz, disciplineIds) {
    return clasz.disciplineIds?.some((classDisciplineId) => {
        return disciplineIds.some((disciplineId) => {
            return equalsIgnoreCase(classDisciplineId, disciplineId);
        });
    });
}
export function matchFilters(clasz, gradeId, disciplineIds) {
    if (gradeId && disciplineIds?.length > 0) {
        return (matchGradeId(clasz, gradeId) && matchDisciplineIds(clasz, disciplineIds));
    }
    else if (gradeId) {
        return matchGradeId(clasz, gradeId);
    }
    else if (disciplineIds?.length > 0) {
        return matchDisciplineIds(clasz, disciplineIds);
    }
    else {
        return true;
    }
}
