import React from 'react';
import { StyledDisciplineName, StyledWrapper } from './styles';
const Discipline = (props) => {
    const colorWithAlphaValue = props.discipline?.color
        ?.replace('(', 'a(')
        ?.replace(')', ', 0.1)');
    return (React.createElement(StyledWrapper, { "$backgroundColor": colorWithAlphaValue },
        React.createElement(StyledDisciplineName, { "$color": props.discipline?.color }, props.discipline?.name)));
};
export default Discipline;
