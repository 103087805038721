import React, { useCallback, useState } from 'react';
import { useSideMenu } from '@campus/components';
import Context from './context';
import { useControlBlipChatVisibility } from './hooks/useControlBlipChatVisibility';
const Provider = (props) => {
    const [isActive, setIsActive] = useState(false);
    const { setHiden: setIsMenuHidden } = useSideMenu();
    const { showChat, hideChat } = useControlBlipChatVisibility();
    const activate = useCallback(() => {
        setIsMenuHidden(true);
        setIsActive(true);
        hideChat();
    }, [setIsMenuHidden, hideChat]);
    const deactivate = useCallback(() => {
        setIsMenuHidden(false);
        setIsActive(false);
        showChat();
    }, [setIsMenuHidden, showChat]);
    return (React.createElement(Context.Provider, { value: { isActive, activate, deactivate } }, props.children));
};
export default Provider;
