import styled from 'styled-components';
import List, { Placeholder } from '../../../components/ClassLessonAndDateList';
import { StyledBasicButton } from '../../../styles';
export const StyledList = styled(List) `
  margin-top: 15px;
  height: min(450px, 50vh);
`;
export const StyledPlaceholder = styled(Placeholder) `
  margin-top: 11px;
  height: min(450px, 50vh);
`;
export const FilterContainer = styled.div `
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray5};

  padding: 7px 0 10px 0;
  column-gap: 4px;
`;
export const StyledFilterButton = styled(StyledBasicButton) `
  background-color: white;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  display: inline-flex;
  height: 24px;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: ${(props) => props.theme.colors.blackAlpha500};
  font-size: 12px;
`;
