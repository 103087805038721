function sortItems(params) {
    return (params.items?.sort((a, b) => {
        const aLabel = params.getItemLabel(a);
        const bLabel = params.getItemLabel(b);
        return aLabel?.localeCompare(bLabel, undefined, {
            sensitivity: 'accent'
        });
    }) ?? []);
}
function createOption(params, item) {
    const label = params.getItemLabel(item);
    const value = params.getItemValue(item);
    return { label, value };
}
export function createOptionsFrom(params) {
    const options = [];
    const sorted = sortItems(params);
    if (!!params.defaultOption) {
        options.push(params.defaultOption);
    }
    options.push(...sorted.map((item) => {
        return createOption(params, item);
    }));
    return options;
}
