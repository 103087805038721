import { FileAnswer } from '@campus/commons';
class Answer {
  id?: string;

  text?: string;

  optionText?: string;

  correct?: boolean;

  url?: string;

  fileName?: string;

  files?: FileAnswer[];

  static fromJson(data: any): Answer {
    const answer = new Answer();

    answer.id = data?.Id;
    answer.text = data?.Text;
    answer.optionText = data?.OptionText;
    answer.correct = data?.Correct;
    answer.url = data?.Url;
    answer.fileName = data?.FileName;
    answer.files = data?.Files?.map(file => FileAnswer.fromJson(file));

    return answer;
  }
}

export default Answer;
