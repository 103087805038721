/* eslint-disable unicorn/prefer-query-selector */

import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useDebounce } from 'use-debounce';

import { useAuthority } from '@campus/auth';
import { Skeleton, PaginationBar } from '@campus/components';
import { useContextManager, useTeacherId } from '@campus/commons';

import {
  Header,
  ContentTitle,
  DivFilters,
  LibraryObjectsDiv,
  LibraryObjectsList,
  LibraryObjectsScreen,
  InputStyled,
  LineBreak,
  Subtitle,
  Title,
  SearchIcon,
  DigitalObjects,
  DigitalObject,
  MyMaterials,
  Content,
  HeaderContainer,
  HeaderInMobile,
  EmptyDigitalObject,
  EmptyDigitalObjects,
  AllDigitalObjects
} from './styles';

import DisciplineSelect from './components/DisciplineSelect';
import TypeSelect from './components/TypeSelect';

import {
  listLibraryObjects,
  listTeacherLibraryObjects
} from 'services/libraryObject';

import useLibraryObjectsFilters from './hooks/useLibraryObjectsFilters';
import LibraryObject from 'models/LibraryObject';

const maximumNumberOfObjectsInMyMaterials = 6;

const ids = {
  myMaterialsObjects: 'my-materials-objects',
  objectList: 'object-list'
} as const;

const boundingContainers = {
  myMaterialsObjects: () => document.getElementById(ids.myMaterialsObjects),
  objectList: () => document.getElementById(ids.objectList)
} as const;

const LibraryObjects: React.FC = () => {
  const { currentContext } = useAuthority();
  const { classroom } = useContextManager();
  const teacherId = useTeacherId();
  const [numberPages, setNumberPages] = useState([]);
  const [libraryObjects, setLibraryObjects] = useState<LibraryObject[]>([]);
  const [myLibraryObjects, setMyLibraryObjects] =
    useState<LibraryObject[]>(null);
  const [loading, setLoading] = useState(true);
  const { filters, handleFilters } = useLibraryObjectsFilters();
  const [filtersDebounce] = useDebounce(filters, 1000);
  const theme = useTheme();
  const navigate = useNavigate();
  const pageZeroButtonRef = useRef();
  const location = useLocation();

  const fetchData = useCallback(
    async (page: number) => {
      const filter = { ...filtersDebounce, page };

      if (filter.grade?.id) {
        setLoading(true);

        const result = await listLibraryObjects({
          disciplineId: filter.discipline?.id,
          gradeId: filter.grade?.id,
          contentType: filter.type?.value?.toString(),
          skipCount: filter.page * filter.pageSize,
          name: filter.text,
          maxResultCount: filter.pageSize
        });

        const data = result.data;

        if (data) {
          const { totalCount } = data;
          const MAX_ARRAY_PAGES = 7;
          const ITEMS_PER_PAGE = 15;

          if (totalCount > 0) {
            const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
            let arrayPages = Array.from(Array(totalPages).keys());
            if (totalPages > MAX_ARRAY_PAGES) {
              let inicio = page / 3;
              if (page > 3) {
                inicio = page - 3;
              }
              if (page > totalPages - 3) {
                inicio = totalPages - MAX_ARRAY_PAGES;
              }
              let fim = inicio + MAX_ARRAY_PAGES;
              arrayPages = arrayPages.slice(inicio, fim);
            }
            setNumberPages(arrayPages);
          } else {
            setNumberPages([]);
          }

          const items = data?.items ?? [];
          const objects = page > 0 ? [...items] : items;
          setLibraryObjects(objects);
        }

        setLoading(false);
      }
    },
    [filtersDebounce]
  );

  const fetchTeacherData = useCallback(async () => {
    if (classroom?.gradeId && currentContext?.studyPeriodId && teacherId) {
      const filters = {
        gradeId: classroom.gradeId,
        studyPeriodId: currentContext.studyPeriodId
      };
      const result = await listTeacherLibraryObjects(teacherId, filters);

      setMyLibraryObjects(result?.data ?? []);
    }
  }, [classroom?.gradeId, currentContext?.studyPeriodId, teacherId]);

  const handleClickLibraryObject = useCallback(
    (libraryObjectId: string, chapterId?: string) => {
      const currentPath = location.pathname;
      if (!chapterId) {
        navigate(`/library-object/${libraryObjectId}/?origin=${currentPath}`);
      } else {
        navigate(
          `/library-object/${libraryObjectId}/chapter/${chapterId}/?origin=${currentPath}`
        );
      }
    },
    [location.pathname, navigate]
  );

  useEffect(() => {
    if (classroom?.gradeId) {
      fetchData(0);
    }
  }, [fetchData, classroom]);

  useEffect(() => {
    if (myLibraryObjects === null) {
      fetchTeacherData();
    }
  }, [fetchTeacherData, myLibraryObjects]);

  useEffect(() => {
    const myMaterialsObjects = boundingContainers.myMaterialsObjects();

    if (!myMaterialsObjects) return;

    const objects = myMaterialsObjects.firstElementChild as HTMLElement;
    const emptyObjects = myMaterialsObjects.lastElementChild as HTMLElement;

    const firstObject = objects.querySelector<HTMLElement>('.object');

    const gap =
      emptyObjects.getBoundingClientRect().left -
      objects.getBoundingClientRect().right;

    const observer = new ResizeObserver(() => {
      const scrollbarIsVisible = objects.scrollWidth > objects.offsetWidth;

      if (scrollbarIsVisible && !emptyObjects.hidden) {
        emptyObjects.hidden = true;
        return;
      }

      const emptyObjectsMinimumSize = firstObject.offsetWidth + gap;
      const availableSpace =
        myMaterialsObjects.offsetWidth - objects.offsetWidth;

      if (availableSpace > emptyObjectsMinimumSize && emptyObjects.hidden) {
        emptyObjects.hidden = false;
      }
    });

    observer.observe(myMaterialsObjects, { box: 'border-box' });

    return () => observer.disconnect();
  }, [myLibraryObjects?.length]);

  function createEmptyObjects() {
    const numberOfObjects = myLibraryObjects?.length ?? 0;
    const numberOfEmptyObjects =
      maximumNumberOfObjectsInMyMaterials - numberOfObjects;

    if (numberOfEmptyObjects <= 0) return [];

    const items = Array.from<ReactNode>({
      length: numberOfEmptyObjects
    });

    for (let i = 0; i < numberOfEmptyObjects; ++i) {
      items.push(<EmptyDigitalObject key={i} />);
    }

    return items;
  }

  return (
    <LibraryObjectsScreen>
      <HeaderContainer>
        <HeaderInMobile color={theme.colors.primary} title="Objetos Digitais" />

        <Header>
          <Title>Biblioteca de Objetos Digitais</Title>
          <Subtitle>
            Acesse os livros e materiais didáticos da biblioteca de objetos
            digitais
          </Subtitle>
        </Header>
      </HeaderContainer>

      {myLibraryObjects?.length > 0 && (
        <MyMaterials $scrollInResponsive>
          <ContentTitle>Meus Materiais</ContentTitle>

          <AllDigitalObjects id={ids.myMaterialsObjects}>
            <DigitalObjects>
              {myLibraryObjects.map((libraryObject) => (
                <DigitalObject
                  hasPermission
                  className="object"
                  key={libraryObject.id}
                  libraryObjectData={libraryObject}
                  onClick={handleClickLibraryObject}
                  getBoundingContainer={boundingContainers.myMaterialsObjects}
                />
              ))}
            </DigitalObjects>

            <EmptyDigitalObjects>{createEmptyObjects()}</EmptyDigitalObjects>
          </AllDigitalObjects>
        </MyMaterials>
      )}
      <Content $bgColor="#fff">
        <ContentTitle $fontSize={20} $lineHeight={130} $mb={1}>
          Encontre por série ou por palavra
        </ContentTitle>
        <ContentTitle $fontSize={14} $fontWeight={600} $mb={15}>
          Utilize os campos abaixo para filtrar e/ ou buscar livros e materiais
          didáticos
        </ContentTitle>
        <DivFilters>
          {/* <DivFiltersContent>
            <GradeSelect grade={filters.grade} onGradeChange={onChangeGrade} />
          </DivFiltersContent> */}
          {/* <DivFiltersContent>
          </DivFiltersContent> */}

          <DisciplineSelect
            discipline={filters.discipline}
            gradeId={filters.grade?.id}
            onDisciplineChange={(discipline) =>
              handleFilters('discipline', discipline, pageZeroButtonRef)
            }
          />
          <TypeSelect
            type={filters.type}
            onTypeChange={(type) => {
              handleFilters('type', type, pageZeroButtonRef);
            }}
          />
          <InputStyled
            StartIcon={<SearchIcon />}
            name="search"
            size="sm"
            aria-label="Digite uma palavra para buscar"
            placeholder="Digite para buscar"
            onChange={(e) =>
              handleFilters('text', e.target.value, pageZeroButtonRef)
            }
          />
        </DivFilters>
        <LineBreak />
        <LibraryObjectsDiv>
          <LibraryObjectsList id={ids.objectList}>
            {loading ? (
              <>
                <Skeleton width="165px" height="221px" marginRigth="10px" />
                <Skeleton width="165px" height="221px" marginRigth="10px" />
                <Skeleton width="165px" height="221px" marginRigth="10px" />
                <Skeleton width="165px" height="221px" marginRigth="10px" />
                <Skeleton width="165px" height="221px" marginRigth="10px" />
                <Skeleton width="165px" height="221px" marginRigth="10px" />
                <Skeleton width="165px" height="221px" marginRigth="10px" />
                <Skeleton width="165px" height="221px" marginRigth="10px" />
                <Skeleton width="165px" height="221px" marginRigth="10px" />
                <Skeleton width="165px" height="221px" marginRigth="10px" />
              </>
            ) : (
              libraryObjects?.map((libraryObject) => (
                <DigitalObject
                  key={libraryObject.id}
                  libraryObjectData={libraryObject}
                  onClick={handleClickLibraryObject}
                  hasPermission
                  getBoundingContainer={boundingContainers.objectList}
                />
              ))
            )}
          </LibraryObjectsList>
          {numberPages && numberPages.length > 1 && (
            <PaginationBar
              numberPages={numberPages}
              fetchData={fetchData}
              pageZeroButtonRef={pageZeroButtonRef}
            />
          )}
        </LibraryObjectsDiv>
      </Content>
    </LibraryObjectsScreen>
  );
};

export default LibraryObjects;
