import styled from 'styled-components';
import { StyledColumnWrapper } from '../common/styles';
export const StyledWrapper = styled(StyledColumnWrapper) `
  padding: 13px 11px 13px 8px;
  background-color: rgba(242, 242, 242);
  border-radius: inherit;
`;
export const StyledText = styled.span `
  font-size: 14px;
  font-weight: 700;
  line-height: 100%;
  writing-mode: vertical-rl;
  rotate: -180deg;
  word-break: break-all;

  color: ${(props) => props.theme.colors.gray1};
`;
