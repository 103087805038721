import { HeightRule, Paragraph, Table, TableCell, TableRow, WidthType } from 'docx';
import EvaluationSection from './components/EvaluationSection';
import PageAndInformation from './components/PageAndInformation';
import { emptyTableCellBorders } from '../../../common/borderDocx';
import { margins, widths } from '../../../common/sizesDocx';
export default function Footer(props) {
    const evaluationSection = new TableRow({
        children: [
            new TableCell({
                children: [EvaluationSection()],
                borders: emptyTableCellBorders,
                width: { type: WidthType.PERCENTAGE, size: widths.holeDocument }
            })
        ]
    });
    const pageAndDocumentInformation = new TableRow({
        children: [
            new TableCell({
                borders: emptyTableCellBorders,
                width: { type: WidthType.PERCENTAGE, size: widths.holeDocument },
                children: [PageAndInformation(props.pageIndex + 1, props.numberOfPages)]
            })
        ]
    });
    const marginTop = new TableRow({
        height: { value: margins.footer.top, rule: HeightRule.EXACT },
        children: [
            new TableCell({
                children: [new Paragraph({})],
                borders: emptyTableCellBorders,
                width: { size: widths.holeDocument, type: WidthType.PERCENTAGE }
            })
        ]
    });
    const pageNumberAndDatesMarginTop = new TableRow({
        height: {
            rule: HeightRule.EXACT,
            value: margins.footer.pageNumberAndDates.top
        },
        children: [
            new TableCell({
                children: [new Paragraph({})],
                borders: emptyTableCellBorders,
                width: { size: widths.holeDocument, type: WidthType.PERCENTAGE }
            })
        ]
    });
    return new Table({
        columnWidths: [widths.holeDocument],
        width: { size: widths.holeDocument, type: WidthType.PERCENTAGE },
        rows: [
            marginTop,
            evaluationSection,
            pageNumberAndDatesMarginTop,
            pageAndDocumentInformation
        ]
    });
}
