import styled from 'styled-components';
export const StyledTableBodyWrapper = styled.div `
  flex: 1;

  min-width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 20px;

  position: relative;
`;
