import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const StyledArrowIconWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledArrowDownIcon = styled(CampusIcon).attrs({
    name: 'selectArrowDown'
}) `
  width: 20px;
  height: 20px;
`;
