import React from 'react';
import DocumentsColumn from './components/DocumentsColumn';
import { StyledColumnsWrapper, StyledTitleWrapper, StyledWrapper } from './styles';
import { StyledTitle } from '../common/styles';
const Popup = (props) => {
    function onGenerateClassDiaryReport() {
        props.generateClassDiaryReport();
    }
    return (React.createElement(StyledWrapper, { isFullScreen: false, canBeDisplayed: props.canBeDisplayed, handleExternalClick: props.hidePopup },
        React.createElement(StyledTitleWrapper, null,
            React.createElement(StyledTitle, null, "Exportar")),
        React.createElement(StyledColumnsWrapper, null,
            React.createElement(DocumentsColumn, { onGenerateClassDiaryReport: onGenerateClassDiaryReport }))));
};
export default Popup;
