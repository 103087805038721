import React from 'react';
import ReactSelect from 'react-select';
import { useTheme } from 'styled-components';

import { SelectOption } from '@campus/components';

import { DropdownIndicator, selectStyles } from '../../utils/selectStyles';

type TypeOption = SelectOption;

const options: ReadonlyArray<TypeOption> = [
  {
    label: 'Todos os objetos',
    value: ''
  },
  {
    label: 'Vídeos',
    value: '1'
  },
  {
    label: 'Livros',
    value: '0'
  }
] as const;

interface TypeSelectProps {
  onTypeChange: (type: TypeOption) => void;
  type: SelectOption;
}

const TypeSelect: React.FC<TypeSelectProps> = ({ onTypeChange, type }) => {
  const theme = useTheme();

  const handleChange = (option: TypeOption) => onTypeChange(option);

  return (
    <ReactSelect
      name="objects_types"
      placeholder="Todos os objetos"
      noOptionsMessage={() => 'Nenhuma opção disponível'}
      loadingMessage={() => 'Carregando tipos de objeto...'}
      options={options}
      onChange={handleChange}
      isClearable={false}
      hideSelectedOptions={true}
      value={type ?? options[0]}
      styles={selectStyles(theme)}
      components={{
        DropdownIndicator: () => <DropdownIndicator />
      }}
    />
  );
};

export default TypeSelect;
