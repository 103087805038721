import React from 'react';
import Editor from './components/Editor';
import { StyledTitle } from '../common/styles';
import { StyledSubtitle, StyledWrapper } from './styles';
const Methodology = () => {
    return (React.createElement(StyledWrapper, null,
        React.createElement("div", null,
            React.createElement(StyledTitle, null, "Metodologia e Recursos Did\u00E1ticos"),
            React.createElement(StyledSubtitle, null, "Sugest\u00E3o: Indique a qual objetivo de aprendizagem a aula se refere")),
        React.createElement(Editor, null)));
};
export default Methodology;
