import React from 'react';
import { StyledCode, StyledDescription } from './styles';
const Description = (props) => {
    return (React.createElement(StyledDescription, { "$emphasize": props.emphasize, "$disabled": props.disabled ?? false, onClick: props.disabled ? null : props.onClick, "$useOrangeAsColor": props.useOrangeAsItsColor ?? false },
        props.code ? (React.createElement(StyledCode, { "$color": props.code.color },
            "(",
            props.code.text,
            ") ")) : null,
        props.content));
};
export default Description;
