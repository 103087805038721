import styled from 'styled-components';
import PopupBox from '../../PopupBox';
export const StyledPopup = styled(PopupBox) `
  max-width: 90dvw;
  padding: 17px 20px 20px 20px;
`;
export const StyledContentWrapper = styled.div `
  margin-top: 9px;
  margin-bottom: 20px;

  padding-top: 15px;
  padding-bottom: 20px;

  display: flex;

  border-width: 1px 0;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.gray5};
`;
export const StyledFirstColumnWrapper = styled.div `
  display: flex;
  flex-direction: column;

  padding-right: 17px;
  border-right: 1px solid ${(props) => props.theme.colors.gray5};

  & > :first-child {
    padding-bottom: 8px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
  }
`;
export const StyledSecondColumnWrapper = styled.div `
  flex-grow: 1;
  padding-left: 15px;
`;
export const StyledButtonsWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
