import { useUpdateAreaSkillStages } from '../../../../common/stagesRelated/hooks/useUpdateAreaSkillStages';
import { getAreaSkillById } from '../../../../../../../../../../../../services/areaSkill';
export function useUpdate() {
    const { requestStatus, update: baseUpdate } = useUpdateAreaSkillStages();
    async function update(params) {
        try {
            const response = await baseUpdate({
                areaSkill: params.areaSkill,
                allStages: params.allStages,
                selectedStages: params.selectedStages
            });
            if (response.skillWasUpdated) {
                const getResponse = await getAreaSkillById({
                    id: params.areaSkill?.id
                });
                return getResponse.data;
            }
            return params.areaSkill;
        }
        catch { }
    }
    return { update, requestStatus };
}
