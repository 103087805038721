import parseISO from 'date-fns/parseISO';

import Answer from './Answer';
import Teacher from './Teacher';

class StudentAnswer {
  contentItemId: string;

  score: number;

  teacher: Teacher;

  teacherCommnent: string;

  teacherScore: number;

  answer: Answer;

  teacherScoreTime?: Date;

  answerText?: string;

  static fromJson(data: any): StudentAnswer {
    const answer = new StudentAnswer();

    answer.contentItemId = data?.ContentItemId;
    answer.score = data?.Score;

    answer.teacherScore = data?.TeacherScore;
    answer.teacherCommnent = data?.TeacherCommnent;
    answer.answerText = data?.AnswerText;

    if (data?.TeacherScoreTime) {
      answer.teacherScoreTime = parseISO(data.TeacherScoreTime);
    }

    if (data?.Teacher) {
      answer.teacher = Teacher.fromJson(data.Teacher);
    }

    if (data?.Answer) {
      answer.answer = Answer.fromJson(data.Answer);
    }

    return answer;
  }

  getText(isOpenQuestion?: boolean) {
    const files = this.answer?.files;

    if (isOpenQuestion && !files?.length) {
      return this.answerText ?? '-';
    } else if (!!this.answerText) {
      return this.answerText;
    } else if (files?.length) {
      return '[Anexo]';
    }
  }
}

export default StudentAnswer;
