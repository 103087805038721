import React, { useMemo } from 'react';
import { useManagerId, Event } from '@campus/commons';
import { useFilters } from '../hooks';
import Context from './context';
export const Provider = ({ children }) => {
    const managerId = useManagerId();
    const events = useMemo(() => {
        return {
            filters: {
                someFilterChanged: new Event(),
                gradeIdChanged: new Event(),
                classIdsChanged: new Event(),
                disciplineIdsChanged: new Event(),
                startDateChanged: new Event(),
                endDateChanged: new Event()
            },
            table: {
                pageIndexChanged: new Event(),
                selectAllChanged: new Event(),
                teacherSelectionChanged: new Event(),
                dataFetched: new Event(),
                dataFetchFailed: new Event()
            }
        };
    }, []);
    const getFilters = useFilters(events);
    return (React.createElement(Context.Provider, { value: { managerId, events, getFilters } }, children));
};
