import styled from 'styled-components';
export const StyledWrapper = styled.div `
  display: flex;

  border-radius: 4px;
  border-style: solid;
  border-width: 0;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: ${(props) => props.theme.colors.gray200};
`;
