import styled from 'styled-components';
export const StyledWrapper = styled.div `
  width: 100%;

  display: flex;
  column-gap: 10px;

  & > * {
    width: 33%;
  }
`;
