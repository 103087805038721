import React from 'react';
import Objective from './components/Objective';
import { StyledWrapper } from '../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/LearningObjectives/styles';
const LearningObjectives = (props) => {
    function renderObjectives() {
        const sorted = props.planning.objectives.sort((a, b) => {
            const aCode = a.learningObjective?.code;
            const bCode = b.learningObjective?.code;
            return aCode?.localeCompare(bCode);
        });
        return sorted?.map((objective) => (React.createElement(Objective, { key: objective.id, objective: objective, areaSkill: props.planning.areaSkill })));
    }
    return React.createElement(StyledWrapper, null, renderObjectives());
};
export default LearningObjectives;
