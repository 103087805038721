import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import Discipline from '../models/Discipline';
import { handleApiCallError } from './common';
const baseEndpointPath = '/discipline';
const endpoints = {
    getAll: {
        disciplines: baseEndpointPath + '/disciplineWithGrades',
        disciplinesAndGrades: baseEndpointPath + '/disciplineAndGrades'
    }
};
export async function getAllDisciplines(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.getAll.disciplines, {
            signal: params?.abortSignal
        });
        const items = response.data.map(Discipline.fromJson);
        return Response.fromData(items, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function getDisciplinesAndGrades(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.getAll.disciplinesAndGrades, { signal: params?.abortSignal });
        const items = response.data.map(Discipline.fromJson);
        return Response.fromData(items, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
