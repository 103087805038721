import { useWeeklyPlanningPageContext } from '../../../../../../../../../../../context';
import { borderShouldBeDashed, borderShouldBeRed } from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/components/Rows/helpers/borders';
export function useBorders(props) {
    const { selectedGrade, selectedSegment, selectedDisciplines } = useWeeklyPlanningPageContext((store) => ({
        selectedGrade: store.curriculumComponents.selectedGrade,
        selectedSegment: store.curriculumComponents.selectedSegment,
        selectedDisciplines: store.curriculumComponents.selectedDisciplines
    }));
    const currentRowPlanning = props.rowInfos.original;
    const lastRowPlanning = props.group.plannings.at(-1);
    const currentRowBorderShouldBeDashed = borderShouldBeDashed({
        relatedPlanning: currentRowPlanning
    });
    const currentRowBorderShouldBeRed = borderShouldBeRed({
        selectedGrade,
        selectedSegment,
        selectedDisciplines,
        relatedPlanning: currentRowPlanning
    });
    const lastRowBorderShouldBeDashed = borderShouldBeDashed({
        relatedPlanning: lastRowPlanning
    });
    const lastRowBorderShouldBeRed = borderShouldBeRed({
        selectedGrade,
        selectedSegment,
        selectedDisciplines,
        relatedPlanning: lastRowPlanning
    });
    return {
        currentRowBorderShouldBeDashed,
        currentRowBorderShouldBeRed,
        lastRowBorderShouldBeDashed,
        lastRowBorderShouldBeRed
    };
}
