import Classroom from './Classroom';
export default class ClassroomGroup {
    className;
    classrooms;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new ClassroomGroup();
        instance.className = json.ClassName;
        instance.classrooms =
            json.Classrooms?.map((item) => {
                return Classroom.fromJson(item);
            }) ?? [];
        return instance;
    }
}
