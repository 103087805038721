import React from 'react';
import { useMainTableFocusContext } from '../../../contexts/mainTableFocus';
import PageSelector from './components/PageSelector';
import { StyledHeaderSubtitle, StyledTitle, StyledTitleAndSelectorWrapper, StyledWrapper } from './styles';
const PageHeader = (props) => {
    const { isActive } = useMainTableFocusContext();
    return isActive ? null : (React.createElement(StyledWrapper, null,
        React.createElement(StyledTitleAndSelectorWrapper, null,
            React.createElement(StyledTitle, null, "Planejamento Pedag\u00F3gico -"),
            React.createElement(PageSelector, null)),
        React.createElement(StyledHeaderSubtitle, null, props.description)));
};
export default PageHeader;
