const placeholders = {
    fillHere: 'Preencha aqui',
    textCannotChangeAnymore: 'O texto não pode mais ser alterado',
    hasNoPermission: 'Desculpe, você não tem permissão para editar esse campo'
};
export function getPlaceholder(params) {
    return params.readOnly
        ? placeholders.hasNoPermission
        : params.isPlanningDeleted
            ? placeholders.textCannotChangeAnymore
            : placeholders.fillHere;
}
