import React from 'react';
import Row from './components/Row';
import { StyledWrapper } from './styles';
const TableHeader = React.forwardRef((props, ref) => {
    function renderHeaderGroups() {
        const groups = props.tableInstance.headerGroups;
        return groups.map((group, index) => (React.createElement(Row, { key: index, asTr: props.asThead, columnGroup: group, allColumns: props.columns, tableElement: props.tableElement })));
    }
    return (React.createElement(StyledWrapper, { ref: ref, className: props.className, as: props.asThead ? 'thead' : 'div' }, renderHeaderGroups()));
});
export default TableHeader;
