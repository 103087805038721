import Row from './components/Row';
import Header from './components/Header';
import { AlignmentType, Table as DocTable, HeightRule, Paragraph, TableCell, TableRow, WidthType } from 'docx';
import { emptyTableCellBorders } from '../../../common/borderDocx';
import { margins, widths } from '../../../common/sizesDocx';
function createMargin(size) {
    return new TableRow({
        height: { value: size, rule: HeightRule.EXACT },
        children: [
            new TableCell({
                children: [new Paragraph({})],
                borders: emptyTableCellBorders,
                width: { size: widths.holeDocument, type: WidthType.PERCENTAGE }
            })
        ]
    });
}
export default function Table(data) {
    const bodyRows = data.map((model, index) => {
        const isTheLastRowInTheTable = index === data.length - 1;
        return Row({ areaSkill: model, isTheLastRowInTheTable });
    });
    const body = new TableRow({
        children: [
            new TableCell({
                borders: emptyTableCellBorders,
                width: { size: widths.holeDocument, type: WidthType.PERCENTAGE },
                children: [
                    new DocTable({
                        rows: bodyRows,
                        width: { size: widths.holeDocument, type: WidthType.PERCENTAGE },
                        columnWidths: [
                            widths.table.columns.studyUnit.skill,
                            widths.table.columns.studyUnit.learningObjectives,
                            widths.table.columns.studyUnit.knowledgeObjects
                        ]
                    })
                ]
            })
        ]
    });
    const marginTop = createMargin(margins.table.top);
    const bodyMarginTop = createMargin(margins.table.body.top);
    return new DocTable({
        alignment: AlignmentType.CENTER,
        columnWidths: [widths.holeDocument],
        rows: [marginTop, Header(), bodyMarginTop, body],
        width: { size: widths.holeDocument, type: WidthType.PERCENTAGE }
    });
}
