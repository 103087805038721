import KnowledgeObject from './KnowledgeObject';
export default class PedagogicalPlanningObject {
    id;
    wasProgrammed;
    pedagogicalPlanningId;
    knowledgeObject;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new PedagogicalPlanningObject();
        instance.id = json.Id;
        instance.wasProgrammed = json.Programmed;
        instance.pedagogicalPlanningId = json.PedagogicalPlanningId;
        instance.knowledgeObject = KnowledgeObject.fromJson(json.ObjectKnowledge);
        return instance;
    }
}
