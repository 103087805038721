import React from 'react';
import { useWeeklyPlanningPageContext } from '../../../../../../../../../context';
import { useGroupContext } from '../../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import BaseButton from '../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/DidacticSequenceRelated/components/AddActivityButton';
const AddActivityButton = (props) => {
    const { addActivity } = useGroupContext((store) => ({
        addActivity: store.sequenceOperations.add
    }));
    const { availableClassrooms, selectedDisciplines, userPermissions } = useWeeklyPlanningPageContext((store) => ({
        userPermissions: store.commonData.userPermissions.data,
        availableClassrooms: store.commonData.availableClassrooms,
        selectedDisciplines: store.curriculumComponents.selectedDisciplines
    }));
    const disabled = !userPermissions?.userCan.updateTheTeacherColumns();
    return (React.createElement(BaseButton, { planning: props.planning, newActivityIsUnforeseen: false, disciplines: selectedDisciplines, classroomGroups: availableClassrooms, handleActivityAdded: addActivity, disabled: disabled }));
};
export default AddActivityButton;
