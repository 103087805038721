import { Api } from '@campus/auth';
import { PaginationModel, Response } from '@campus/commons';
import { handleApiCallError } from './common';
import AreaSkillAndStage from '../models/AreaSkillAndStage';
const baseEndpointsPath = '/areaskillplanningstage';
const endpoints = {
    add: baseEndpointsPath,
    delete: baseEndpointsPath,
    get: {
        groupedAndPaginated: 'areaskill/grouped'
    }
};
export async function addAreaSkillAndStage(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.post(endpoints.add, {
            Type: 'U',
            AreaSkillId: params.areaSkillId,
            PlanningStageId: params.stageId
        }, { signal: params?.abortSignal });
        const content = AreaSkillAndStage.fromJson(response.data);
        return Response.fromData(content, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function deleteAreaSkillAndStage(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const endpointWithId = `${endpoints.delete}/${params.id}`;
        const response = await axiosInstance.delete(endpointWithId, {
            signal: params?.abortSignal
        });
        return Response.fromData(true, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
export async function getAreaSkillsGrouped(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const skipCount = params.pageIndex * params.pageSize;
        const response = await axiosInstance.get(endpoints.get.groupedAndPaginated, {
            signal: params?.abortSignal,
            params: {
                SkipCount: skipCount,
                GradeId: params.gradeId,
                SemesterId: params.stageId,
                SegmentId: params.segmentId,
                PlanningStageId: params.stageId,
                MaxResultCount: params.pageSize,
                DisciplineId: params.disciplineId
            }
        });
        const amount = response.data.TotalCount;
        const itemsCount = response.data.ItemsCount;
        const fetched = (skipCount ?? 0) + itemsCount;
        const hasNextPage = fetched < amount;
        const items = response.data.Items?.map((items) => {
            return items.map((skillsAndStages) => {
                return skillsAndStages.map((item) => {
                    return AreaSkillAndStage.fromJson(item);
                });
            });
        }) ?? [];
        const paginated = new PaginationModel(items, amount, itemsCount, hasNextPage);
        return Response.fromData(paginated, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
