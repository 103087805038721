import { StyleSheet } from '@react-pdf/renderer';
import { paddings } from './sizes';
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Gilroy',
        paddingTop: paddings.page.top,
        paddingLeft: paddings.page.left,
        paddingRight: paddings.page.right
    }
});
export default styles;
