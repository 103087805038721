import styled from 'styled-components';
import Select, { elementClassNames } from '../../../../../Basics/Select';
export const StyledSelect = styled((Select)) `
  & ${elementClassNames.wrapper} {
    height: 40px;

    padding: 0 16px;

    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.colors.gray4};
  }

  & ${elementClassNames.menu} {
    max-height: 350px;
  }

  &
    ${elementClassNames.option},
    &
    ${elementClassNames.placeholder},
    &
    ${elementClassNames.selectedValue} {
    font-size: 16px;
    font-weight: 600;

    color: ${(props) => props.theme.colors.gray2};
  }

  & ${elementClassNames.noOptionsMessage} > span {
    font-size: 14px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.gray2};
  }

  & ${elementClassNames.option}, & ${elementClassNames.wrapper} {
    padding-left: 16px;
    padding-right: 16px;
  }

  & ${elementClassNames.option}:hover {
    color: ${(props) => props.theme.colors.gray2};

    background-color: ${(props) => props.theme.colors.gray50};
  }

  & ${elementClassNames.dropdown} {
    margin-left: 10px;
    color: ${(props) => props.theme.colors.gray2};
  }
`;
export const StyledTabContentWrapper = styled.div `
  padding: 30px;

  flex-grow: 1;

  display: flex;
  flex-direction: column;

  overflow: hidden;

  background-color: white;
`;
export const StyledLineBehindFilters = styled.hr `
  width: 100%;
  margin: 15px 0;
  color: ${(props) => props.theme.colors.gray5};
`;
