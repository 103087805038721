import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import Select from 'react-select';
import { useWeeklyPlanningPageContext } from '../../../../../../context';
import { FilterGroup } from '../../styles';
import { SelectDropdownIndicator } from './SelectDropdownIndicator';
import { createStyles } from './styles';
const defaultOption = {
    label: 'Todas',
    value: null
};
const FilterClass = () => {
    const filters = useWeeklyPlanningPageContext((store) => ({
        classes: store.commonData.classes.data,
        selectedClass: store.mainTable.filters.selectedClass,
        classesStatus: store.commonData.classes.status,
        mainTableContent: store.mainTable.filteredContent,
        disciplinesSelecteds: store.curriculumComponents.selectedDisciplines,
        setSelectedClass: store.mainTable.filters.setSelectedClass
    }));
    const theme = useTheme();
    const options = useMemo(() => {
        return [defaultOption, ...(filters.classes ?? [])];
    }, [filters.classes]);
    function onChange(newValue) {
        if ('value' in newValue) {
            filters.setSelectedClass(null);
        }
        else {
            filters.setSelectedClass(newValue.id);
        }
    }
    return (React.createElement(FilterGroup, null,
        React.createElement("span", null, "Turmas:"),
        React.createElement(Select, { options: options, defaultValue: defaultOption, isClearable: false, styles: createStyles(theme), isSearchable: true, closeMenuOnSelect: true, onChange: onChange, noOptionsMessage: () => 'Nenhuma opção disponível', components: {
                DropdownIndicator: SelectDropdownIndicator,
                IndicatorSeparator: null
            }, getOptionValue: (classOrOption) => {
                return 'value' in classOrOption
                    ? classOrOption.value
                    : classOrOption.id;
            }, getOptionLabel: (classOrOption) => {
                return 'label' in classOrOption
                    ? classOrOption.label
                    : classOrOption.name;
            } })));
};
export default FilterClass;
