import React from 'react';
import Disciplines from './components/Disciplines';
import GradeColumn from './components/SimpleColumns/GradeColumn';
import SegmentColumn from './components/SimpleColumns/SegmentColumn';
import { StyledTitle } from '../common/styles';
import { StyledContentWrapper, StyledPopupBox } from './styles';
const SelectCurriculumComponentsPopup = (props) => {
    return (React.createElement(StyledPopupBox, { ...props, isFullScreen: false },
        React.createElement(StyledTitle, null, "Selecione o(s) Componente(s) Curricular(es)"),
        React.createElement(StyledContentWrapper, null,
            React.createElement(SegmentColumn, { segments: props.segments, selectedSegment: props.selectedSegment, onSelectedSegmentChange: props.setSelectedSegment }),
            React.createElement(GradeColumn, { grades: props.grades, selectedGrade: props.selectedGrade, selectedSegment: props.selectedSegment, onSelectedGradeChange: props.setSelectedGrade }),
            React.createElement(Disciplines, { disciplines: props.disciplines, selectedGrade: props.selectedGrade, selectedDisciplines: props.selectedDisciplines, addToSelectedDisciplines: props.addToSelectedDisciplines, removeFromSelectedDisciplines: props.removeFromSelectedDisciplines }))));
};
export default SelectCurriculumComponentsPopup;
