import styled from 'styled-components';
export const StyledMessageWrapper = styled.div `
  width: 100%;
  height: 100%;

  max-height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const StyledIconWrapper = styled.div `
  margin-bottom: 9px;
`;
const titleAndDescriptionCommon = `
  font-style: normal;
  font-weight: 600;
  line-height: 18px;

  color: ${(props) => props.theme.colors.gray700};
`;
export const StyledTitle = styled.h5 `
  ${titleAndDescriptionCommon}
  font-size: 20px;
`;
export const StyledDescription = styled.h6 `
  ${titleAndDescriptionCommon}
  font-size: 14px;
`;
export const StyledHighlightedText = styled.span `
  color: ${(props) => props.theme.colors.blue1};
`;
