import React from 'react';
import { useFollowUpPageContext } from '../../context';
import BaseSubtitle from '../../../common/components/PageRelated/Subtitle/';
export default function Subtitle() {
    const evaluationTypes = useFollowUpPageContext((store) => {
        return store.commonData.evaluationTypes.data;
    });
    return (React.createElement(BaseSubtitle, { content: [
            'used',
            'unnused',
            'evaluativeObjective',
            'notPlanned',
            'planned',
            evaluationTypes,
            'br',
            'skillOfOtherComponent',
            'plannedButDeletedSkill'
        ] }));
}
