import React, { useState } from 'react';
import BaseDatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { StyledWrapper, StyledArrowIcon, StyledCloseIcon } from './styles';
const DatePicker = (props) => {
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    function onChange(newDate) {
        props.onChange(newDate);
    }
    function onCalendarOpen() {
        setIsCalendarOpen(true);
    }
    function onCalendarClose() {
        setIsCalendarOpen(false);
    }
    return (React.createElement(StyledWrapper, null,
        React.createElement(BaseDatePicker, { locale: "pt-br", maxDetail: "month", minDetail: "decade", format: "dd/MM/yyyy", dayPlaceholder: "dd", monthPlaceholder: "mm", yearPlaceholder: "aaaa", value: props.value, onChange: onChange, onCalendarOpen: onCalendarOpen, onCalendarClose: onCalendarClose, calendarIcon: React.createElement(StyledArrowIcon, null), clearIcon: isCalendarOpen || !!props.value ? React.createElement(StyledCloseIcon, null) : null })));
};
export default DatePicker;
