import React, { useEffect, useState } from 'react';
import { useCalendarContext } from '../../context';
import { calculateDistanceToRight } from './helpers/distances';
import { StyledLine, StyledWrapper } from './styles';
const BlueLayer = (props) => {
    const [right, setRight] = useState(-1);
    const months = useCalendarContext((store) => store.months);
    useEffect(() => {
        if (!props.parentElement)
            return;
        const right = calculateDistanceToRight(months, props.parentElement);
        if (right >= 0) {
            setRight(right);
        }
    }, [props.parentElement, months]);
    return right < 0 ? null : (React.createElement(React.Fragment, null,
        React.createElement(StyledWrapper, { "$right": right }),
        React.createElement(StyledLine, { "$right": right })));
};
export default BlueLayer;
