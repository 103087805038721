import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
import { StyledColumnWrapper } from '../common/styles';
export const StyledWrapper = styled(StyledColumnWrapper) `
  height: 100%;

  padding: 12px 14px;

  background-color: white;

  display: flex;
  column-gap: 8.3px;

  border-right-width: 0;

  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.25);
`;
export const StyledCircleWithCheck = styled(CampusIcon).attrs({
    name: 'circleWithCheckBold'
}) `
  color: ${(props) => props.theme.colors.green2};
`;
export const StyledCircleWithCheckWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
`;
