import React from 'react';
import { View } from '@react-pdf/renderer';
import Row from './components/Row';
import styles from './styles';
const Body = (props) => {
    function renderRows() {
        return props.rows.map((columns, index) => (React.createElement(Row, { key: index, columns: columns, isTheFirstRow: index === 0, isTheLastRow: index === props.rows.length - 1 })));
    }
    return React.createElement(View, { style: styles.wrapper }, renderRows());
};
export default Body;
