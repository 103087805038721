import styled from 'styled-components';
import { Popover } from '@campus/components';
import { Button } from '@campus/components';

export const TriedPopoverContainer = styled(Popover)`
  &.popover {
    max-width: 643px !important;
    border: 2px solid ${(props) => props.theme.colors.CSA.darkBlue};

    & .popover-arrow::before {
      border-right-color: ${(props) => props.theme.colors.CSA.darkBlue};
    }

    & .popover-arrow::after {
      left: 2px;
    }
  }
`;

export const TriedContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

export const TriedHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
  }

  & .btn-primary,
  & .btn-primary:disabled,
  & .btn-primary:focus,
  & .btn-primary:hover,
  & .btn-primary:active {
    color: ${(props) => props.theme.colors.gray500};
    border: 1px solid ${(props) => props.theme.colors.gray200};
    background-color: transparent;
  }
`;

export const TriedBody = styled.div`
  margin-bottom: 24px;

  table {
    thead,
    tbody {
      tr {
        th,
        td {
          :first-child {
            border-left-color: transparent !important;
          }
          :last-child {
            border-right-color: transparent !important;
          }
        }
      }
    }
  }
`;

export const TriedFooter = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #4f4f4f;

    :first-child {
      font-weight: 700;
      font-size: 20px;
      line-height: 110%;
      margin-bottom: 10px;
    }
  }
`;

export const CenterElement = styled.div`
  text-align: center;
`;

export const ScoreText = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: ${(p) => p.color};
`;

export const DataText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.gray600};
`;

export const TriedTitle = styled.h3`
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 130%;
  color: #333333;
  max-width: 500px;
`;

export const StyledButton = styled(Button)`
  max-height: 45px;
`;
