import { Response } from '@campus/commons';
import { handleApiCallError } from '../common';
import { deleteClassroomAndSequence } from '../classroomAndDidaticSequence';
export async function deleteClassroomAndDidacticSequences(params) {
    try {
        await Promise.all(params.classroomAndSequences?.map((c) => {
            return deleteClassroomAndSequence({
                abortSignal: params.abortSignal,
                classroomAndSequenceId: c.id
            });
        }));
        return Response.fromData(true, 200);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
