import React from 'react';
import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
const StyledWrapper = styled.div `
  &,
  & > div {
    width: 100%;
    height: 100%;
  }

  & > div {
    display: block;
    position: relative;
  }
`;
const StyledIcon = styled(CampusIcon).attrs({
    name: 'vcheck'
}) `
  color: white;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const Check = () => {
    return (React.createElement(StyledWrapper, null,
        React.createElement(StyledIcon, null)));
};
export default Check;
