import React, { useEffect, useState } from 'react';
import { useLaunchReportContext } from '../../../../../../../context';
import { useTableAndRelatedContext } from '../../../../../context';
import { Checkbox as BaseCheckbox } from '../../../../../../Checkbox';
export const Checkbox = ({ teacherId }) => {
    const { events } = useLaunchReportContext();
    const { isTeacherSelected } = useTableAndRelatedContext();
    const [isChecked, setIsChecked] = useState(isTeacherSelected(teacherId));
    useEffect(() => {
        return events.table.selectAllChanged.subscribe((payload) => {
            setIsChecked(payload?.isChecked ?? false);
        });
    }, [events.table.selectAllChanged]);
    useEffect(() => {
        return events.filters.someFilterChanged.subscribe(() => {
            setIsChecked(false);
        });
    }, [events.filters.someFilterChanged]);
    function onChange(event) {
        setIsChecked(event.target.checked);
        events.table.teacherSelectionChanged.emit({
            teacherId,
            isSelected: event.target.checked
        });
    }
    return React.createElement(BaseCheckbox, { checked: isChecked, onChange: onChange });
};
