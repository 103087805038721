import { useCallback, useEffect } from 'react';
import { useTeacherId } from '@campus/commons';
import { useFetch } from './useFetch';
import { getAvailableClassrooms } from '../../../../services/classroom';
export function useFetchAvailableClassrooms(props) {
    const teacherId = useTeacherId();
    const { data, requestStatus, fetchStarted, resetStatus, handleFailure, handleSuccess } = useFetch({ initialData: [] });
    const fetchClassrooms = useCallback((abortSignal) => {
        if (!props.selectedGrade)
            return;
        if (!props.selectedDisciplines)
            return;
        if (props.selectedDisciplines.length === 0)
            return;
        fetchStarted();
        getAvailableClassrooms({
            abortSignal,
            teacherId,
            gradeId: props.selectedGrade.id,
            disciplineId: props.selectedDisciplines.map((d) => d.id).join(',')
        })
            .then((response) => handleSuccess(response.data))
            .catch((response) => handleFailure(response.error?.error))
            .finally(resetStatus);
    }, [
        teacherId,
        fetchStarted,
        resetStatus,
        handleSuccess,
        handleFailure,
        props.selectedGrade,
        props.selectedDisciplines
    ]);
    useEffect(() => {
        const abortController = new AbortController();
        fetchClassrooms(abortController.signal);
        return () => abortController.abort();
    }, [fetchClassrooms]);
    return { data, requestStatus };
}
