import styled from 'styled-components';
export const StyledBox = styled.div `
  width: 92px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  border-width: 1px;
  border-radius: 6px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.gray200};
`;
export const StyledText = styled.span `
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.blackAlpha500};
`;
