import { Paragraph, TableCell, TextRun, VerticalAlign, WidthType } from 'docx';
import { teacherTheme } from '@campus/components';
import { columnBorder, createColumnPadding } from './common';
import { fontSizes, margins, widths } from '../../../../../common/sizesDocx';
export default function Skill(infos) {
    const content = new Paragraph({
        indent: {
            left: margins.table.body.columns.left,
            right: margins.table.body.columns.right
        },
        children: [
            new TextRun({
                bold: true,
                text: `(${infos.code?.trim()}) `,
                size: fontSizes.table.columnContent,
                color: teacherTheme.colors.CSA.purple
            }),
            new TextRun({
                bold: true,
                text: infos.description?.trim(),
                color: teacherTheme.colors.gray700,
                size: fontSizes.table.columnContent
            })
        ]
    });
    const padding = createColumnPadding(widths.table.columns.annualTimeline.skill);
    return new TableCell({
        verticalAlign: VerticalAlign.TOP,
        width: { size: widths.table.columns.annualTimeline.skill, type: WidthType.PERCENTAGE },
        children: [padding, content, padding],
        borders: {
            top: columnBorder,
            bottom: columnBorder,
            right: columnBorder,
            left: columnBorder
        }
    });
}
