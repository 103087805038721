import { BorderStyle, Paragraph, TableCell, TextRun, VerticalAlign, WidthType } from 'docx';
import { emptyTableCellBorders } from '../../../../common/borderDocx';
import { fontSizes, widths } from '../../../../common/sizesDocx';
export default function TitleAndSubtitle(informationData) {
    return new TableCell({
        rowSpan: 2,
        verticalAlign: VerticalAlign.CENTER,
        width: { size: widths.header.titleAndSubtitle, type: WidthType.PERCENTAGE },
        borders: {
            ...emptyTableCellBorders,
            right: { size: 15, style: BorderStyle.SINGLE }
        },
        children: [
            new Paragraph({
                children: [
                    new TextRun({
                        bold: true,
                        text: 'Cronograma Anual',
                        size: fontSizes.header.title
                    })
                ]
            }),
            new Paragraph({
                children: [
                    new TextRun({
                        bold: true,
                        size: fontSizes.header.subtitle,
                        text: `COMPONENTE CURRICULAR: ${informationData.discipline}`
                    })
                ]
            })
        ]
    });
}
