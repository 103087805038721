import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useWeeklyPlanningPageContext } from '../../../../../../../../context';
import { useWeeklyPlanningPageMainTableContext } from '../../../../context';
import { GroupContextProvider } from '../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/context';
import Row from './components/Row';
import { StyledTable, StyledTableBody } from './styles';
const Group = (props) => {
    const { group: groupFromFilteredContent, index } = props;
    const { updateGroup, getGroupFromNonFilteredContent } = useWeeklyPlanningPageContext((store) => ({
        updateGroup: store.mainTable.operations.updateGroup,
        getGroupFromNonFilteredContent: store.mainTable.operations.getGroupFromNonFilteredContent
    }));
    const { tableInstance } = useWeeklyPlanningPageMainTableContext();
    function renderRows(draggableProvided) {
        return groupFromFilteredContent.plannings.map((planning) => {
            const row = tableInstance.rowsById[planning.id];
            tableInstance.prepareRow(row);
            return (React.createElement(Row, { key: row.id, rowInfos: row, draggableProvided: draggableProvided }));
        });
    }
    return (React.createElement(GroupContextProvider, { updateGroupOnMainTable: updateGroup, groupFromFilteredContent: groupFromFilteredContent, groupFromNonFilteredContent: getGroupFromNonFilteredContent(groupFromFilteredContent.number) },
        React.createElement(Draggable, { index: index, draggableId: groupFromFilteredContent.number.toString() }, (provided) => (React.createElement(StyledTable, { ref: provided.innerRef, ...provided.draggableProps },
            React.createElement(StyledTableBody, null, renderRows(provided)))))));
};
export default Group;
