import { Response } from '@campus/commons';
import { handleApiCallError } from '../common';
import { deleteDidaticSequence, updateDidaticSequence } from '../didaticSequence';
import { deleteClassroomAndDidacticSequences } from './deleteClassroomAndDidacticSequences';
async function deleteSequenceByUpdatingDeletedField(params) {
    const response = await updateDidaticSequence({
        abortSignal: params.abortSignal,
        isDeleted: true,
        id: params.sequence.id,
        newIndex: params.sequence.order,
        description: params.sequence.description,
        impactTheMDC: params.sequence.impactTheMDC,
        evaluationTypeId: params.sequence.evaluationType?.id
    });
    return Response.fromData({ wasReallyDeleted: false }, response.statusCode);
}
async function deleteSequencePhysically(params) {
    const response = await deleteDidaticSequence({
        id: params.sequence.id
    });
    return Response.fromData({ wasReallyDeleted: true }, response.statusCode);
}
export async function deleteDidaticSequenceAndItsClassrooms(params) {
    try {
        await deleteClassroomAndDidacticSequences({
            abortSignal: params.abortSignal,
            classroomAndSequences: params.sequence.classrooms
        });
        const planningWasFinished = params.planning.status?.isFinished();
        if (planningWasFinished) {
            return deleteSequenceByUpdatingDeletedField(params);
        }
        return deleteSequencePhysically(params);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
