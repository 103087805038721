import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import styles from './styles';
const KnowledgeObjects = (props) => {
    function renderObjects() {
        const sorted = props.objects?.sort((a, b) => {
            return a?.description.localeCompare(b?.description);
        });
        return sorted.map((object) => (React.createElement(Text, { widows: 1, key: object.id, style: styles.description }, object.description?.trim())));
    }
    return React.createElement(View, { style: styles.wrapper }, renderObjects());
};
export default KnowledgeObjects;
