import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import styles from './styles';
const LearningObjectives = (props) => {
    function renderObjectives() {
        let objectives = props.areaSkill.learningObjectives;
        if (props.showJustEvaluativeObjectives) {
            objectives = objectives?.filter((o) => o.isEvaluative);
        }
        const sorted = objectives?.sort((a, b) => {
            return a?.code?.localeCompare(b?.code);
        });
        return sorted?.map((objective) => (React.createElement(View, { key: objective.id },
            React.createElement(Text, { style: {
                    ...styles.codeAndDescription,
                    fontWeight: objective.isEvaluative ? 700 : 600
                } },
                React.createElement(Text, { style: {
                        color: props.areaSkill?.discipline?.color
                    } },
                    "(",
                    objective.code?.trim(),
                    ")"),
                React.createElement(Text, { style: styles.description },
                    ' ',
                    objective.description?.trim())))));
    }
    return React.createElement(View, { style: styles.wrapper }, renderObjectives());
};
export default LearningObjectives;
