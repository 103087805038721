import { useCallback, useEffect } from 'react';
import { getAllDisciplines } from '../../../../services/disciplineAndGrade';
import { useFetch } from './useFetch';
export function useFetchDisciplines() {
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess } = useFetch({
        initialData: []
    });
    const fetchDisciplines = useCallback((abortSignal) => {
        fetchStarted();
        return getAllDisciplines({ abortSignal })
            .then((response) => handleSuccess(response.data))
            .catch(handleFailure);
    }, [fetchStarted, handleFailure, handleSuccess]);
    useEffect(() => {
        const abortController = new AbortController();
        fetchDisciplines(abortController.signal);
        return () => abortController.abort();
    }, [fetchDisciplines]);
    return { data, requestStatus };
}
