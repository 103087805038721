import styled from 'styled-components';
import { Table } from '../../../TableCommons';
export const Container = styled(Table.Container) ``;
export const Header = styled(Table.Header) `
  th:nth-child(1) {
    min-width: 38.7%;
  }

  th:nth-child(2) {
    min-width: 32.3%;
  }

  th:nth-child(3) {
    min-width: 9.3%;
  }

  th:nth-child(4) {
    min-width: 10.5%;
  }

  th:nth-child(5) {
    min-width: 9.2%;
  }
`;
export const Body = styled(Table.Body) ``;
