import { useState, useRef, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useContent } from '@campus/discipline';
import { toast } from '@campus/components';

import { changeAnswerScore } from 'services/answers';
import { useQuestionReview } from '../../../context/QuestionReviewContext';
import { useActivityReport } from '../../../context/ActivityReportContext';
import { useShowCorrection } from '../context/ShowCorrectionContext';

type FormFields = {
  correction: string;
  score: string;
};

const useCorrection = () => {
  const { contentId } = useContent();
  const { cancelEdit } = useShowCorrection();
  const { question, answer } = useQuestionReview();
  const { selectedStudent, updateStudents } = useActivityReport();

  const [loading, setLoading] = useState(false);

  const defaultValues = useMemo(
    () => ({
      correction: answer?.teacherCommnent ?? '',
      score: `${answer?.teacherScore ?? ''}`
    }),
    [answer]
  );

  const { handleSubmit, reset, control, setValue } = useForm<FormFields>({
    defaultValues: defaultValues
  });

  const studentIdRef = useRef<string>();
  const studentId = selectedStudent.student.id;

  useEffect(() => {
    reset({
      correction: answer?.teacherCommnent ?? '',
      score: `${answer?.teacherScore ?? ''}`
    });
  }, [reset, answer]);

  useEffect(() => {
    if (studentIdRef.current !== studentId) {
      studentIdRef.current = studentId;
    }
  }, [studentId]);

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const response = await changeAnswerScore(contentId, {
      score: parseFloat(data.score),
      commnent: data.correction,
      studentId: [selectedStudent.student.id],
      contentItemId: question.contentItemId
    });

    if (response.data) {
      updateStudents(response.data);
      cancelEdit();
      toast.success('Correção efetuada com sucesso!');
    } else if (response.error) {
      toast.error(response.error.message);
    }
    setLoading(false);
  });

  return {
    loading,
    onSubmit,
    control,
    weight: question.weight,
    setValue
  };
};

export default useCorrection;