import { updateSlice } from './slice';
import { getDistinct } from '../../../utils/arrays';
export function tryFillAvailableSegments(api) {
    const store = api.getState();
    const grades = store.commonData.grades.data;
    const segments = store.commonData.segments.data;
    if (!grades || !segments)
        return;
    const availableSegments = segments.filter((s) => {
        return grades.some((g) => g.segment?.id === s.id);
    });
    api.setState((store) => {
        return updateSlice(store, { availableSegments });
    });
}
export function tryFillAvailableGrades(api) {
    const store = api.getState();
    const grades = store.commonData.grades.data;
    const disciplines = store.commonData.disciplines.data;
    const { selectedSegment } = store.curriculumComponents;
    if (!grades || !disciplines || !selectedSegment)
        return;
    const availableGrades = grades.filter((g) => {
        return (g.segment?.id === selectedSegment?.id &&
            disciplines.some((d) => d.gradeId === g.id));
    });
    api.setState((store) => {
        return updateSlice(store, { availableGrades });
    });
}
export function tryFillAvailableDisciplines(api) {
    const store = api.getState();
    let disciplines = store.commonData.disciplines.data;
    const { selectedGrade } = store.curriculumComponents;
    if (!disciplines || !selectedGrade)
        return;
    disciplines = disciplines.filter((d) => d.gradeId === selectedGrade.id);
    const availableDisciplines = getDistinct(disciplines, (d) => d.id);
    api.setState((store) => {
        return updateSlice(store, { availableDisciplines });
    });
}
