import { Upload } from '@campus/commons';
import { useAddOrEditActivityPopupContext } from '../../../../../context';
export function useUploadImage() {
    const setIsUploading = useAddOrEditActivityPopupContext((store) => store.setAnImageIsBeingUploaded);
    async function uploadImage(file) {
        setIsUploading(true);
        try {
            const upload = new Upload('content', { type: 'image' });
            await upload.attrFile(file);
            await upload.exec();
            return upload.getViewUrl();
        }
        catch {
            console.error('Failed to upload image');
        }
        finally {
            setIsUploading(false);
        }
    }
    return { uploadImage };
}
