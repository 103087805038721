import { errorHappened, loadingData, notAllCurriculumComponentsHaveBeenSelected } from '../../../common/components/PageRelated/Body/components/Table/components/TablePlaceholder';
import { RequestStatus } from '../../../common/types/dataFetching';
export const noContentPlaceholder = {
    title: 'Planejamento não finalizado',
    description: 'Para poder fazer o Acompanhamento é necessário que ' +
        'o Planejamento seja finalizado'
};
export function getPlaceholderContent(params) {
    const componentsPlaceholderAlias = notAllCurriculumComponentsHaveBeenSelected;
    if (!params.selectedGrade)
        return componentsPlaceholderAlias;
    if (!params.selectedSegment)
        return componentsPlaceholderAlias;
    if (!params.selectedDisciplines)
        return componentsPlaceholderAlias;
    if (params.selectedDisciplines.length === 0)
        return componentsPlaceholderAlias;
    if (params.requestStatus === RequestStatus.Loading)
        return loadingData;
    if (params.requestStatus === RequestStatus.Failed)
        return errorHappened;
    return noContentPlaceholder;
}
