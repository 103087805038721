import styled from 'styled-components';
export const StyledWrapper = styled.div `
  height: 24px;

  padding: 5px 12px;

  display: flex;
  align-items: center;

  ${(props) => {
    if (props.$disabled)
        return;
    return `
      cursor: pointer;

      &:hover {
        background-color: ${props.theme.colors.gray50};
      }
    `;
}}
`;
export const StyledLabel = styled.span `
  font-size: 14px;
  font-weight: 500;
  font-style: normal;

  user-select: none;
  white-space: nowrap;

  color: ${(props) => props.$disabled ? props.theme.colors.gray300 : props.theme.colors.gray3};
`;
