import React, { useCallback, useEffect, useMemo } from 'react';
import { useAddSkillPopupContext } from '../../../../../context';
import { useFetchTableContent } from './hooks/useFetchTableContent';
import BaseTable from '../../../common/components/Table';
import { getPlaceholderMessage } from '../../../common/components/Table/placeholders';
import { RequestStatus } from '../../../../../../../../types/dataFetching';
import { StyledMessage, StyledMessageWrapper } from '../../../common/components/Table/styles';
const Table = () => {
    const { selectedGrade, selectedSegment, selectedDisciplines, tableContent, setTableContent } = useAddSkillPopupContext((store) => ({
        tableContent: store.tableContent,
        setTableContent: store.setTableContent,
        selectedGrade: store.selectedGrade,
        selectedSegment: store.selectedSegment,
        selectedDisciplines: store.selectedDisciplines
    }));
    const abortController = useMemo(() => new AbortController(), []);
    const fetch = useFetchTableContent();
    const fetchMoreSkills = useCallback(() => {
        fetch.fetchMoreSkills(abortController.signal);
    }, [abortController.signal, fetch]);
    useEffect(() => {
        setTableContent(fetch.data);
    }, [fetch.data, setTableContent]);
    useEffect(() => {
        return () => abortController.abort();
    }, [abortController]);
    function renderPlaceholder() {
        const message = getPlaceholderMessage({
            requestStatus: fetch.requestStatus,
            isGradeSelected: !!selectedGrade,
            isSegmentSelected: !!selectedSegment,
            isThereSomeDisciplineSelected: selectedDisciplines?.length > 0
        });
        return (React.createElement(StyledMessageWrapper, { "$fillSpace": true },
            React.createElement(StyledMessage, null, message)));
    }
    function renderLoadingContentMessage() {
        if (!fetch.data)
            return null;
        if (fetch.data.length === 0)
            return null;
        if (fetch.requestStatus !== RequestStatus.Loading)
            return null;
        return (React.createElement(StyledMessageWrapper, null,
            React.createElement(StyledMessage, null, "Carregando mais itens...")));
    }
    function renderTable() {
        return (React.createElement(BaseTable, { content: tableContent, handleLastItemReached: fetchMoreSkills }, renderLoadingContentMessage()));
    }
    return tableContent?.length > 0 ? renderTable() : renderPlaceholder();
};
export default Table;
