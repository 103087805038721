import styled from 'styled-components';
export const StyledRightSideWrapper = styled.div `
  width: fit-content;

  display: flex;
  column-gap: 11.5px;
  align-items: center;

  @media (max-width: 760px) {
    justify-content: center;
  }
`;
