import React, { useEffect, useRef, useState } from 'react';
import Menu from './components/Menu';
import { StyledBox, StyledWrapper } from './styles';
const ClassBox = (props) => {
    const [boundingRect, setBoundingRect] = useState();
    const [canMenuBeDisplayed, setCanMenuBeDisplayed] = useState(false);
    const wrapperRef = useRef(null);
    const showMenu = () => setCanMenuBeDisplayed(true);
    const hideMenu = () => setCanMenuBeDisplayed(false);
    useEffect(() => {
        const wrapper = wrapperRef.current;
        setBoundingRect(wrapper?.getBoundingClientRect());
    }, []);
    function handleOptionClicked(option) {
        hideMenu();
        props.handleOptionClicked(option);
    }
    return (React.createElement(StyledWrapper, { ref: wrapperRef },
        React.createElement(StyledBox, { onClick: showMenu },
            "Aula ",
            props.infos.selectedClassroom.number),
        React.createElement(Menu, { infos: props.infos, canBeDisplayed: canMenuBeDisplayed, parentBoundingRect: boundingRect, handleExternalClick: hideMenu, handleOptionClicked: handleOptionClicked })));
};
export default ClassBox;
