import { useEffect } from 'react';
import { useStore } from 'zustand';
import { useFetchGrades } from '../../common/hooks/dataFetching/useFetchGrades';
import { useFetchStages } from '../../common/hooks/dataFetching/useFetchStages';
import { useFetchSegments } from '../../common/hooks/dataFetching/useFetchSegments';
import { useFetchDisciplines } from '../../common/hooks/dataFetching/useFetchDisciplines';
import { useFetchUserPermissions } from '../../common/hooks/dataFetching/useFetchUserPermissions';
export function useFetchCommonData(storeApi) {
    const things = useStore(storeApi, (store) => ({
        selectedGrade: store.curriculumComponents.selectedGrade,
        selectedDisciplines: store.curriculumComponents.selectedDisciplines,
        setGrades: store.commonData.setGrades,
        setStages: store.commonData.setStages,
        setSegments: store.commonData.setSegments,
        setDisciplines: store.commonData.setDisciplines,
        setUserPermissions: store.commonData.setUserPermissions
    }));
    const { data: stages, requestStatus: stagesStatus } = useFetchStages();
    const { data: grades, requestStatus: gradesStatus } = useFetchGrades();
    const { data: segments, requestStatus: segmentsStatus } = useFetchSegments();
    const { data: disciplines, requestStatus: disciplinesStatus } = useFetchDisciplines();
    const { data: userPermissions, requestStatus: userPermissionsStatus } = useFetchUserPermissions({
        selectedGrade: things.selectedGrade,
        selectedDisciplines: things.selectedDisciplines
    });
    useEffect(() => {
        things.setGrades({ data: grades, status: gradesStatus });
    }, [things.setGrades, grades, gradesStatus]);
    useEffect(() => {
        things.setSegments({ data: segments, status: segmentsStatus });
    }, [things.setSegments, segments, segmentsStatus]);
    useEffect(() => {
        things.setStages({ data: stages, status: stagesStatus });
    }, [things.setStages, stages, stagesStatus]);
    useEffect(() => {
        things.setDisciplines({
            data: disciplines,
            status: disciplinesStatus
        });
    }, [things.setDisciplines, disciplines, disciplinesStatus]);
    useEffect(() => {
        things.setUserPermissions({
            data: userPermissions,
            status: userPermissionsStatus
        });
    }, [things.setUserPermissions, userPermissions, userPermissionsStatus]);
}
