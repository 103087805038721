import { useState } from 'react';
import { useTeacherId } from '@campus/commons';
import { useAddSkillPopupContext } from '../../../../../../../../../../../../context';
import { useIsMounted } from '../../../../../../../../../../../../../../../hooks';
import { RequestStatus } from '../../../../../../../../../../../../../../../types/dataFetching';
import { times } from '../../../../../../../../../../../../../../../constants';
import { addAreaSkillAndRelated } from '../../../../../../../../../../../../../../../../../services/agregators/addSkillAndRelated';
export function useAddSkill(infos) {
    const [requestStatus, setRequestStatus] = useState(RequestStatus.NotStarted);
    const teacherId = useTeacherId();
    const { selectedGrade, selectedSegment, newSkillDisciplineId, allPlanningStatuses, addPlanningToGroupWithNumber, handleAddSkillSucceeded } = useAddSkillPopupContext((store) => ({
        selectedGrade: store.selectedGrade,
        selectedSegment: store.selectedSegment,
        allPlanningStatuses: store.allPlanningStatuses,
        newSkillDisciplineId: store.newSkillDisciplineId,
        addPlanningToGroupWithNumber: store.addPlanningToGroupWithNumber,
        handleAddSkillSucceeded: store.handleAddSkillSucceeded
    }));
    const { isMounted } = useIsMounted();
    function resetStatusAfterDelay() {
        setTimeout(() => {
            if (isMounted()) {
                setRequestStatus(RequestStatus.NotStarted);
            }
        }, times.toResetARequestStatus);
    }
    function handleAddFailure(reject) {
        return () => {
            if (isMounted()) {
                setRequestStatus(RequestStatus.Failed);
            }
            reject();
        };
    }
    function handleAddSuccess(resolve) {
        return (response) => {
            handleAddSkillSucceeded(response.data);
            if (isMounted()) {
                setRequestStatus(RequestStatus.Succeeded);
            }
            resolve();
        };
    }
    function addSkill(areaSkill) {
        return new Promise((resolve, reject) => {
            if (!selectedGrade)
                reject();
            if (!selectedSegment)
                reject();
            if (infos.isInMainTable)
                reject();
            const status = allPlanningStatuses.find((status) => {
                return status.isBeingPlanned();
            });
            if (!status)
                reject();
            setRequestStatus(RequestStatus.Loading);
            addAreaSkillAndRelated({
                teacherId,
                areaSkill,
                statusId: status.id,
                gradeId: selectedGrade.id,
                segmentId: selectedSegment.id,
                disciplineId: newSkillDisciplineId,
                group: addPlanningToGroupWithNumber
            })
                .then(handleAddSuccess(resolve))
                .catch(handleAddFailure(reject))
                .finally(resetStatusAfterDelay);
        });
    }
    return { addSkill, requestStatus };
}
