import React, { useState } from 'react';
import { ConfirmationModal, Dropdown, toast } from '@campus/components';

import LaunchGrades from './LaunchGrades';
import DescriptionResetGrades from './DescriptionResetGrades';

import { useReportActions } from '../context/ReportActionsContext';
import ArrowSelectIcon from '@campus/components/assets/icons/arrow-select.svg';

import { ActionsButton, SelectContent } from '../styles';

const ReportActions: React.FC = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    resetGrades,
    zeroGrades,
    showGradeModal,
    hasSelectedStudents,
    setShowGradeModal
  } = useReportActions();

  const showLaunchGradeModal = () => {
    if (hasSelectedStudents) {
      setShowGradeModal(true);
    } else {
      toast.error('Nenhum aluno selecionado');
    }
  };

  const handleResetGrades = () => {
    if (hasSelectedStudents) {
      resetGrades();
      toast.success('Alunos resetados com sucesso!');
    } else {
      toast.error('Nenhum aluno selecionado');
    }
  };

  type Props = {
    onClick: (e: any) => void;
    value: string;
  };

  const CustomToggle = React.forwardRef<HTMLInputElement, Props>(
    ({ children, onClick }, ref) => (
      <ActionsButton
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </ActionsButton>
    )
  );

  const handleClickResetGrades = () => {
    if (hasSelectedStudents) {
      setShowDeleteModal(true);
    } else {
      toast.error('Nenhum aluno selecionado');
    }
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <SelectContent>
            Aplicar ação
            <img src={ArrowSelectIcon} alt="" />
          </SelectContent>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={zeroGrades}>
            Lançar zeros para quem não entregou a atividade até o momento
          </Dropdown.Item>
          <Dropdown.Item onClick={showLaunchGradeModal}>
            Lançar nota da atividade para os estudantes selecionados
          </Dropdown.Item>
          <Dropdown.Item onClick={handleClickResetGrades}>
            Resetar atividade dos estudantes selecionados
          </Dropdown.Item>
        </Dropdown.Menu>
        <ConfirmationModal
          title="Resetar Entrega do Aluno"
          description={<DescriptionResetGrades />}
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          onSubmit={handleResetGrades}
          labelCancel="Cancelar"
          labelConfirm="Apagar agora"
        />
      </Dropdown>
      {showGradeModal && <LaunchGrades />}
    </>
  );
};

export default ReportActions;
