import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  column-gap: 7px;
  align-items: center;
  padding-left: 8px;
`;
export const SelectAll = styled.div `
  display: flex;
  column-gap: 8px;
  align-items: center;

  input:not(:disabled) + label {
    cursor: pointer;
  }

  label {
    font-size: 14px;
    font-weight: 500;

    line-height: 120%;

    user-select: none;

    color: ${({ theme }) => theme.colors.gray600};
  }
`;
export const NumberOfSelectedItems = styled.div `
  min-height: 32px;

  padding: 7px 17px 7px 14px;

  display: flex;
  column-gap: 14px;
  align-items: center;

  border-radius: 40px;
  background: ${({ theme }) => theme.colors.gray100};

  > span {
    font-size: 14px;
    font-weight: 500;

    line-height: 120%;

    color: ${({ theme }) => theme.colors.gray600};
  }
`;
