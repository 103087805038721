import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useWeeklyPlanningPageMainTableContext } from '../../context';
import { useWeeklyPlanningPageContext } from '../../../../../../context';
import Group from './components/Group';
import { StyledWrapper } from './styles';
const TableBody = () => {
    const { tableInstance } = useWeeklyPlanningPageMainTableContext();
    const { content } = useWeeklyPlanningPageContext((store) => ({
        content: store.mainTable.filteredContent
    }));
    function renderGroups() {
        return content.map((group, index) => (React.createElement(Group, { index: index, group: group, key: `${group.number}-${group.plannings.length}` })));
    }
    return (React.createElement(Droppable, { droppableId: "main-table" }, (provided) => (React.createElement(StyledWrapper, { ref: provided.innerRef, ...provided.droppableProps, ...tableInstance.getTableBodyProps() },
        renderGroups(),
        provided.placeholder))));
};
export default TableBody;
