import { StyleSheet } from '@react-pdf/renderer';
import { fontSizes, paddings } from '../../../../../../../sizes';
import commonStyles from '../commonStyles';
const styles = StyleSheet.create({
    wrapper: {
        ...commonStyles,
        paddingTop: paddings.table.columns.learningObjectives.top,
        paddingBottom: paddings.table.columns.learningObjectives.bottom,
        paddingLeft: paddings.table.columns.learningObjectives.left,
        paddingRight: paddings.table.columns.learningObjectives.right,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 5
    },
    codeAndDescription: {
        lineHeight: '120%',
        fontSize: fontSizes.table.columnContent
    },
    description: {
        color: '#4D4D4D'
    }
});
export default styles;
