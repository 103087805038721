import styled from 'styled-components';
export const StyledWrapper = styled.div `
  display: flex;
  column-gap: 5px;
  align-items: center;
`;
export const StyledClassName = styled.span `
  font-size: 14px;
  font-weight: 500;
  font-style: normal;

  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  color: ${(props) => props.theme.colors.gray3};
`;
