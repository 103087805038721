import React from 'react';
import Header from './components/Header';
import Skill from './components/Skill';
import { withIntersectionObserver } from '../../../../../../../../../../hocs/withIntersectionObserver';
import { StyledContentWrapper } from './styles';
const TableItem = (props) => {
    const { discipline } = props.infos;
    function renderSkills() {
        return props.infos.skills.map((infos, index) => {
            const key = infos.skill.id + index;
            const isTheLastItem = index === props.infos.skills.length - 1;
            if (!props.handleLastItemReached || !isTheLastItem) {
                return (React.createElement(Skill, { key: key, infos: infos, discipline: discipline, highlightedCodeText: props.highlightedCodeText }));
            }
            const Wrapped = withIntersectionObserver(React.createElement(Skill, { infos: infos, discipline: discipline, highlightedCodeText: props.highlightedCodeText }));
            return (React.createElement(Wrapped, { key: key, stopObservingAfterReach: true, whenReached: props.handleLastItemReached }));
        });
    }
    return (React.createElement("div", null,
        React.createElement(Header, { infos: props.infos }),
        React.createElement(StyledContentWrapper, null, renderSkills())));
};
export default TableItem;
