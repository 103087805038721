import React, { useCallback } from 'react';
import TextArea from '../common/components/TextArea';
import { updateTeacherNotes } from './updates';
import { getPlaceholder } from '../common/components/TextArea/placeholders';
import { StyledWrapper } from './styles';
const TeacherNotes = (props) => {
    const isPlanningDeleted = props.planning?.status?.isDeleted() ?? false;
    const readOnly = props.doNotAllowUpdates || isPlanningDeleted;
    const placeholder = getPlaceholder({
        readOnly,
        isPlanningDeleted
    });
    const updateContent = useCallback(async (content) => {
        return updateTeacherNotes(props.planning, content).then(() => {
            props.planning.teacherNotes = content;
            props.handleTeacherNotesUpdated(props.planning);
        });
    }, [props.planning, props.handleTeacherNotesUpdated]);
    const onChange = useCallback((content) => {
        props.planning.teacherNotes = content;
        props.handleTeacherNotesUpdated(props.planning);
    }, [props.planning, props.handleTeacherNotesUpdated]);
    return (React.createElement(StyledWrapper, null,
        React.createElement(TextArea, { key: props.planning.id, readOnly: readOnly, placeholder: placeholder, initialValue: props.planning.teacherNotes, onChange: onChange, updateContent: updateContent })));
};
export default TeacherNotes;
