import styled from 'styled-components';
import { zIndices } from '../../../../../../../stylesRelated';
export const StyledWrapper = styled.div `
  z-index: ${zIndices.rectangles};

  width: 11px;
  height: ${(props) => props.$height}px;

  position: absolute;
  top: ${(props) => props.$top}px;
  left: ${(props) => props.$left}px;

  border-radius: 2px;

  border: 1px ${(props) => (props.$useDashedBorder ? 'dashed' : 'solid')}
    ${(props) => (props.$borderColor ? props.$borderColor : 'white')};

  background-color: ${(props) => props.$backgroundColor ? props.$backgroundColor : 'white'};
`;
