import React from 'react';
import Description from '../common/objectsAndObjectives/components/Description';
import { StyledWrapper } from '../KnowledgeObjects/styles';
const ReadOnlyKnowledgeObjects = (props) => {
    function renderObjects() {
        const sorted = props.objects.sort((a, b) => {
            return a.description?.localeCompare(b.description);
        });
        return sorted?.map((object) => (React.createElement(Description, { disabled: true, key: object.id, content: object.description })));
    }
    return React.createElement(StyledWrapper, null, renderObjects());
};
export default ReadOnlyKnowledgeObjects;
