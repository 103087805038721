import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import Teacher from './Teacher';
export default class PedagogicalPlanningDiary {
    lastModificationDate;
    lastModificationDateFormated;
    teachers;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new PedagogicalPlanningDiary();
        if (json?.LastModificationDate) {
            instance.lastModificationDate = parseISO(json?.LastModificationDate);
            instance.lastModificationDateFormated = format(instance.lastModificationDate, "dd/MM/yyyy ', às' HH:mm");
        }
        instance.teachers = json.Teachers?.map((item) => {
            return Teacher.fromJson(item);
        });
        return instance;
    }
}
