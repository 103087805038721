import React, { useMemo } from 'react';
import { Document, Page } from '@react-pdf/renderer';
import Header from '../common/components/Header';
import Body from '../common/components/Body';
import Footer from '../common/components/Footer';
import { paddings } from '../common/sizes';
import { createHeaders, createRow } from './table';
import { isThereMoreThanOneDisciplineIn, setup } from '../common/helpers';
import styles from '../common/styles';
setup();
const StudyUnitReport = (props) => {
    const skills = useMemo(() => {
        const skills = props.shortVersion
            ? props.skills.filter((s) => s.evaluative)
            : props.skills;
        return skills.filter((areaSkill) => {
            const stages = areaSkill.unitStages?.length > 0
                ? areaSkill.unitStages
                : areaSkill.programStages;
            return stages?.some(({ stage }) => {
                return stage?.fullName === props.stage;
            });
        });
    }, [props.shortVersion, props.skills, props.stage]);
    const displayDisciplineColumn = useMemo(() => {
        return isThereMoreThanOneDisciplineIn(skills);
    }, [skills]);
    const tableHeaders = createHeaders({ displayDisciplineColumn });
    const tableRows = useMemo(() => {
        return skills.map((skill) => createRow({
            areaSkill: skill,
            displayDisciplineColumn,
            shortVersion: props.shortVersion
        }));
    }, [displayDisciplineColumn, props.shortVersion, skills]);
    return (React.createElement(Document, null,
        React.createElement(Page, { size: "A4", orientation: "landscape", style: {
                ...styles.page,
                paddingBottom: paddings.page.bottom.withEvaluationSection
            } },
            React.createElement(Header, { period: props.stage, title: 'Unidade de Estudo', information: props.information }),
            React.createElement(Body, { tableHeaders: tableHeaders, tableRows: tableRows }),
            React.createElement(Footer, { skills: skills, showEvaluationSection: true }))));
};
export default StudyUnitReport;
