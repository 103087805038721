import styled from 'styled-components';
export const StyledWrapper = styled.div `
  padding: 10px 0;

  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
`;
export const StyledLabel = styled.span `
  font-size: 14px;
  font-weight: 600;

  color: ${(props) => props.theme.colors.gray3};
`;
