import { isDateInvalid } from './dates';
import { isNullOrUndefined } from './nullability';
export function swapItemsInPlace(items, firstIndex, secondIndex) {
    const auxiliar = items[firstIndex];
    items[firstIndex] = items[secondIndex];
    items[secondIndex] = auxiliar;
}
export function reorderItemsInPlace(items, startIndex, endIndex) {
    const [removed] = items.splice(startIndex, 1);
    items.splice(endIndex, 0, removed);
}
export function isIndexValid(index, items) {
    if (index === null)
        return false;
    if (index === undefined)
        return false;
    return index >= 0 && index < items.length;
}
export function addItem(item, items, atIndex) {
    if (items.length === 0)
        return [item];
    if (isNullOrUndefined(atIndex) || !isIndexValid(atIndex, items)) {
        return [...items, item];
    }
    const itemsBeforeIndex = items.slice(0, atIndex);
    const itemsAfterIndex = items.slice(atIndex);
    return [...itemsBeforeIndex, item, ...itemsAfterIndex];
}
function isInfinity(num) {
    const isNegativeInfinity = num === -Infinity;
    const isPositiveInfinity = num === Infinity;
    return isNegativeInfinity || isPositiveInfinity;
}
export function getMinimumValueFrom(items) {
    const minimum = Math.min(...items);
    return isInfinity(minimum) ? null : minimum;
}
export function getMaximumValueFrom(items) {
    const maximum = Math.max(...items);
    return isInfinity(maximum) ? null : maximum;
}
export function getMinimumDateFrom(items) {
    const timestamp = Math.min.apply(null, items);
    return isDateInvalid(timestamp) ? null : new Date(timestamp);
}
export function getMaximumDateFrom(array) {
    const timestamp = Math.max.apply(null, array);
    return isDateInvalid(timestamp) ? null : new Date(timestamp);
}
export function getNewItemIndexIfItMoves(items, currentItemIndex, direction) {
    return direction === 'down'
        ? (currentItemIndex + 1) % items.length
        : (currentItemIndex - 1 + items.length) % items.length;
}
export function getDistinct(items, keySelector) {
    if (!items || items.length === 0)
        return [];
    const map = new Map();
    for (const item of items) {
        const key = keySelector(item);
        map.set(key, item);
    }
    return [...map.values()];
}
export function areArraysEqual(array1, array2) {
    if (array1.length !== array2.length) {
        return false;
    }
    const sortedArray1 = array1.slice().sort();
    const sortedArray2 = array2.slice().sort();
    return sortedArray1.every((value, index) => value === sortedArray2[index]);
}
