import { useCallback, useEffect } from 'react';
import { getAllGrades } from '../../../../services/grade';
import { useFetch } from './useFetch';
export function useFetchGrades() {
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess } = useFetch({
        initialData: []
    });
    const fetchGrades = useCallback((abortSignal) => {
        fetchStarted();
        return getAllGrades({ abortSignal })
            .then((response) => handleSuccess(response.data))
            .catch(handleFailure);
    }, [fetchStarted, handleFailure, handleSuccess]);
    useEffect(() => {
        const abortController = new AbortController();
        fetchGrades(abortController.signal);
        return () => abortController.abort();
    }, [fetchGrades]);
    return { data, requestStatus };
}
