import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const StyledWrapper = styled.div `
  height: 24px;

  padding: 0 7px;

  display: flex;
  column-gap: 5px;
  align-items: center;

  background: white;

  border-width: 1px;
  border-radius: 4px;
  border-style: solid;
  border-color: ${(props) => props.$borderColor ? props.$borderColor : props.theme.colors.gray200};
`;
export const StyledCalendarIconWrapper = styled.div `
  & div {
    display: block;
  }
`;
export const StyledCalendarIcon = styled(CampusIcon).attrs({
    name: 'smallCalendar'
}) `
  display: block;

  width: 14px;
  height: 14px;

  & path {
    stroke: ${(props) => props.$color ? props.$color : props.theme.colors.gray200};
  }
`;
export const StyledText = styled.span `
  font-size: 12px;
  font-weight: 600;

  color: ${(props) => props.$color ? props.$color : props.theme.colors.gray700};
`;
