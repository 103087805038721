import React from 'react';
import { StyledCode, StyledDescription, StyledWrapper } from './styles';
const CodeAndDescription = (props) => {
    const { areaSkill } = props;
    return (React.createElement(StyledWrapper, { "$isEvaluative": areaSkill?.evaluative },
        React.createElement(StyledCode, { "$color": areaSkill?.discipline?.color },
            "(",
            areaSkill?.code,
            ")",
            ' '),
        React.createElement(StyledDescription, { "$addLineThrough": props.addLineThroughDescription }, areaSkill?.description)));
};
export default CodeAndDescription;
