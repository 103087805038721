export function getMainClassroomAndSequenceFrom(classrooms) {
    const toSort = classrooms ?? [];
    const sorted = toSort.sort((a, b) => {
        const aClassName = a?.classroom?.class?.name;
        const bClassName = b?.classroom?.class?.name;
        return aClassName?.localeCompare(bClassName, undefined, {
            sensitivity: 'accent'
        });
    });
    return sorted.at(0) ?? null;
}
