function splitHsl(css) {
    const percentage = (s) => {
        const end = s.length - 2;
        const withoutSymbol = s.trim().slice(0, end);
        return Number(withoutSymbol) / 100;
    };
    const pieces = css.split(',');
    const h = Number(pieces[0].split('(')[1].trim());
    const s = Number(percentage(pieces[1]));
    const l = Number(percentage(pieces[2].split(')')[0]));
    return [h, s, l];
}
// adapted from https://www.w3.org/TR/css-color-3/#hsl-color
export function hslToRgb(css) {
    let [h, s, l] = splitHsl(css);
    h = h % 360;
    if (h < 0)
        h += 360;
    function f(n) {
        let k = (n + h / 30) % 12;
        let a = s * Math.min(l, 1 - l);
        return l - a * Math.max(-1, Math.min(k - 3, 9 - k, 1));
    }
    return [
        Math.round(255 * f(0)),
        Math.round(255 * f(8)),
        Math.round(255 * f(4))
    ];
}
