import { Table, TableRow, WidthType } from 'docx';
import TitleAndSubtitle from './components/TitleAndSubtitle';
import Information from './components/Information';
import Logo from './components/Logo';
import { widths } from '../../../common/sizesDocx';
export default async function Header(informationData, stage) {
    const logo = await Logo();
    return new Table({
        width: { size: widths.holeDocument, type: WidthType.PERCENTAGE },
        columnWidths: [
            widths.header.titleAndSubtitle,
            widths.header.informations,
            widths.header.logo
        ],
        rows: [
            new TableRow({
                children: [TitleAndSubtitle(informationData), Information(informationData, stage), logo]
            })
        ]
    });
}
