import { getMainClassroomAndSequenceFrom } from '../../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/common/helpers/sequenceRelated';
import { copyDateWithoutTimePortion } from '../../../../../../../../../../../../../common/utils/dates';
function areDatesEqual(first, second) {
    const yearsAreEqual = first.getFullYear() === second.getFullYear();
    const monthsAreEqual = first.getMonth() === second.getMonth();
    const daysAreEqual = first.getDate() === second.getDate();
    return yearsAreEqual && monthsAreEqual && daysAreEqual;
}
export function getActivityRelatedRectanglesInfos(activity, colors) {
    const classrooms = activity.classrooms;
    const mainClassroom = getMainClassroomAndSequenceFrom(classrooms);
    const expectedDate = mainClassroom?.classroom?.date;
    const executedDate = mainClassroom?.executedClassroom?.date;
    const circleColor = activity?.evaluationType?.color;
    const infos = [
        {
            startDate: copyDateWithoutTimePortion(expectedDate),
            borderShouldBeDashed: activity.isDeleted && !executedDate,
            circleColor,
            borderColor: colors.blue,
            backgroundColor: !executedDate || !areDatesEqual(expectedDate, executedDate)
                ? colors.white
                : colors.blue
        }
    ];
    if (executedDate && !areDatesEqual(expectedDate, executedDate)) {
        infos.push({
            circleColor,
            borderColor: colors.orange,
            backgroundColor: colors.orange,
            borderShouldBeDashed: false,
            startDate: copyDateWithoutTimePortion(executedDate)
        });
    }
    return infos;
}
