import { useCallback, useEffect } from 'react';
import { useFetch } from '../../../../../../../../../hooks/dataFetching/useFetch';
import { getDisciplinesAndGrades } from '../../../../../../../../../../../services/disciplineAndGrade';
export function useFetchDisciplines() {
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess } = useFetch({
        initialData: []
    });
    const fetchData = useCallback((abortSignal) => {
        return new Promise((resolve, reject) => {
            fetchStarted();
            getDisciplinesAndGrades({ abortSignal })
                .then((response) => {
                handleSuccess(response.data);
                resolve();
            })
                .catch((response) => {
                handleFailure(response);
                reject(response);
            });
        });
    }, [fetchStarted, handleFailure, handleSuccess]);
    useEffect(() => {
        const abortController = new AbortController();
        fetchData(abortController.signal);
        return () => abortController.abort();
    }, [fetchData]);
    return { data, requestStatus };
}
