import React, { useState } from 'react';
import { useIsMounted } from '../../../../../hooks';
import { StyledLabel, StyledWrapper } from './styles';
const loadingActionLabel = '...';
const ListItem = (props) => {
    const [label, setLabel] = useState(props.label);
    const [isDisabled, setIsDisabled] = useState(props.disabled ?? false);
    const { isMounted } = useIsMounted();
    function afterHandleClick() {
        if (isMounted()) {
            setLabel(props.label);
            setIsDisabled(false);
        }
    }
    function onClick() {
        if (isDisabled)
            return;
        if (label === loadingActionLabel)
            return;
        setIsDisabled(true);
        setLabel(loadingActionLabel);
        props.onClick().finally(afterHandleClick);
    }
    return (React.createElement(StyledWrapper, { "$disabled": isDisabled, onClick: onClick },
        React.createElement(StyledLabel, { "$disabled": isDisabled }, label)));
};
export default ListItem;
