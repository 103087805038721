import React from 'react';
import Checkbox from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/common/objectsAndObjectives/components/Checkbox';
import Description from '../../../../../../../../../../../../common/components/PageRelated/Body/components/Table/Columns/common/objectsAndObjectives/components/Description';
import { StyledWrapper } from './styles';
const Objective = (props) => {
    const learningObjective = props.objective?.learningObjective;
    return (React.createElement(StyledWrapper, null,
        React.createElement(Checkbox, { disabled: true, checked: props.objective.wasProgrammed }),
        React.createElement(Description, { disabled: true, content: learningObjective?.description, emphasize: learningObjective?.isEvaluative, code: {
                text: learningObjective.code,
                color: props.areaSkill?.discipline?.color
            } }, learningObjective.description)));
};
export default Objective;
