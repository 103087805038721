import { registerGilroyFontFamily, removeHiphenation } from './fonts';
export function setup() {
    removeHiphenation();
    registerGilroyFontFamily();
}
export function isThereMoreThanOneDisciplineIn(skills) {
    const distinctDisciplines = new Set();
    for (const skill of skills) {
        const disciplineId = skill.discipline?.id;
        distinctDisciplines.add(disciplineId);
        if (distinctDisciplines.size > 1)
            return true;
    }
    return false;
}
