import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import EvaluationSection from './components/EvaluationSection/EvaluationSection';
import { toBrazilianDateString } from '../../../../../utils/dates';
import { createFormatedTimeFrom, getUpdateDateFrom } from './dates';
import { margins } from '../../sizes';
import styles from './styles';
const Footer = (props) => {
    const today = new Date();
    const updateDate = getUpdateDateFrom(props.skills);
    const generationTime = createFormatedTimeFrom(today);
    const generationDate = toBrazilianDateString(today);
    const lastUpdateTime = createFormatedTimeFrom(updateDate);
    const lastUpdateDate = toBrazilianDateString(updateDate);
    const marginTop = props.showEvaluationSection
        ? margins.footer.top.withEvaluationSection
        : margins.footer.top.withoutEvaluationSection;
    const marginBottom = props.showEvaluationSection
        ? margins.footer.bottom.withEvaluationSection
        : margins.footer.bottom.withoutEvaluationSection;
    return (React.createElement(View, { fixed: true, style: { ...styles.wrapper, marginTop, marginBottom } },
        props.showEvaluationSection ? React.createElement(EvaluationSection, null) : null,
        React.createElement(View, { style: styles.pageAndDatesWrapper },
            React.createElement(Text, { style: styles.text, render: (props) => `Página ${props.pageNumber} de ${props.totalPages}` }),
            React.createElement(Text, { style: styles.text },
                !!updateDate
                    ? `Última atualização do documento: ${lastUpdateDate} às ${lastUpdateTime} - `
                    : '',
                "Documento gerado no dia ",
                generationDate,
                " \u00E0s ",
                generationTime))));
};
export default Footer;
