import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Row,
  Modal,
  Button,
  FormInput,
  FormSelector
} from '@campus/components';

import {
  LaunchGradeForm,
  useReportActions
} from '../context/ReportActionsContext';

const LaunchGrades: React.FC = () => {
  const { allowedQuestions, loading, setShowGradeModal, launchGrades } =
    useReportActions();

  const defaultOption = allowedQuestions?.[0];

  const methods = useForm<LaunchGradeForm>({
    defaultValues: { question: defaultOption }
  });
  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(launchGrades);

  return (
    <Modal centered show onHide={() => setShowGradeModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Aplicar nota da questão</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          <Row className="gy-2 gx-2">
            <FormSelector
              name="question"
              label="Questão"
              options={allowedQuestions}
              required
              isClearable={false}
              grid={{ xs: 10 }}
            />
            <FormInput
              name="score"
              label="Nota"
              placeholder="0,0"
              required
              type="number"
              grid={{ xs: 2 }}
            />
          </Row>
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" loading={loading} onClick={onSubmit}>
          Aplicar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LaunchGrades;
