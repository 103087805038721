import { QuestionType } from '@campus/commons';
import Answer from './Answer';

class AnswerQuestion {
  title?: string;

  contentItemId?: string;

  questionType?: QuestionType;

  answers?: Answer[];

  weight?: number;

  answerText?: string;

  static fromJson(data: any): AnswerQuestion {
    const answerQuestion = new AnswerQuestion();

    answerQuestion.title = data.Title;
    answerQuestion.contentItemId = data.ContentItemId;
    answerQuestion.questionType = data.QuestionType;
    answerQuestion.weight = data.Weight;
    answerQuestion.answerText = data.AnswerText;

    const answers = data.Answers;
    if (answers instanceof Array) {
      answerQuestion.answers = answers.map((a) => Answer.fromJson(a));
    }

    return answerQuestion;
  }
}

export default AnswerQuestion;
