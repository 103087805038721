import { Api } from '@campus/auth';
import { PaginationModel, Response } from '@campus/commons';
import Grade from '../models/Grade';
import { handleApiCallError } from './common';
const endpoints = {
    getAll: '/grade'
};
export async function getAllGrades(params) {
    try {
        const axiosInstance = Api.INSTANCE.getApi();
        const response = await axiosInstance.get(endpoints.getAll, {
            signal: params?.abortSignal,
            params: { MaxResultCount: 1000 }
        });
        const paginated = PaginationModel.fromJson(Grade, response.data);
        return Response.fromData(paginated.items, response.status);
    }
    catch (error) {
        return handleApiCallError(error);
    }
}
