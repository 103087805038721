import { updatePlanning } from '../../../../../../../../../services/pedagogicalPlanning';
export function updateSupervisorNotes(planning, notes) {
    return updatePlanning({
        supervisorNotes: notes,
        id: planning.id,
        order: planning.order,
        stageId: planning.stage?.id,
        statusId: planning.status?.id,
        group: planning.associatedGroup,
        observedLags: planning.observedLags,
        analystNotes: planning.analystNotes,
        teacherNotes: planning.teacherNotes
    });
}
