import React from 'react';
import { useAddOrEditActivityPopupContext } from '../../../common/addOrEditActivity/context';
import { RequestStatus } from '../../../../../types/dataFetching';
import { useAddSequenceAndClassrooms } from './hooks/useAddSequenceAndClassrooms';
import { StyledBlueButton } from '../../../common/styles';
const statusesAndButtonContent = {
    [RequestStatus.NotStarted]: 'Adicionar Atividade',
    [RequestStatus.Loading]: 'Adicionando...',
    [RequestStatus.Succeeded]: 'Adicionado!',
    [RequestStatus.Failed]: 'Falha ao adicionar'
};
const AddActivityButton = (props) => {
    const { description, classesAndInfos, anImageIsBeingUploaded } = useAddOrEditActivityPopupContext((store) => ({
        description: store.sequenceDescription,
        classesAndInfos: store.classesAndInfos.data,
        anImageIsBeingUploaded: store.anImageIsBeingUploaded
    }));
    const { add, requestStatus } = useAddSequenceAndClassrooms({
        handleActivityAdded: props.handleAddSuccess
    });
    function isThereAClassroomSelected() {
        if (!classesAndInfos)
            return false;
        for (const infos of classesAndInfos.values()) {
            if (infos.isChecked)
                return true;
        }
        return false;
    }
    function onClick() {
        add({
            planningId: props.planning?.id,
            areClassroomsUnforeseen: props.newActivityIsUnforeseen
        });
    }
    const disabled = !description ||
        anImageIsBeingUploaded ||
        !isThereAClassroomSelected() ||
        requestStatus !== RequestStatus.NotStarted;
    return (React.createElement(StyledBlueButton, { onClick: disabled ? null : onClick, disabled: disabled }, statusesAndButtonContent[requestStatus]));
};
export default AddActivityButton;
