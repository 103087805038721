import React from 'react';

import { useContextManager } from '@campus/commons';
import { toast } from '@campus/components';
import { useContent } from '@campus/discipline';

import StudentAnswerReport from 'models/StudentAnswerReport';
import { changeTeacherAnswerScore } from 'services/answers';

import { CenterElement, TableScoreInput } from '../styles';
import { useActivityReport } from '../context/ActivityReportContext';

type TeacherScoreProps = {
  data: StudentAnswerReport;
  questionWeight: number;
  currentQuestionIndex?: number;
  currentStudentIndex?: number;
  inputRef?: any;
  onEnter?: (
    currentStudentIndex: number,
    shiftKey: boolean,
    event: React.KeyboardEvent<HTMLInputElement>,
    currentQuestionIndex?: number
  ) => void;
  onTab?: (
    currentStudentIndex: number,
    shiftKey: boolean,
    event: React.KeyboardEvent<HTMLInputElement>,
    closedQuestionsPreviousCount?: number,
    currentQuestionIndex?: number
  ) => void;
};

const TeacherScore: React.FC<TeacherScoreProps> = ({
  data,
  questionWeight,
  currentQuestionIndex,
  currentStudentIndex,
  inputRef,
  onEnter,
  onTab
}) => {
  const { contentId } = useContent();

  const teacherScore = data?.teacherScore;

  const { updateStudents } = useActivityReport();
  const { isCurrentPeriod } = useContextManager();

  const submitScore = async (score: number) => {
    if (teacherScore !== score && isCurrentPeriod) {
      const response = await changeTeacherAnswerScore(contentId, {
        studentId: [data.student.id],
        score
      });

      if (response.data) {
        updateStudents(response.data);
      } else if (response.error) {
        toast.error(response.error.message);
      }
    }
  };

  return (
    <CenterElement>
      <TableScoreInput
        name="teacher-score"
        placeholder="-"
        value={teacherScore}
        onBlur={submitScore}
        onEnter={onEnter}
        onTab={onTab}
        questionWeight={questionWeight}
        currentStudentIndex={currentStudentIndex}
        currentQuestionIndex={currentQuestionIndex}
        inputRef={inputRef}
        maxScore={questionWeight}
        disabled={!isCurrentPeriod}
      />
    </CenterElement>
  );
};

export default TeacherScore;
