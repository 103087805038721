export function waitForElementToRender(elementId) {
    return new Promise((resolve) => {
        const element = document.getElementById(elementId);
        if (element)
            resolve(element);
        const observer = new MutationObserver(() => {
            const element = document.getElementById(elementId);
            if (element) {
                resolve(element);
                observer.disconnect();
            }
        });
        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}
