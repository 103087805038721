import styled from 'styled-components';

import { CampusIcon, Select, ThemeType } from '@campus/components';

type Styles = Parameters<typeof Select>[0]['styles'];

export const DropdownIndicator = styled(CampusIcon).attrs({
  name: 'selectArrowDown'
})`
  display: block;
  color: ${({ theme }) => theme.colors.gray2};
`;

function createTextStyles(theme: ThemeType): ReturnType<Styles['option']> {
  return {
    fontFamily: 'Gilroy',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '110%',
    color: theme.colors.gray2
  };
}

export function selectStyles(theme: ThemeType): Styles {
  const textStyles = createTextStyles(theme);

  return {
    menu: (base) => ({
      ...base,
      margin: '3px 0 0 0',
      paddingTop: 4,
      paddingBottom: 4,
      boxShadow: 'none',
      border: `1px solid ${theme.colors.gray4}`
    }),
    menuList: (baseStyles) => ({
      ...baseStyles,
      padding: 0
    }),
    control: () => ({
      height: '40px',
      padding: '8px 12px 8px 16px',
      cursor: 'pointer',
      display: 'flex',
      columnGap: '12px',
      alignItems: 'center',
      borderRadius: '6px',
      border: `1px solid ${theme.colors.gray4}`
    }),
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      padding: 0
    }),
    option: (base, state) => ({
      ...base,
      ...textStyles,
      '&:hover': {
        background: theme.colors.gray50
      },
      backgroundColor: state.isSelected ? '#e9ecef' : 'white',
      padding: '8px 16px',
      cursor: 'pointer'
    }),
    placeholder: (base) => ({
      ...base,
      ...textStyles
    }),
    loadingMessage: (base) => ({
      ...base,
      ...textStyles
    }),
    noOptionsMessage: (base) => ({
      ...base,
      ...textStyles
    }),
    input: (base) => ({
      ...base,
      caretColor: 'transparent'
    }),
    singleValue: (base) => ({
      ...base,
      ...textStyles,
      fontWeight: 600,
      margin: 0
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    menuPortal: () => ({
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0
    })
  };
}
