import React from 'react';
import { getDates } from './helpers';
import { formatDate } from '../common/helpers/dates';
import { StyledInfoContent, StyledInfoLabel, StyledInfoWrapper } from '../common/styles';
const Planned = (props) => {
    const dates = getDates(props.sequences);
    const start = formatDate(dates.startDate);
    const end = formatDate(dates.endDate);
    return (React.createElement(StyledInfoWrapper, null,
        React.createElement(StyledInfoLabel, null, "Per\u00EDodo Planejado: "),
        React.createElement(StyledInfoContent, null,
            start,
            " at\u00E9 ",
            end)));
};
export default Planned;
