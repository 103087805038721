import React from 'react';
import { LaunchReportContextProvider } from './context';
import { PageHeader, Filters, TableAndRelated } from './components';
import { Page, PageContent, FiltersAndTableSeparator } from './styles';
const LaunchReport = () => {
    return (React.createElement(LaunchReportContextProvider, null,
        React.createElement(Page, null,
            React.createElement(PageHeader, null),
            React.createElement(PageContent, { id: "launch-report-page-content" },
                React.createElement(Filters, null),
                React.createElement(FiltersAndTableSeparator, null),
                React.createElement(TableAndRelated, null)))));
};
export default LaunchReport;
