import React, { useCallback, useEffect, useRef } from 'react';
import { useFollowUpMainTableContext } from '../../../../../../context';
import Cell from '../../../../../../../../../common/components/PageRelated/Body/components/Table/components/TableBodyRelated/PlanningGroup/components/Rows/components/Cell';
import { columns } from '../../columns';
import { useBorders } from './hooks/useBorders';
import { StyledWrapper } from './styles';
const Row = (props) => {
    const { rowInfos, tableInstance } = props;
    const wrapperRef = useRef(null);
    const borders = useBorders(props);
    const rowProps = rowInfos.getRowProps();
    rowProps.style.display = 'table-row';
    const setPlanningInfos = useFollowUpMainTableContext((store) => store.setPlanningInfos);
    const getBoundingClientRect = useCallback(() => {
        return wrapperRef.current.getBoundingClientRect();
    }, []);
    useEffect(() => {
        if (!props.rowInfos?.original)
            return;
        const planningId = props.rowInfos.original.id;
        setPlanningInfos(planningId, { getBoundingClientRect });
    }, [props.rowInfos, setPlanningInfos, getBoundingClientRect]);
    function renderCells() {
        return rowInfos.cells.map((cell) => (React.createElement(Cell, { key: cell.column.id, cellInfos: cell, allColumns: columns, isInTheFirstRowOfTheGroup: rowInfos.index === 0, numberOfPlanningsInGroup: tableInstance.rows.length })));
    }
    return (React.createElement(StyledWrapper, { ...rowProps, ref: wrapperRef, "$currentRowBorder": {
            isRed: borders.currentRowBorderShouldBeRed,
            isDashed: borders.currentRowBorderShouldBeDashed
        }, "$lastRowBorder": {
            isRed: borders.lastRowBorderShouldBeRed,
            isDashed: borders.lastRowBorderShouldBeDashed
        } }, renderCells()));
};
export default Row;
