import React from 'react';
import Separator from '../../../../../../../Separator';
import { createBodyColumnWeekId } from '../../../../../../../../elements';
import { StyledSpace, StyledWrapper } from './styles';
const Week = (props) => {
    return (React.createElement(StyledWrapper, null,
        React.createElement(StyledSpace, { id: createBodyColumnWeekId(props.week) }),
        React.createElement(Separator, { month: props.month, correspondingWeek: props.week })));
};
export default Week;
