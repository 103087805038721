import styled from 'styled-components';
export const StyledTitle = styled.h6 `
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  margin: 0;

  color: ${(props) => props.theme.colors.gray300};
`;
