import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import styles from './styles';
const Skill = (props) => {
    return (React.createElement(React.Fragment, null, props.areasSkills.map((areaSkill) => (React.createElement(View, { style: styles.wrapper, key: areaSkill.code },
        React.createElement(Text, { widows: 1, style: {
                ...styles.codeAndDescription,
                fontWeight: areaSkill.evaluative ? 700 : 600
            } },
            React.createElement(Text, { style: {
                    color: areaSkill?.discipline?.color
                } },
                "(",
                areaSkill.code?.trim(),
                ")"),
            React.createElement(Text, { style: styles.description },
                ' ',
                areaSkill.description?.trim())))))));
};
export default Skill;
