import styled from 'styled-components';
import PopupBox from '../../../../../../../../../Popups/PopupBox';
export const StyledWrapper = styled(PopupBox) `
  min-width: 430px;

  position: absolute;
  top: calc(100% + 5px);
  right: 0;

  background-color: white;

  padding: 17px 20px 15px 20px;
`;
export const StyledTitleWrapper = styled.div `
  padding-bottom: 9px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
`;
export const StyledColumnsWrapper = styled.div `
  margin-top: 9px;

  display: flex;
`;
export const StyledMessageWrapper = styled.div `
  margin-top: 9px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledMessage = styled.i `
  font-size: 14px;
  font-weight: 500;
`;
