import React, { useMemo } from 'react';
import CalendarContext from './context';
import { createCalendarStore } from '../store';
const Provider = (props) => {
    const store = useMemo(() => {
        return createCalendarStore({
            groups: props.groups,
            months: props.months
        });
    }, [props.groups, props.months]);
    return (React.createElement(CalendarContext.Provider, { value: store }, props.children));
};
export default Provider;
