import React from 'react';
import DisciplineComponent from './components/Discipline';
import { StyledNoContentMessage, StyledNoContentMessageWrapper, StyledWrapper } from './styles';
const Disciplines = (props) => {
    function renderItems() {
        return (props.disciplines
            ?.sort((a, b) => a?.name?.localeCompare(b?.name))
            ?.map((discipline) => (React.createElement(DisciplineComponent, { ...props, key: discipline.id, discipline: discipline }))) ?? []);
    }
    function renderNoContentMessage() {
        return (React.createElement(StyledNoContentMessageWrapper, null,
            React.createElement(StyledNoContentMessage, null, "Nada a ser exibido.")));
    }
    function render() {
        if (!props.selectedGrade)
            return null;
        if (props.disciplines.length === 0) {
            return renderNoContentMessage();
        }
        return React.createElement(StyledWrapper, null, renderItems());
    }
    return render();
};
export default Disciplines;
