import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import ArrowSelectIcon from '@campus/components/assets/icons/arrow-select.svg';
import { Dropdown } from '@campus/components';
import { dowloadFile } from '@campus/commons';
import { useActivityReport } from '../context/ActivityReportContext';
import { ActionsButton, SelectContent, SpinnerStyled } from '../styles';

const DowloadAction: React.FC = () => {
  const { classId } = useActivityReport();
  const { contentId } = useParams<{ contentId: string }>();
  const [loading, setLoading] = useState(false);

  const dowloadGrades = async () => {
    setLoading(true);
    await dowloadFile('notas', `report/content/${contentId}/score`, {
      classId
    });
    setLoading(false);
  };

  const dowloadAnswers = async () => {
    setLoading(true);
    await dowloadFile('respostas', `report/content/${contentId}/answer`, {
      classId
    });
    setLoading(false);
  };

  type Props = {
    onClick: (e: any) => void;
    value: string;
  };

  const CustomToggle = React.forwardRef<HTMLInputElement, Props>(
    ({ children, onClick }, ref) => (
      <ActionsButton
        $width={loading ? 168 : 150}
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        disabled={loading}
      >
        {children}
      </ActionsButton>
    )
  );

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={CustomToggle}
        id="dropdown-custom-downloads"
        disabled={loading}
      >
        <SelectContent>
          {loading && (
            <SpinnerStyled animation="border" variant="primary" size="sm" />
          )}{' '}
          Baixar Planilhas
          <img src={ArrowSelectIcon} alt="" />
        </SelectContent>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={dowloadGrades}>Baixar notas</Dropdown.Item>
        <Dropdown.Item onClick={dowloadAnswers}>Baixar respostas</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DowloadAction;
