import StudentAnswerReport from './StudentAnswerReport';

export interface FrequentAnswer {
  comment: string;
  score: number;
  count: number;
}

class StudentAnswerList {
  private students: StudentAnswerReport[] = [];

  constructor(students: StudentAnswerReport[]) {
    this.students = students;
  }

  getFrequentAnswers(): FrequentAnswer[] {
    const answers: FrequentAnswer[] = [];

    this.students.forEach((student) => {
      student.answers.forEach((answer) => {
        if (answer.teacherCommnent === null || answer.teacherScore === null) {
          return;
        }

        const answerIndex = answers.findIndex(
          (a) =>
            a.comment === answer.teacherCommnent &&
            a.score === answer.teacherScore
        );

        if (answerIndex > -1) {
          answers[answerIndex].count += 1;
        } else {
          answers.push({
            comment: answer.teacherCommnent,
            score: answer.score,
            count: 1
          });
        }
      });
    });

    return answers.sort((a, b) => b.count - a.count);
  }
}

export default StudentAnswerList;
