import React from 'react';
import { useMainTableFocusContext } from '../../../../../../../../contexts/mainTableFocus';
import { StyledButton, StyledExpandIcon } from './styles';
const ToggleFullScreenButton = (props) => {
    const { activate, deactivate, isActive } = useMainTableFocusContext();
    function onClick() {
        if (isActive) {
            deactivate();
        }
        else {
            activate();
        }
    }
    return (React.createElement(StyledButton, { onClick: onClick, className: props.className },
        React.createElement(StyledExpandIcon, null),
        isActive ? 'Reduzir' : 'Ampliar'));
};
export default ToggleFullScreenButton;
