import { createStore } from 'zustand';
import { createClassesAndInfos } from './helpers';
export function createFinishActivityPopupStore(params) {
    return createStore()((set) => ({
        sequence: params.sequence,
        planning: params.planning,
        abortSignal: params.abortSignal,
        classroomGroups: params.classroomGroups,
        classesAndInfos: createClassesAndInfos(params),
        updateClassesAndInfos(className, updates) {
            set((store) => {
                const oldMap = store.classesAndInfos;
                const newMap = new Map(oldMap);
                const oldInfos = newMap.get(className);
                const newInfos = { ...oldInfos, ...updates };
                newMap.set(className, newInfos);
                return { ...store, classesAndInfos: newMap };
            });
        },
        updateSelectedClassroomForEveryClass(classroomNumber) {
            set((store) => {
                const oldMap = store.classesAndInfos;
                const newMap = new Map(oldMap);
                for (const [className, infos] of newMap) {
                    const selectedClassroom = infos.allClassrooms.find((o) => {
                        return o.number === classroomNumber;
                    });
                    newMap.set(className, { ...infos, selectedClassroom });
                }
                return { ...store, classesAndInfos: newMap };
            });
        }
    }));
}
