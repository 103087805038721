import { teacherTheme } from '@campus/components';
import { BorderStyle, HeightRule, Paragraph, Table, TableCell, TableRow, WidthType } from 'docx';
import { margins } from '../../../../../common/sizesDocx';
import { emptyTableCellBorders } from '../../../../../common/borderDocx';
export const columnBorder = {
    size: 2,
    style: BorderStyle.SINGLE,
    color: teacherTheme.colors.gray300
};
export function createColumnPadding(width) {
    return new Table({
        columnWidths: [width],
        width: { size: width, type: WidthType.PERCENTAGE },
        rows: [
            new TableRow({
                height: {
                    value: margins.table.body.columns.top,
                    rule: HeightRule.EXACT
                },
                children: [
                    new TableCell({
                        children: [new Paragraph({})],
                        borders: emptyTableCellBorders,
                        width: { size: width, type: WidthType.PERCENTAGE }
                    })
                ]
            })
        ]
    });
}
