import styled from 'styled-components';
export const StyledWrapper = styled.div ``;
export const StyledTitle = styled.h6 `
  margin: 0;

  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;

  color: ${(props) => props.theme.colors.gray300};
`;
export const StyledSelectsWrapper = styled.div `
  display: flex;
  column-gap: 10px;

  padding-top: 4px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;

    column-gap: 0;
    row-gap: 3px;
  }

  @media (max-width: 450px) {
    padding: 0;
  }
`;
export const StyledSelectWithLabelWrapper = styled.div `
  display: flex;
  align-items: center;
  column-gap: 11px;
`;
export const StyledSelectLabel = styled.span `
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;

  color: ${(props) => props.theme.colors.gray3};
`;
