import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const Button = styled.button `
  padding: 0;
  border: none;
  background: none;

  :not(:disabled) {
    cursor: pointer;
  }
`;
export const Icon = styled(CampusIcon) `
  display: block;
`;
