import styled from 'styled-components';
export const StyledBasicButton = styled.button `
  height: 32px;

  white-space: nowrap;

  font-size: 14px;
  font-weight: 600;
  font-style: normal;

  border-width: 1px;
  border-radius: 6px;
  border-style: solid;

  &:not(:disabled):hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.7;
  }
`;
