import React from 'react';
import { View } from '@react-pdf/renderer';
import Header from './components/Header';
import Body from './components/Body';
import styles from './styles';
const Table = (props) => {
    return (React.createElement(View, { style: styles.wrapper },
        React.createElement(Header, { columns: props.headers }),
        React.createElement(Body, { rows: props.rows })));
};
export default Table;
