import { StyleSheet } from '@react-pdf/renderer';
import commonStyles from '../commonStyles';
import { fontSizes, paddings } from '../../../../../../../sizes';
const styles = StyleSheet.create({
    wrapper: {
        ...commonStyles,
        paddingTop: paddings.table.columns.discipline.top,
        paddingBottom: paddings.table.columns.discipline.bottom,
        paddingLeft: paddings.table.columns.discipline.left,
        paddingRight: paddings.table.columns.discipline.right
    },
    discipline: {
        fontWeight: 700,
        lineHeight: '130%',
        fontSize: fontSizes.table.columnContent
    }
});
export default styles;
