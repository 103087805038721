import { useWeeklyPlanningPageContext } from '../../../../../../../../../../context';
export function useDisable(props) {
    const { getMainTableContent, userPermissions } = useWeeklyPlanningPageContext((store) => ({
        getMainTableContent: store.mainTable.getFlatPlannings,
        userPermissions: store.commonData.userPermissions.data
    }));
    const allPlannings = getMainTableContent();
    const isTheOnlyPlanningOnTable = allPlannings.length === 1 && allPlannings[0].id === props.planning.id;
    const isPlanningDeleted = props.planning.status?.isDeleted() ?? false;
    const isPlanningFinished = props.planning.status?.isFinished() ?? false;
    const isPlanningFinishedOrDeleted = isPlanningFinished || isPlanningDeleted;
    const userNotAllowedToUpdate = !userPermissions?.userCan.updateTheTeacherColumns();
    const disableRemove = userNotAllowedToUpdate || isPlanningDeleted;
    const disableDuplicateAndAddToBlock = userNotAllowedToUpdate || isPlanningFinishedOrDeleted;
    const disableMoveAndSeparate = userNotAllowedToUpdate ||
        isPlanningFinishedOrDeleted ||
        isTheOnlyPlanningOnTable;
    return {
        remove: disableRemove,
        move: disableMoveAndSeparate,
        separate: disableMoveAndSeparate,
        duplicate: disableDuplicateAndAddToBlock,
        addToBlock: disableDuplicateAndAddToBlock
    };
}
