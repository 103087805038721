export function isStageInAreaSkillStages(stageId, skill) {
    if (!skill)
        return false;
    const { unitStages, programStages } = skill;
    const stages = unitStages?.length > 0 ? unitStages : programStages;
    return stages?.some((s) => s?.stage?.id === stageId);
}
export function getStagesWithRelatedAreaSkills(stages, mainTableContent) {
    return stages.filter((s) => {
        return mainTableContent.some((g) => {
            return g.plannings.some((p) => {
                return isStageInAreaSkillStages(s.id, p.areaSkill);
            });
        });
    });
}
