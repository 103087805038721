import styled from 'styled-components';
import { Card } from '@campus/components';

export const Content = styled.div`
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  max-width: 600px;
  margin: 0 auto;
`;

export const RowContent = styled.div<{ $marginTop?: number }>`
  margin-top: ${({ $marginTop }) => ($marginTop ? `${$marginTop}px` : 0)} ;

  h1 {
    color: ${(props) => props.theme.colors.gray2};
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: 20px;
    margin: 0;
    padding-bottom: 2px;
    line-height: 22px;
  }

  h2 {
    color: ${(props) => props.theme.colors.gray600};
    font-weight: ${(props) => props.theme.weight.regular};
    font-size: 14px;
    margin: 0;
  }
`;

export const CardContainer = styled.div`
  margin: 1rem 0 0.438rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
`;

export const StyledCard = styled(Card)<{ $height?: number }>`
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 13px;
  text-align: center;
  color: ${(props) => props.theme.colors.gray600};
  ${(props) => (props.$height ? `height: ${props.$height}px` : '')};

  h1 {
    font-weight: ${(props) => props.theme.weight.semiBold};
    font-size: 14px;
    padding-bottom: 2px;
  }

  h2 {
    color: ${(props) => props.theme.colors.blue1};
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: 20px;
    padding-bottom: 2px;
  }

  p {
    font-weight: ${(props) => props.theme.weight.regular};
    font-size: 12px;
    margin: 0;
    line-height: normal;
  }
`;

export const MarginCardBanner = styled.div`
  margin-bottom: 24px;
`;

export const MessageNotInsigts = styled.div`
  margin-top: 13px;
`;

export const InfoText = styled.p`
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  color: ${(props) => props.theme.colors.gray600};
`;

export const Question = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 0.5rem;
  border-radius: 4px;

  p {
    color: ${(props) => props.theme.colors.gray600};
    font-weight: ${(props) => props.theme.weight.regular};
    font-size: 14px;
    margin: 0;
    text-align: left;
  }

  span {
    color: ${(props) => props.theme.colors.gray400};
    font-weight: ${(props) => props.theme.weight.regular};
    font-size: 12px;
    margin: 0;
  }
`;

export const BarChartContainer = styled.div`
  display: flex;
  width: 100%;
`;
export const BarChartContainerTitles = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-top: 8px;
  align-items: center;
  justify-content: flex-start;
`;

export const BarChartTitles = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #666666;
  margin: 0px 9px 0px 5px;
`;

export const BarChartTitleAverage = styled(BarChartTitles)`
  margin: 0px
`

export const BarChartContainerAverage = styled(BarChartContainerTitles)`
  width: 20%;
  justify-content: flex-end;
`;

export const BarChartRangeColor = styled.div<{ $range?: number }>`
  width: 10px;
  height: 10px;
  background: ${({ $range, theme }) => handleColorType($range, theme)};
  border-radius: 2px;
`;

const handleColorType = (range, theme) => {
  switch (range) {
    case 1:
      return `linear-gradient(315deg, ${theme.colors.darkPink} 0%, #FF6A00 100%)`;

    case 2:
      return `linear-gradient(315deg, #FEB942 0%, #FFCD00 100%)`;

    case 3:
      return `linear-gradient(315deg, ${theme.colors.CSA.cyan} 0%, ${theme.colors.CSA.darkBlue} 100%)`;

    case 4:
      return `linear-gradient(315deg, ${theme.colors.CSA.cyan} 0%, ${theme.colors.CSA.green} 100%)`;
  }
};

type BarProps = {
  $percentage?: number;
  $correct?: boolean;
  $alignRight?: boolean;
  $isOpenQuestion?: boolean;
  $borderRadius?: string;
  $range?: number;
};

export const Container = styled.div<BarProps>`
  position: relative;

  width: ${({ $percentage }) => $percentage}%;
  min-width: ${({ $percentage }) => $percentage > 0 && '5%'};
  height: 26px;
`;

export const ChartBarContainer = styled(Container)`
  width: 100%;
  display: flex;
  gap: 2px;
`;

export const ChartBar = styled.div<BarProps>`
  background: ${({ theme, $correct }) =>
    $correct
      ? `linear-gradient(313.31deg, ${theme.colors.CSA.green} 0%, ${theme.colors.CSA.cyan} 100%)`
      : theme.colors.gray5};

  width: ${({ $percentage }) => $percentage ?? 100}%;
  height: 100%;
  ${({ $borderRadius }) => ($borderRadius ? `border-radius: ${$borderRadius};` : '')}

  p {
    position: absolute;

    height: 100%;
    width: 100%;
    padding: 0 8px;
    margin: 0px;

    display: flex;
    justify-content: ${({ $alignRight }) => $alignRight && 'flex-end'};
    align-items: center;
    text-align: start;

    color: ${({ theme, $correct }) =>
      $correct ? '#ffffff' : theme.colors.gray3} !important;

    font-weight: ${(props) => props.theme.weight.bold};
    font-size: 14px;
  }
`;

export const ChartBarQuestionOpen = styled(ChartBar)`
  background: ${({ $range, theme }) => handleColorType($range, theme)};
`;
