import React from 'react';
import Column from './Column';
const GradeColumn = (props) => {
    function createItems() {
        return props.grades
            ?.sort((a, b) => a?.name?.localeCompare(b?.name))
            ?.map((g) => {
            return { label: g.name, value: g.id };
        });
    }
    function handleItemSelected(item) {
        props.onSelectedGradeChange(item.value);
    }
    return props.selectedSegment ? (React.createElement(Column, { items: createItems(), onChange: handleItemSelected, labelOfDefaultSelectedItem: props.selectedGrade?.name })) : null;
};
export default GradeColumn;
