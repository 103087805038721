import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Classroom } from '@campus/commons';
import { useContent } from '@campus/discipline';

import StudentAnswerReport from 'models/StudentAnswerReport';
import AnswerQuestion from 'models/AnswerQuestion';
import { getContentAnswer } from 'services/answers';

type ActivityReportProps = {
  classId: string;
  loading: boolean;
  classFilter: Classroom;
  classrooms: Classroom[];
  students: StudentAnswerReport[];
  questions: AnswerQuestion[];
  questionWeight: number;
  selectedStudent: StudentAnswerReport;
  refetchAnswer: () => void;
  viewStudent: (row: any) => void;
  updateStudents: (students: StudentAnswerReport[]) => void;
  changeClassroom: (classId: string) => void;
  changeStatusFilter: (statusFilter: Array<string>) => void;
  uniqueStatuses: Array<string>;
};

const ActivityReportContext = React.createContext<ActivityReportProps>(null);

export const useActivityReport = () => useContext(ActivityReportContext);

export const ActivityReportProvider: React.FC = ({ children }) => {
  const { contentId } = useContent();

  const [classId, setClassId] = useState('');

  const [loading, setLoading] = useState(true);

  const [students, setStudents] = useState<StudentAnswerReport[]>([]);
  const [questions, setQuestions] = useState<AnswerQuestion[]>([]);

  const [allStudents, setAllStudents] = useState<StudentAnswerReport[]>([]);
  const [student, setStudent] = useState<StudentAnswerReport>();
  const [questionWeight, setQuestionWeight] = useState(null);

  const [classFilter, setClassFilter] = useState<Classroom>();
  const [classrooms, setClassrooms] = useState<Classroom[]>([]);

  const [uniqueStatuses, setUniqueStatuses] = useState([]);

  const fetchAnswer = useCallback(
    async (classroomId: string) => {
      setLoading(true);

      if (contentId) {
        const contentAnswer = await getContentAnswer(contentId, classroomId);
        const data = contentAnswer.data;
        if (data) {
          setStudents(data.students);
          setAllStudents(data.students);
          setQuestions(data.questions);
          setQuestionWeight(data.totalWeight);
          setClassrooms(data.classrooms);

          const status = data.students.map((value) => value.state);
          setUniqueStatuses([...new Set(status)]);

          const selectedClass = data.classrooms.find(
            (c) => c.id === classroomId
          );

          setClassFilter(selectedClass);
        }
      }

      setLoading(false);
    },
    [contentId]
  );

  useEffect(() => {
    fetchAnswer('');
    setClassId('');
  }, [fetchAnswer]);

  const viewStudent = (row: any) => {
    const studentId = row?.student?.id;
    setStudent((p) => {
      const student = students.find((s) => s.student.id === studentId);
      return p?.student?.id !== studentId ? student : null;
    });
  };

  const updateStudents = (updatedStudents: StudentAnswerReport[]) => {
    if (updatedStudents.length === 1) {
      setStudent((s) => {
        const selectedStudent = updatedStudents.find((u) => u.id === s?.id);

        if (selectedStudent) {
          return s.mergeStudent(selectedStudent);
        }

        return null;
      });
    }

    setStudents((prev) =>
      prev.map((p) => {
        const updatedStudent = updatedStudents.find((s) => p.id === s.id);
        return p.mergeStudent(updatedStudent);
      })
    );

    changeStatus();
  };

  const changeClassroom = (classId: string) => {
    setClassId(classId);
    fetchAnswer(classId);
  };

  const changeStatus = () => {
    const status = students.map((value) => value.state);
    setUniqueStatuses([...new Set(status)]);
  };

  const changeStatusFilter = (statusFilter: Array<string>) => {
    setStudents(
      allStudents.filter((value) => {
        return statusFilter.includes(value.state);
      })
    );
  };

  const refetchAnswer = () => fetchAnswer(classFilter?.id);

  return (
    <ActivityReportContext.Provider
      value={{
        classId,
        loading,
        students,
        classrooms,
        classFilter,
        questions,
        questionWeight,
        changeStatusFilter,
        viewStudent,
        updateStudents,
        changeClassroom,
        selectedStudent: student,
        refetchAnswer,
        uniqueStatuses
      }}
    >
      {children}
    </ActivityReportContext.Provider>
  );
};
