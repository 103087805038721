import React from 'react';
import Column from './Column';
const SegmentColumn = (props) => {
    function createItems() {
        return props.segments
            ?.sort((a, b) => a?.name?.localeCompare(b?.name))
            ?.map((s) => {
            return { label: s.name, value: s.id };
        });
    }
    function handleItemSelected(item) {
        props.onSelectedSegmentChange(item.value);
    }
    return (React.createElement(Column, { items: createItems(), onChange: handleItemSelected, labelOfDefaultSelectedItem: props.selectedSegment?.name }));
};
export default SegmentColumn;
