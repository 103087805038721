import React from 'react';
import { View } from '@react-pdf/renderer';
import Column from './components/Column';
import styles from './styles';
const Row = (props) => {
    function renderColumns() {
        return props.columns.map((column, index) => (React.createElement(Column, { key: column.label, instance: column, isTheFirstColumn: index === 0, isInTheLastRow: props.isTheLastRow, isInTheFirstRow: props.isTheFirstRow, isTheLastColumn: index === props.columns.length - 1 })));
    }
    return React.createElement(View, { style: styles.wrapper }, renderColumns());
};
export default Row;
