import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const StyledColumnWrapper = styled.div `
  padding-right: 10px;

  border-right: 1px solid ${(props) => props.theme.colors.gray5};
`;
export const StyledContentWrapper = styled.div `
  display: flex;
  row-gap: 3px;
  flex-direction: column;
`;
export const StyledItemWrapper = styled.div `
  height: 24px;

  width: 100%;
  min-width: 200px;

  padding: 0 12px;

  border-radius: 4px;

  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }

  ${(props) => props.$isSelected ? `background-color: ${props.theme.colors.gray50};` : ''}
`;
export const StyledItemLabel = styled.span `
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  white-space: nowrap;

  color: ${(props) => props.theme.colors.gray3};
`;
export const StyledArrowIcon = styled(CampusIcon).attrs({
    name: 'miniArrowRight'
}) `
  width: auto;
  height: 9px;
`;
export const StyledNoContentMessageWrapper = styled.div `
  width: 100%;
`;
export const StyledNoContentMessage = styled.i `
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;

  color: ${(props) => props.theme.colors.gray3};
`;
