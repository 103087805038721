var UserCan;
(function (UserCan) {
    UserCan["NotUpdateAnything"] = "NotUpdateAnything";
    UserCan["UpdateOnlyTheManagerColumns"] = "UpdateOnlyTheManagerColumns";
    UserCan["UpdateAllColumnsExceptTheManagerOnes"] = "UpdateAllColumnsExceptTheManagerOnes";
})(UserCan || (UserCan = {}));
class WhatUserCanDo {
    userCan;
    constructor(userCan) {
        this.userCan = userCan;
    }
    updateTheManagerColumns() {
        return this.userCan === UserCan.UpdateOnlyTheManagerColumns;
    }
    updateTheTeacherColumns() {
        return this.userCan === UserCan.UpdateAllColumnsExceptTheManagerOnes;
    }
}
export default class UserPermissions {
    userCan;
    static fromJson(json) {
        if (!json)
            return null;
        const instance = new UserPermissions();
        instance.userCan = new WhatUserCanDo(json.UserCan);
        return instance;
    }
}
