import React from 'react';
import FilterSkill from './components/FilterSkill';
import FilterDate from './components/FilterDate';
import FilterClass from './components/FilterClass';
import { Container } from './styles';
const FiltersAndSelectors = () => {
    return (React.createElement(Container, null,
        React.createElement(FilterSkill, null),
        React.createElement(FilterDate, null),
        React.createElement(FilterClass, null)));
};
export default FiltersAndSelectors;
