import React from 'react';
import { RequestStatus } from '@campus/commons';
import { NoTableContent as NoContent } from '../../../NoTableContent';
export const NoTableContent = ({ requestStatus }) => {
    let text = null;
    switch (requestStatus) {
        case RequestStatus.Loading: {
            text = 'Carregando lista de aulas...';
            break;
        }
        case RequestStatus.Failed: {
            text = 'Falha ao carregar lista de aulas';
            break;
        }
        default:
            break;
    }
    return text ? React.createElement(NoContent, { text: text }) : null;
};
