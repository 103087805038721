import WeeksGenerator from './weeksGenerator';
export default class CalendarGenerator {
    weeksGenerator;
    constructor() {
        const currentYear = new Date().getFullYear();
        this.weeksGenerator = new WeeksGenerator(currentYear);
    }
    generate() {
        const months = [];
        for (let month = 0; month < 12; month++) {
            const weeks = this.weeksGenerator.generate(month);
            months.push({ number: month, weeks });
        }
        return months;
    }
}
