import { useCallback, useEffect } from 'react';
import { useFetch, usePagination } from '@campus/commons';
import { toast } from '@campus/components';
import { getEmptyTeacherClasses } from '../../../../../../services/launchReport';
import { useLaunchReportContext } from '../../../../context';
const pageSize = 10;
export function useFetchTableContent({ teacherId, filters }) {
    const { managerId } = useLaunchReportContext();
    const { data, requestStatus, fetchStarted, handleFailure, handleSuccess } = useFetch({
        initialData: null
    });
    const { pageIndex, setPageIndex, numberOfPages, setNumberOfPages, switchPageIndexToThePreviuosOne } = usePagination({ pageSize });
    const fetchContent = useCallback(async () => {
        try {
            fetchStarted();
            const response = await getEmptyTeacherClasses({
                ...filters,
                managerId,
                teacherId,
                pageSize,
                pageIndex
            });
            setNumberOfPages(response.data.numberOfPages);
            handleSuccess(response.data.disciplines);
        }
        catch (error) {
            handleFailure(error);
            toast.error('Falha ao buscar lista de aulas vazias');
            switchPageIndexToThePreviuosOne();
        }
    }, [
        managerId,
        teacherId,
        filters,
        pageIndex,
        fetchStarted,
        handleSuccess,
        handleFailure,
        switchPageIndexToThePreviuosOne
    ]);
    useEffect(() => {
        fetchContent();
    }, [fetchContent]);
    return { data, requestStatus, pageIndex, setPageIndex, numberOfPages };
}
