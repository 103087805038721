import React from 'react';
import Table from './components/Table';
import Filters from './components/Filters';
import DisciplineToAddSkillOn from './components/DisciplineToAddSkillOn';
import { StyledWrapper } from './styles';
import { StyledLineBehindFilters } from '../common/styles';
const OtherSkillsTab = () => {
    return (React.createElement(StyledWrapper, null,
        React.createElement(Filters, null),
        React.createElement(StyledLineBehindFilters, null),
        React.createElement(DisciplineToAddSkillOn, null),
        React.createElement(Table, null)));
};
export default OtherSkillsTab;
